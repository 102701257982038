import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { QueryKey } from './ApiQueries'
import { toast } from 'react-toastify'
import ApiErrors, { ErrorType } from './ApiErrors'

const ApiMutations = {
  // Because this is using the useQueryClient hook, this object must be
  // constructed from directly inside a react component
  constructMutation: <T>(
    fn: MutationFunction<T>,
    queriesToInvalidate: QueryKey[] = [],
    opts: ConstructMutationOptions = {
      successMessage: null,
    }
  ): UseMutationResult => {
    const queryClient = useQueryClient()

    return useMutation({
      mutationFn: async (params) => {
        try {
          const response = await fn(params)
          opts.successMessage && toast.success(opts.successMessage)
          return response
        } catch (error: ErrorType) {
          toast.error(ApiErrors.getErrorMessage(error))
          return error
        }
      },
      onSuccess: () => {
        queriesToInvalidate.forEach((query) => {
          queryClient.invalidateQueries({ queryKey: query })
        })
      }
    })
  },

  invalidateQueries: (queriesToInvalidate: QueryKey[] = []): void => {
    const queryClient = useQueryClient()

    queriesToInvalidate.forEach((query) => {
      queryClient.invalidateQueries({ queryKey: query })
    })
  },

  // Because this is using the useQueryClient hook, this object must be
  // constructed from directly inside a react component
  updateInfiniteQueryData: (queryKeys: QueryKey[] = []): UpdateInfiniteQueryData => {
    const queryClient = useQueryClient()

    return (newRecord) => {
      queryKeys.forEach((queryKey) => {
        queryClient.setQueriesData(queryKey, (previous: PreviousData = { pages: []}) => (
          {
            ...previous,
            pages: previous.pages.map((page) => (
              {
                ...page,
                results: page.results?.map((result: Result) => (
                  result.id === newRecord.id
                    ? newRecord
                    : result
                ))
              }
            ))
          }
        ))
      })
    }
  },

  addToInfiniteQueryData: (queryKeys: QueryKey[] = []): UpdateInfiniteQueryData => {
    const queryClient = useQueryClient()

    return (newRecord) => {
      queryKeys.forEach((queryKey) => {
        queryClient.setQueriesData(queryKey, (previous: PreviousData = { pages: []}) => {
          // console.log(previous)

          return (
            {
              ...previous,
              pages: previous.pages.map((page) => (
                {
                  ...page,
                  results: page.results?.map((result: Result) => (
                    result.id === newRecord.id
                      ? newRecord
                      : result
                  ))
                }
              ))
            }
          )
        })
      })
    }
  },
}

export type MutationFunction<T> = (params: any) => Promise<T>

export type ConstructMutationOptions = {
  successMessage?: string | null;
}

export type ConstructMutationResult<T> = {
  mutate: (params: any) => Promise<T>
}

type UpdateInfiniteQueryData = (newRecord: any) => void;

type Page = { results: any; }
type PreviousData = { pages: Page[]; } | undefined;

type Result = {
  id: number
  [key: string]: any
};

export default ApiMutations
