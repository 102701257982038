import React from 'react'
import { NavLink } from 'react-router-dom'

import styles from './styles.mod.scss'

const MyStuffUtilMenu = () => (
  <div className="MyStuffUtilMenu">
    <NavLink to="/my/favorites" className={styles.link}>
      Favorites
    </NavLink>
    <NavLink to="/my/friends" className={styles.link}>
      Friends
    </NavLink>
    <NavLink to="/my/rivals" className={styles.link}>
      Rivals
    </NavLink>
    <NavLink to="/my/leagues" className={styles.link}>
      Leagues
    </NavLink>
  </div>
)

export default MyStuffUtilMenu
