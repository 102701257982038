import { ApiRequest, ApiConfig } from '..'
import { CreateParams, DestroyParams, PaginatedIndexParams, PaginatedSearchParams, ShowParams, UpdateParams } from '../ApiRequest'

const CategoriesResource = {
  getAdminIndex: ({ page }: PaginatedIndexParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl('admin/categories'), { params: { page } })
  ),
  getIndex: ({ page }: PaginatedIndexParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl('categories'), { params: { page } })
  ),
  getSearch: ({ page, query }: PaginatedSearchParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl('categories/search'), { params: { page, query } })
  ),
  getShow: ({ id }: ShowParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl(`categories/${id}`))
  ),
  create: ({ data: category }: CreateParams) => (
    ApiRequest.privatePost(ApiConfig.apiUrl('categories'), { category })
  ),
  update: ({ id, data: category }: UpdateParams) => (
    ApiRequest.privatePut(ApiConfig.apiUrl(`categories/${id}`), { category })
  ),
  delete: ({ id }: DestroyParams) => (
    ApiRequest.privateDelete(ApiConfig.apiUrl(`categories/${id}`))
  ),
}

export default CategoriesResource
