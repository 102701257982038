import SubmissionsResource from '../resources/Submissions'
import { ApiQueries } from '..'
import { QueryParamsObject } from '../ApiQueries'

const SubmissionsQuery = {
  getAdminIndex: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Submissions', 'getAdminIndex', ApiQueries.queryParams(params)],
    queryFn: SubmissionsResource.getAdminIndex,
    params,
  }),
  getSearch: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Submissions', 'getSearch', ApiQueries.queryParams(params)],
    queryFn: SubmissionsResource.getSearch,
    params,
  }),
  getShow: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Submissions', 'getShow', ApiQueries.queryParams(params)],
    queryFn: SubmissionsResource.getShow,
    params,
  }),
  getInfiniteFavorites: (params: QueryParamsObject) => ApiQueries.constructInfiniteQuery({
    queryKey: ['Submissions', 'getInfiniteFavorites', ApiQueries.queryParams(params)],
    queryFn: SubmissionsResource.getFavorites,
    params,
  }),
  getInfiniteIndex: (params: QueryParamsObject) => ApiQueries.constructInfiniteQuery({
    queryKey: ['Submissions', 'getInfiniteIndex', ApiQueries.queryParams(params)],
    queryFn: SubmissionsResource.getIndex,
    params,
  }),
  getInfiniteComments: (params: QueryParamsObject) => ApiQueries.constructInfiniteQuery({
    queryKey: ['Submissions', 'getInfiniteComments', ApiQueries.queryParams(params)],
    queryFn: SubmissionsResource.getComments,
    params,
  }),
}

export default SubmissionsQuery
