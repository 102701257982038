import RelationshipsResource from '../resources/Relationships'
import { ApiQueries } from '..'
import { QueryParamsObject } from '../ApiQueries'

const RelationshipsQuery = {
  getFriends: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Messages', 'getFriends', ApiQueries.queryParams(params)],
    queryFn: RelationshipsResource.getFriends,
    params,
  }),
  getRivals: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Relationships', 'getRivals', ApiQueries.queryParams(params)],
    queryFn: RelationshipsResource.getRivals,
    params,
  }),
}

export default RelationshipsQuery
