import { ApiRequest, ApiConfig } from '..'
import { PaginatedIndexParams } from '../ApiRequest'

const NotificationsResource = {
  getIndex: ({ page }: PaginatedIndexParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl('notifications'), { params: { page } })
  )
}

export default NotificationsResource
