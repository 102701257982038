import React, {
    cloneElement, createContext, forwardRef, PropsWithChildren, useContext, useMemo, useState
} from 'react'

import {
    autoUpdate, flip, FloatingFocusManager, offset, safePolygon, shift, useDismiss, useFloating,
    useHover, useInteractions, useMergeRefs, useRole, useTransitionStyles, UseTransitionStylesProps
} from '@floating-ui/react'

import styles from './styles.mod.scss'
import classNames from 'classnames'

const TooltipContext = createContext<TooltipContextValue>(null)

const TooltipProvider = ({
  children,
  placement = 'top',
  transitions,
}: TooltipProviderProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const data = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      flip({
        fallbackAxisSideDirection: 'start'
      }),
      shift()
    ]
  })
  const { context } = data
  const getMarginOffset = {
    top: { marginTop: '10px' },
    bottom: { marginTop: '-10px' },
    left: { marginLeft: '10px' },
    right: { marginLeft: '-10px' },
  }
  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    duration: {
      open: 200,
      close: 200,
    },
    initial: ({ side }) => ({
      opacity: 0,
      ...getMarginOffset[side],
    }),
    ...transitions,
  })

  const hover = useHover(context, {
    handleClose: safePolygon({
      requireIntent: true,
      buffer: 10,
      // blockPointerEvents: true,
    }),
  })
  // const hover = useHover(context, { move: false })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'tooltip' })

  const interactions = useInteractions([hover, dismiss, role])

  const value = useMemo(() => ({
    ...data,
    ...interactions,
    isMounted,
    transitionStyles,
  }), [data, interactions, isMounted, transitionStyles])

  return (
    <TooltipContext.Provider value={value}>
      {children}
    </TooltipContext.Provider>
  )
}

export const TooltipContent = forwardRef((props: PropsWithChildren, propRef) => {
  const {
    context,
    getFloatingProps,
    isMounted,
    refs,
    transitionStyles,
    floatingStyles,
  } = useTooltip()
  const { children } = props
  const ref = useMergeRefs([refs.setFloating, propRef])

  if (!isMounted) return null

  return (
    <FloatingFocusManager context={context}>
      <div
        // className="TooltipContent"
        className={classNames('TooltipContent', styles.content)}
        ref={ref}
        style={{ ...floatingStyles, ...transitionStyles }}
        {...getFloatingProps()}
      >
        {children}
      </div>
    </FloatingFocusManager>
  )
})

export const TooltipTrigger = forwardRef((props: PropsWithChildren, propRef) => {
  const { children } = props

  const { getReferenceProps, refs } = useTooltip()
  const childrenRef = children.ref

  const ref = useMergeRefs([
    refs.setReference,
    propRef,
    childrenRef,
  ])

  return cloneElement(
    children,
    getReferenceProps({
      ref,
      ...props,
      ...children.props,
    })
  )
})

export const Tooltip  = ({
  children,
  content,
  ...props
}: TooltipProps) => (
  <TooltipProvider {...props}>
    <TooltipTrigger>
      {children}
    </TooltipTrigger>
    <TooltipContent>{content}</TooltipContent>
  </TooltipProvider>
)

type TooltipProps = {
  children: React.ReactNode,
  content: string,
}

type TooltipProviderProps = {
  children: React.ReactNode,
  placement: 'top' | 'bottom' | 'left' | 'right',
  transitions: UseTransitionStylesProps,
}

type TooltipContextValue = {
  context: any
  getFloatingProps: () => any
  isMounted: boolean
  getReferenceProps: (props: any) => any
  refs: {
    setFloating: React.Ref<HTMLElement>
    setReference: React.Ref<HTMLElement>
  }
  transitionStyles: Record<string, any>
  floatingStyles: React.CSSProperties
} | null

export default Tooltip

export const useTooltip = (): TooltipContextValue => useContext(TooltipContext)
