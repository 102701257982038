import React from 'react'
import { Link } from 'react-router-dom'
import { leagueDefault, leagueType } from '../../../../../schemas/types/league'

import styles from './styles.mod.scss'

const SubmissionLeague = ({
  league = leagueDefault,
}: SubmissionLeagueProps) => {
  // Since a submission can be created without a league, we need to check for
  // the existence of the league before we try to render it.
  if (!league) return <div />

  const { id, name } = league

  return (
    <Link
      aria-label={`Go to ${name} league page`}
      className={styles.league}
      to={`/leagues/${id}`}
    >
      {name}
    </Link>
  )
}

type SubmissionLeagueProps = {
  league: leagueType,
}

export default SubmissionLeague
