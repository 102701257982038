// src/components/cards/submissions/CardAdmin/index.jsx
import React, { useMemo } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import SubmissionsMutation from '../../../../api/mutations/Submissions'

import CardAdmin, { ActionsCell, CardCell } from '../../CardAdmin'
import ChallengeBadge from '../../../badges/ChallengeBadge'
import ChallengeRewards from '../../../ChallengeRewards'
import SubmissionTime from '../components/SubmissionTime'
import SubmissionTitle from '../components/SubmissionTitle'
import SubmissionUser from '../components/SubmissionUser'
import { submissionDefault, submissionType } from '../../../../schemas/types/submission'

import AcceptIconLight from '../../../../assets/images/icons/thumbs-up-light.svg'
import AcceptIconSolid from '../../../../assets/images/icons/thumbs-up-solid.svg'
import RejectIconLight from '../../../../assets/images/icons/thumbs-down-light.svg'
import RejectIconSolid from '../../../../assets/images/icons/thumbs-down-solid.svg'

import styles from './styles.mod.scss'

const ApprovalButton = ({
  ActiveIcon,
  InactiveIcon,
  className,
  isActive,
  onClick,
}: ApprovalButtonProps) => (
  <button
    className={
      classNames(
        styles.approval,
        className,
        { [styles.active]: isActive }
      )
    }
    aria-label="Approve submission"
    onClick={onClick}
  >
    {isActive ? <ActiveIcon /> : <InactiveIcon />}
  </button>
)

const SubmissionCardAdmin = ({
  submission = submissionDefault,
}: SubmissionCardAdminProps) => {
  const { challenge, id, status } = submission

  const acceptSubmission = SubmissionsMutation.accept({ id })
  const rejectSubmission = SubmissionsMutation.reject({ id })

  const ApproveButton = () => useMemo(() => (
    <ApprovalButton
      ActiveIcon={AcceptIconSolid}
      InactiveIcon={AcceptIconLight}
      className={styles.accept}
      isActive={status === 'accepted'}
      onClick={() => acceptSubmission.mutate({ id })}
    />
  ), [status])

  const RejectButton = () => useMemo(() => (
    <ApprovalButton
      ActiveIcon={RejectIconSolid}
      InactiveIcon={RejectIconLight}
      className={styles.reject}
      isActive={['rejected', 'revoked'].includes(status)}
      onClick={() => rejectSubmission.mutate({ id })}
    />
  ), [status])

  return (
    <CardAdmin>
      <CardCell className={styles.name}>
        <SubmissionTitle isLink submission={submission} />
      </CardCell>
      <CardCell className={styles.challenge}>
        {challenge ? (
          <Link aria-label="Go to challenge page" to={`/challenges/${challenge.id}`}>
            <ChallengeBadge challenge={challenge} />
          </Link>
        ) : null}
      </CardCell>
      <CardCell className={styles.user}>
        <SubmissionUser submission={submission} />
      </CardCell>
      <CardCell className={styles.rewards}>
        {challenge ? (
          <ChallengeRewards challenge={challenge} />
        ) : null}
      </CardCell>
      <CardCell className={styles.submitted_on}>
        <SubmissionTime submission={submission} />
      </CardCell>
      <ActionsCell className={styles.actions}>
        {challenge ? (
          <>
            <ApproveButton />
            <RejectButton />
          </>
        ) : null}
      </ActionsCell>
    </CardAdmin>
  )
}

type ApprovalButtonProps = {
  ActiveIcon: React.FC<React.SVGProps<SVGSVGElement>>,
  InactiveIcon: React.FC<React.SVGProps<SVGSVGElement>>,
  className: string,
  isActive: boolean,
  onClick: () => void,
}

type SubmissionCardAdminProps = {
  submission: submissionType,
}

export default SubmissionCardAdmin
