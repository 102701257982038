import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import PageWrapper from '@/components/layouts/PageWrapper'
import withScrollTop from '@/hocs/withScrollTop'

import styles from './styles.mod.scss'

const HomePage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/explore')
  }, [])

  return (
    <PageWrapper name="HomePage" opts={{ noPadding: true }} title="Home" />
  )
}

export default withScrollTop(HomePage)
