import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import DeleteConfirmationDialog, { DeleteConfirmationTrigger } from '@/components/dialogs/DeleteConfirmationDialog'
import ProgressiveLoadImage from '@/components/ProgressiveLoadImage'
import { cardOptsDefaults, cardOptsType } from '../Card'
import { useAuth } from '@/context/auth'
import { imageSmall } from '@/common/helpers'

import EditIcon from '@/assets/images/icons/pencil-regular.svg'

import styles from './styles.mod.scss'

export const CardCell = ({
  children,
  className = ''
}: CardCellProps) => (
  <div className={classNames(styles.col, className)}>
    {children}
  </div>
)

export const ActionsCell = ({
  children,
  className = ''
}: ActionsCellProps) => (
  <CardCell className={classNames(styles.actions, className)}>
    {children}
  </CardCell>
)

export const DeleteAction = ({
  onConfirm = () => {},
  resource = 'resource',
}: DeleteActionProps) => (
  <DeleteConfirmationDialog
    Trigger={DeleteConfirmationTrigger}
    className={classNames(styles.action, styles.delete)}
    onConfirm={onConfirm}
    resource={resource}
  />
)

export const EditAction = ({ to = '' }: EditActionProps) => (
  <Link
    aria-label="Edit"
    className={classNames(styles.action, styles.edit)}
    to={to}
  >
    <EditIcon />
  </Link>
)

export const LogoCell = ({
  className = '',
  url = ''
}: LogoCellProps) => (
  <div className={classNames(styles.logo, className)}>
    <ProgressiveLoadImage
      alt="logo"
      className={styles.logo}
      img={imageSmall(url)}
      placeholderImgs={[imageSmall(url)]}
      rounded
    />
  </div>
)

export const RewardsCell = ({
  children,
  className = ''
}: RewardsCellProps) => (
  <CardCell className={classNames(styles.rewards, className)}>
    {children}
  </CardCell>
)

const CardAdmin = ({
  children,
  className = '',
  opts = cardOptsDefaults,
}: CardAdminProps) => {
  const { token } = useAuth()

  if (!token) return null

  return (
    <div
      className={classNames(
        styles.wrapper,
        className,
      )}
    >
      {opts.noContainer ? children : (
        <div className={styles.container}>
          {children}
        </div>
      )}
    </div>
  )
}

type ActionsCellProps = {
  children: React.ReactNode
  className?: string
}

type CardAdminProps = {
  children: React.ReactNode
  className?: string
  opts?: cardOptsType
}

type CardCellProps = {
  children: React.ReactNode
  className?: string
}

type DeleteActionProps = {
  onConfirm: () => void
  resource?: string
}

type EditActionProps = {
  to: string
}

type LogoCellProps = {
  className?: string
  url?: string
}

type RewardsCellProps = {
  children: React.ReactNode
  className?: string
}

export default CardAdmin
