import RuleSectionsResource from '../resources/RuleSections'
import { ApiMutations, ApiQueries } from '..'
import { QueryParamsObject } from '../ApiQueries'

const RuleSectionsMutation = {
  create: () => (
    ApiMutations.constructMutation(
      RuleSectionsResource.create,
      [
        ['RuleSections', 'getIndex'],
      ],
      { successMessage: 'Rule section created.' },
    )
  ),
  update: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      RuleSectionsResource.update,
      [
        ['Rules', 'getShow', ApiQueries.queryParams(params)],
      ],
      { successMessage: 'Rule section updated.' },
    )
  ),
  delete: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      RuleSectionsResource.delete,
      [
        ['Rules', 'getShow', ApiQueries.queryParams(params)],
      ],
      { successMessage: 'Rule section deleted.' },
    )
  ),
}

export default RuleSectionsMutation
