import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import ChallengesQuery from '@/api/queries/Challenges'

import ChallengeCard from '@/components/cards/challenges/Card'
import PageContent from '@/components/layouts/PageContent'
import PageWrapper from '@/components/layouts/PageWrapper'
import ScrollableResource from '@/components/ScrollableResource'
import withScrollTop from '@/hocs/withScrollTop'
import { InfiniteScrollProvider } from '@/context/infiniteScroll'
import CategoriesFilterUtilMenu from '@/components/utilityMenu/CategoriesFilter'
import { resetUtilMenu, setUtilMenuContent } from '@/store/utilMenu'

import styles from './styles.mod.scss'

const ScrollableChallenges = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setUtilMenuContent(
      <CategoriesFilterUtilMenu />
    ))

    return () => dispatch(resetUtilMenu())
  }, [])

  return (
    <ScrollableResource
      Component={ChallengeCard}
      className={styles.challenges}
      resourceName="challenge"
    />
  )
}

const ChallengesListPage = () => (
  <PageWrapper name="ChallengesIndex" title="Challenges">
    <PageContent>
      <InfiniteScrollProvider
        cardClassName="ChallengeCard"
        filters={['categories']}
        query={ChallengesQuery.getInfiniteIndex}
      >
        <ScrollableChallenges />
      </InfiniteScrollProvider>
    </PageContent>
  </PageWrapper>
)

export default withScrollTop(ChallengesListPage)
