import React from 'react'
import classNames from 'classnames'
import { Link, useNavigate } from 'react-router-dom'

import HeaderPopover, { HeaderPopoverTrigger } from '../HeaderPopover'
import { useAuth } from '@/context/auth'
import { userDefault } from '@/schemas/types/user'
import UserIcon from '@/assets/images/icons/user-regular.svg'

import styles from './styles.mod.scss'

const UserImg = ({
  avatar
}: UserImgProps) => (
  avatar
    ? <img src={avatar} alt="" />
    : <UserIcon />
)

const SettingsPopoverTrigger = () => {
  const { currentUser } = useAuth()
  const { avatar_url_tiny } = currentUser || userDefault

  return (
    <HeaderPopoverTrigger
      ActiveIcon={<UserImg avatar={avatar_url_tiny} />}
      InactiveIcon={<UserImg avatar={avatar_url_tiny} />}
      name={styles.trigger}
    />
  )
}

const SettingsPopover = () => (
  <HeaderPopover Trigger={SettingsPopoverTrigger}>
    <SettingsPopoverContent />
  </HeaderPopover>
)

const SettingsPopoverContent = () => {
  const { signOut } = useAuth()
  const navigate = useNavigate()

  const logoutClick = () => {
    signOut()
    navigate('/users/login')
  }

  return (
    <div className={styles.content}>
      <div className={styles.items}>
        <div className={styles.section}>
          <h2 className={styles.section_header}>My Stuff</h2>
          <Link aria-label="Favorites" to="/my/favorites">
            <span>Favorites</span>
          </Link>
          <Link aria-label="Friends" to="/my/friends">
            <span>Friends</span>
          </Link>
          <Link aria-label="Rivals" to="/my/rivals">
            <span>Rivals</span>
          </Link>
          <Link aria-label="Leagues" to="/my/leagues">
            <span>Leagues</span>
          </Link>
        </div>
        <div className={styles.section}>
          <h2 className={styles.section_header}>Account</h2>
          <Link aria-label="Profile" to="/account/profile">
            <span>Profile</span>
          </Link>
          <Link aria-label="Password" to="/account/password">
            <span>Password</span>
          </Link>
        </div>
        <div className={styles.section}>
          <h2 className={styles.section_header}>Clout</h2>
          <Link aria-label="Badges" to="/clout/badges">
            <span>Badges</span>
          </Link>
          <Link aria-label="Wallet" to="/clout/wallet">
            <span>Wallet</span>
          </Link>
        </div>
        <div className={classNames(styles.section, styles.section_logout)}>
          <hr />
          <button aria-label="Log out" onClick={logoutClick}>
            <span>Log out</span>
          </button>
        </div>
      </div>
    </div>
  )
}

type UserImgProps = {
  avatar: string
}

export default SettingsPopover
