import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import AdminIndexResource from '@/hocs/AdminIndexResource'
import AdminTable, { TableContent, TableHeader, TableHeaderCol } from '../../Dashboard/AdminTable'
import RuleCardAdmin from '@/components/cards/rules/CardAdmin'
import { ruleType } from '@/schemas/types/rule'
import { setAdminFooterType, setIsAdminFooterEnabled } from '@/store/adminFooter'

import styles from './styles.mod.scss'

const RESOURCE = 'rules'

const AdminRules = ({
  results = [],
}: AdminRulesProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setAdminFooterType('newLink'))
    dispatch(setIsAdminFooterEnabled(true))
  }, [])

  return (
    <AdminTable>
      <TableHeader>
        <TableHeaderCol className={styles.title} name="title" />
        <TableHeaderCol className={styles.content} name="content" />
        <TableHeaderCol className={styles.category} name="category" />
        <TableHeaderCol className={styles.actions} />
      </TableHeader>

      <TableContent>
        {results?.map((rule) => (
          <RuleCardAdmin
            key={`rule-${rule.id}`}
            rule={rule}
          />
        ))}
      </TableContent>
    </AdminTable>
  )
}

type AdminRulesProps = {
  results: Array<ruleType>
}

export default AdminIndexResource({ RESOURCE })(AdminRules)
