import React from 'react'
import classNames from 'classnames'

import styles from './styles.mod.scss'

const verticalAlignDirections = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
}

const Cards = ({
  children,
  className = '',
  tapered = false,
  unStyled = false,
  verticalAlign = 'start',
}: CardsProps) => (
  <div
    className={classNames(
      'Cards',
      className,
      { [styles.tapered]: tapered },
      { [styles.unstyled]: unStyled },
    )}
    style={{ justifyContent: verticalAlignDirections[verticalAlign] }}
  >
    {children}
  </div>
)

type CardsProps = {
  children: React.ReactNode
  className?: string
  tapered?: boolean
  unStyled?: boolean
  verticalAlign?: 'start' | 'center' | 'end'
}

export default Cards
