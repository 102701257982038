import LeaguesResource from '../resources/Leagues'
import { ApiMutations, ApiQueries } from '..'
import { QueryParamsObject } from '../ApiQueries'

const LeaguesMutation = {
  create: () => (
    ApiMutations.constructMutation(
      LeaguesResource.create,
      [
        ['Leagues', 'getAdminIndex'],
        ['Leagues', 'getIndex'],
        ['Leagues', 'getInfiniteIndex'],
      ],
      { successMessage: 'League created.' },
    )
  ),
  update: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      LeaguesResource.update,
      [
        ['Leagues', 'getAdminIndex'],
        ['Leagues', 'getIndex'],
        ['Leagues', 'getInfiniteIndex'],
        ['Leagues', 'getShow', ApiQueries.queryParams(params)],
      ],
      { successMessage: 'League updated.' },
    )
  ),
  delete: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      LeaguesResource.delete,
      [
        ['Leagues', 'getAdminIndex'],
        ['Leagues', 'getIndex'],
        ['Leagues', 'getInfiniteIndex'],
        ['Leagues', 'getShow', ApiQueries.queryParams(params)],
      ],
      { successMessage: 'League deleted.' },
    )
  ),
  join: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      LeaguesResource.join,
      [['Leagues', 'getShow', ApiQueries.queryParams(params)]],
    )
  ),
  leave: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      LeaguesResource.leave,
      [['Leagues', 'getShow', ApiQueries.queryParams(params)]],
    )
  ),
}

export default LeaguesMutation
