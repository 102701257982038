import React from 'react'

import AdminIndexResource from '@/hocs/AdminIndexResource'
import AdminTable, { TableContent, TableHeader, TableHeaderCol } from '../../Dashboard/AdminTable'
import SubmissionCardAdmin from '@/components/cards/submissions/CardAdmin'
import { submissionType } from '@/schemas/types/submission'

import styles from './styles.mod.scss'

const RESOURCE = 'submissions'

const AdminSubmissions = ({
  results = [],
}: AdminSubmissionsProps) => (
  <AdminTable>
    <TableHeader>
      <TableHeaderCol className={styles.name} name="name" />
      <TableHeaderCol className={styles.challenge} name="challenge" />
      <TableHeaderCol className={styles.user} name="user" />
      <TableHeaderCol className={styles.reward} name="reward" />
      <TableHeaderCol className={styles.submitted_on} name="submitted" />
      <TableHeaderCol className={styles.actions} name="approval" />
    </TableHeader>

    <TableContent>
      {results?.map((submission) => (
        <SubmissionCardAdmin
          key={`submission-${submission.id}`}
          submission={submission}
        />
      ))}
    </TableContent>
  </AdminTable>
)

type AdminSubmissionsProps = {
  results: Array<submissionType>
}

export default AdminIndexResource({ RESOURCE })(AdminSubmissions)
