import React from 'react'

import PrivateRoute from '../layouts/private'

import AccountWrapper from '@/pages/wrappers/AccountWrapper'
import CloutWrapper from '@/pages/wrappers/CloutWrapper'
import MyStuffWrapper from '@/pages/wrappers/MyStuffWrapper'

import BadgesPage from '@/pages/Badges'
import ChallengesNewSubmissionPage from '@/pages/challenges/NewSubmission'
import FavoritesListPage from '@/pages/my/Favorites'
import FriendsPage from '@/pages/my/Friends'
import LeaguesMinePage from '@/pages/my/Leagues'
import LeaguesNewPage from '@/pages/leagues/New'
import MessagesListPage from '@/pages/messages/List'
import MessagesShowPage from '@/pages/messages/Show'
import NotificationsListPage from '@/pages/notifications/List'
import ProfilePage from '@/pages/account/Profile'
import RivalsPage from '@/pages/my/Rivals'
import PasswordPage from '@/pages/account/Password'
import SubmissionsNewPage from '@/pages/submissions/New'
import TeamsMinePage from '@/pages/teams/Mine'
import TeamsNewPage from '@/pages/teams/New'
import WalletsPage from '@/pages/Wallet'

const privateRoutes = [
  {
    element: <PrivateRoute />,
    children: [
      {
        path: '/messages/:type',
        element: <MessagesListPage />,
        children: [
          {
            path: ':id',
            element: <MessagesShowPage />,
          },
        ]
      },
      {
        path: '/notifications/:type',
        element: <NotificationsListPage />,
      },
      {
        path: '/teams/new',
        element: <TeamsNewPage />,
      },
      {
        path: '/challenges/:challenge_id/submissions/new',
        element: <ChallengesNewSubmissionPage />,
      },
      {
        path: '/leagues/new',
        element: <LeaguesNewPage />,
      },
      {
        path: '/submissions/new',
        element: <SubmissionsNewPage />,
      },
      {
        path: '/my',
        element: <MyStuffWrapper />,
        children: [
          {
            path: 'leagues',
            element: <LeaguesMinePage />,
          },
          {
            path: 'teams',
            element: <TeamsMinePage />,
          },
          {
            path: 'friends',
            element: <FriendsPage />,
          },
          {
            path: 'rivals',
            element: <RivalsPage />,
          },
          {
            path: 'favorites',
            element: <FavoritesListPage />,
          },
        ],
      },
      {
        path: '/clout',
        element: <CloutWrapper />,
        children: [
          {
            path: 'badges',
            element: <BadgesPage />,
          },
          {
            path: 'wallet',
            element: <WalletsPage />,
          },
        ],
      },
      {
        path: '/account',
        element: <AccountWrapper />,
        children: [
          {
            path: 'profile',
            element: <ProfilePage />,
          },
          {
            path: 'password',
            element: <PasswordPage />,
          },
        ],
      }
    ]
  },
]

export default privateRoutes
