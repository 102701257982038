import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import RitualForm from '@/components/forms/rituals/RitualForm'
import { ritualType } from '@/schemas/types/ritual'
import { setPageTitle } from '@/store/pages'

const AdminRitualsNew = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const afterUpdate = (ritual: ritualType) => {
    navigate(`/admin/rituals/${ritual.id}/edit`)
  }

  useEffect(() => {
    dispatch(setPageTitle('New Ritual'))
  }, [])

  return (
    <RitualForm afterUpdate={afterUpdate} />
  )
}

export default AdminRitualsNew
