import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'

const defaultContext: DeviceObserverContextType = {
  isPhone: false,
}

const DeviceObserver = createContext(defaultContext)

const landscapeHeightMatcher = '(max-device-height: 480px) and (orientation: landscape)'
const portraitWidthMatcher = '(max-width: 812px) and (orientation: portrait)'

export const DeviceObserverProvider = ({ children }: React.PropsWithChildren) => {
  const [isPhone, setIsPhone] = useState(false)

  const resizeObserver = new ResizeObserver((_entries) => {
    // const { height, width } = entries[0].contentRect
    // setIsMobile(width <= smallestMobileBreakpoint)
    const isPhoneLandscape = window.matchMedia(landscapeHeightMatcher).matches
    const isPhonePortrait = window.matchMedia(portraitWidthMatcher).matches

    setIsPhone(isPhoneLandscape || isPhonePortrait)
  })

  useEffect(() => {
    resizeObserver.observe(document.body)

    return () => resizeObserver.unobserve(document.body)
  }, [])

  const value = useMemo(() => ({
    isPhone,
  }), [isPhone])

  return (
    <DeviceObserver.Provider value={value}>
      {children}
    </DeviceObserver.Provider>
  )
}

type DeviceObserverContextType = {
  isPhone: boolean
}

export const useDeviceObserver = () => useContext(DeviceObserver)
