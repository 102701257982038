import React from 'react'
import classNames from 'classnames'

import { imageLarge } from '@/common/helpers'

import styles from './styles.mod.scss'

const ChallengeCardBackground = ({
  children,
  img = undefined,
  isExpanded = false
}: ChallengeCardBackgroundProps) => (
  <div
    className={classNames(
      styles.background,
      { [styles.isExpanded]: isExpanded }
    )}
    style={{ backgroundImage: `url(${imageLarge(img)})` }}
  >
    {children }
  </div>
)

type ChallengeCardBackgroundProps = {
  children?: React.ReactNode
  img?: string
  isExpanded?: boolean
}

export default ChallengeCardBackground
