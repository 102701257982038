import React, { useRef } from 'react'

import ProgressiveLoadImage from '../../../../ProgressiveLoadImage'
import { submissionDefault, submissionType } from '../../../../../schemas/types/submission'

import styles from './styles.mod.scss'

const SubmissionMedia = ({
  fullSize = false,
  submission = submissionDefault,
}: SubmissionMediaProps) => {
  const videoRef = useRef(null)
  const {
    attachment_url,
    attachment_url_large,
    attachment_url_small,
    attachment_url_tiny,
    kind,
  } = submission

  return (
    <div className={styles.base}>
      {kind === 'image' ? (
        <ProgressiveLoadImage
          alt="Submission media"
          className={styles.img}
          color="black"
          img={fullSize ? attachment_url : attachment_url_large}
          placeholderImgs={[attachment_url_tiny, attachment_url_small]}
          withMessage
        />
      ) : (
        <video
          allowFullScreen
          controls
          height="100%"
          onClick={(e) => e.stopPropagation()}
          preload="auto"
          ref={videoRef}
          src={attachment_url}
          width="100%"
        />
      )}
    </div>
  )
}

type SubmissionMediaProps = {
  fullSize: boolean,
  submission: submissionType,
}

export default SubmissionMedia
