import React from 'react'
import classNames from 'classnames'

import VoteButton from '../VoteButton'
import { submissionDefault, submissionType } from '../../../../../schemas/types/submission'

import ArrowDownIcon from '../../../../../assets/images/icons/arrow-alt-down-regular.svg'
import ArrowDownSolidIcon from '../../../../../assets/images/icons/arrow-alt-down-solid.svg'
import ArrowUpIcon from '../../../../../assets/images/icons/arrow-alt-up-regular.svg'
import ArrowUpSolidIcon from '../../../../../assets/images/icons/arrow-alt-up-solid.svg'

import styles from './styles.mod.scss'

const SubmissionVotes = ({
  className = '',
  submission = submissionDefault,
}: SubmissionVotesProps) => (
  <div className={classNames(styles.votes, className)}>
    <VoteButton
      submission={submission}
      kind="upvote"
      voteActiveIcon={<ArrowUpSolidIcon />}
      voteInactiveIcon={<ArrowUpIcon />}
    />
    <div className={styles.vote_count}>{submission.vote_count}</div>
    <VoteButton
      submission={submission}
      kind="downvote"
      voteActiveIcon={<ArrowDownSolidIcon />}
      voteInactiveIcon={<ArrowDownIcon />}
    />
  </div>
)

type SubmissionVotesProps = {
  className: string,
  submission: submissionType,
}

export default SubmissionVotes
