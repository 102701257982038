import React, { useEffect, useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import './react-datepicker-overrides.scss';

import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';

import { ApiConfig } from '@/api';
import CompetitionsMutation from '@/api/mutations/Competitions';
import CategoriesResource from '@/api/resources/Categories';
import { competitionSchema } from '@/schemas/forms/competition';
import { categoryType } from '@/schemas/types/category';
import { competitionDefault, competitionType } from '@/schemas/types/competition';
import { yupResolver } from '@hookform/resolvers/yup';

import FormPrimary, {
    FormButtons, FormFile, FormInput, FormInputs, FormMultiSelect, FormSubmit, FormTextArea
} from '../../FormPrimary';
import styles from './styles.mod.scss';

const CompetitionForm = ({
  competition = competitionDefault,
  afterUpdate = () => {},
}: CompetitionFormProps) => {
  const { id, start_time, logo_url_medium, end_time } = competition

  const createCompetition = CompetitionsMutation.create()
  const updateCompetition = CompetitionsMutation.update({ id })

  // const [categories, setCategories] = useState(competition.categories || [])
  const [endTime, setEndTime] = useState(new Date(end_time || Date()))
  const [logoChanged, setLogoChanged] = useState(false)
  const [startTime, setStartTime] = useState(new Date(start_time || Date()))

  const form = useForm({
    resolver: yupResolver(competitionSchema),
    defaultValues: competition,
  })

  const {
    // formState: { errors },
    setValue,
  } = form

  const onSubmit = async (data: FormData) => {
    const response = id
      ? await updateCompetition.mutateAsync({ id, data })
      : await createCompetition.mutateAsync({ data })

      ApiConfig.successfulResponseCallback(response, () => {
      afterUpdate(response.data)
    })
  }

  // const onCategoriesChange = (cats) => {
  //   setValue('category_ids', cats.map((cat) => cat.id))
  // }

  const onLogoUploadComplete = (data) => {
    setLogoChanged(true)
    setValue('logo', data)
  }

  useEffect(() => {
    document.querySelector('#startTime')?.setAttribute('autocomplete', 'off')
    document.querySelector('#endTime')?.setAttribute('autocomplete', 'off')
  }, [])

  useEffect(() => {
    setValue('end_time', endTime)
    setValue('start_time', startTime)
  }, [endTime, startTime])

  return (
    <FormPrimary
      form={form}
      name={styles.base}
      onSubmit={onSubmit}
    >
      <FormInputs>
        <FormInput name="name" />
        <FormFile
          name="logo"
          currentFile={logo_url_medium}
          onUpload={(data) => onLogoUploadComplete(data)}
        />
        <FormTextArea name="description" />
        {/* <input {...register('categories')} type="hidden" /> */}
        {/* <div className="ValidatedInput">
          <label htmlFor={styles.categories}>Categories</label>
        </div> */}

        <FormMultiSelect
          fetchQuery={CategoriesResource.getSearch}
          field="category_ids"
          maxItems={3}
          name="categories"
          initial={competition.categories}
        />
        {/* <CategoriesMultiSelect
          maxCategories={3}
          onChange={onCategoriesChange}
          preselectedSlugs={competition.categories.map((c) => c.slug)}
        /> */}
        <div className={styles.date_pickers}>
          <div className={styles.date_picker}>
            <label htmlFor="startTime">Start Time</label>
            <DatePicker
              showTimeSelect
              selected={startTime}
              dateFormat="Pp"
              id="startTime"
              onChange={(time: Date) => setStartTime(time)}
            />
          </div>
          <div className={styles.date_picker}>
            <label htmlFor="endTime">End Time</label>
            <DatePicker
              showTimeSelect
              selected={endTime}
              dateFormat="Pp"
              id="endTime"
              onChange={(time: Date) => setEndTime(time)}
            />
          </div>
        </div>
      </FormInputs>
      <FormButtons>
        <FormSubmit text="Save competition" />
      </FormButtons>
    </FormPrimary>
  )
}

type CompetitionFormProps = {
  afterUpdate?: (competition: competitionType) => void,
  competition?: competitionType,
}

export default CompetitionForm
