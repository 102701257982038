import { decrypt, encrypt } from '../common/cry'

const userCookie: string = process.env.REACT_APP_USER_COOKIE || ''

const LocalStorageService = {
  clearUser: (): void => {
    localStorage.removeItem(userCookie);
  },
  getAccessToken: (): string => {
    const user = LocalStorageService.tryDecrypt(LocalStorageService.getUserSalt());
    return user.access_token || '';
  },
  getRefreshToken: (): string => {
    const user = LocalStorageService.tryDecrypt(LocalStorageService.getUserSalt());
    return user.refresh_token || '';
  },
  getUser: (): Partial<User> => {
    return LocalStorageService.tryDecrypt(LocalStorageService.getUserSalt()) || {};
  },
  getUserSalt: (): string | null => {
    return localStorage.getItem(userCookie);
  },
  setUser: (user: User): void => {
    localStorage.setItem(userCookie, encrypt(user));
  },
  tryDecrypt: (userSalt: string | null): Partial<User> => {
    try {
      return decrypt(userSalt) || {};
    } catch (error) {
      return {};
    }
  },
}

interface User {
  access_token: string;
  refresh_token: string;
  // Add other properties in the user object
}

export default LocalStorageService
