import React from 'react'

import LeaguesMutation from '../../../../api/mutations/Leagues'

import { ButtonPrimary, ButtonGrey } from '../../../../common/buttons/Button'
import { userAction } from '../../../../common/helpers'

import styles from './styles.mod.scss'

const LeagueJoinLeave = ({
  id = undefined,
  joined = false,
}: LeagueJoinLeaveProps) => {
  const joinLeague = LeaguesMutation.join({ id })
  const leaveLeague = LeaguesMutation.leave({ id })

  const joinOrLeaveLeague = userAction(async () => {
    joined
      ? await leaveLeague.mutate({ id })
      : await joinLeague.mutate({ id })
  })

  return (
    <div className={styles.wrapper}>
      {joined ? (
        <ButtonGrey
          label="Leave League"
          onClick={joinOrLeaveLeague}
          scale={0.8}
        >
          Leave League
        </ButtonGrey>
      ) : (
        <ButtonPrimary
          label="Join League"
          onClick={joinOrLeaveLeague}
          scale={0.8}
        >
          Join League
        </ButtonPrimary>
      )}
    </div>
  )
}

type LeagueJoinLeaveProps = {
  id: number | undefined,
  joined: boolean,
}

export default LeagueJoinLeave
