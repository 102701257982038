import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'

import FormPrimary from '../../FormPrimary'

import styles from './styles.mod.scss'
import { UseFormReturn } from 'react-hook-form'

export const AuthOptions = ({ children }: PropsWithChildren) => (
  <div className={styles.options}>
    {children}
  </div>
)

export const AuthButtons = ({ children }: PropsWithChildren) => (
  <div className={styles.buttons}>
    {children}
  </div>
)

const AuthForm = ({
  children,
  className,
  form,
  onSubmit = () => {},
  ...rest
}: AuthFormProps) => (
  <FormPrimary
    name={classNames(styles.wrapper, className)}
    form={form}
    onSubmit={onSubmit}
    {...rest}
  >
    {children}
  </FormPrimary>
)

type AuthFormProps = {
  children: React.ReactNode
  className?: string
  onSubmit?: (data: FormData) => void
  form: UseFormReturn<any>
}

export default AuthForm
