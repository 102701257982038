import React from 'react'
import { Link } from 'react-router-dom'

import CardAdmin, { CardCell, LogoCell } from '../../CardAdmin'
import CategoryBadge from '../../../badges/CategoryBadge'
import { imageSmall } from '../../../../common/helpers'
import { userDefault, userType } from '../../../../schemas/types/user'

import classes from './styles.mod.scss'

const UserCardAdmin = ({
  user = userDefault
}: UserCardAdminProps) => {
  const { avatar_url_small, bio, categories, email, id, name } = user

  return (
    <CardAdmin>
      <LogoCell className={classes.logo} url={imageSmall(avatar_url_small)} />
      <CardCell className={classes.name}>
        <Link
          aria-label={`Go to user page for ${user.name}`}
          to={`/users/${id}`}
        >
          {name}
        </Link>
      </CardCell>
      <CardCell className={classes.email}>
        {email}
      </CardCell>
      <CardCell className={classes.bio}>
        {bio}
      </CardCell>
      <CardCell className={classes.categories}>
        {categories.map((category) => (
          <CategoryBadge
            key={`LeagueCardAdmin__category__${category.slug}`}
            name={category.name}
            slug={category.slug}
          />
        ))}
      </CardCell>
    </CardAdmin>
  )
}

type UserCardAdminProps = {
  user: userType,
}

export default UserCardAdmin
