import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import UsersQuery from '../../../api/queries/Users'

import LoadingContainer from '../../LoadingContainer'
import UserCardExpanded from '../../cards/users/CardExpanded'
import { setPageTitle } from '../../../store/pages'
import { userDefault } from '../../../schemas/types/user'

const UserUtilMenu = ({ id }: UserUtilMenuProps) => {
  const { data, isSuccess, isLoading } = UsersQuery.getShow({ id })
  const dispatch = useDispatch()

  const { name } = data || userDefault

  useEffect(() => {
    isSuccess && dispatch(setPageTitle(name))
  }, [isSuccess])

  return (
    <LoadingContainer
      isLoading={isLoading}
      hasContent={!!data}
      resource="user"
    >
      <UserCardExpanded user={data} />
    </LoadingContainer>
  )
}

type UserUtilMenuProps = {
  id?: number
}

export default UserUtilMenu
