/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { setterFunction } from './helpers'

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    isModalOpen: false,
  },
  reducers: {
    setIsModalOpen: setterFunction('isModalOpen'),
  },
})

export const {
  setIsModalOpen,
} = modalSlice.actions

export default modalSlice.reducer
