import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'

import CardExpandedBackground from './Background'

import styles from './styles.mod.scss'

export const Content = ({ children }: PropsWithChildren) => (
  <div className={styles.content}>
    {children}
  </div>
)

const CardExpanded = ({
  children,
  img = undefined,
  isLoaded = false,
  name = undefined,
  placeholderImgs = [],
}: CardExpandedProps) => (
  <div className={classNames(styles.container, name)}>
    <CardExpandedBackground
      img={img}
      placeholderImgs={placeholderImgs}
    />
    <div className={styles.body}>
      {isLoaded ? (
        children
      ) : (
        null
      )}
    </div>
  </div>
)

type CardExpandedProps = {
  children: React.ReactNode
  img?: string
  isLoaded?: boolean
  name?: string
  placeholderImgs?: string[]
}

export default CardExpanded
