import React from 'react'
import classNames from 'classnames'
// import { View } from 'arena-core'

import styles from './styles.mod.scss'

const Card = ({
  children,
  className = '',
  opts = cardOptsDefaults,
}: CardProps) => {
  return (
    <div
      className={classNames(
        className,
        styles.base,
        { [styles.container]: !opts.noContainer },
      )}
    >
      {children}
    </div>
  )
}
// const Card = ({
//   children,
//   className = '',
//   opts = cardOptsDefaults,
// }: CardProps) => {
//   <View
//     className={classNames(
//       className,
//       styles.base,
//       { [styles.container]: !opts.noContainer },
//     )}
//   >
//     {children}
//   </View>
// )

export type cardOptsType = {
  noContainer: boolean
}

export const cardOptsDefaults = {
  noContainer: false,
}

type CardProps = {
  children: React.ReactNode
  className?: string
  opts?: cardOptsType
}

export default Card
