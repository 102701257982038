import React from 'react'

import CardExpanded from '../../CardExpanded'
import CategoryBadge from '../../../badges/CategoryBadge'
import { userDefault, userType } from '../../../../schemas/types/user'

import styles from './styles.mod.scss'

const UserCardExpanded = ({
  user = userDefault
}: UserCardExpandedProps) => {
  const {
    avatar_url,
    avatar_url_small,
    bio,
    categories,
    cover_url,
    id,
    name,
  } = user

  return (
    <CardExpanded
      backgroundUrl={cover_url}
      img={avatar_url}
      isLoaded={!!id}
      className="UserCardExpanded"
      placeholderImgs={[avatar_url_small]}
    >
      <div className={styles.content}>
        <div className={styles.title}>
          {name}
        </div>
        <div className={styles.bio}>
          {bio}
        </div>
        <div className={styles.categories}>
          {categories.map((cat) => (
            <CategoryBadge
              key={`user-category-${id}-${cat.slug}`}
              name={cat.name}
              slug={cat.slug}
            />
          ))}
        </div>
      </div>
    </CardExpanded>
  )
}

type UserCardExpandedProps = {
  user: userType,
}

export default UserCardExpanded
