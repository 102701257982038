import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import RuleForm from '@/components/forms/rules/RuleForm'
import { ruleType } from '@/schemas/types/rule'
import { setAdminFooterType, setIsAdminFooterEnabled } from '@/store/adminFooter'
import { setPageTitle } from '@/store/pages'

const AdminRulesNew = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const afterUpdate = (rule: ruleType) => {
    navigate(`/admin/rules/${rule.id}/edit`)
  }

  useEffect(() => {
    dispatch(setAdminFooterType('viewModeButton'))
    dispatch(setIsAdminFooterEnabled(true))
    dispatch(setPageTitle('New Rule'))
  }, [])

  return (
    <RuleForm afterUpdate={afterUpdate} />
  )
}

export default AdminRulesNew
