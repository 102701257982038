import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import UsersMutation from '../../../../api/mutations/Users'

import FormPrimary, { FormButtons, FormFile } from '../../FormPrimary'
// import { AvatarButton } from '../../../Avatar'
import { ButtonPrimary } from '../../../../common/buttons/Button'
import { profileSchema } from '../../../../schemas/forms/profile'
import { userDefault, userType } from '../../../../schemas/types/user'

import styles from './styles.mod.scss'

const ProfileCoverForm = ({
  user = userDefault
}: ProfileCoverFormProps) => {
  const { id } = user

  const updateUser = UsersMutation.update({ id, successMessage: 'Cover updated.' })
  const { isLoading } = updateUser

  const [isFormDisabled, setIsFormDisabled] = useState(false)

  const buttonText = isFormDisabled || isLoading ? 'Please wait...' : 'Update cover'

  const form = useForm({
    resolver: yupResolver(profileSchema),
    defaultValues: user,
  })
  const { register, reset, setValue } = form

  const onSubmit = (data: FormData) => {
    updateUser.mutate({ id, data })
  }

  const onCoverUpload = (data: File) => {
    setIsFormDisabled(false)
    setValue('cover', data)
    // trigger('attachment')
  }

  const onFileAdded = () => {
    setIsFormDisabled(true)
  }

  useEffect(() => {
    reset(user)
  }, [user])

  return (
    <FormPrimary
      // disabled={isFormDisabled || isLoading}
      name="ProfileCoverForm"
      form={form}
      className={styles.base}
      onSubmit={onSubmit}
    >
      {/* <div className={styles.cover}>
        <div className={styles.cover_container}> */}
      <FormFile
        currentFile={user.cover_url}
        mediaType="image"
        name="cover"
        onClear={() => setValue('cover', null)}
        onFileAdded={onFileAdded}
        onUpload={onCoverUpload}
      />
      <FormButtons>
        <ButtonPrimary
          disabled={isFormDisabled || isLoading}
          label={buttonText}
          scale={0.85}
          type="submit"
        >
          {buttonText}
        </ButtonPrimary>
      </FormButtons>
    </FormPrimary>
  )
}

type ProfileCoverFormProps = {
  user: userType,
}

export default ProfileCoverForm
