import React from 'react'

import CardLink from '../../CardLink'
import CategoryBadge from '../../../badges/CategoryBadge'
import ProgressiveLoadImage from '../../../ProgressiveLoadImage'
import { imageLarge } from '../../../../common/helpers'
import { userDefault, userType } from '../../../../schemas/types/user'

import '../shared.scss'
import styles from './styles.mod.scss'

const UserCard = ({
  user = userDefault
}: UserCardProps) => {
  const {
    avatar_url,
    avatar_url_small,
    avatar_url_tiny,
    categories,
    id,
    name,
  } = user

  return (
    <CardLink
      className="UserCard"
      opts={{ noContainer: true }}
      to={`/users/${user.id}`}
    >
      <ProgressiveLoadImage
        alt="user avatar"
        className={styles.img}
        img={imageLarge(avatar_url)}
        placeholderImgs={[avatar_url_tiny, avatar_url_small]}
      />
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.title}>
            {name}
          </div>
          <div className={styles.categories}>
            {categories.map((cat) => (
              <CategoryBadge
                key={`user-category-${id}-${cat.slug}`}
                name={cat.name}
                slug={cat.slug}
              />
            ))}
          </div>
        </div>
      </div>
    </CardLink>
  )
}

type UserCardProps = {
  user: userType,
}

export default UserCard
