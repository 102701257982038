import { ApiRequest, ApiConfig } from '..'
import { CreateParams, DestroyParams } from '../ApiRequest';

const VotesResource = {
  create: ({ data: vote }: CreateParams) => (
    ApiRequest.privatePost(
      ApiConfig.apiUrl('votes'), { vote }
    )
  ),
  delete: ({ data: { kind, submission_id } }: CreateParams) => (
    ApiRequest.privateDelete(
      ApiConfig.apiUrl('votes/null'),
      { submission_id, kind }
    )
  ),
}

export default VotesResource
