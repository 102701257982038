import React from 'react'
import classNames from 'classnames'

import MessagesQuery from '../../../api/queries/Messages'

import HeaderPopover, { HeaderPopoverContent, HeaderPopoverTrigger } from '../HeaderPopover'
import LoadingContainer from '../../LoadingContainer'
import MessageCard from '../../cards/messages/Card'

import MessagesIcon from '../../../assets/images/icons/envelope-regular.svg'
import MessagesIconActive from '../../../assets/images/icons/envelope-solid.svg'

import styles from './styles.mod.scss'

const MessagesPopoverTrigger = () => (
  <HeaderPopoverTrigger
    ActiveIcon={<MessagesIconActive />}
    InactiveIcon={<MessagesIcon />}
    name="MessagesPopoverTrigger"
  />
)

const MessagesPopover = () => (
  <HeaderPopover Trigger={MessagesPopoverTrigger}>
    <MessagesPopoverContent />
  </HeaderPopover>
)

const MessagesPopoverContent = () => {
  const { data, isLoading } = MessagesQuery.getIndex({ page: 1 })

  return (
    <HeaderPopoverContent
      className={styles.content}
      header="Messages"
      to="/messages/direct"
    >
      <LoadingContainer
        isLoading={isLoading}
        hasContent={!!data?.results?.length}
        resource="Messages"
      >
        {data?.results?.map((message) => (
          <div
            className={classNames(
              styles.item,
              {
                [styles.unread]: !message.read,
                [styles.read]: message.read,
              },
            )}
            key={message.id}
          >
            <MessageCard message={message} />
          </div>
        ))}
      </LoadingContainer>
    </HeaderPopoverContent>
  )
}

export default MessagesPopover
