import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import UsersResource from '@/api/resources/Users'

import AuthForm, { AuthButtons } from '../AuthForm'
import { ButtonPrimary } from '@/common/buttons/Button'
import { FormInput, FormInputs } from '../../FormPrimary'
import { LinkButtonPrimaryInverted } from '@/common/buttons/LinkButton'
import ApiErrors from '@/api/ApiErrors'
import { passwordResetSchema } from '@/schemas/forms/auth'

import EmailIcon from '@/assets/images/icons/envelope.svg'

import styles from './styles.mod.scss'

const PasswordForgotForm = () => {
  const form = useForm({ resolver: yupResolver(passwordResetSchema) })
  const { setFocus } = form

  const onSubmit = async (data) => {
    try {
      await UsersResource.passwordReset(data)
      toast.success('Password reset email sent.')
    } catch (error) {
      toast.error(ApiErrors.getErrorMessage(error))
    }
  }

  useEffect(() => {
    setFocus('email')
  }, [])

  return (
    <AuthForm
      name="PasswordForgotForm"
      onSubmit={onSubmit}
      className={styles.base}
      form={form}
    >
      <FormInputs>
        <FormInput Icon={EmailIcon} name="email" type="email" />
      </FormInputs>
      <AuthButtons>
        <ButtonPrimary label="Send Reset Instructions" type="submit">
          Send Reset Instructions
        </ButtonPrimary>
        <LinkButtonPrimaryInverted
          label="Log in"
          to="/users/login"
        >
          Log In
        </LinkButtonPrimaryInverted>
      </AuthButtons>
    </AuthForm>
  )
}

export default PasswordForgotForm
