import React from 'react'

import LeaguesResource from '../../../../../api/resources/Leagues'

import DropdownMultiSelect from '../../../DropdownMultiSelect'
import { dropdownOptsDefaults, dropdownOptsType } from '../../../../../context/dropdown'

import styles from './styles.mod.scss'

const LeaguesMultiSelect = ({
  maxItems = 5,
  onChange = () => {},
  onMaxItems = () => {},
  opts = dropdownOptsDefaults,
  preselectedSlugs = [],
}: LeaguesMultiSelectProps) => (
  <DropdownMultiSelect
    fetchQuery={LeaguesResource.getSearch}
    id="LeaguesMultiSelect"
    maxItems={maxItems}
    onChange={onChange}
    onMaxItems={onMaxItems}
    opts={opts}
    preselectedSlugs={preselectedSlugs}
    resourceName="leagues"
  />
)

type LeaguesMultiSelectProps = {
  onChange?: () => void,
  opts: dropdownOptsType,
  preselectedSlugs?: string[],
  maxItems?: number,
  onMaxItems?: () => void,
}

export default LeaguesMultiSelect
