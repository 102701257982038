import React from 'react'
import classNames from 'classnames'

import FriendAddRemove from './FriendAddRemove'
import PageHero from '../../../PageHero'
import PopoverProvider, { PopoverContent, PopoverTrigger } from '../../../popovers/Popover'
import ProgressiveLoadImage from '../../../ProgressiveLoadImage'
import RivalAddRemove from './RivalAddRemove'
import SendMessageDialog from '../../../dialogs/SendMessageDialog'
import SendMessageTrigger from './SendMessageTrigger'
import { coverLarge } from '../../../../common/helpers'
import { useAuth } from '../../../../context/auth'
import { userDefault, userType } from '../../../../schemas/types/user'

import FriendAddIcon from '../../../../assets/images/icons/user-group-solid.svg'
import RivalAddIcon from '../../../../assets/images/icons/fire-flame-curved-solid.svg'
import ArrowDownIcon from '../../../../assets/images/icons/chevron-down.svg'

import styles from './styles.mod.scss'

const ActionsPopoverContent = ({
  user = userDefault
}: ActionsPopoverContentProps) => {
  const { id, is_friend, is_rival } = user

  return (
    <PopoverContent className={styles.actions_popover}>
      <div className={styles.actions}>
        <FriendAddRemove
          className={styles.action}
          user_id={id}
          isFriend={is_friend}
        />
        <RivalAddRemove
          className={styles.action}
          user_id={id}
          isRival={is_rival}
        />
      </div>
    </PopoverContent>
  )
}

const UserCardBackground = ({
  user = userDefault
}: UserCardBackgroundProps) => {
  const { currentUser, token } = useAuth()
  const {
    cover_url,
    cover_url_small,
    cover_url_tiny,
    id,
    is_friend,
    is_rival,
  } = user

  return (
    <PageHero
      className={styles.hero}
      isDefault={!cover_url}
      isImage
    >
      <ProgressiveLoadImage
        alt="user cover image"
        className={styles.img}
        img={coverLarge(cover_url)}
        placeholderImgs={[cover_url_tiny, cover_url_small]}
      />
      {token && id !== currentUser.id ? (
        <>
          <div className={classNames(styles.actions, styles.actions_desk)}>
            <FriendAddRemove
              className={styles.action}
              user_id={id}
              isFriend={is_friend}
            />
            <RivalAddRemove
              className={styles.action}
              user_id={id}
              isRival={is_rival}
            />
            <SendMessageDialog
              Trigger={SendMessageTrigger}
              className={classNames(styles.message, styles.action)}
              user={user}
            />
          </div>
          <div className={classNames(styles.actions, styles.actions_mobile)}>
            <SendMessageDialog
              Trigger={() => SendMessageTrigger({ mobile: true })}
              className={classNames(styles.message, styles.action)}
              user={user}
            />
            <PopoverProvider placement="bottom">
              <PopoverTrigger>
                <button
                  className={styles.actions_popover_trigger}
                  aria-label="Open user options"
                >
                  <FriendAddIcon className={styles.FriendAddIcon} />
                  <RivalAddIcon className={styles.RivalAddIcon} />
                  {/* <MessageIcon className={styles.MessageIcon} /> */}
                  <ArrowDownIcon className={styles.ArrowDownIcon} />
                </button>
              </PopoverTrigger>
              <ActionsPopoverContent user={user} />
            </PopoverProvider>
          </div>
        </>
      ) : null }
    </PageHero>
  )
}

type ActionsPopoverContentProps = {
  user: userType,
}

type UserCardBackgroundProps = {
  user: userType,
}

export default UserCardBackground
