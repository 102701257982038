import React from 'react'

import PageContent from '@/components/layouts/PageContent'
import PageWrapper from '@/components/layouts/PageWrapper'
import withScrollTop from '@/hocs/withScrollTop'

import styles from './styles.mod.scss'

const SupportPage = () => (
  <PageWrapper name="SupportPage" title="Support">
    <PageContent>
      Support
    </PageContent>
  </PageWrapper>
)

export default withScrollTop(SupportPage)
