import React from 'react'

import Avatar from '../../../Avatar'
import CategoryBadge from '../../../badges/CategoryBadge'
import LeagueCardMembers from '../../../cards/leagues/Members'
import ResourceResults, { ResourceResult } from '../../ResourceResults'
import { leagueDefault, leagueType } from '../../../../schemas/types/league'

import styles from './styles.mod.scss'

const LeagueResult = ({
  resource = leagueDefault,
}: LeagueResultProps) => {
  const { avatar_url, categories, id, memberships_count, name } = resource

  return (
    <ResourceResult
      className="LeagueResult"
      resourceName="league"
      name={name}
      to={`/leagues/${id}`}
    >
      <div className={styles.avatar_name}>
        <Avatar className={styles.avatar} img={avatar_url} />
        <div className={styles.name}>{name}</div>
      </div>
      <div className={styles.members_categories}>
        <LeagueCardMembers members={memberships_count} />
        <div className={styles.categories}>
          {categories.map((category) => (
            <CategoryBadge
              key={`LeagueResult__category__${category.id}`}
              name={category.name}
              slug={category.slug}
            />
          ))}
        </div>
      </div>
    </ResourceResult>
  )
}

const LeagueResults = ({
  isLoading = true,
  results = [],
}: LeagueResultsProps) => (
  <ResourceResults
    ResourceComponent={LeagueResult}
    className="LeagueResults"
    isLoading={isLoading}
    resourceName="leagues"
    results={results}
  />
)

type LeagueResultProps = {
  resource: leagueType
}

type LeagueResultsProps = {
  isLoading: boolean
  results: Array<leagueType>
}

export default LeagueResults
