import { categoryDefault, categoryType } from './category'
import { challengeType } from './challenge'
import { rewardType } from './reward'

export const ritualDefault: ritualType = {
  id: undefined,
  category: categoryDefault,
  category_id: undefined,
  challenges: [],
  description: '',
  name: '',
  rewards: [],
  slug: ''
}

export type ritualType = {
  id: number | undefined
  category: categoryType,
  category_id: number | undefined
  challenges: Array<challengeType>,
  description: string,
  name: string,
  rewards: Array<rewardType>,
  slug: string
}
