import React from 'react'

import CategoriesResource from '../../../../../api/resources/Categories'

import DropdownMultiSelect from '../../../DropdownMultiSelect'
import { dropdownOptsDefaults, dropdownOptsType } from '../../../../../context/dropdown'

import styles from './styles.mod.scss'

const CategoriesMultiSelect = ({
  maxCategories = 5,
  onChange = () => {},
  opts = dropdownOptsDefaults,
  // preselectedSlugs,
}: CategoriesMultiSelectProps) => (
  <DropdownMultiSelect
    fetchQuery={CategoriesResource.getSearch}
    id="CategoriesMultiSelect"
    onChange={onChange}
    maxItems={maxCategories}
    opts={opts}
    // preselectedSlugs={preselectedSlugs}
    resourceName="categories"
  />
)

type CategoriesMultiSelectProps = {
  maxCategories?: number,
  onChange?: () => void,
  opts: dropdownOptsType,
}

export default CategoriesMultiSelect
