import React from 'react'
import classNames from 'classnames'

import RelationshipsMutation from '../../../../../api/mutations/Relationships'

import UserBackgroundAction from '../Action'

import RivalAddIcon from '../../../../../assets/images/icons/fire-flame-curved-solid.svg'
import RivalRemoveIcon from '../../../../../assets/images/icons/check-duotone.svg'

import styles from './styles.mod.scss'

const RivalAddRemove = ({
  className = '',
  user_id,
  isRival,
}: RivalAddRemoveProps) => {
  const addRival = RelationshipsMutation.addRival({ user_id })
  const removeRival = RelationshipsMutation.removeRival({ user_id })

  return (
    isRival ? (
      <UserBackgroundAction
        Icon={RivalRemoveIcon}
        className={classNames(styles.remove, className)}
        label="Remove Rival"
        onClick={() => removeRival.mutate({ user_id })}
        text="Rivals"
      />
    ) : (
      <UserBackgroundAction
        Icon={RivalAddIcon}
        className={classNames(styles.add, className)}
        onClick={() => addRival.mutate({ user_id })}
        text="Add Rival"
      />
    )
  )
}

type RivalAddRemoveProps = {
  className?: string,
  isRival: boolean,
  user_id: number | undefined,
}

export default RivalAddRemove
