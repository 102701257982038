import React, { useEffect } from 'react';

import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import CompetitionsQuery from '../../../api/queries/Competitions';
import { imageSmall } from '../../../common/helpers';
import SubmissionCard from '../../../components/cards/submissions/Card';
import UserAvatar from '../../../components/cards/users/Avatar';
import PageContent from '../../../components/layouts/PageContent';
import PageWrapper from '../../../components/layouts/PageWrapper';
import Tabs, { ActiveTab, EmptyTab } from '../../../components/layouts/Tabs';
import LoadingContainer from '../../../components/LoadingContainer';
import PageHero from '../../../components/PageHero';
import ScrollableResource from '../../../components/ScrollableResource';
import CompetitionUtilMenu from '../../../components/utilityMenu/Competition';
import { InfiniteScrollProvider } from '../../../context/infiniteScroll';
import { TabsProvider } from '../../../context/tabs';
import withScrollTop from '../../../hocs/withScrollTop';
import { userDefault, userType } from '../../../schemas/types/user';
import { resetUtilMenu, setUtilMenuContent } from '../../../store/utilMenu';
import styles from './styles.mod.scss';

const Place = ({
  place,
  user = userDefault,
}: PlaceProps) => (
  <div className={styles.place_container}>
    <div className={classNames(styles.place, styles[place])}>
      <UserAvatar img={imageSmall(null)} placeholderImgs={[imageSmall(null)]} />
      <div className={styles.placeOverlay} />
    </div>
  </div>
)

const SubmissionsTab = ({ id }: SubmissionsTabProps) => (
  <InfiniteScrollProvider
    cardClassName="SubmissionCard"
    query={CompetitionsQuery.getInfiniteSubmissions}
    params={{ id }}
  >
    <ScrollableResource
      Component={SubmissionCard}
      resourceName="submission"
    />
  </InfiniteScrollProvider>
)

const TABS = {
  leaderboard: EmptyTab,
  details: EmptyTab,
  rewards: EmptyTab,
  submissions: SubmissionsTab,
}

const CompetitionsShowPage = () => {
  const { id } = useParams()

  const dispatch = useDispatch()
  const { data, isLoading } = CompetitionsQuery.getSubmissions({ id })

  useEffect(() => {
    dispatch(setUtilMenuContent(
      <CompetitionUtilMenu id={Number(id)} />
    ))

    return () => dispatch(resetUtilMenu())
  }, [id])

  return (
    <LoadingContainer
      hasContent={!!data}
      isLoading={isLoading}
      className="UsersShowPage__loading-container"
      resource="user"
    >
      <PageHero className={styles.hero}>
        <div className={styles.places}>
          <Place place="silver" />
          <Place place="gold" />
          <Place place="bronze" />
        </div>
      </PageHero>
      <PageWrapper name={styles.base} layout="partial">
        <PageContent>
          <TabsProvider tabs={TABS}>
            <Tabs />
            <ActiveTab id={Number(id)} />
          </TabsProvider>
        </PageContent>
      </PageWrapper>
    </LoadingContainer>
  )
}

type PlaceProps = {
  place: string
  user?: userType
}

type SubmissionsTabProps = {
  id: number
}

// export default withScrollTop(withProvider(TabsProvider)(CompetitionsShowPage))
export default withScrollTop((CompetitionsShowPage))
