import React from 'react'
// import { AnimatePresence, motion } from 'framer-motion'

import UsersQuery from '@/api/queries/Users'

import LoadingContainer from '@/components/LoadingContainer'
import PageContent from '@/components/layouts/PageContent'
import PageWrapper from '@/components/layouts/PageWrapper'
import ProfileAvatarForm from '@/components/forms/users/ProfileAvatarForm'
import ProfileCoverForm from '@/components/forms/users/ProfileCoverForm'
import ProfileDetailsForm from '@/components/forms/users/ProfileDetailsForm'
import Tabs, { ActiveTab, EmptyTab } from '@/components/layouts/Tabs'
import withScrollTop from '@/hocs/withScrollTop'
import { TabsProvider } from '@/context/tabs'
import { userDefault } from '@/schemas/types/user'

import styles from './styles.mod.scss'

const TABS = {
  details: ProfileDetailsForm,
  cover: ProfileCoverForm,
  avatar: ProfileAvatarForm,
  stats: EmptyTab,
}

const ProfilePage = () => {
  const { data, isLoading } = UsersQuery.getMe()
  const user = data || userDefault

  return (
    <PageWrapper
      layout="form"
      name={styles.base}
      title="Account"
    >
      <TabsProvider tabs={TABS}>
        <Tabs />
        <PageContent>
          <LoadingContainer
            isLoading={isLoading}
            hasContent={!!user}
          >
            <div className={styles.wrapper}>
              <ActiveTab user={user} />
            </div>
          </LoadingContainer>
        </PageContent>
      </TabsProvider>
    </PageWrapper>
  )
}

export default withScrollTop(ProfilePage)
