/* eslint-disable no-extra-boolean-cast */
import * as yup from 'yup'

export const submissionSchema = yup.object({
  description: yup.string()
    .trim()
    .max(400, 'Description must be less than 400 characters.')
    .required('Description required.'),
  name: yup.string()
    .trim()
    .max(40, 'Name must be less than 40 characters.')
    .required('Name required.'),
  league_id: yup.number()
    .nullable()
    .transform((v) => (!!v ? v : null)),
  challenge_id: yup.number()
    .nullable()
    .transform((v) => (!!v ? v : null)),
  attachment: yup.object()
    .nullable()
    .required('Please upload an image or video.'),
  kind: yup.string()
    .required('Submission type required.'),
}).required()
