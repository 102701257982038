import React from 'react'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

import MessagesQuery from '@/api/queries/Messages'

import FormError from '@/components/forms/FormPrimary/components/FormError'
import FormPrimary from '@/components/forms/FormPrimary'
import MessageCardSlim from '@/components/cards/messages/CardSlim'
import MessagesMutation from '@/api/mutations/Messages'
import ScrollableResource from '@/components/ScrollableResource'
import withScrollTop from '@/hocs/withScrollTop'
import { InfiniteScrollProvider } from '@/context/infiniteScroll'
import { messageSchema } from '@/schemas/forms/message'
import { ApiConfig } from '@/api'

import SendIcon from '@/assets/images/icons/paper-plane-top-solid.svg'

import styles from './styles.mod.scss'

const MessageInput = () => {
  const createMessage = MessagesMutation.create()
  const addToInfiniteMessages = MessagesMutation.addToInfiniteMessages()

  const { id: user_id } = useParams()

  const form = useForm({ resolver: yupResolver(messageSchema) })
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = form

  const formSubmit = async (data) => {
    const response = await createMessage.mutateAsync({ data: { ...data, recipient_id: user_id } })

    ApiConfig.successfulResponseCallback(response, () => {
      addToInfiniteMessages(response.data)
      setValue('content', '')
    })
  }

  return (
    <FormPrimary
      className={classNames(styles.form)}
      form={form}
      onSubmit={handleSubmit(formSubmit)}
      autocomplete="off"
    >
      <div className={styles.form_container}>
        <div className={styles.input_container}>
          <input
            name="content"
            {...register('content')}
          />
          <button aria-label="Send message" type="submit">
            <SendIcon />
          </button>
        </div>
        <FormError present={errors.content}>{errors.content?.message}</FormError>
      </div>
    </FormPrimary>
  )
}

const MessagesShowPage = () => {
  const { type, id } = useParams()

  return (
    <InfiniteScrollProvider
      cardClassName="MessageCardSlim"
      query={MessagesQuery.getInfiniteDirectIndex}
      params={{ type, user_id: id }}
    >
      <ScrollableResource
        Component={MessageCardSlim}
        className={styles.scrollable_messages}
        resourceName="message"
      />
      <div className={styles.form_wrapper}>
        <MessageInput />
      </div>
    </InfiniteScrollProvider>
  )
}

export default withScrollTop((MessagesShowPage))
