import LocalStorageService from '@/services/LocalStorageService'

export const API_PATH = '/api/v1'

const API_URLS: Record<string, string> = {
  development: `http://localhost:${process.env.REACT_APP_API_PORT ?? 3000}`,
  staging: process.env.REACT_APP_API_DOMAIN ?? '',
  production: process.env.REACT_APP_API_DOMAIN ?? '',
}

export const API_BASE: string = API_URLS[process.env.REACT_APP_ENVIRONMENT ?? 'development']

const ApiConfig = {
  API_BASE,
  apiUrl: (path: string) => `${API_BASE}${API_PATH}/${path}`,
  getBearerToken: () => `Bearer ${ApiConfig.getToken()}`,
  getToken: () => LocalStorageService.getAccessToken(),
  successfulResponseCallback: (response: Response, callback: Callback) => {
    if ([201, 200, 204].includes(response.status)) {
      callback()
    }
  }
}

type Response = {
  data: any
  status: number
}

type Callback = () => void

export default ApiConfig
