import React from 'react';

import { useForm } from 'react-hook-form';

import { ApiConfig } from '@/api';
import { yupResolver } from '@hookform/resolvers/yup';

import RitualsMutation from '../../../../api/mutations/Rituals';
import CategoriesResource from '../../../../api/resources/Categories';
import ChallengesResource from '../../../../api/resources/Challenges';
import { ritualSchema } from '../../../../schemas/forms/ritual';
import { ritualDefault, ritualType } from '../../../../schemas/types/ritual';
import FormPrimary, {
    FormButtons, FormInput, FormInputs, FormMultiSelect, FormSelect, FormSubmit, FormTextArea
} from '../../FormPrimary';

const RitualForm = ({
  afterUpdate = () => {},
  ritual = ritualDefault,
}: RitualFormProps) => {
  const { id } = ritual

  const createRitual = RitualsMutation.create()
  const updateRitual = RitualsMutation.update({ id })

  const form = useForm({
    resolver: yupResolver(ritualSchema),
    defaultValues: ritual,
  })

  const onSubmit = async (data) => {
    const response = id
      ? await updateRitual.mutateAsync({ id, data })
      : await createRitual.mutateAsync({ data })

      ApiConfig.successfulResponseCallback(response, () => {
      afterUpdate(response.data)
    })
  }

  return (
    <FormPrimary
      name="RitualForm"
      form={form}
      onSubmit={onSubmit}
    >
      <FormInputs>
        <FormInput name="name" />
        <FormTextArea name="description" />
        <FormSelect
          fetchQuery={CategoriesResource.getSearch}
          field="category_id"
          name="category"
          initial={ritual.category}
        />
        <FormMultiSelect
          fetchQuery={ChallengesResource.getSearch}
          field="challenge_ids"
          maxItems={3}
          name="challenges"
          initial={ritual.challenges}
        />
      </FormInputs>
      <FormButtons>
        <FormSubmit text="Save ritual" />
      </FormButtons>
    </FormPrimary>
  )
}

type RitualFormProps = {
  afterUpdate?: (ritual: ritualType) => void
  ritual?: ritualType
}

export default RitualForm
