export const currencyDefault: currencyType = {
  id: undefined,
  name: '',
  slug: '',
}

export type currencyType = {
  id: number | undefined,
  name: string,
  slug: string,
}
