import * as yup from 'yup'

export const competitionRewardSchema = yup.object({
  currency_id: yup.number()
    .required('Category required.'),
  value: yup.number()
    .min(1)
    .required('Value required.'),
  // competition_id: yup.number()
  //   .required('Competition required.'),
}).required()

export type competitionRewardSchema = yup.InferType<typeof competitionRewardSchema>
