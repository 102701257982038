import { ApiQueries } from '..'
import { QueryParamsObject } from '../ApiQueries'
import UsersResource from '../resources/Users'

const UsersQuery = {
  getIndex: (params: QueryParamsObject = {}) => ApiQueries.constructQuery({
    queryKey: ['Users', 'getIndex', ApiQueries.queryParams(params)],
    queryFn: UsersResource.getIndex,
    params,
  }),
  getSearch: (params: QueryParamsObject = {}) => ApiQueries.constructQuery({
    queryKey: ['Users', 'getSearch', ApiQueries.queryParams(params)],
    queryFn: UsersResource.getSearch,
    params,
  }),
  getLedgerAccounts: (params: QueryParamsObject = {}) => ApiQueries.constructQuery({
    queryKey: ['Users', 'getLedgerAccounts', ApiQueries.queryParams(params)],
    queryFn: UsersResource.getLedgerAccounts,
    params,
  }),
  getMe: (params: QueryParamsObject = {}) => ApiQueries.constructQuery({
    queryKey: ['Users', 'getMe', ApiQueries.queryParams(params)],
    queryFn: UsersResource.getMe,
    params,
  }),
  getShow: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Users', 'getShow', ApiQueries.queryParams(params)],
    queryFn: UsersResource.getShow,
    params,
  }),
  // getSubmissions: (params = {}) => ApiQueries.constructQuery({
  //   queryKey: ['Users', 'getSubmissions', ApiQueries.queryParams(params)],
  //   queryFn: UsersResource.getSubmissions,
  //   params,
  // }),
  getInfiniteSubmissions: (params: QueryParamsObject = {}) => ApiQueries.constructInfiniteQuery({
    queryKey: ['Users', 'getInfiniteSubmissions', ApiQueries.queryParams(params)],
    queryFn: UsersResource.getSubmissions,
    params,
  }),
}

export default UsersQuery
