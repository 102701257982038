import React from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Link } from 'react-router-dom';

import moment from 'moment';
import rehypeRaw from 'rehype-raw';

import { commentDefault, commentType } from '../../../../schemas/types/comment';
import Avatar from '../../../Avatar';
import styles from './styles.mod.scss';

const CommentCard = ({
  comment = commentDefault
}: CommentCardProps) => {
  const { user, created_at, content } = comment
  const { id, avatar_url_small, name } = user

  return (
    <div className="CommentCard">
      <div className={styles.user_created}>
        <Link
          aria-label={`Go to ${name} user page`}
          className={styles.user}
          to={`/users/${id}`}
        >
          <Avatar img={avatar_url_small} />
          <span>{name}</span>
        </Link>
        <div className={styles.created}>{moment(created_at).fromNow()}</div>
      </div>
      <p className={styles.content}>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {content}
        </ReactMarkdown>
      </p>
    </div>
  )
}

type CommentCardProps = {
  comment: commentType,
}

export default CommentCard
