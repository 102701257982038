import React from 'react'
import rehypeRaw from 'rehype-raw'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

import Avatar from '../../../Avatar'
import CardLink from '../../CardLink'
import MomentService from '../../../../services/MomentService'
import { messageDefault, messageType } from '../../../../schemas/types/message'

import styles from './styles.mod.scss'

const MessageCard = ({
  message = messageDefault
}: MessageCardProps) => {
  const { content, created_at, sender } = message
  const { avatar_url_small, id, name } = sender

  return (
    <CardLink
      className="MessageCard"
      label={`Go to direct message with ${name}`}
      to={`/messages/direct/${id}`}
    >
      <div className={styles.header}>
        <div className={styles.user}>
          <Avatar img={avatar_url_small} />
          <span>{name}</span>
        </div>
        <div className={styles.time}>
          {MomentService.formattedTime(created_at)}
        </div>
      </div>
      <p className={styles.content}>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {content}
        </ReactMarkdown>
      </p>
    </CardLink>
  )
}

type MessageCardProps = {
  message: messageType
}

export default MessageCard
