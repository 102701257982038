import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { throttle } from 'lodash'
import { useDispatch } from 'react-redux'

import RulesQuery from '../../../api/queries/Rules'

import LoadingContainer from '../../../components/LoadingContainer'
import PageContent from '../../../components/layouts/PageContent'
import PageWrapper from '../../../components/layouts/PageWrapper'
import RuleCard from '../../../components/cards/rules/Card'
import withScrollTop from '../../../hocs/withScrollTop'
import { getLinkTitle } from '../../../components/RulesMarkdown'
import { resetUtilMenu, setUtilMenuContent } from '../../../store/utilMenu'
import { ruleDefault, ruleType } from '../../../schemas/types/rule'
import { ruleSectionDefault, ruleSectionType } from '../../../schemas/types/ruleSection'

import styles from './styles.mod.scss'
import headerStyles from '@/components/layouts/Header/styles.mod.scss'

const RuleLink = ({
  activeLink = '',
  linkTitle = '',
  rule = ruleDefault,
  setActiveLink = () => {},
}: RuleLinkProps) => (
  <Link
    aria-label={`Go to ${rule.title} rule`}
    className={classNames(
      styles.rule,
      { [styles.active]: activeLink === linkTitle },
    )}
    onClick={() => setActiveLink(getLinkTitle({ type: 'ruleSection', rule }))}
    to={`#${linkTitle}`}
  >
    {rule.title}
  </Link>
)

const RuleSectionLink = ({
  activeLink = '',
  linkTitle = '',
  setActiveLink = () => {},
  ruleSection = ruleSectionDefault,
}: RuleSectionLinkProps) => (
  <Link
    aria-label={`Go to ${ruleSection.title} rule section`}
    className={classNames(
      styles.ruleSection,
      { [styles.active]: activeLink === linkTitle },
    )}
    onClick={() => setActiveLink(linkTitle)}
    to={`#${linkTitle}`}
  >
    {ruleSection.title}
  </Link>
)

const RulesTOC = ({
  rules = [],
}: RulesTOCProps) => {
  const [activeLink, setActiveLink] = useState<string | undefined>(undefined)
  const [elements, setElements] = useState<Array<HTMLElement>>([])
  const [headerHeight, setHeaderHeight] = useState<number>(0)

  const scrollHandler = throttle(() => {
    // using .every because "break" is a syntax error for "forEach"...
    elements.every((element) => {
      const elementTop = element.getBoundingClientRect().top
      const elementInView = elementTop < (headerHeight * 2) && (elementTop > headerHeight / 2)
      if (elementInView) {
        setActiveLink(element.dataset.title)
        return false
      }
      return true
    })
  }, 50, { trailing: true })

  useEffect(() => {
    const layout = document.getElementById('Layout')
    if (!layout) return

    if (elements.length) {
      layout.addEventListener('scroll', scrollHandler)
    }

    return () => layout.removeEventListener('scroll', scrollHandler)
  }, [elements])

  useEffect(() => {
    setHeaderHeight(document.getElementsByClassName(headerStyles.base)[0].clientHeight)
    const ruleSections = document.getElementsByClassName('rule-section-title')
    const ruleTitles = document.getElementsByClassName('rule-title')
    setElements([...ruleSections, ...ruleTitles])
  }, [rules])

  return (
    <div className={styles.toc}>
      {rules?.map((rule) => (
        <div
          className={styles.tocItem}
          key={`toc_item__${rule.id}`}
        >
          <RuleLink
            activeLink={activeLink}
            key={`rule-${rule.id}`}
            linkTitle={getLinkTitle({ type: 'rule', rule })}
            rule={rule}
            setActiveLink={setActiveLink}
          />
          {rule.rule_sections.map((ruleSection) => (
            <RuleSectionLink
              activeLink={activeLink}
              key={`ruleSection-${ruleSection.id}`}
              linkTitle={getLinkTitle({ type: 'ruleSection', rule, ruleSection })}
              ruleSection={ruleSection}
              setActiveLink={setActiveLink}
            />
          ))}
        </div>
      ))}
    </div>
  )
}

const RulesListPage = () => {
  const dispatch = useDispatch()

  const { data, isLoading } = RulesQuery.getIndex({ page: 1 })
  const { results } = data || { results: [] }

  useEffect(() => {
    dispatch(setUtilMenuContent(
      <RulesTOC
        rules={results}
      />
    ))

    return () => dispatch(resetUtilMenu())
  }, [results])

  return (
    <PageWrapper name="RulesIndex" title="Rules">
      <PageContent>
        <LoadingContainer
          isLoading={isLoading}
          hasContent={!!results?.length}
          resource="rules"
        >
          <div className={styles.content}>
            {results?.map((rule) => (
              <RuleCard
                rule={rule}
                key={`rule-${rule.id}`}
              />
            ))}
          </div>
        </LoadingContainer>
      </PageContent>
    </PageWrapper>
  )
}

type RulesTOCProps = {
  rules: Array<ruleType>
}

type RuleLinkProps = {
  activeLink: string | undefined
  linkTitle: string
  rule: ruleType
  setActiveLink: (link: string) => void
}

type RuleSectionLinkProps = {
  activeLink: string | undefined
  linkTitle: string
  ruleSection: ruleSectionType
  setActiveLink: (link: string) => void
}

export default withScrollTop(RulesListPage)
