import SubmissionsResource from '../resources/Submissions'
import { ApiMutations, ApiQueries } from '..'
import { QueryParamsObject } from '../ApiQueries'

const SubmissionsMutation = {
  create: () => (
    ApiMutations.constructMutation(
      SubmissionsResource.create,
      [
        ['Submissions', 'getAdminIndex'],
        ['Submissions', 'getInfiniteIndex'],
      ],
      { successMessage: 'Submission created.' },
    )
  ),
  update: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      SubmissionsResource.update,
      [
        ['Submissions', 'getAdminIndex'],
        ['Submissions', 'getInfiniteIndex'],
        ['Submissions', 'getShow', ApiQueries.queryParams(params)],
      ],
      { successMessage: 'Submission updated.' },
    )
  ),
  delete: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      SubmissionsResource.delete,
      [
        ['Submissions', 'getAdminIndex'],
        ['Submissions', 'getInfiniteIndex'],
        ['Submissions', 'getShow', ApiQueries.queryParams(params)],
      ],
      { successMessage: 'Submission deleted.' },
    )
  ),
  accept: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      SubmissionsResource.putAccept,
      [
        ['Submissions', 'getAdminIndex'],
        ['Submissions', 'getShow', ApiQueries.queryParams(params)],
      ],
      // { successMessage: 'Submission accepted.' },
    )
  ),
  reject: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      SubmissionsResource.putReject,
      [
        ['Submissions', 'getAdminIndex'],
        ['Submissions', 'getShow', ApiQueries.queryParams(params)],
      ],
      // { successMessage: 'Submission rejected.' },
    )
  ),
}

export default SubmissionsMutation
