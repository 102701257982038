import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import AdminIndexResource from '@/hocs/AdminIndexResource'
import AdminTable, { TableContent, TableHeader, TableHeaderCol } from '../../Dashboard/AdminTable'
import RitualCardAdmin from '@/components/cards/rituals/CardAdmin'
import { ritualType } from '@/schemas/types/ritual'
import { setAdminFooterType, setIsAdminFooterEnabled } from '@/store/adminFooter'

import styles from './styles.mod.scss'

const RESOURCE = 'rituals'

const AdminRituals = ({
  results = [],
}: AdminRitualsProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setAdminFooterType('newLink'))
    dispatch(setIsAdminFooterEnabled(true))
  }, [])

  return (
    <AdminTable>
      <TableHeader>
        <TableHeaderCol className={styles.name} name="name" />
        <TableHeaderCol className={styles.category} name="category" />
        <TableHeaderCol className={styles.challenges} name="challenges" />
        <TableHeaderCol className={styles.reward} name="reward" />
        <TableHeaderCol className={styles.actions} />
      </TableHeader>

      <TableContent>
        {results?.map((ritual) => (
          <RitualCardAdmin
            key={`ritual-${ritual.id}`}
            ritual={ritual}
          />
        ))}
      </TableContent>
    </AdminTable>
  )
}

type AdminRitualsProps = {
  results: Array<ritualType>
}

export default AdminIndexResource({ RESOURCE })(AdminRituals)
