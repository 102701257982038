import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import draftToHtml from 'draftjs-to-html'
import { ContentState, EditorState, convertFromHTML, convertToRaw, getDefaultKeyBinding } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import styles from './styles.mod.scss'

const WYSIWYG = ({
  className = undefined,
  // initialContent = EditorState.createEmpty(),
  initialContent = '',
  onChange = () => {},
  onSubmit = () => {},
  ...props
}: WYSIWYGProps) => {
  const editorRef = useRef(null)

  const getStateFromHTML = (content: string) => {
    const blocksFromHTML = convertFromHTML(content)
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    )
    return EditorState.createWithContent(state)
  }

  const [editorState, setEditorState] = useState(getStateFromHTML(initialContent))

  const onEditorStateChange = (contentValue: EditorState) => {
    const contentHTML = draftToHtml(
      convertToRaw(contentValue.getCurrentContent())
    )
    onChange(contentHTML)
    setEditorState(contentValue)
  }

  const customEnterKey = (e: React.KeyboardEvent) => (
    e.key === 'Enter' && e.metaKey
      ? onSubmit()
      : getDefaultKeyBinding(e)
  )

  const setEditorRef = (ref: any) => {
    editorRef.current = ref
  }

  useEffect(() => {
    setEditorState(getStateFromHTML(initialContent))
    editorRef.current?.focus()
  }, [initialContent])

  return (
    <Editor
      editorState={editorState}
      editorRef={setEditorRef}
      wrapperClassName={classNames(styles.wrapper, className)}
      keyBindingFn={customEnterKey}
      editorClassName={styles.editor}
      toolbarClassName={styles.toolbar}
      onEditorStateChange={onEditorStateChange}
      toolbar={{
        options: ['inline', 'list', 'link', 'emoji', 'history'],
        inline: {
          options: ['bold', 'italic', 'underline', 'strikethrough'],
        }
      }}
      {...props}
    />
  )
}

type WYSIWYGProps = {
  className?: string
  initialContent?: string
  onChange?: (contentHTML: string) => void
  onSubmit?: () => void
}

export default WYSIWYG
