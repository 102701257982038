import React from 'react'

import ProgressiveLoadImage from '@/components/ProgressiveLoadImage'
import { imageLarge } from '@/common/helpers'

import styles from './styles.mod.scss'

const CardExpandedBackground = ({
  img = undefined,
  placeholderImgs = []
}: CardExpandedBackgroundProps) => (
  <ProgressiveLoadImage
    className={styles.wrapper}
    img={imageLarge(img)}
    placeholderImgs={placeholderImgs}
  />
)

type CardExpandedBackgroundProps = {
  img?: string
  placeholderImgs?: string[]
}

export default CardExpandedBackground
