import classNames from "classnames"
import FormField from "../FormField"
import { useFormPrimary } from "../.."

import sharedStyles from "../../styles.mod.scss"

const FormInput = ({
  Icon = undefined,
  className = '',
  label = '',
  name = '',
  ...rest
} : FormInputProps) => {
  const { register, formName } = useFormPrimary()

  return (
    <FormField
      className={classNames(sharedStyles.field, className)}
      label={label}
      name={name}
    >
      <input
        id={`${formName}__${name}`}
        {...register(name)}
        {...rest}
      />
      {Icon ? <Icon /> : null}
    </FormField>
  )
}

type FormInputProps = {
  Icon?: React.FC
  // Icon?: string
  className?: string
  label?: string
  name: string
}

export default FormInput
