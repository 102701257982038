import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'

const defaultContext: PaginatedQueryContextType = {
  fetchQuery: () => Promise.resolve({ data: { results: [], total_pages: 1 } }),
  isLoading: false,
  items: [],
  page: 1,
  params: {},
  setPage: () => {},
  setParams: () => {},
  totalPages: 1,
}

const PaginatedQueryContext = createContext(defaultContext)

// export const PaginatedQueryProvider = ({ children, fetchQuery: fetchQueryProps }) => {
export const PaginatedQueryProvider = ({
  children,
  fetchQuery = () => Promise.resolve({ data: { results: [], total_pages: 1 } }),
}: PaginatedQueryProviderProps) => {
  // const [fetchQuery, setFetchQuery] = useState(fetchQueryProps)
  const [isDataStale, setIsDataStale] = useState(true)
  // const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [items, setItems] = useState<Array<any>>([])
  const [page, setPage] = useState(1)
  const [params, setParams] = useState({})
  const [totalPages, setTotalPages] = useState(1)

  const fetchItems = async () => {
    setIsLoading(true)

    const queryParams = { page, ...params }
    const response = await fetchQuery(queryParams)
    const { results, total_pages } = response.data

    setIsLoading(false)
    setIsDataStale(false)
    setItems([...items, ...results])
    setTotalPages(total_pages)
  }

  useEffect(() => {
    if (!isDataStale || isLoading) return

    fetchItems()
  }, [isDataStale])

  useEffect(() => {
    setItems([])
    setIsDataStale(true)
  }, [params, fetchQuery])

  useEffect(() => {
    setIsDataStale(true)
  }, [page])

  const value = useMemo(() => ({
    fetchQuery,
    isLoading,
    items,
    page,
    params,
    setPage,
    setParams,
    totalPages,
  }), [
    fetchQuery,
    isLoading,
    items,
    page,
    params,
    setPage,
    totalPages,
  ])

  return (
    <PaginatedQueryContext.Provider value={value}>
      {children}
    </PaginatedQueryContext.Provider>
  )
}

type PaginatedQueryProviderProps = {
  children: React.ReactNode
  fetchQuery: (params: any) => Promise<any>
}

type PaginatedQueryContextType = {
  fetchQuery: (params: any) => Promise<any>
  isLoading: boolean
  items: Array<any>
  page: number
  params: any
  setPage: (page: number) => void
  setParams: (params: any) => void
  totalPages: number
}

export const usePaginatedQuery = () => useContext(PaginatedQueryContext)
