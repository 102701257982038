import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'

import LoadingContainer from '../../LoadingContainer'
import withProvider from '../../../hocs/withProvider'
import { DeviceObserverProvider, useDeviceObserver } from '../../../context/deviceObserver'
import { useTabs } from '../../../context/tabs'

import styles from './styles.mod.scss'

export const EmptyTab = () => (
  <LoadingContainer
    isLoading={false}
    hasContent={false}
  >
    no content
  </LoadingContainer>
)

export const Tab = ({ name }: TabProps) => {
  const { activeTabName, setActiveTabName } = useTabs()
  const isActive = activeTabName === name

  const onClick = () => {
    setActiveTabName(name)
  }

  return (
    <button
      aria-label={name}
      className={classNames(styles.tab, { [styles.active]: isActive })}
      onClick={onClick}
    >
      {name}
      <div className={styles.tab_hover} />
    </button>
  )
}

export const ActiveTab = (props: any) => {
  const { activeTabName, tabs } = useTabs()

  const TabComponent = tabs[activeTabName]

  return TabComponent ? <TabComponent {...props} /> : null
}

const Tabs = ({ sticky = true }: TabsProps) => {
  const indicatorRef = useRef(null)
  const { activeTabName, tabNames } = useTabs()
  const { isPhone } = useDeviceObserver()

  useEffect(() => {
    const activeTabEle = document.getElementsByClassName(styles.active)[0]
    if (!activeTabEle) return

    activeTabEle.scrollIntoView({ behavior: 'smooth', block: 'nearest' })

    const { style } = indicatorRef.current
    style.width = `${activeTabEle.offsetWidth}px`
    style.transform = `translateX(${activeTabEle.offsetLeft}px)`
  }, [activeTabName, isPhone])

  return (
    <div
      className={classNames(
        'Tabs',
        { [styles.sticky]: sticky },
      )}
    >
      <div className={styles.wrapper}>
        {tabNames.map((tab) => (
          <Tab key={`Tab__${tab}`} name={tab} />
        ))}
        <div className={styles.indicator} ref={indicatorRef} />
      </div>
    </div>
  )
}

type TabProps = {
  name?: string
}

type TabsProps = {
  sticky?: boolean
}

export default withProvider(DeviceObserverProvider)(Tabs)
