import React from 'react'

import LeagueForm from '@/components/forms/leagues/LeagueForm'
import PageContent from '@/components/layouts/PageContent'
import PageWrapper from '@/components/layouts/PageWrapper'
import withScrollTop from '@/hocs/withScrollTop'

import styles from './styles.mod.scss'

const LeaguesNewPage = () => (
  <PageWrapper name={styles.wrapper} layout="form" title="New League">
    <PageContent layout="form">
      <LeagueForm />
    </PageContent>
  </PageWrapper>
)

export default withScrollTop(LeaguesNewPage)
