import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import CategoriesResource from '../../../api/resources/Categories'
import ChallengesResource from '../../../api/resources/Challenges'
import LeaguesResource from '../../../api/resources/Leagues'
import SubmissionsMutation from '../../../api/mutations/Submissions'
import { ApiConfig } from '@/api'

import FormPrimary, {
  FormButtons,
  FormFile,
  FormInput,
  FormInputs,
  FormMultiSelect,
  FormSelect,
  FormSubmit,
  FormTextArea,
} from '../FormPrimary'
import { challengeDefault, challengeType } from '../../../schemas/types/challenge'
import { submissionDefault, submissionType } from '../../../schemas/types/submission'
import { submissionSchema } from '../../../schemas/forms/submission'

import ImageIcon from '../../../assets/images/icons/image-solid.svg'
import VideoIcon from '../../../assets/images/icons/video-solid.svg'

import styles from './styles.mod.scss'

const MEDIA_TYPES = ['image', 'video']

const MediaTypeButton = ({
  icon = undefined,
  isActive = false,
  onClick = () => {},
  type = MEDIA_TYPES[0],
}: MediaTypeButtonProps) => {
  const onMediaTypeChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    onClick(type)
  }

  return (
    <button
      aria-label={`Select ${type} media`}
      className={classNames(
        styles.media_type,
        { [styles.active]: isActive }
      )}
      disabled={isActive}
      onClick={onMediaTypeChange}
    >
      {icon}
      <span>{type}</span>
    </button>
  )
}

const SubmissionForm = ({
  afterMutation = () => {},
  challenge = challengeDefault,
  submission = submissionDefault,
}: SubmissionFormProps) => {
  // const initialCategoryIds = submission.categories.map((cat) => Number(cat.id))
  const { id } = submission

  const createSubmission = SubmissionsMutation.create()
  const updateSubmission = SubmissionsMutation.update()

  const [activeMediaType, setActiveMediaType] = useState(MEDIA_TYPES[0])

  const form = useForm({
    resolver: yupResolver(submissionSchema),
    defaultValues: submission,
  })

  const { setValue, trigger } = form

  const onSubmit = async (data: FormData) => {
    const response = id
      ? await updateSubmission.mutateAsync({ id, data })
      : await createSubmission.mutateAsync({ data })

      ApiConfig.successfulResponseCallback(response, () => {
      afterMutation(response.data)
    })
  }

  const onMediaUpload = (data: File) => {
    // setValue('attachment', data)
    // console.log(data)
    setValue('attachment', JSON.parse(data))
    trigger('attachment')
  }

  useEffect(() => {
    setValue('kind', activeMediaType)
  }, [activeMediaType])

  return (
    <FormPrimary
      form={form}
      name={styles.base}
      onSubmit={onSubmit}
    >
      <FormInputs>
        <FormInput name="name" />
        <div className={styles.media_types}>
          <MediaTypeButton
            type="image"
            icon={<ImageIcon />}
            isActive={activeMediaType === 'image'}
            onClick={setActiveMediaType}
          />
          <MediaTypeButton
            type="video"
            icon={<VideoIcon />}
            isActive={activeMediaType === 'video'}
            onClick={setActiveMediaType}
          />
        </div>
        <FormFile
          mediaType={activeMediaType}
          name="attachment"
          onUpload={onMediaUpload}
        />
        <FormTextArea name="description" />
        <FormMultiSelect
          className={styles.categories}
          fetchQuery={CategoriesResource.getSearch}
          field="category_ids"
          name="categories"
          initial={submission.categories}
          maxItems={3}
        />
        <FormSelect
          className={styles.league}
          fetchQuery={LeaguesResource.getSearch}
          field="league_id"
          label="league (optional)"
          name="league"
          initial={submission.league}
        />
        <FormSelect
          className={styles.challenge}
          fetchQuery={ChallengesResource.getSearch}
          field="challenge_id"
          initial={challenge}
          label="challenge (optional)"
          name="challenge"
        />
      </FormInputs>
      <FormButtons>
        <FormSubmit />
      </FormButtons>
    </FormPrimary>
  )
}

type SubmissionFormProps = {
  afterMutation?: (responseData: any) => void
  challenge?: challengeType
  submission?: submissionType
}

type MediaTypeButtonProps = {
  icon?: React.ReactNode
  isActive?: boolean
  onClick?: (type: string) => void
  type?: string
}

export default SubmissionForm
