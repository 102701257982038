import React from 'react'

import PageWrapper from '@/components/layouts/PageWrapper'
import withScrollTop from '@/hocs/withScrollTop'

import styles from './styles.mod.scss'

const NotFoundPage = () => (
  <PageWrapper name="NotFoundPage" title="Not Found">
    <div className={styles.errorConainer}>
      <h1>Oops! Try another page...</h1>
    </div>
  </PageWrapper>
)

export default withScrollTop(NotFoundPage)
