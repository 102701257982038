import * as yup from 'yup'

export const challengeSchema = yup.object({
  name: yup.string()
    .trim()
    .max(50, 'Name must be less than 50 characters.')
    .required('Name required.'),
  description: yup.string()
    .trim()
    .required('Description required.'),
  category_id: yup.number()
    .required('Category required.'),
}).required()
