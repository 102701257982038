import RulesResource from '../resources/Rules'
import { ApiMutations, ApiQueries } from '..'
import { QueryParamsObject } from '../ApiQueries'

const RulesMutation = {
  create: () => (
    ApiMutations.constructMutation(
      RulesResource.create,
      [
        ['Rules', 'getAdminIndex'],
        ['Rules', 'getIndex'],
      ],
      { successMessage: 'Rule created.' },
    )
  ),
  update: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      RulesResource.update,
      [
        ['Rules', 'getAdminIndex'],
        ['Rules', 'getIndex'],
        ['Rules', 'getShow', ApiQueries.queryParams(params)],
      ],
      { successMessage: 'Rule updated.' },
    )
  ),
  delete: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      RulesResource.delete,
      [
        ['Rules', 'getAdminIndex'],
        ['Rules', 'getIndex'],
        ['Rules', 'getShow', ApiQueries.queryParams(params)],
      ],
      { successMessage: 'Rule deleted.' },
    )
  ),
}

export default RulesMutation
