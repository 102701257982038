import { ApiRequest, ApiConfig } from '..'
import { CreateParams, UpdateParams, DestroyParams } from '../ApiRequest';

const RewardsResource = {
  create: ({ data: reward }: CreateParams) => (
    ApiRequest.privatePost(ApiConfig.apiUrl('rewards'), { reward })
  ),
  update: ({ id, data: reward }: UpdateParams) => (
    ApiRequest.privatePut(ApiConfig.apiUrl(`rewards/${id}`), { reward })
  ),
  delete: ({ id }: DestroyParams) => (
    ApiRequest.privateDelete(ApiConfig.apiUrl(`rewards/${id}`))
  ),
}

export default RewardsResource
