import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import UsersMutation from '../../../../api/mutations/Users'

import FormPrimary, { FormButtons, FormFile } from '../../FormPrimary'
import { ButtonPrimary } from '../../../../common/buttons/Button'
import { profileSchema } from '../../../../schemas/forms/profile'
import { userDefault, userType } from '../../../../schemas/types/user'

const ProfileAvatarForm = ({
  user = userDefault
}: ProfileAvatarFormProps) => {
  const { id } = user

  const updateUser = UsersMutation.update({ id, successMessage: 'Avatar updated.' })
  const { isLoading } = updateUser

  const [isFormDisabled, setIsFormDisabled] = useState(false)

  const buttonText = isFormDisabled || isLoading ? 'Please wait...' : 'Update avatar'

  const form = useForm({ resolver: yupResolver(profileSchema), defaultValues: user })
  const { reset, setValue } = form

  const onSubmit = (data: FormData) => {
    updateUser.mutate({ id, data })
  }

  const onAvatarUpload = (data: string) => {
    setIsFormDisabled(false)
    setValue('avatar', data)
  }

  const onFileAdded = () => {
    setIsFormDisabled(true)
  }

  useEffect(() => {
    reset(user)
  }, [user])

  return (
    <FormPrimary
      // disabled={isFormDisabled}
      form={form}
      name="ProfileAvatarForm"
      onSubmit={onSubmit}
    >
      <FormFile
        mediaType="image"
        name="avatar"
        onUpload={onAvatarUpload}
        currentFile={user.avatar_url}
        onFileAdded={onFileAdded}
      />
      <FormButtons>
        <ButtonPrimary
          disabled={isFormDisabled || isLoading}
          label={buttonText}
          scale={0.85}
          type="submit"
        >
          {buttonText}
        </ButtonPrimary>
      </FormButtons>
    </FormPrimary>
  )
}

type ProfileAvatarFormProps = {
  user: userType,
}

export default ProfileAvatarForm
