import React from 'react'
import { Outlet } from 'react-router-dom'

import LocalStorageService from '../../services/LocalStorageService'
import NotFoundPage from '../../pages/static/NotFound'

const AdminRoute = () => {
  const token = LocalStorageService.getAccessToken()
  const user = LocalStorageService.getUser()

  return token && user.role === 'admin'
    ? <Outlet />
    : <NotFoundPage />
}

export default AdminRoute
