import React from 'react'

import RewardForms from '../RewardForms'
import { ApiQueries } from '../../../../api'
import { challengeDefault, challengeType } from '../../../../schemas/types/challenge'
import { challengeRewardSchema } from '../../../../schemas/forms/challengeReward'

const ChallengeRewardForm = ({
  challenge = challengeDefault
}: ChallengeRewardFormProps) => (
  <RewardForms
    deleteConfirmationMessage="If anyone has already completed this challenge with this reward, this will invalidate those rewards."
    kind="challenge"
    queriesToInvalidate={[
      ['Challenges', 'getShow', ApiQueries.queryParams({ id: challenge.id })]
    ]}
    resourceId={challenge.id}
    resourceName="challenge"
    rewardSchema={challengeRewardSchema}
    rewards={challenge.rewards}
  />
)

type ChallengeRewardFormProps = {
  challenge: challengeType,
}

export default ChallengeRewardForm
