import React from 'react'
import classNames from 'classnames'
import { Link, LinkProps } from 'react-router-dom'

import styles from './styles.mod.scss'

const BUTTON_VARIANTS = [
  'default',
  'primary',
  'primary-inverted',
  'secondary',
  'white-inverted',
  'grey',
]

const LinkButton = ({
  children,
  className = '',
  disabled = false,
  full = true,
  label,
  scale = 0.8,
  to,
  variant = 0,
}: LinkButtonProps) => (
  <Link
    className={classNames(
      className,
      styles.base,
      styles[BUTTON_VARIANTS[variant]],
      // `LinkButton--${BUTTON_VARIANTS[variant]}`,
      { [styles.full]: full }
    )}
    disabled={disabled}
    aria-label={label}
    to={to}
    style={{ fontSize: `${scale}rem` }}
  >
    {children}
  </Link>
)

export const LinkButtonPrimary = (props: LinkButtonProps) => (
  <LinkButton variant={1} {...props}>
    {props.children}
  </LinkButton>
)

export const LinkButtonPrimaryInverted = (props: LinkButtonProps) => (
  <LinkButton variant={2} {...props}>
    {props.children}
  </LinkButton>
)

export const LinkButtonSecondary = (props: LinkButtonProps) => (
  <LinkButton variant={3} {...props}>
    {props.children}
  </LinkButton>
)

export const LinkButtonWhiteInverted = (props: LinkButtonProps) => (
  <LinkButton variant={4} {...props}>
    {props.children}
  </LinkButton>
)

export const LinkButtonGrey = (props: LinkButtonProps) => (
  <LinkButton variant={5} {...props}>
    {props.children}
  </LinkButton>
)

type LinkButtonProps = LinkProps & {
  children: React.ReactNode
  className?: string
  disabled?: boolean
  full?: boolean
  label: string
  scale?: number
  to: string
  variant?: number
}

export default LinkButton
