import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import CompetitionsQuery from '@/api/queries/Competitions'

import CompetitionForm from '@/components/forms/competitions/CompetitionForm'
import LoadingContainer from '@/components/LoadingContainer'
import CompetitionRewardForm from '@/components/forms/rewards/CompetitionRewardForm'
import { setPageTitle } from '@/store/pages'

const AdminCompetitionsEdit = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { data, isLoading } = CompetitionsQuery.getShow({ id })

  useEffect(() => {
    dispatch(setPageTitle('Edit Competition'))
  }, [])

  return (
    <LoadingContainer
      hasContent={!!data}
      isLoading={isLoading}
      resource="competitions"
    >
      <CompetitionForm competition={data} />
      <CompetitionRewardForm competition={data} />
    </LoadingContainer>
  )
}

export default AdminCompetitionsEdit
