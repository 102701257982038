import { PropsWithChildren, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Location, useLocation } from 'react-router-dom'

import { setIsMenuActive } from '../../../store/menu'
import { setLastPage } from '../../../store/pages'
import { setActiveDropdown } from '../../../store/dropdowns'

const AUTH_PATHS = [
  '/users/login',
  '/users/signup',
  '/users/password/reset',
  '/users/password/edit',
]

export const scrollToTop = () => document.getElementById('Layout')?.scrollTo(0, 0)

const setLastNonAuthPage = (dispatchSetLastPage: (lastPage: string) => void, location: Location) => {
  const { pathname, search } = location
  if (AUTH_PATHS.indexOf(pathname) < 0) {
    dispatchSetLastPage(`${pathname}${search}`)
  }
}

const NavigationActions = ({ children }: PropsWithChildren) => {
  const dispatch = useDispatch()
  const dispatchSetLastPage = (payload) => dispatch(setLastPage(payload))
  const location = useLocation()
  const { pathname } = location

  useEffect(() => {
    setLastNonAuthPage(dispatchSetLastPage, location)
    dispatch(setIsMenuActive(false))
  }, [location])

  useEffect(() => {
    dispatch(setActiveDropdown(null))
  }, [pathname])

  return children
}

export default NavigationActions
