import React from 'react'
import classNames from 'classnames'

import styles from './styles.mod.scss'

const COLORS = [
  'grey',
  'primary',
  'white',
]

const Badge = ({
  children,
  color = 'primary',
  className = '',
}: BadgeProps) => (
  <div className={classNames(styles.base, styles[color], className)}>
    {children}
  </div>
)

type BadgeProps = {
  children: React.ReactNode,
  color?: typeof COLORS[number],
  className?: string,
}

export default Badge
