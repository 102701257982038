/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const defaultState: ComponentsState = {
  layout: {
    isMounted: false
  }
}

export const componentsSlice = createSlice({
  name: 'components',
  initialState: defaultState,
  reducers: {
    setIsLayoutMounted: (state, action) => { state.layout.isMounted = action.payload },
  },
})

type ComponentsState = {
  layout: {
    isMounted: boolean
  }
}

export const {
  setIsLayoutMounted,
} = componentsSlice.actions

export default componentsSlice.reducer
