import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import SubmissionsQuery from '@/api/queries/Submissions'

import CommentsUtilMenu from '@/components/utilityMenu/Comments'
import LoadingContainer from '@/components/LoadingContainer'
import PageContent from '@/components/layouts/PageContent'
import PageWrapper from '@/components/layouts/PageWrapper'
import SubmissionCardExpanded from '@/components/cards/submissions/CardExpanded'
import withScrollTop from '@/hocs/withScrollTop'
import { resetUtilMenu, setUtilMenuContent, setUtilMenuOpts } from '@/store/utilMenu'

import styles from './styles.mod.scss'

const SubmissionsShowPage = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { data, isLoading } = SubmissionsQuery.getShow({ id })

  const submission = data

  useEffect(() => {
    dispatch(setUtilMenuContent(
      <CommentsUtilMenu submissionId={Number(id)} />
    ))
    dispatch(setUtilMenuOpts({ scrollable: true }))

    return () => dispatch(resetUtilMenu())
  }, [id])

  return (
    <PageWrapper name="SubmissionsShow" title="Submission Details" layout="center">
      <PageContent className={styles.pageContent}>
        <LoadingContainer
          hasContent={!!submission}
          isLoading={isLoading}
          resource="submission"
        >
          <SubmissionCardExpanded
            submission={submission}
          />
        </LoadingContainer>
      </PageContent>
    </PageWrapper>
  )
}

export default withScrollTop(SubmissionsShowPage)
