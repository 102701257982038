import React from 'react'
import { Link } from 'react-router-dom'

import Badge from '../../../../badges/Badge'
import { submissionDefault, submissionType } from '../../../../../schemas/types/submission'

import styles from './styles.mod.scss'

const SubmissionUser = ({
  submission = submissionDefault,
}: SubmissionUserProps) => (
  <Link
    aria-label={`Go to user page for ${submission.user.name}`}
    to={`/users/${submission.user.id}`}
    className={styles.user_link}
  >
    <Badge color="grey" className={styles.user}>
      {submission.user.name}
    </Badge>
  </Link>
)

type SubmissionUserProps = {
  submission: submissionType,
}

export default SubmissionUser
