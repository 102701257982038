import { useMediaUploader } from '../../context'

const ProgressBar = () => {
  const { progressRef } = useMediaUploader()

  return (
    <div style={{ marginTop: '1em' }} ref={progressRef} />
  )
}

export default ProgressBar
