import { toast } from 'react-toastify'

export const restrictedPageToast = () => (
  toast.info(
    'Please sign in first.',
    { toastId: 'restrictedPageToast' },
  )
)

export const restrictedActionToast = () => (
  toast.info(
    'You need to sign in to do that.',
    { toastId: 'restrictedActionToast' },
  )
)
