import { ApiRequest, ApiConfig } from '..'
import { PaginatedIndexParams, PaginatedSearchParams, ShowParams, DestroyParams, CreateParams, UpdateParams } from '../ApiRequest';

const LeaguesResource = {
  getAdminIndex: ({ page }: PaginatedIndexParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl('admin/leagues'), { params: { page } })
  ),
  getIndex: ({ categories, page }: PaginatedIndexWithCategoryFilterParams) => (
    ApiRequest.publicGet(
      ApiConfig.apiUrl('leagues'),
      {
        params: {
          categories: categories?.join(';'),
          page,
        }
      }
    )
  ),
  getSearch: ({ page, query }: PaginatedSearchParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl('leagues/search'), { params: { page, query } })
  ),
  getShow: (params: ShowParams) => (
    ApiConfig.getToken() ? LeaguesResource.getPrivateShow(params) : LeaguesResource.getPublicShow(params)
  ),
  getPrivateShow: ({ id }: ShowParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl(`private/leagues/${id}`))
  ),
  getPublicShow: ({ id }: ShowParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl(`public/leagues/${id}`))
  ),
  getMyLeagues: ({ page }: PaginatedIndexParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl('my/leagues'), { params: { page } })
  ),
  getSubmissions: (params: ShowParams) => (
    ApiConfig.getToken() ? LeaguesResource.getPrivateSubmissions(params) : LeaguesResource.getPublicSubmissions(params)
  ),
  getPrivateSubmissions: ({ id }: ShowParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl(`private/leagues/${id}/submissions`))
  ),
  getPublicSubmissions: ({ id }: ShowParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl(`public/leagues/${id}/submissions`))
  ),
  join: ({ id }: ShowParams) => (
    ApiRequest.privatePost(ApiConfig.apiUrl('leagues/join'), { id })
  ),
  leave: ({ id }: DestroyParams) => (
    ApiRequest.privateDelete(ApiConfig.apiUrl(`leagues/${id}/leave`))
  ),
  create: ({ data: league }: CreateParams) => (
    ApiRequest.privatePost(ApiConfig.apiUrl('leagues'), { league })
  ),
  delete: ({ id }: DestroyParams) => (
    ApiRequest.privateDelete(ApiConfig.apiUrl(`leagues/${id}`))
  ),
  update: ({ id, data: league }: UpdateParams) => (
    ApiRequest.privatePut(ApiConfig.apiUrl(`leagues/${id}`), { league })
  ),
}

// TODO: Either move categories to string or make other category filters accept string[]
type PaginatedIndexWithCategoryFilterParams = {
  categories?: string[]
  page?: number
}


export default LeaguesResource
