import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import UsersQuery from '@/api/queries/Users';
import UserCardBackground from '@/components/cards/users/Background';
import PageContent from '@/components/layouts/PageContent';
import PageWrapper from '@/components/layouts/PageWrapper';
import Tabs, { ActiveTab, EmptyTab } from '@/components/layouts/Tabs';
import LoadingContainer from '@/components/LoadingContainer';
import UserUtilMenu from '@/components/utilityMenu/User';
import { DeviceObserverProvider, useDeviceObserver } from '@/context/deviceObserver';
import { TabsProvider } from '@/context/tabs';
import withProvider from '@/hocs/withProvider';
import withScrollTop from '@/hocs/withScrollTop';
import { userDefault } from '@/schemas/types/user';
import { resetUtilMenu, setUtilMenuContent } from '@/store/utilMenu';
import ProfileTab from './ProfileTab';
import SubmissionsTab from './SubmissionsTab';

import styles from './styles.mod.scss';

const DESKTOP_TABS = {
  stats: EmptyTab,
  achievements: EmptyTab,
  submissions: SubmissionsTab,
}

const MOBILE_TABS = {
  profile: ProfileTab,
  stats: EmptyTab,
  achievements: EmptyTab,
  submissions: SubmissionsTab,
}

const UsersShowPage = () => {
  const { id } = useParams()

  const dispatch = useDispatch()
  const userQuery = UsersQuery.getShow({ id })
  const { isPhone } = useDeviceObserver()

  const tabs = isPhone ? MOBILE_TABS : DESKTOP_TABS

  const user = userQuery.data ?? userDefault

  useEffect(() => {
    dispatch(setUtilMenuContent(
      <UserUtilMenu id={Number(id)} />
    ))

    return () => dispatch(resetUtilMenu())
  }, [id])

  return (
    <LoadingContainer
      hasContent={!!userQuery.data}
      isLoading={userQuery.isLoading}
      className={styles.loadingContainer}
      resource="user"
    >
      <UserCardBackground user={user} />
      <PageWrapper name={styles.base} layout="partial">
        <PageContent>
          <TabsProvider tabs={tabs}>
            <Tabs />
            <ActiveTab id={id} user={user} />
          </TabsProvider>
        </PageContent>
      </PageWrapper>
    </LoadingContainer>
  )
}

export default withScrollTop(withProvider(DeviceObserverProvider)(UsersShowPage))
