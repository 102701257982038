/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { setterFunction } from './helpers'

export const dropdownsSlice = createSlice({
  name: 'dropdowns',
  initialState: {
    activeDropdown: null,
  },
  reducers: {
    setActiveDropdown: setterFunction('activeDropdown'),
  },
})

export const {
  setActiveDropdown,
} = dropdownsSlice.actions

export default dropdownsSlice.reducer
