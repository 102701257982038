import React from 'react'
import classNames from 'classnames'

import CategoryBadge from '../../../../badges/CategoryBadge'
import { submissionDefault, submissionType } from '../../../../../schemas/types/submission'

import styles from './styles.mod.scss'

const SubmissionCategories = ({
  mobile = false,
  submission = submissionDefault,
}: SubmissionCategoriesProps) => (
  <div className={classNames(styles.categories, { [styles.mobile]: mobile })}>
    {submission.categories.map((cat) => (
      <CategoryBadge
        name={cat.name}
        slug={cat.slug}
        key={`category-${submission.id}-${cat.slug}`}
      />
    ))}
  </div>
)

type SubmissionCategoriesProps = {
  mobile: boolean,
  submission: submissionType,
}

export default SubmissionCategories
