import RitualsResource from '../resources/Rituals'
import { ApiQueries } from '..'
import { QueryParamsObject } from '../ApiQueries'

const RitualsQuery = {
  getIndex: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Rituals', 'getIndex', ApiQueries.queryParams(params)],
    queryFn: RitualsResource.getIndex,
    params,
  }),
  getSearch: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Rituals', 'getSearch', ApiQueries.queryParams(params)],
    queryFn: RitualsResource.getSearch,
    params,
  }),
  getShow: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Rituals', 'getShow', ApiQueries.queryParams(params)],
    queryFn: RitualsResource.getShow,
    params,
  }),
  getInfiniteSubmissions: (params: QueryParamsObject) => ApiQueries.constructInfiniteQuery({
    queryKey: ['Rituals', 'getInfiniteSubmissions', ApiQueries.queryParams(params)],
    queryFn: RitualsResource.getSubmissions,
    params,
  }),
}

export default RitualsQuery
