import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { usePaginatedQuery } from './paginatedQuery'

const DROPDOWN_TYPES: Array<dropdownType> = [
  'multiselect',
  'select',
]

export const dropdownOptsDefaults = {
  noContainer: false,
  urlUpdates: false,
  withAnimation: false,
}

const defaultContext: DropdownContextType = {
  id: '',
  isActive: false,
  isLoaded: false,
  items: [],
  opts: dropdownOptsDefaults,
  preselectedSlugs: [],
  onChange: () => {},
  resourceName: '',
  selectedItems: [],
  setQuery: () => {},
  setSelectedItems: () => {},
  type: DROPDOWN_TYPES[0],
}

export const DropdownContext = createContext(defaultContext)

export const DropdownProvider = ({
  children,
  resourceName = 'challenges',
  id = Math.floor(Math.random() * 10 ** 10).toString(),
  initial = [],
  onChange = () => {},
  opts = dropdownOptsDefaults,
  // preselectedSlugs,
  preselectedSlugs: preselectedSlugsProps,
  type = DROPDOWN_TYPES[0],
}: DropdownProviderProps) => {
  const { activeDropdown } = useSelector((state) => state.dropdowns)
  const { items, setParams } = usePaginatedQuery()

  const [query, setQuery] = useState('')
  const [selectedItems, setSelectedItems] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)

  // Must be used otherwise onChange call causes endless update loop because of
  // the props being different on component re-render
  const preselectedSlugs = useMemo(() => preselectedSlugsProps, [preselectedSlugsProps])
  const isActive = useMemo(() => activeDropdown === resourceName, [activeDropdown])

  // const getPreselectedItems = () => {
  //   const queriedSlugs = preselectedSlugs.map((itemSlug) => (
  //     items.find((item) => (
  //       item.slug === itemSlug
  //     ))
  //   ))
  //   setIsLoaded(true)
  //   return queriedSlugs.filter((i) => !!i)
  // }

  useEffect(() => {
    setParams({ query })
  }, [query])

  useEffect(() => {
    setIsLoaded(isLoaded || !!items.length)
  }, [items])

  useEffect(() => {
    type === 'select' ? onChange(selectedItems[0]) : onChange(selectedItems)
  }, [selectedItems])

  useEffect(() => {
    // if (initial) {
    // }
    type === 'select' ? setSelectedItems([initial]) : setSelectedItems(initial)
  }, [initial])

  const value = useMemo(() => ({
    id,
    isActive,
    isLoaded,
    items,
    opts,
    preselectedSlugs,
    onChange,
    resourceName,
    selectedItems,
    setQuery,
    setSelectedItems,
    type,
  }), [
    id,
    isActive,
    isLoaded,
    items,
    onChange,
    preselectedSlugs,
    resourceName,
    selectedItems,
    type,
  ])

  return (
    <DropdownContext.Provider value={value}>
      {children}
    </DropdownContext.Provider>
  )
}

export type dropdownOptsType = {
  noContainer?: boolean
  urlUpdates?: boolean
  withAnimation?: boolean
}

export type dropdownItemType = {
  id: number
  slug: string
}

type dropdownType = 'multiselect' | 'select'

type DropdownProviderProps = {
  children: React.ReactNode
  id?: string
  initial?: Array<dropdownItemType> | dropdownItemType
  resourceName: string
  onChange?: (selectedItems: any) => void
  opts?: dropdownOptsType
  preselectedSlugs?: string[]
  type?: dropdownType
}

type DropdownContextType = {
  id: string
  isActive: boolean
  isLoaded: boolean
  items: Array<dropdownItemType>
  opts: dropdownOptsType
  preselectedSlugs: string[] | undefined
  onChange: (selectedItems: any) => void
  resourceName: string
  selectedItems: Array<dropdownItemType>
  setQuery: (query: string) => void
  setSelectedItems: (selectedItems: Array<dropdownItemType>) => void
  type: dropdownType
}

export const dropdownItemDefault = {
  id: null,
  slug: null,
}

export const useDropdown = () => useContext(DropdownContext)
