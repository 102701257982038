import React from 'react'
import classNames from 'classnames'

import Card from '../../Card'
import FavoriteButton from '../components/FavoriteButton'
import SubmissionCategories from '../components/SubmissionCategories'
import SubmissionLeague from '../components/SubmissionLeague'
import SubmissionMedia from '../components/SubmissionMedia'
import SubmissionTime from '../components/SubmissionTime'
import SubmissionTitle from '../components/SubmissionTitle'
import SubmissionUser from '../components/SubmissionUser'
import SubmissionVotes from '../components/SubmissionVotes'
import { submissionDefault, submissionType } from '../../../../schemas/types/submission'

import styles from './styles.mod.scss'
import sharedStyles from '../styles.mod.scss'

const SubmissionCardExpanded = ({
  submission = submissionDefault
}: SubmissionCardExpandedProps) => {
  console.log('foobar')

  return (
    <Card className="SubmissionCardExpanded" opts={{ noContainer: true }}>
      <div className={classNames(sharedStyles.content, styles.content)}>
        <div className={classNames(sharedStyles.content_wrapper, styles.content_wrapper)}>
          <div className={classNames(sharedStyles.header, styles.header)}>
            <div className={classNames(sharedStyles.favorite_title, styles.favorite_title)}>
              <FavoriteButton className={styles.favorite} submission={submission} />
              <SubmissionTitle submission={submission} />
            </div>
            <SubmissionLeague league={submission.league} />
          </div>
          <SubmissionMedia submission={submission} fullSize />
        </div>
        <div className={classNames(sharedStyles.subheader, styles.subheader)}>
          <SubmissionCategories submission={submission} />
          <SubmissionCategories submission={submission} mobile />
          <div className={classNames(styles.actions_wrapper)}>
            <div className={classNames(sharedStyles.actions, styles.actions)}>
              <SubmissionVotes className={styles.votes} submission={submission} />
              {/* <FavoriteButton mobile submission={submission} /> */}
            </div>
            <div className={classNames(sharedStyles.user_time, styles.user_time)}>
              <SubmissionTime submission={submission} />
              <SubmissionUser submission={submission} />
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}
// const SubmissionCardExpanded = ({ submission }) => (
//   <Card className="SubmissionCardExpanded" opts={{ noContainer: true }}>
//     <div className={classNames(sharedStyles.content, styles.content)}>
//       <div className={classNames(sharedStyles.content_wrapper, styles.content_wrapper)}>
//         <div className={classNames(sharedStyles.header, styles.header)}>
//           <div className={classNames(sharedStyles.favorite_title, styles.favorite_title)}>
//             <FavoriteButton className={styles.favorite} submission={submission} />
//             <SubmissionTitle submission={submission} />
//           </div>
//           <SubmissionLeague league={submission.league} />
//         </div>
//         <SubmissionMedia submission={submission} fullSize />
//       </div>
//       <div className={classNames(sharedStyles.subheader, styles.subheader)}>
//         <SubmissionCategories submission={submission} />
//         <SubmissionCategories submission={submission} mobile />
//         <div className={classNames(styles.actions_wrapper)}>
//           <div className={classNames(sharedStyles.actions, styles.actions)}>
//             <SubmissionVotes className={styles.votes} submission={submission} />
//             {/* <FavoriteButton mobile submission={submission} /> */}
//           </div>
//           <div className={classNames(sharedStyles.user_time, styles.user_time)}>
//             <SubmissionTime submission={submission} />
//             <SubmissionUser submission={submission} />
//           </div>
//         </div>
//       </div>
//     </div>
//   </Card>
// )

type SubmissionCardExpandedProps = {
  submission: submissionType,
}

export default SubmissionCardExpanded
