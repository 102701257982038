import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import LeaguesMutation from '../../../../api/mutations/Leagues'

import CategoriesMultiSelect from '../../../dropdowns/resources/categories/CategoriesMultiSelect'
import FormPrimary, { FormButtons, FormFile, FormInput, FormInputs, FormTextArea } from '../../FormPrimary'
import { ButtonPrimary } from '../../../../common/buttons/Button'
import { leagueDefault, leagueType } from '../../../../schemas/types/league'
import { leagueSchema } from '../../../../schemas/forms/league'

import styles from './styles.mod.scss'

const LeagueForm = ({
  league = leagueDefault,
}: LeagueFormProps) => {
  const [categories, setCategories] = useState(league.categories)

  const createLeague = LeaguesMutation.create()

  const form = useForm({
    resolver: yupResolver(leagueSchema),
    defaultValues: league,
  })

  const { setValue } = form

  const onSubmit = async (data) => {
    createLeague({ league: data })
  }

  const onCategoriesChange = (cats) => {
    setCategories(cats.map((cat) => cat.id))
  }

  useEffect(() => {
    setValue('category_ids', categories)
  }, [categories])

  return (
    <FormPrimary
      form={form}
      name={styles.base}
      onSubmit={onSubmit}
    >
      <FormInputs>
        <FormInput name="name" />
        <FormFile name="logo" currentFile={league.logo_url} />
        <FormTextArea name="description" />
        <CategoriesMultiSelect
          // preselectedSlugs={categories.map((c) => c.slug)}
          initial={categories}
          maxCategories={3}
          onChange={onCategoriesChange}
        />
      </FormInputs>
      <FormButtons>
        <ButtonPrimary label="Save league" scale={0.85} type="submit">
          Save league
        </ButtonPrimary>
      </FormButtons>
    </FormPrimary>
  )
}

type LeagueFormProps = {
  league: leagueType
}

export default LeagueForm
