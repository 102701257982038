import React from 'react'

import CardLink from '../../CardLink'
import ProgressiveLoadImage from '../../../ProgressiveLoadImage'
import { contactDefault, contactType } from '../../../../schemas/types/contact'
import { imageSmall } from '../../../../common/helpers'

import styles from './styles.mod.scss'

const ContactCard = ({
  contact = contactDefault
}: ContactCardProps) => {
  const { avatar_url_tiny, id, name } = contact.associated_user

  return (
    <CardLink
      className="ContactCard"
      to={`/messages/direct/${id}`}
      opts={{ noContainer: true }}
    >
      <div className={styles.header}>
        <ProgressiveLoadImage
          alt="user avatar"
          className={styles.avatar}
          img={imageSmall(avatar_url_tiny)}
          placeholderImgs={[avatar_url_tiny]}
        />
        <div className={styles.title}>
          {name}
        </div>
      </div>
    </CardLink>
  )
}

type ContactCardProps = {
  contact: contactType,
}

export default ContactCard
