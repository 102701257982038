import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import ChallengesQuery from '@/api/queries/Challenges'

import LoadingContainer from '@/components/LoadingContainer'
import PageContent from '@/components/layouts/PageContent'
import PageWrapper from '@/components/layouts/PageWrapper'
import SubmissionForm from '@/components/forms/SubmissionForm'
import withScrollTop from '@/hocs/withScrollTop'
import { setPageTitle } from '@/store/pages'

const ChallengesNewSubmissionPage = () => {
  const dispatch = useDispatch()
  const { challenge_id } = useParams()
  const navigate = useNavigate()

  const { data, isLoading } = ChallengesQuery.getShow({ id: challenge_id })

  const challengeName = data ? data.name : 'Challenge'
  const pageTitle = challengeName ? `New Submission for ${challengeName}` : 'New Submission'

  const afterMutation = () => {
    navigate('/explore')
  }

  useEffect(() => {
    dispatch(setPageTitle(pageTitle))
  }, [pageTitle])

  return (
    <motion.div>
      <PageWrapper
        layout="form"
        name="ChallengesNewSubmission"
      >
        <PageContent layout="form">
          <LoadingContainer
            isLoading={isLoading}
            hasContent={!!data}
            resource="challenge"
          >
            <SubmissionForm
              afterMutation={afterMutation}
              challenge={data}
            />
          </LoadingContainer>
        </PageContent>
      </PageWrapper>
    </motion.div>
  )
}

export default withScrollTop(ChallengesNewSubmissionPage)
