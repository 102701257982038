import React, { useEffect } from 'react';

import classNames from 'classnames';
import { useForm } from 'react-hook-form';

import { ApiConfig } from '@/api';
import { yupResolver } from '@hookform/resolvers/yup';

import MessagesMutation from '../../../../api/mutations/Messages';
import SendIcon from '../../../../assets/images/icons/paper-plane-top-solid.svg';
import { ButtonPrimary } from '../../../../common/buttons/Button';
import { messageSchema } from '../../../../schemas/forms/message';
import { DialogCancel } from '../../../Dialog';
import FormError from '../../FormPrimary/components/FormError';
import WYSIWYG from '../../WYSIWYG';
import styles from './styles.mod.scss';

const MessageForm = ({
  user_id,
  onSubmitSuccess = () => {},
}: MessageFormProps) => {
  const createMessage = MessagesMutation.create()

  const {
    formState: { errors },
    clearErrors,
    handleSubmit,
    setValue,
  } = useForm({ resolver: yupResolver(messageSchema) })

  const formSubmit = async (data) => {
    // const response = await createMessage.mutateAsync({ data: { ...data, recipient_id: user_id } })
    const response = await createMessage.mutateAsync({ data })

    ApiConfig.successfulResponseCallback(response, () => {
      onSubmitSuccess(response.data)
    })
  }

  const onContentChange = (contentHTML) => {
    clearErrors('content')
    setValue('content', contentHTML)
  }

  useEffect(() => {
    setValue('recipient_id', user_id)
  }, [])

  return (
    <form className={styles.form} onSubmit={handleSubmit(formSubmit)}>
      <div className={styles.wrapper}>
        <WYSIWYG
          initialContent=""
          onChange={onContentChange}
          onSubmit={handleSubmit(formSubmit)}
          className={styles.wysiwyg}
          toolbar={{
            options: ['inline'],
            inline: {
              options: ['bold', 'italic', 'underline'],
            }
          }}
        />
        <FormError
          className={styles.error}
          present={errors.content}
        >
          {errors.content?.message}
        </FormError>
      </div>

      <div className={styles.buttonContainer}>
        <DialogCancel className={styles.button} />
        <ButtonPrimary
          className={classNames(styles.button, styles.buttonSubmit)}
          label="Send"
          type="submit"
        >
          Send
          <SendIcon />
        </ButtonPrimary>
      </div>
    </form>
  )
}

type MessageFormProps = {
  // onCancelClick: () => void
  onSubmitSuccess: () => void
  user_id: number
}

export default MessageForm
