import React from 'react'
import { NavLink } from 'react-router-dom'

import styles from './styles.mod.scss'

const CloutUtilMenu = () => (
  <div className="CloutUtilMenu">
    <NavLink to="/clout/badges" className={styles.link}>
      Badges
    </NavLink>
    <NavLink to="/clout/wallet" className={styles.link}>
      Wallet
    </NavLink>
  </div>
)

export default CloutUtilMenu
