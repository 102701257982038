import React from 'react'

import styles from './styles.mod.scss'

const NoContent = ({
  resource = 'content'
}: NoContentProps) => (
  // <div className="NoContent">
  <div className={styles.base}>
    {`No ${resource} to display.`}
  </div>
)

type NoContentProps = {
  resource: string,
}

export default NoContent
