import RitualsResource from '../resources/Rituals'
import { ApiMutations, ApiQueries } from '..'
import { QueryParamsObject } from '../ApiQueries'

const RitualsMutation = {
  create: () => (
    ApiMutations.constructMutation(
      RitualsResource.create,
      [
        ['Rituals', 'getAdminIndex'],
        ['Rituals', 'getIndex'],
      ],
      { successMessage: 'Ritual created.' },
    )
  ),
  update: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      RitualsResource.update,
      [
        ['Rituals', 'getAdminIndex'],
        ['Rituals', 'getIndex'],
        ['Rituals', 'getShow', ApiQueries.queryParams(params)],
      ],
      { successMessage: 'Ritual updated.' },
    )
  ),
  delete: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      RitualsResource.delete,
      [
        ['Rituals', 'getAdminIndex'],
        ['Rituals', 'getIndex'],
        ['Rituals', 'getShow', ApiQueries.queryParams(params)],
      ],
      { successMessage: 'Ritual deleted.' },
    )
  ),
}

export default RitualsMutation
