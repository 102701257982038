import * as yup from 'yup'

export const ruleSchema = yup.object({
  title: yup.string()
    .trim()
    .max(50, 'Title must be less than 50 characters.')
    .required('Title required.'),
  content: yup.string()
    .trim(),
  category_id: yup.number()
    .required('Category required.'),
  relevance: yup.number(),
}).required()
