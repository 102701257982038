import React from 'react'
import classNames from 'classnames'
import { Link, NavLink, useLocation } from 'react-router-dom'

import AddButton from './AddButton'
// import MobileMenu from '../MobileMenu'
import menuImage from '../../../assets/images/logo/logo-small.png'

// import challengesImgActive from '../../../assets/images/dzastr-coin-green.png'
// import challengesImgInactive from '../../../assets/images/dzastr-coin-grey.png'

import ChallengesIcon from '../../../assets/images/logo/dzastr-coin.svg'
// import challengesImgInactive from '../../../assets/images/icons/dzastr-coin.svg'
import CompeteIcon from '../../../assets/images/icons/swords-regular.svg'
import CompeteIconActive from '../../../assets/images/icons/swords-solid.svg'
import ExploreIcon from '../../../assets/images/icons/compass-regular.svg'
import ExploreIconActive from '../../../assets/images/icons/compass-solid.svg'
import LeaguesIconActive from '../../../assets/images/icons/users-solid.svg'

import styles from './styles.mod.scss'
import { Tooltip } from '../../tooltips/Tooltip'

const MenuLink = ({
  ActiveIcon,
  InactiveIcon,
  className,
  name,
  to,
}: MenuLinkProps) => {
  const { pathname } = useLocation()
  const isActive = pathname.split('/')[1] === to

  return (
    <Tooltip
      content={name}
      placement="right"
      // initialTransitions={{ marginLeft: '10px' }}
      // transitions={{
      //   initial: {
      //     opacity: 0,
      //     transform: 'scale(0.8)',
      //   },
      // }}
    >
      <Link
        aria-label={name}
        to={to}
        className={classNames(
          styles.link,
          className,
          { [styles.active]: isActive }
        )}
      >
        {isActive ?
          <ActiveIcon className={styles.svg} />
          : <InactiveIcon className={styles.svg} />
        }
        <div className={styles.link__name}>{name}</div>
      </Link>
    </Tooltip>
  )
}

const Menu = () => (
  <header className={styles.base}>
    <NavLink to="/explore" className={styles.logo}>
      <img src={menuImage} alt="dzastr" />
    </NavLink>
    {/* <SearchBar /> */}
    <div className={styles.items}>
      <div className={styles.items__links}>
        <MenuLink
          ActiveIcon={ExploreIconActive}
          InactiveIcon={ExploreIcon}
          name="Explore"
          to="explore"
        />
        <MenuLink
          ActiveIcon={CompeteIconActive}
          InactiveIcon={CompeteIcon}
          name="Compete"
          to="competitions"
        />
        <AddButton mobile />
        <MenuLink
          // activeSrc={challengesImgActive}
          ActiveIcon={ChallengesIcon}
          // inactiveSrc={challengesImgInactive}
          InactiveIcon={ChallengesIcon}
          name="Challenges"
          to="challenges"
        />
        <MenuLink
          // ActiveIcon={LeaguesIconActive}
          ActiveIcon={LeaguesIconActive}
          InactiveIcon={LeaguesIconActive}
          name="Leagues"
          to="leagues"
        />
        <AddButton />
      </div>
      {/* <UserButtons /> */}
      {/* {token && <MobileMenu />} */}
      {/* <MobileMenu /> */}
    </div>
  </header>
)

type MenuLinkProps = {
  ActiveIcon: any,
  InactiveIcon: any,
  className?: string,
  name: string,
  to: string,
}

export default Menu
