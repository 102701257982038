import React from 'react'
import classNames from 'classnames'

import FavoritesMutation from '../../../../../api/mutations/Favorites'
import { ApiConfig } from '../../../../../api'

import { userAction } from '../../../../../common/helpers'
import { submissionDefault, submissionType } from '../../../../../schemas/types/submission'

import StarIcon from '../../../../../assets/images/icons/star-regular.svg'
import StarSolidIcon from '../../../../../assets/images/icons/star-solid.svg'

import styles from './styles.mod.scss'

const FavoriteButton = ({
  className = undefined,
  mobile = false,
  submission = submissionDefault,
}: FavoriteButtonProps) => {
  const createFavorite = FavoritesMutation.create({ submission_id: submission.id })
  const deleteFavorite = FavoritesMutation.delete({ submission_id: submission.id })
  const invalidateSubmission = FavoritesMutation.invalidateSubmission()
  const updateInfiniteSubmissions = FavoritesMutation.updateInfiniteSubmissions()

  const favoriteOrRedirect = userAction(async (e) => {
    e.preventDefault()
    const response = submission.favorite
      ? await deleteFavorite.mutateAsync({ id: submission.favorite.id })
      : await createFavorite.mutateAsync({ data: { kind: 'submission', association_id: submission.id } })

    ApiConfig.successfulResponseCallback(response, () => {
      updateInfiniteSubmissions({ ...submission, favorite: response.data })
      invalidateSubmission(submission)
    })
  })

  return (
    <button
      className={classNames(
        styles.base,
        className,
        { [styles.active]: submission.favorite },
        { [styles.mobile]: mobile },
      )}
      aria-label="Save Favorite"
      onClick={favoriteOrRedirect}
    >
      {submission.favorite ? <StarSolidIcon /> : <StarIcon />}
    </button>
  )
}

type FavoriteButtonProps = {
  className: string | undefined,
  mobile: boolean,
  submission: submissionType,
}

export default FavoriteButton
