/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { setterFunction } from './helpers'

export const adminFooterSlice = createSlice({
  name: 'adminFooter',
  initialState: {
    isAdminFooterEnabled: false,
    adminFooterMode: 'edit',
    adminFooterType: '',
  },
  reducers: {
    setIsAdminFooterEnabled: setterFunction('isAdminFooterEnabled'),
    setAdminFooterType: setterFunction('adminFooterType'),
    setAdminFooterMode: setterFunction('adminFooterMode'),
  },
})

export const {
  setIsAdminFooterEnabled,
  setAdminFooterType,
  setAdminFooterMode,
} = adminFooterSlice.actions

export default adminFooterSlice.reducer
