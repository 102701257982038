import { ApiQueries } from '..'
import { QueryParamsObject } from '../ApiQueries'
import MessagesResource from '../resources/Messages'

const MessagesQuery = {
  getIndex: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Messages', 'getIndex', ApiQueries.queryParams(params)],
    queryFn: MessagesResource.getIndex,
    params,
  }),
  getInfiniteDirectIndex: (params: QueryParamsObject) => ApiQueries.constructInfiniteQuery({
    queryKey: ['Messages', 'getInfiniteDirectIndex', ApiQueries.queryParams(params)],
    queryFn: MessagesResource.getDirectIndex,
    params,
  }),
}

export default MessagesQuery
