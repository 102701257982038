import classNames from 'classnames'
import styles from './styles.mod.scss'

const FormButtons = ({
  children,
  className = '',
} : FormButtonsProps) => (
  <div className={classNames(styles.buttons, className)}>
    {children}
  </div>
)

type FormButtonsProps = {
  children: React.ReactNode
  className?: string
}

export default FormButtons
