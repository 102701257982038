import { ApiRequest, ApiConfig } from '..'
import { PaginatedAssociationParams, PaginatedIndexParams, ShowParams, PaginatedSearchParams } from '../ApiRequest';

const client_id = process.env.REACT_APP_OAUTH_CLIENT
const client_secret = process.env.REACT_APP_OAUTH_SECRET

const UsersResource = {
  getIndex: ({ page }: PaginatedIndexParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl('users'), { params: { page } })
  ),

  getLedgerAccounts: () => (
    ApiRequest.privateGet(ApiConfig.apiUrl('my/ledger_accounts'))
  ),

  getMe: () => (
    ApiRequest.privateGet(ApiConfig.apiUrl('users/me'))
  ),

  getPrivateShow: ({ id }: ShowParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl(`private/users/${id}`))
  ),

  getPrivateSubmissions: ({ id, page }: PaginatedAssociationParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl(`private/users/${id}/submissions`), { params: { page } })
  ),

  getPublicSubmissions: ({ id, page }: PaginatedAssociationParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl(`public/users/${id}/submissions`), { params: { page } })
  ),

  getPublicShow: ({ id }: ShowParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl(`public/users/${id}`))
  ),

  getSearch: ({ page, query }: PaginatedSearchParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl('users/search'), { params: { page, query } })
  ),

  getShow: (params: ShowParams) => (
    ApiConfig.getToken() ? UsersResource.getPrivateShow(params) : UsersResource.getPublicShow(params)
  ),

  getSubmissions: (params: PaginatedAssociationParams) => (
    ApiConfig.getToken() ? UsersResource.getPrivateSubmissions(params) : UsersResource.getPublicSubmissions(params)
  ),

  authenticate: ({ email, password }: AuthenticateParams) => (
    ApiRequest.publicPost(
      `${ApiConfig.API_BASE}/oauth/token`,
      { client_id, client_secret, email, grant_type: 'password', password }
    )
  ),

  authenticateRefresh: ({ refresh_token }: AuthenticateRefreshParams) => (
    ApiRequest.publicPost(
      `${ApiConfig.API_BASE}/oauth/token`,
      { client_id, client_secret, grant_type: 'refresh_token', refresh_token }
    )
  ),

  passwordReset: ({ email }: PasswordResetParams) => (
    ApiRequest.publicPost(
      ApiConfig.apiUrl('users/password/reset'),
      { client_id, client_secret, email }
    )
  ),

  passwordResetByToken: ({ password, password_confirmation, reset_password_token }: PasswordResetByTokenParams) => (
    ApiRequest.publicPost(
      ApiConfig.apiUrl('users/password/reset_by_token'),
      { client_id, client_secret, password, password_confirmation, reset_password_token }
    )
  ),

  register: ({ name, email, password }: RegisterParams) => (
    ApiRequest.publicPost(
      ApiConfig.apiUrl('users'),
      { client_id, client_secret, user: { name, email, password } }
    )
  ),

  update: ({ data: user }: UpdateParams) => (
    ApiRequest.privatePut(ApiConfig.apiUrl('users'), { user })
  ),

  updatePassword: ({ data }: UpdatePasswordParams) => (
    ApiRequest.privatePut(ApiConfig.apiUrl('users/password'), data)
  ),
}

type AuthenticateParams = {
  email: string
  password: string
}

type AuthenticateRefreshParams = {
  refresh_token: string
}

type PasswordResetParams = {
  email: string
}

type PasswordResetByTokenParams = {
  password: string
  password_confirmation: string
  reset_password_token: string
}

type RegisterParams = {
  name: string
  email: string
  password: string
}

type UpdateParams = {
  data: any
}

type UpdatePasswordParams = {
  data: any
}

export default UsersResource
