export const ruleSectionDefault: ruleSectionType = {
  id: undefined,
  content: '',
  relevance: undefined,
  title: '',
}

export type ruleSectionType = {
  id: number | undefined,
  content: string,
  relevance: number | undefined,
  title: string,
}
