import { categoryType } from './category'
import { rewardType } from './reward'
import { leagueDefault, leagueType } from './league'
import { userType } from './user'

export const competitionDefault: competitionType = {
  id: undefined,
  categories: [],
  description: '',
  end_time: '',
  first_place_user: {
    name: '',
  },
  league: leagueDefault,
  logo_url: '',
  logo_url_medium: '',
  logo_url_small: '',
  logo_url_tiny: '',
  name: '',
  rewards: [],
  second_place_user: {
    name: '',
  },
  start_time: '',
  slug: '',
  third_place_user: {
    name: '',
  },
  users: [],
};


export type competitionType = {
  id: number | undefined
  categories: categoryType[]
  description: string
  end_time: string
  first_place_user: {
    name: string
  }
  league: leagueType
  logo_url: string
  logo_url_medium: string
  logo_url_small: string
  logo_url_tiny: string
  name: string
  rewards: rewardType[]
  second_place_user: {
    name: string
  }
  slug: string
  start_time: string
  third_place_user: {
    name: string
  }
  users: userType[]
}
