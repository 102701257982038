/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { setterFunction } from './helpers'

export const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    isMenuActive: false,
  },
  reducers: {
    setIsMenuActive: setterFunction('isMenuActive'),
  },
})

export const {
  setIsMenuActive,
} = menuSlice.actions

export default menuSlice.reducer
