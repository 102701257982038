import React, { createContext, useContext, useMemo, useState } from 'react'

const defaultContext: AdminSidebarFooterContextType = {
  adminFooterEnabled: false,
  footerType: '',
  mode: 'edit',
  setAdminFooterEnabled: () => {},
  setFooterType: () => {},
  setMode: () => {},
}

const AdminSidebarFooter = createContext(defaultContext)

export const AdminSidebarFooterProvider = ({ children }: React.PropsWithChildren) => {
  const [adminFooterEnabled, setAdminFooterEnabled] = useState(false)
  const [footerType, setFooterType] = useState('')
  const [mode, setMode] = useState('edit')

  const value = useMemo(() => (
    {
      adminFooterEnabled,
      footerType,
      mode,
      setAdminFooterEnabled,
      setFooterType,
      setMode,
    }
  ), [adminFooterEnabled, footerType, mode])

  return (
    <AdminSidebarFooter.Provider value={value}>
      {children}
    </AdminSidebarFooter.Provider>
  )
}

type AdminSidebarFooterContextType = {
  adminFooterEnabled: boolean
  footerType: string
  mode: string
  setAdminFooterEnabled: (adminFooterEnabled: boolean) => void
  setFooterType: (footerType: string) => void
  setMode: (mode: string) => void
}

export const useAdminSidebarFooter = () => useContext(AdminSidebarFooter)
