// src/schemas/types/submission.js
import { userDefault, userType } from '../types/user'
import { categoryType } from './category'
import { challengeDefault, challengeType } from './challenge'
import { favoriteDefault, favoriteType } from './favorite'
import { leagueDefault, leagueType } from './league'
import { voteType } from './vote'

export const submissionDefault: submissionType = {
  id: undefined,
  attachment_url: '',
  attachment_url_large: '',
  attachment_url_small: '',
  attachment_url_tiny: '',
  categories: [],
  challenge: challengeDefault,
  created_at: '',
  description: '',
  favorite: favoriteDefault,
  kind: 'image',
  league: leagueDefault,
  name: '',
  status: 'pending',
  user: userDefault,
  vote_count: 0,
  votes: [],
}

export type submissionType = {
  id: number | undefined,
  attachment_url: string,
  attachment_url_large: string,
  attachment_url_small: string,
  attachment_url_tiny: string,
  categories: categoryType[],
  challenge: challengeType,
  created_at: string,
  description: string,
  favorite: favoriteType,
  kind: string,
  league: leagueType,
  name: string,
  status: 'pending' | 'accepted' | 'rejected' | 'revoked'
  user: userType,
  vote_count: number,
  votes: voteType[],
}
