import { categoryType } from "./category"

export const leagueDefault: leagueType = {
  avatar_url: '',
  categories: [],
  description: '',
  id: undefined,
  joined: false,
  logo_url: '',
  logo_url_small: '',
  logo_url_tiny: '',
  memberships_count: 0,
  name: '',
  slug: '',
}

export type leagueType = {
  avatar_url: string,
  categories: Array<categoryType>,
  description: string,
  id: number | undefined,
  joined: boolean,
  logo_url: string,
  logo_url_small: string,
  logo_url_tiny: string,
  memberships_count: number,
  name: string,
  slug: string,
}
