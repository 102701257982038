import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import CategoriesMutation from '../../../../api/mutations/Categories'

import FormPrimary, { FormButtons, FormInput, FormInputs } from '../../FormPrimary'
import { ButtonPrimary } from '../../../../common/buttons/Button'
import { categorySchema } from '../../../../schemas/forms/category'
import { categoryDefault, categoryType } from '../../../../schemas/types/category'

const CategoryForm = ({
  afterUpdate = () => {},
  category = categoryDefault,
}: CategoryFormProps) => {
  const { id } = category

  const form = useForm({
    resolver: yupResolver(categorySchema),
    defaultValues: category,
  })

  const createCategory = CategoriesMutation.create()
  const upateCategory = CategoriesMutation.update({ id })

  const onSubmit = async (data: FormData) => {
    if (id) {
      upateCategory.mutate({ id, data })
    } else {
      const response = await createCategory.mutateAsync({ data })
      response.status === 201 && afterUpdate(response.data)
    }
  }

  return (
    <FormPrimary
      form={form}
      name="CategoryForm"
      onSubmit={onSubmit}
    >
      <FormInputs>
        <FormInput name="name" />
        <FormInput name="relevance" type="number" />
      </FormInputs>
      <FormButtons>
        <ButtonPrimary label="Save category" scale={0.85} type="submit">
          Save category
        </ButtonPrimary>
      </FormButtons>
    </FormPrimary>
  )
}

type CategoryFormProps = {
  afterUpdate?: (category: categoryType) => void
  category?: categoryType
}

export default CategoryForm
