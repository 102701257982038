import { ButtonPrimary } from '@/common/buttons/Button'
import { useFormPrimary } from '../..'
import classNames from 'classnames'
import styles from './styles.mod.scss'

const FormSubmit = ({
  className = '',
  text = 'Submit',
  ...rest
} : FormSubmitProps) => {
  const { formState: { errors, isSubmitting, isValid } } = useFormPrimary()
  const disabled = isSubmitting
  const buttonText = isSubmitting ? 'Please wait...' : text

  const onClick = () => {
    if (!isValid && Object.keys(errors).length) {
      const errorMessages = Object.keys(errors).map((key) => (
        errors[key].message
      ))
      console.error(`Form invalid for the following reasons: ${errorMessages}`)
    }
  }

  return (
    <ButtonPrimary
      className={classNames(styles.submit, className)}
      disabled={disabled}
      onClick={onClick}
      label={text}
      type="submit"
      {...rest}
    >
      {buttonText}
    </ButtonPrimary>
  )
}

type FormSubmitProps = {
  className?: string
  text?: string
}

export default FormSubmit
