import { PropsWithChildren } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import PageTitle from '../PageTitle'
import { RootState } from '@/store'

import styles from './styles.mod.scss'

export const UtilityMenuTitle = ({ children }: PropsWithChildren) => (
  <div className={styles.title}>
    {children}
  </div>
)

export const UtilityMenuFooter = ({ children }: PropsWithChildren) => (
  <div className={styles.footer}>
    {children}
  </div>
)

export const UtilityMenuContent = ({ children, opts }: UtilityMenuContentProps) => (
  <div
    className={classNames(
      styles.content,
      { [styles.scrollable]: opts.scrollable },
    )}
  >
    {children}
  </div>
)

const UtilityMenu = ({ className = '' }: UtilityMenuProps) => {
  const {
    isUtilMenuActive,
    isUtilMenuEnabled,
    utilMenuContent,
    utilMenuOpts,
  } = useSelector((state: RootState) => state.utilMenu)

  return (
    <div
      className={classNames(
        styles.base,
        className,
        {
          [styles.active]: isUtilMenuEnabled && isUtilMenuActive,
          [styles.noPadding]: utilMenuOpts.noPadding,
        },
      )}
    >
      <UtilityMenuTitle>
        <PageTitle />
      </UtilityMenuTitle>

      <UtilityMenuContent opts={utilMenuOpts}>
        {utilMenuContent}
      </UtilityMenuContent>

      <UtilityMenuFooter>
        <Link aria-label="Terms" to="terms">Terms</Link>
        <Link aria-label="Privacy" to="privacy">Privacy</Link>
        <Link aria-label="Rules" to="rules">Rules</Link>
      </UtilityMenuFooter>
    </div>
  )
}

interface UtilityMenuContentProps extends PropsWithChildren {
  opts: {
    noPadding: boolean
    scrollable: boolean
  };
}

type UtilityMenuProps = {
  className?: string
}

export default UtilityMenu
