import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import RuleSectionsMutation from '../../../../api/mutations/RuleSections'

import DeleteConfirmationDialog, { DeleteConfirmationTrigger } from '../../../dialogs/DeleteConfirmationDialog'
import FormError from '../../FormPrimary/components/FormError'
import FormPrimary, { FormButtons, FormInput, FormInputs } from '../../FormPrimary'
import WYSIWYG from '../../WYSIWYG'
import { ButtonSecondary } from '../../../../common/buttons/Button'
import { ruleDefault, ruleType } from '../../../../schemas/types/rule'
import { ruleSectionDefault, ruleSectionType } from '../../../../schemas/types/ruleSection'
import { ruleSectionSchema } from '../../../../schemas/forms/ruleSection'

import styles from './styles.mod.scss'
import { ApiConfig } from '@/api'

const RuleSectionForm = ({
  afterMutation = () => {},
  rule = ruleDefault,
  ruleSection = ruleSectionDefault,
  updateText = 'Update Form'
}: RuleSectionFormProps) => {
  const { content, id, relevance, title } = ruleSection

  const updateRuleSection = RuleSectionsMutation.update({ rule_id: rule.id })
  const deleteRuleSection = RuleSectionsMutation.delete({ rule_id: rule.id })
  const createRuleSection = RuleSectionsMutation.create()

  const form = useForm({
    resolver: yupResolver(ruleSectionSchema),
    defaultValues: ruleSection,
  })
  const {
    formState: { errors },
    setValue,
  } = form

  const onDeleteConfirm = async () => {
    const response = await deleteRuleSection.mutateAsync({ id })
    afterMutation()
    return response
  }

  const onSubmit = async (data: FormData) => {
    const response = id
      ? await updateRuleSection.mutateAsync({ id, data })
      : await createRuleSection.mutateAsync({ data })

      ApiConfig.successfulResponseCallback(response, () => {
      afterMutation()
    })
  }

  const onContentChange = (contentHTML: string) => {
    setValue('content', contentHTML)
  }

  useEffect(() => {
    setValue('rule_id', rule.id)
  }, [])

  useEffect(() => {
    setValue('content', content)
    setValue('id', id)
    setValue('relevance', relevance || 0)
    setValue('title', title)
  }, [ruleSection])

  return (
    <FormPrimary
      name="RuleSectionForm"
      onSubmit={onSubmit}
      form={form}
    >
      <FormInputs>
        <FormInput name="title" />
        <div className={styles.wysiwygContainer}>
          <label htmlFor="RuleForm__content">Content</label>
          <WYSIWYG
            initialContent={ruleSection.content}
            onChange={onContentChange}
            // onSubmit={handleSubmit(formSubmit)}
          />
          <FormError present={errors.content}>{errors.content?.message}</FormError>
        </div>
        <FormInput name="relevance" />
      </FormInputs>
      <FormButtons>
        <ButtonSecondary
          label={updateText}
          scale={0.75}
          type="submit"
        >
          {updateText}
        </ButtonSecondary>
        {ruleSection.id ? (
          <DeleteConfirmationDialog
            Trigger={DeleteConfirmationTrigger}
            onConfirm={onDeleteConfirm}
            resource="section"
          />
        ) : null}
      </FormButtons>
    </FormPrimary>
  )
}

type RuleSectionFormProps = {
  afterMutation: () => void
  rule: ruleType
  ruleSection: ruleSectionType
  updateText: string
}

export default RuleSectionForm
