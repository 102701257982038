import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import SignupForm from '@/components/forms/auth/SignupForm'
import withScrollTop from '@/hocs/withScrollTop'
import { setPageTitle } from '@/store/pages'

import styles from './styles.mod.scss'

const SignupPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPageTitle('Sign up'))
  }, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1>Sign up to start competing</h1>
      </div>
      <SignupForm />
    </div>
  )
}

export default withScrollTop(SignupPage)
