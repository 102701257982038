import LeaguesResource from '../resources/Leagues'
import { ApiQueries } from '..'
import { QueryParamsObject } from '../ApiQueries'

const LeaguesQuery = {
  getAdminIndex: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Leagues', 'getAdminIndex', ApiQueries.queryParams(params)],
    queryFn: LeaguesResource.getAdminIndex,
    params,
  }),
  getSearch: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Leagues', 'getSearch', ApiQueries.queryParams(params)],
    queryFn: LeaguesResource.getSearch,
    params,
  }),
  getMyLeagues: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Leagues', 'getMyLeagues', ApiQueries.queryParams(params)],
    queryFn: LeaguesResource.getMyLeagues,
    params,
  }),
  getShow: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Leagues', 'getShow', ApiQueries.queryParams(params)],
    queryFn: LeaguesResource.getShow,
    params,
  }),
  getInfiniteSubmissions: (params: QueryParamsObject) => ApiQueries.constructInfiniteQuery({
    queryKey: ['Leagues', 'getInfiniteSubmissions', ApiQueries.queryParams(params)],
    queryFn: LeaguesResource.getSubmissions,
    params,
  }),
  getInfiniteIndex: (params: QueryParamsObject) => ApiQueries.constructInfiniteQuery({
    queryKey: ['Leagues', 'getInfiniteIndex', ApiQueries.queryParams(params)],
    queryFn: LeaguesResource.getIndex,
    params,
  }),
}

export default LeaguesQuery
