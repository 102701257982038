import classNames from 'classnames';

import { useFormPrimary } from '../..';
import sharedStyles from '../../styles.mod.scss';
import FormError from '../FormError';
import styles from './styles.mod.scss';

const FormField = ({
  children,
  className = '',
  fieldName = '',
  label = '',
  name = '',
} : FormFieldProps) => {
  const { errors, formName } = useFormPrimary()

  return (
    <div className={classNames(sharedStyles.field, className)}>
      <label htmlFor={`${formName}__${name}`}>
        {label || name}
      </label>
      <div className={styles.inputContainer}>
        {children}
      </div>
      <FormError present={errors[name]}>
        {errors[fieldName || name]?.message}
      </FormError>
    </div>
  )
}

type FormFieldProps = {
  children: React.ReactNode
  className?: string
  fieldName?: string
  label?: string
  name: string
}

export default FormField
