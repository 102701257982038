import * as yup from 'yup'

export const signupSchema = yup.object({
  name: yup.string()
    .trim()
    .max(50, 'Name must be less than 50 characters.')
    .required('Name required.'),
  email: yup.string().email()
    .trim()
    .required('Email required.'),
  password: yup.string()
    .required('Password required.')
    .max(40, 'Password must be less than 40 characters.')
    .min(8, 'Password must be at least 8 characters long.'),
  password_confirmation: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Password confirmation required.')
}).required()

export const loginSchema = yup.object({
  email: yup.string().email()
    .trim()
    .required('Email required.'),
  password: yup.string()
    .required('Password required.')
    .max(40, 'Password must be less than 40 characters.')
    .min(8, 'Password must be at least 8 characters long.'),
}).required()

export const passwordResetSchema = yup.object({
  email: yup.string().email()
    .trim()
    .required('Email required.'),
}).required()

export const passwordEditSchema = yup.object({
  password: yup.string()
    .required('Password required.')
    .max(40, 'Password must be less than 40 characters.')
    .min(8, 'Password must be at least 8 characters long.'),
  password_confirmation: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Password confirmation required.')
}).required()
