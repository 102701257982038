import TimesIcon from '@/assets/images/icons/xmark-solid.svg'
import { useMediaUploader } from '../../context'

import styles from './styles.mod.scss'

const ClearButton = () => {
  const { isPreviewPresent, onClear, setPreview, setVideoSrc, uppy } = useMediaUploader()

  const clearFile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    setPreview('')
    setVideoSrc('')
    uppy.reset()
    onClear()
  }

  return (
    <button
      aria-label="Clear media"
      className={styles.clear}
      disabled={isPreviewPresent}
      onClick={clearFile}
    >
      <TimesIcon />
    </button>
  )
}

export default ClearButton
