import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import RulesMutation from '../../../../api/mutations/Rules'
import { ApiConfig } from '@/api'

import FormError from '../../FormPrimary/components/FormError'
import FormPrimary, { FormButtons, FormInput, FormInputs, FormSelect } from '../../FormPrimary'
import WYSIWYG from '../../WYSIWYG'
import { ButtonPrimary } from '../../../../common/buttons/Button'
import { ruleDefault, ruleType } from '../../../../schemas/types/rule'
import { ruleSchema } from '../../../../schemas/forms/rule'

const RuleForm = ({
  afterUpdate = () => {},
  rule = ruleDefault,
}: RuleFormProps) => {
  const { id } = rule

  const createRule = RulesMutation.create()
  const updateRule = RulesMutation.update({ id })

  const [selectedCategoryId, setSelectedCategoryId] = useState(0)

  const form = useForm({
    resolver: yupResolver(ruleSchema),
    defaultValues: rule,
  })
  const {
    formState: { errors },
    setValue,
  } = form

  const onSubmit = async (data: FormData) => {
    const response = id
      ? await updateRule.mutateAsync({ id, data })
      : await createRule.mutateAsync({ data })

      ApiConfig.successfulResponseCallback(response, () => {
      afterUpdate(response.data)
    })
  }

  const onCategoryChange = (categoryId: number) => {
    setSelectedCategoryId(Number(categoryId))
  }

  const onContentChange = (contentHTML) => {
    setValue('content', contentHTML)
  }

  useEffect(() => {
    if (rule.category.id) {
      setSelectedCategoryId(rule.category.id)
    }
  }, [rule])

  useEffect(() => {
    setValue('relevance', rule.relevance || 0)
  }, [rule])

  useEffect(() => {
    setValue('category_id', selectedCategoryId)
  }, [selectedCategoryId])

  return (
    <FormPrimary
      name="RuleForm"
      form={form}
      onSubmit={onSubmit}
    >
      <FormInputs>
        <FormInput name="title" />
        <div>
          <label htmlFor="RuleForm__content">Content</label>
          <div>
            <WYSIWYG
              initialContent={rule.content}
              onChange={onContentChange}
            />
          </div>
          <FormError present={errors.content}>{errors.content?.message}</FormError>
        </div>
        <div>
          <FormSelect
            fieldName="category_id"
            initial={rule.category}
            onChange={onCategoryChange}
            selectedResourceId={selectedCategoryId}
          />
          <FormError present={errors.category_id}>
            {errors.category_id?.message}
          </FormError>
        </div>
        <FormInput name="relevance" type="number" />
      </FormInputs>
      <FormButtons>
        <ButtonPrimary label="Save rule" scale={0.85} type="submit">
          Save rule
        </ButtonPrimary>
      </FormButtons>
    </FormPrimary>
  )
}

type RuleFormProps = {
  afterUpdate?: Function
  rule: ruleType
}

export default RuleForm
