import React, {
  cloneElement,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  FloatingArrow,
  FloatingFocusManager,
  FloatingPortal,
  Placement,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react'
import classNames from 'classnames'

import { useLocation } from 'react-router-dom'

import './styles.mod.scss'

const ARROW_WIDTH = 30
const ARROW_HEIGHT = 15

const PopoverContext = createContext(null)

const PopoverProvider = ({
  children,
  placement = 'bottom',
}: PopoverProviderProps) => {
  const arrowRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()
  const data = useFloating({
    placement,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(ARROW_HEIGHT + 10),
      flip({ padding: 5 }),
      shift({ padding: 16 }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
  })
  const { context, middlewareData } = data

  const arrowX = middlewareData.arrow?.x ?? 0
  const arrowY = middlewareData.arrow?.y ?? 0
  const transformX = arrowX + ARROW_WIDTH / 2
  const transformY = arrowY + ARROW_HEIGHT

  const { isMounted, styles } = useTransitionStyles(context, {
    duration: {
      open: 250,
      close: 250,
    },
    common: ({ side }) => ({
      transformOrigin: {
        top: `${transformX}px calc(100% + ${ARROW_HEIGHT}px)`,
        bottom: `${transformX}px ${-ARROW_HEIGHT}px`,
        left: `calc(100% + ${ARROW_HEIGHT}px) ${transformY}px`,
        right: `${-ARROW_HEIGHT}px ${transformY}px`,
      }[side],
    }),
  })

  const onClick = useClick(context)
  const onDismiss = useDismiss(context)
  const role = useRole(context)

  const interactions = useInteractions([onClick, onDismiss, role])

  useEffect(() => {
    setIsOpen(false)
  }, [location])

  const value = useMemo(() => ({
    ...data,
    ...interactions,
    arrowRef,
    isMounted,
    isOpen,
    setIsOpen,
    transitionStyles: styles,
  }), [arrowRef, isOpen, interactions, data, styles, isMounted])

  return (
    <PopoverContext.Provider value={value}>
      {children}
    </PopoverContext.Provider>
  )
}

export const PopoverTrigger = forwardRef((props, propRef) => {
  const { children } = props

  const { getReferenceProps, refs } = usePopover()
  const childrenRef = children.ref

  const ref = useMergeRefs([
    refs.setReference,
    propRef,
    childrenRef,
  ])

  return cloneElement(
    children,
    getReferenceProps({
      ref,
      ...props,
      ...children.props,
    })
  )
})

export const PopoverContent = forwardRef(({
  children,
  className = '',
  isModal = false,
  style = {},
}: PopoverContentProps, propRef) => {
  const {
    arrowRef,
    context,
    floatingStyles,
    refs,
    transitionStyles,
    isMounted,
  } = usePopover()

  const { setFloating } = refs

  const ref = useMergeRefs([setFloating, propRef])

  if (!isMounted) return null

  return (
    <FloatingPortal>
      <FloatingFocusManager context={context} modal={isModal}>
        <div
          key="Popover1"
          ref={ref}
          style={{ ...floatingStyles, ...style, ...transitionStyles }}
          className={classNames('PopoverContent', className)}
        >
          <FloatingArrow
            ref={arrowRef}
            context={context}
            fill="#191919"
            stroke="#646464"
            strokeWidth={1}
            width={ARROW_WIDTH}
            height={ARROW_HEIGHT}
          />
          {children}
        </div>
      </FloatingFocusManager>
    </FloatingPortal>

  )
})

type PopoverProviderProps = {
  children: React.ReactNode
  placement?: Placement
}

type PopoverContentProps = {
  children: React.ReactNode
  className?: string
  isModal?: boolean
  style?: React.CSSProperties
}

export default PopoverProvider

export const usePopover = () => useContext(PopoverContext)
