import React from 'react'

import Avatar from '../../../Avatar'
import CategoryBadge from '../../../badges/CategoryBadge'
import ResourceResults, { ResourceResult } from '../../ResourceResults'
import { userDefault, userType } from '../../../../schemas/types/user'

import styles from './styles.mod.scss'

const UserResult = ({
  resource = userDefault
}: UserResultProps) => {
  const { avatar_url_small, categories, id, name } = resource

  return (
    <ResourceResult
      className="UserResult"
      resourceName="user"
      name={name}
      to={`/users/${id}`}
    >
      <div className={styles.avatar_name}>
        <Avatar className={styles.avatar} img={avatar_url_small} />
        <div className={styles.name}>{name}</div>
      </div>
      <div className={styles.categories}>
        {categories.map((category) => (
          <CategoryBadge
            key={`UserResult__category__${category.id}`}
            name={category.name}
            slug={category.slug}
          />
        ))}
      </div>
    </ResourceResult>
  )
}

const UserResults = ({
  isLoading = true,
  results = [],
}: UserResultsProps) => (
  <ResourceResults
    ResourceComponent={UserResult}
    className="UserResults"
    isLoading={isLoading}
    resourceName="users"
    results={results}
  />
)

type UserResultProps = {
  resource: userType,
}

type UserResultsProps = {
  isLoading: boolean,
  results: Array<userType>,
}

export default UserResults
