import React, { useEffect, useRef } from 'react'
import pluralize from 'pluralize'

import LoadingContainer from '@/components/LoadingContainer'
import QueriedItems from '../../QueriedItems'
import { useDropdown } from '@/context/dropdown'
import { usePaginatedQuery } from '@/context/paginatedQuery'

import styles from './styles.mod.scss'

const DropdownCardContent = ({
  children,
  onSelectItem = () => {},
}: DropdownCardContentProps) => {
  const inputRef = useRef(null)
  const { isLoading, items } = usePaginatedQuery()
  const { isActive, query, resourceName, setQuery } = useDropdown()

  useEffect(() => {
    if (isActive) {
      inputRef.current?.focus()
    }
  }, [isActive])

  return (
    <div className="DropdownCardContent">
      {children}
      <input
        className={styles.input}
        onChange={(e) => setQuery(e.target.value)}
        placeholder={`Search for ${resourceName}`}
        ref={inputRef}
        type="text"
        value={query}
      />
      <LoadingContainer
        isLoading={isLoading}
        hasContent={!!items.length}
        resource={pluralize(resourceName)}
      >
        <QueriedItems onClick={onSelectItem} />
      </LoadingContainer>
    </div>
  )
}

type DropdownCardContentProps = {
  children?: React.ReactNode
  onSelectItem?: (item: any) => void
}

export default DropdownCardContent
