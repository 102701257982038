import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import AdminIndexResource from '@/hocs/AdminIndexResource'
import AdminTable, { TableContent, TableHeader, TableHeaderCol } from '../../Dashboard/AdminTable'
import ChallengeCardAdmin from '@/components/cards/challenges/CardAdmin'
import { challengeType } from '@/schemas/types/challenge'
import { setAdminFooterType, setIsAdminFooterEnabled } from '@/store/adminFooter'

import styles from './styles.mod.scss'

const RESOURCE = 'challenges'

const AdminChallenges = ({
  results = [],
}: AdminChallengesProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setAdminFooterType('newLink'))
    dispatch(setIsAdminFooterEnabled(true))
  }, [])

  return (
    <AdminTable>
      <TableHeader>
        <TableHeaderCol className={styles.name} name="name" />
        <TableHeaderCol className={styles.description} name="description" />
        <TableHeaderCol className={styles.category} name="category" />
        <TableHeaderCol className={styles.rewards} name="rewards" />
        <TableHeaderCol className={styles.actions} />
      </TableHeader>

      <TableContent>
        {results?.map((challenge) => (
          <ChallengeCardAdmin
            challenge={challenge}
            key={`challenge-${challenge.id}`}
          />
        ))}
      </TableContent>
    </AdminTable>
  )
}

type AdminChallengesProps = {
  results: Array<challengeType>
}

export default AdminIndexResource({ RESOURCE })(AdminChallenges)
