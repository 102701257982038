import { categoryDefault, categoryType } from './category'
import { ruleSectionType } from './ruleSection'

export const ruleDefault: ruleType = {
  category: categoryDefault,
  id: undefined,
  content: '',
  relevance: undefined,
  title: '',
  rule_sections: [],
}

export type ruleType = {
  category: categoryType
  id: number | undefined,
  content: string,
  relevance: number | undefined,
  title: string,
  rule_sections: Array<ruleSectionType>,
}
