import React from 'react'
import pluralize from 'pluralize'

import AdminSearchBar from './SearchBar'
import CardLink from '../../cards/CardLink'
import Footer from './Footer'

import ChevronRight from '../../../assets/images/icons/chevron-right.svg'

import styles from './styles.mod.scss'

const AdminCardLink = ({ resource }: AdminCardLinkProps) => (
  <CardLink
    className={styles.link}
    to={`/admin/${pluralize(resource)}`}
    opts={{ noContainer: true }}
  >
    {pluralize(resource)}
    <ChevronRight />
  </CardLink>
)

const AdminDashboardUtilMenu = ({
  currentResource = '',
  isIndexPath = false,
  setFilterActive = () => {},
  setQuery = () => {},
}: AdminDashboardUtilMenuProps) => (
  <>
    {isIndexPath ? (
      <AdminSearchBar
        enabled={isIndexPath}
        onActiveChange={setFilterActive}
        onQueryChange={setQuery}
        resource={currentResource}
      />
    ) : null}

    <div className={styles.links}>
      <AdminCardLink resource="challenge" />
      <AdminCardLink resource="competition" />
      <AdminCardLink resource="category" />
      <AdminCardLink resource="league" />
      <AdminCardLink resource="ritual" />
      <AdminCardLink resource="rule" />
      <AdminCardLink resource="submission" />
      <AdminCardLink resource="user" />
    </div>
    <Footer
      resource={currentResource}
    />
  </>
)

type AdminCardLinkProps = {
  resource: string
}

type AdminDashboardUtilMenuProps = {
  currentResource?: string
  isIndexPath?: boolean
  setFilterActive?: () => void
  setQuery?: () => void
}

export default AdminDashboardUtilMenu
