import React, { useEffect, useState } from 'react'
import pluralize from 'pluralize'
import { useDispatch } from 'react-redux'
import { useOutletContext } from 'react-router-dom'

import LoadingContainer from '@/components/LoadingContainer'
import { setAdminFooterType, setIsAdminFooterEnabled } from '@/store/adminFooter'

const AdminIndexResource = ({
  RESOURCE
}: AdminIndexResourceProps) => (
  Component: React.FC
) => (
  props: any
) => {
  const {
    isLoading,
    resource,
    results: resultsContext,
  } = useOutletContext() || {}

  const dispatch = useDispatch()
  // const { isEnabled, mode, type } = useSelector((state) => state.adminFooter)

  useEffect(() => {
    dispatch(setIsAdminFooterEnabled(false))
    dispatch(setAdminFooterType(null))
  }, [])

  const [results, setResults] = useState([])

  useEffect(() => {
    if (resource === RESOURCE && !isLoading) {
      setResults(resultsContext)
    }
  }, [resultsContext, resource, isLoading])

  return (
    <LoadingContainer
      isLoading={isLoading}
      resource={pluralize(RESOURCE)}
      hasContent={!!results?.length}
    >
      <Component
        results={results}
        {...props}
      />
    </LoadingContainer>
  )
}

type AdminIndexResourceProps = {
  RESOURCE: resourceTypes
}

type resourceTypes = 'categories' |
  'challenges' |
  'competitions' |
  'leagues' |
  'rituals' |
  'rules' |
  'submissions' |
  'users'

export default AdminIndexResource
