import React, { useRef } from 'react'
import { isEqual } from 'lodash'

import { useDropdown } from '@/context/dropdown'

import styles from './styles.mod.scss'
import headerClasses from './styles.mod.scss'

const QueriedItem = ({
  item,
  onClick,
}: QueriedItemProps) => {
  const { id, selectedItems } = useDropdown()
  const ref = useRef(null)
  const disabled = selectedItems.some((selectedItem) => isEqual(selectedItem, item))

  const onSelectItem = (e) => {
    e.preventDefault()
    try {
      onClick(e, item)
      document.getElementById(id)
        .getElementsByClassName(headerClasses.activate)[0]
        ?.focus()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <button
      aria-label="Select item"
      className={styles.item}
      disabled={disabled}
      onClick={(e) => onSelectItem(e, item)}
      ref={ref}
    >
      {item.name}
    </button>
  )
}

const QueriedItems = ({
  onClick
}: QueriedItemsProps) => {
  const { resourceName, items } = useDropdown()

  return (
    <div className={styles.wrapper}>
      {items.map((item) => (
        <QueriedItem
          item={item}
          key={`queried_${resourceName}_${item.slug}`}
          onClick={onClick}
        />
      ))}
    </div>
  )
}

type QueriedItemProps = {
  item: any
  onClick: (e: any, item: any) => void
}

type QueriedItemsProps = {
  onClick: (e: any, item: any) => void
}

export default QueriedItems
