import { PropsWithChildren, useEffect } from 'react'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'
import { RootState } from '@/store'
import { ToastContainer, Bounce, Flip } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'

import Menu from '../Menu'
import Header from '../Header'
import UtilityMenu from '../UtilityMenu'
import { setIsLayoutMounted } from '../../../store/components'

import styles from './styles.mod.scss'
import 'react-toastify/dist/ReactToastify.css'

const Layout = ({ children }: PropsWithChildren) => {
  const { pageTitle } = useSelector((state: RootState) => state.pages)
  const { isModalOpen } = useSelector((state: RootState) => state.modal)
  const helmetTitle = pageTitle ? `${pageTitle} | ` : ''

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setIsLayoutMounted(true))
  })

  return (
    <div className={classNames(styles.App, { [styles.modalActive]: isModalOpen })}>
      <Helmet>
        <title>{`${helmetTitle}DZASTR Arena`}</title>
      </Helmet>
      <Menu />
      <UtilityMenu />
      <div className={styles.container}>
        <main id="Layout" className={styles.wrapper}>
          <Header />
          {children}
        </main>
      </div>
      <ToastContainer
        autoClose={500}
        hideProgressBar
        limit={1}
        position="top-center"
        theme="colored"
        // transition={Bounce}
        transition={Flip}
      />
    </div>
  )
}

export default Layout
