import React from 'react'
import dzastrImg from '../../../assets/images/dzastr-coin-green.png'

import styles from './styles.mod.scss'

const BlankUtilMenu = () => (
  <div className="BlankUtilMenu">
    <img src={dzastrImg} alt="" className={styles.img} />
  </div>
)

export default BlankUtilMenu
