import React from 'react'
// import AwsS3 from '@uppy/aws-s3'
// import ThumbnailGenerator from '@uppy/thumbnail-generator'
// import Uppy from '@uppy/core'
import classNames from 'classnames'
import { DragDrop } from '@uppy/react'

// import TimesIcon from '../../assets/images/icons/xmark-solid.svg'
import defaultImgSmall from '@/assets/images/default-background-square-small.png'

import '@uppy/core/dist/style.css'
import '@uppy/status-bar/dist/style.css'
import styles from './styles.mod.scss'

// const IMAGE_FILE_TYPES = [
//   'image/gif',
//   'image/jpeg',
//   'image/jpg',
//   'image/png',
//   'image/x-png',
// ]

const Avatar = ({
  className = '',
  img = '',
}: AvatarProps) => (
  <span className={classNames(styles.base, className)}>
    <img src={img || defaultImgSmall} alt="" />
  </span>
)

// const CurrentImage = ({ image, onClick, name }) => {
//   const [overlayActive, setOverlayActive] = useState(false)

//   return (
//     <button
//       className="CurrentImage"
//       aria-label="avatar"
//       onMouseEnter={() => setOverlayActive(true)}
//       onMouseLeave={() => setOverlayActive(false)}
//       onClick={onClick}
//     >
//       <img
//         src={image}
//         alt="Current"
//         className="Uppy__preview"
//       />
//       <div
//         className={classNames(
//           'CurrentImage__overlay',
//           { active: overlayActive }
//         )}
//       >
//         change {name}
//       </div>
//     </button>
//   )
// }

// export const AvatarButton = ({
//   currentImage: currentImageProp,
//   onClear,
//   onFileAdded,
//   onUploadComplete,
//   name,
// }) => {
//   const [preview, setPreview] = useState('')
//   const [currentImage, setCurrentImage] = useState(currentImageProp)

//   // const uppy = new Uppy({
//   //   meta: { type: 'content' },
//   //   restrictions: {
//   //     allowedFileTypes: IMAGE_FILE_TYPES,
//   //     maxNumberOfFiles: 1
//   //   },
//   //   autoProceed: true,
//   // })

//   // const clearFile = () => {
//   //   setPreview('')
//   //   uppy.reset()
//   //   onClear()
//   // }

//   // useEffect(() => {
//   //   if (!uppy.store.state.plugins['react:DragDrop']) return

//   //   uppy
//   //     .use(ThumbnailGenerator, {
//   //       id: `ThumbnailGenerator:${Math.random()}`,
//   //       thumbnailWidth: 600
//   //     })
//   //     .use(AwsS3, {
//   //       id: `AwsS3:${Math.random()}`,
//   //       companionUrl: API_BASE
//   //     })

//   //   uppy.on('upload-success', (file, _response) => {
//   //     const { size, type, meta } = file
//   //     const id = meta.key.split(`${process.env.REACT_APP_ENV}/cache/`)[1]
//   //     const uploadedFileData = {
//   //       id,
//   //       storage: 'cache',
//   //       metadata: {
//   //         size,
//   //         filename: file.name,
//   //         mime_type: type,
//   //       }
//   //     }

//   //     onUploadComplete(JSON.stringify(uploadedFileData))
//   //   })

//   //   uppy.on('file-added', (_file) => {
//   //     onFileAdded()
//   //   })

//   //   uppy.on('thumbnail:generated', (_file, thumbnail) => {
//   //     setPreview(thumbnail)
//   //   })

//   //   uppy.on('upload-error', (_file, error, _response) => {
//   //     console.error(error);
//   //   })
//   // }, [uppy])

//   return (
//     <div className="AvatarButton">
//       {currentImage ? (
//         <CurrentImage
//           image={currentImage}
//           onClick={onChangeImage}
//           name={name}
//         />
//       ) : (
//         preview ? (
//           <div className="Uppy__preview__wrapper">
//             <img
//               src={preview || ''}
//               alt="Current"
//               className="Uppy__preview"
//             />
//             <button
//               className="MediaUploader__clear"
//               aria-label="Clear media"
//               onClick={clearFile}
//             >
//               <TimesIcon />
//             </button>
//           </div>
//         ) : (
//           <DragDrop
//             uppy={uppy}
//             className="UploadForm"
//             locale={{
//               strings: {
//                 dropHereOr: 'Drop here or %{browse}',
//                 browse: 'browse',
//               },
//             }}
//           />
//         )
//       )}
//     </div>
//   )
// }

type AvatarProps = {
  className?: string
  img?: string
}

// AvatarButton.propTypes = {
//   currentImage: PropTypes.string,
//   onClear: PropTypes.func,
//   onFileAdded: PropTypes.func,
//   onUploadComplete: PropTypes.func,
//   name: PropTypes.string,
// }

// AvatarButton.defaultProps = {
//   currentImage: '',
//   onClear: () => {},
//   onFileAdded: () => {},
//   onUploadComplete: () => {},
//   name: 'avatar',
// }

export default Avatar
