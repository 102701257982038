import { ApiRequest, ApiConfig } from '..'
import { CreateParams, DestroyParams } from '../ApiRequest';

const FavoritesResource = {
  create: ({ data: { kind, association_id } }: CreateParams) => (
    ApiRequest.privatePost(
      ApiConfig.apiUrl('favorites'),
      { favorite: { kind, association_id } },
    )
  ),
  delete: ({ id }: DestroyParams) => (
    ApiRequest.privateDelete(ApiConfig.apiUrl(`favorites/${id}`))
  ),
}

export default FavoritesResource
