import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import { Helmet } from 'react-helmet'
import { Provider as ReduxProvider } from 'react-redux'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createRoot } from 'react-dom/client'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import Layout from './components/layouts/Layout'
import NavigationActions from './components/layouts/NavigationActions'
import Router from './routes/router'
import reportWebVitals from './reportWebVitals'
import store from './store'
import { AuthProvider } from './context/auth'

import './assets/stylesheets/global.scss'

const container = document.getElementById('root')!
const root = createRoot(container)
const queryClient = new QueryClient()

root.render(
  <>
    <Helmet>
      <title>DZASTR Arena</title>
      <meta name="description" content="Challenge yourself." />
    </Helmet>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <CookiesProvider>
        <ReduxProvider store={store}>
          <AuthProvider>
            <BrowserRouter>
              <NavigationActions>
                <Layout>
                  <Router />
                </Layout>
              </NavigationActions>
            </BrowserRouter>
          </AuthProvider>
        </ReduxProvider>
      </CookiesProvider>
    </QueryClientProvider>
  </>
)

serviceWorkerRegistration.register()

reportWebVitals()
