import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import SubmissionsQuery from '../../api/queries/Submissions';
import SubmissionCard from '../../components/cards/submissions/Card';
import CategoriesMultiSelect from '../../components/dropdowns/resources/categories/CategoriesMultiSelect';
import LeaguesMultiSelect from '../../components/dropdowns/resources/leagues/LeaguesMultiSelect';
import Filters from '../../components/Filters';
import PageContent from '../../components/layouts/PageContent';
import PageWrapper from '../../components/layouts/PageWrapper';
import ScrollableResource from '../../components/ScrollableResource';
import { InfiniteScrollProvider } from '../../context/infiniteScroll';
import withScrollTop from '../../hocs/withScrollTop';
import { resetUtilMenu, setUtilMenuContent, setUtilMenuOpts } from '../../store/utilMenu';

const ScrollableSubmissions = () => {
  const dispatch = useDispatch()
  // const { prefilteredParams } = useInfiniteScroll()

  useEffect(() => {
    dispatch(setUtilMenuOpts({ noPadding: true }))
    dispatch(setUtilMenuContent(
      <Filters>
        <CategoriesMultiSelect
          opts={{ urlUpdates: true, noContainer: true }}
        />
        <LeaguesMultiSelect
          opts={{ urlUpdates: true, noContainer: true }}
        />
      </Filters>
    ))

    return () => dispatch(resetUtilMenu())
  }, [])

  return (
    <ScrollableResource
      Component={SubmissionCard}
      resourceName="submission"
    />
  )
}

const ExplorePage = () => (
  <PageWrapper name="Explore" title="Explore">
    <PageContent>
      <InfiniteScrollProvider
        cardClassName="SubmissionCard"
        filters={['categories', 'leagues']}
        query={SubmissionsQuery.getInfiniteIndex}
      >
        <ScrollableSubmissions />
      </InfiniteScrollProvider>
    </PageContent>
  </PageWrapper>
)

export default withScrollTop(ExplorePage)
