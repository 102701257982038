import classNames from 'classnames';

import { useFormPrimary } from '../..';
import sharedStyles from '../../styles.mod.scss';
import FormField from '../FormField';
import styles from './styles.mod.scss';

const FormTextArea = ({
  className = '',
  label = '',
  name = '',
  ...rest
} : FormTextAreaProps) => {
  const { register, formName } = useFormPrimary()

  return (
    <FormField
      className={classNames(sharedStyles.field, className)}
      label={label}
      name={name}
    >
      <textarea
      className={styles.textarea}
        id={`${formName}__${name}`}
        {...register(name)}
        {...rest}
      />
    </FormField>
  )
}

type FormTextAreaProps = {
  className?: string
  label?: string
  name: string
}

export default FormTextArea
