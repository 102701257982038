import React from 'react'
import { Link } from 'react-router-dom'

import { submissionDefault, submissionType } from '../../../../../schemas/types/submission'

import styles from './styles.mod.scss'

const SubmissionTitle = ({
  isLink = false,
  submission = submissionDefault,
}: SubmissionTitleProps) => (
  isLink ? (
    <Link
      aria-label={`View ${submission.name} page`}
      className={styles.title}
      to={`/submissions/${submission.id}`}
    >
      {submission.name}
    </Link>
  ) : (
    <div className={styles.title}>
      {submission.name}
    </div>
  )
)

type SubmissionTitleProps = {
  isLink: boolean,
  submission: submissionType,
}

export default SubmissionTitle
