import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import LocalStorageService from '../../services/LocalStorageService'
import { restrictedPageToast } from '../../common/toasts'

const PrivateRoute = () => {
  const token = LocalStorageService.getAccessToken()
  if (!token) {
    restrictedPageToast()
  }

  return token
    ? <Outlet />
    : <Navigate to="/users/login" replace />
}

export default PrivateRoute
