/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { setterFunction } from './helpers'

// const [isUtilMenuActive, setIsUtilMenuActive] = useState(!pathname.split('/')[1].includes('users'))
const defaultClassName = ''
const defaultContent = undefined
const defaultIcon = undefined
const defaultTitle = ''

export type UtilMenuOpts = {
  noPadding: boolean
  scrollable: boolean
}

const defaultUtilMenuOpts: UtilMenuOpts = {
  noPadding: false,
  scrollable: false,
}

export type UtilMenuState = {
  isUtilMenuActive: boolean
  isUtilMenuCollapsible: boolean
  isUtilMenuEnabled: boolean
  isUtilMenuMobileActive: boolean
  utilIconLink: Element | undefined
  utilIconOnClick: Function | undefined
  utilMenuClassName: string
  utilMenuContent: Element |undefined
  utilMenuIcon: Element | undefined
  utilMenuOpts: UtilMenuOpts
  utilMenuTitle: string | undefined
}

const defaultUtilMenuState: UtilMenuState = {
  isUtilMenuActive: true,
  isUtilMenuCollapsible: false,
  isUtilMenuEnabled: true,
  isUtilMenuMobileActive: false,
  utilIconLink: undefined,
  utilIconOnClick: undefined,
  utilMenuClassName: defaultClassName,
  utilMenuContent: defaultContent,
  utilMenuIcon: defaultIcon,
  utilMenuOpts: defaultUtilMenuOpts,
  utilMenuTitle: defaultTitle,
}

// export type Payload = (UtilMenuState)[keyof UtilMenuState]

export const utilMenuSlice = createSlice({
  name: 'utilMenu',
  initialState: defaultUtilMenuState,
  reducers: {
    resetUtilMenu: () => defaultUtilMenuState,
    setIsUtilMenuActive: setterFunction<UtilMenuState>('isUtilMenuActive'),
    setIsUtilMenuCollapsible: setterFunction<UtilMenuState>('isUtilMenuCollapsible'),
    setIsUtilMenuEnabled: setterFunction<UtilMenuState>('isUtilMenuEnabled'),
    setIsUtilMenuMobileActive: setterFunction<UtilMenuState>('isUtilMenuMobileActive'),
    setUtilIconLink: setterFunction<UtilMenuState>('utilIconLink'),
    setUtilIconOnClick: setterFunction<UtilMenuState>('utilIconOnClick'),
    setUtilMenuClassName: setterFunction<UtilMenuState>('utilMenuClassName'),
    setUtilMenuContent: setterFunction<UtilMenuState>('utilMenuContent'),
    setUtilMenuIcon: setterFunction<UtilMenuState>('utilMenuIcon'),
    setUtilMenuOpts: setterFunction<UtilMenuState>('utilMenuOpts'),
    setUtilMenuTitle: setterFunction<UtilMenuState>('utilMenuTitle'),
    utilMenuClick: (state) => {
      state.isUtilMenuActive = state.isUtilMenuEnabled && !state.isUtilMenuActive
    },
    utilMenuMobileClick: (state) => {
      state.isUtilMenuMobileActive = state.isUtilMenuEnabled && !state.isUtilMenuMobileActive
    },
  },
})

export const {
  resetUtilMenu,
  setIsUtilMenuActive,
  setIsUtilMenuCollapsible,
  setIsUtilMenuEnabled,
  setIsUtilMenuMobileActive,
  setUtilIconLink,
  setUtilIconOnClick,
  setUtilMenuClassName,
  setUtilMenuContent,
  setUtilMenuIcon,
  setUtilMenuOpts,
  setUtilMenuTitle,
  utilMenuClick,
  utilMenuMobileClick,
} = utilMenuSlice.actions

export default utilMenuSlice.reducer
