import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import UsersMutation from '../../../../api/mutations/Users'

import CategoriesMultiSelect from '../../../dropdowns/resources/categories/CategoriesMultiSelect'
import FormPrimary, { FormButtons, FormInput, FormInputs, FormTextArea } from '../../FormPrimary'
import { ButtonPrimary } from '../../../../common/buttons/Button'
import { profileSchema } from '../../../../schemas/forms/profile'
import { userDefault, userType } from '../../../../schemas/types/user'
import { categoryType } from '@/schemas/types/category'

import styles from './styles.mod.scss'

const MAX_CATEGORIES = 3

const ProfileDetailsForm = ({
  user = userDefault
}: ProfileDetailsFormProps) => {
  const { id } = user

  const updateUser = UsersMutation.update({ id, successMessage: 'Profile updated.' })
  const { isLoading } = updateUser

  const buttonText = isLoading ? 'Please wait...' : 'Update profile'

  const form = useForm({
    resolver: yupResolver(profileSchema),
    defaultValues: user,
  })

  const { reset, setValue } = form

  const onSubmit = (data: FormData) => {
    updateUser.mutate({ id, data })
  }

  const onCategoriesChange = (cats: categoryType[]) => {
    setValue('category_ids', cats.map((cat) => cat.id))
  }

  useEffect(() => {
    reset(user)
  }, [user])

  return (
    <FormPrimary
      form={form}
      name={styles.base}
      onSubmit={onSubmit}
    >
      <FormInputs>
        <FormInput name="name" />
        <FormInput name="email" type="email" />
        <FormTextArea name="bio" />
        <CategoriesMultiSelect
          maxCategories={MAX_CATEGORIES}
          onChange={onCategoriesChange}
          preselectedSlugs={user.categories.map((cat) => cat.slug)}
        />
      </FormInputs>
      <FormButtons>
        <ButtonPrimary
          disabled={isLoading || isLoading}
          label={buttonText}
          scale={0.85}
          type="submit"
        >
          {buttonText}
        </ButtonPrimary>
      </FormButtons>
    </FormPrimary>
  )
}

type ProfileDetailsFormProps = {
  user: userType,
}

export default ProfileDetailsForm
