import React, { createContext, useContext } from 'react'

import { Uploader } from './components/Uploader'
import MediaUploaderProvider from './context'

const MediaUploaderContext = createContext(null)

export const useMediaUploader = () => useContext(MediaUploaderContext)

const MediaUploader = ({
  mediaType = 'image',
  onFileAdded = () => {},
  ...props
}: MediaUploaderProps) => (
  <MediaUploaderProvider
    mediaType={mediaType}
    onFileAdded={onFileAdded}
    {...props}
  >
    <Uploader />
  </MediaUploaderProvider>
)

type MediaUploaderProps = {
  currentFile?: string
  mediaType?: string
  name: string
  onClear: () => void
  onFileAdded?: () => void
  onUploadComplete: () => void
}

export default MediaUploader
