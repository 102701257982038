import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { ObjectSchema } from 'yup'

import Reward from '../../../Reward'
import RewardForm from '../RewardForm'
import { ButtonSecondaryInverted } from '../../../../common/buttons/Button'
import { rewardType } from '@/schemas/types/reward'
import { competitionRewardSchema } from '@/schemas/forms/competitionReward'
import { challengeRewardSchemaType } from '@/schemas/forms/challengeReward'
import { ritualRewardSchemaType } from '../../../../schemas/forms/ritualReward'

import TimesIcon from '../../../../assets/images/icons/times-circle-solid.svg'
import EditIcon from '../../../../assets/images/icons/pencil-regular.svg'

import styles from './styles.mod.scss'

const RewardForms = ({
  deleteConfirmationMessage = 'Are you sure?',
  kind,
  resourceId,
  resourceName,
  rewardSchema,
  rewards = [],
  queriesToInvalidate = [[]],
}: RewardFormsProps) => {
  const [activeReward, setActiveReward] = useState(null)
  const [rewardFormActive, setRewardFormActive] = useState(false)

  const addReward = () => {
    setActiveReward(null)
    setRewardFormActive(true)
  }

  const onRewardClick = (reward: rewardType) => {
    setActiveReward(reward)
    setRewardFormActive(true)
  }

  const afterMutation = () => {
    setRewardFormActive(false)
    setActiveReward(null)
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, true)

    return () => {
      document.removeEventListener('keydown', onKeyDown, true)
    }
  }, [])

  const onKeyDown = (e) => {
    if (e.key === 'Escape') setRewardFormActive(false)
  }

  useEffect(() => {
    if (rewardFormActive) {
      document.querySelector('input#RewardForm__value')?.focus()
    } else {
      setActiveReward(null)
    }
  }, [activeReward, rewardFormActive])

  return (
    <div className="AssociationForms AssociationForms">
      <h2>Rewards</h2>
      <div className="AssociationForms__container">
        {rewards.map((reward) => (
          <button
            aria-label="Edit reward"
            className={classNames('AssociationForms__reward__container', { active: activeReward === reward })}
            onClick={() => onRewardClick(reward)}
            key={`AssociationForms__rewards--${reward.id}`}
          >
            <Reward
              key={`AssociationForms__reward--${reward.id}`}
              reward={reward}
            />
            <EditIcon />
          </button>
        ))}
      </div>
      {rewardFormActive ? (
        <div className="AssociationForm__container">
          <h2>{`${activeReward ? 'Edit' : 'Add'} Reward`}</h2>
          <button
            aria-label="Close edit menu"
            className="AssociationForm__close"
            onClick={() => setRewardFormActive(false)}
          >
            <TimesIcon />
          </button>
          {activeReward ? (
            <RewardForm
              afterMutation={afterMutation}
              deleteConfirmationMessage={deleteConfirmationMessage}
              kind={kind}
              queriesToInvalidate={queriesToInvalidate}
              resource={resourceName}
              resourceId={resourceId}
              reward={activeReward}
              rewardSchema={rewardSchema}
              updateText="Update Reward"
            />
          ) : (
            <RewardForm
              afterMutation={afterMutation}
              deleteConfirmationMessage={deleteConfirmationMessage}
              kind={kind}
              queriesToInvalidate={queriesToInvalidate}
              resource={resourceName}
              resourceId={resourceId}
              rewardSchema={rewardSchema}
              updateText={`Add Reward to ${resourceName}`}
            />
          )}
        </div>
      ) : null}
      {!rewardFormActive ? (
        <ButtonSecondaryInverted label="Add Reward" scale={0.85} onClick={addReward}>
          Add Reward
        </ButtonSecondaryInverted>
      ) : null}
    </div>
  )
}

type RewardFormsProps = {
  deleteConfirmationMessage?: string,
  kind: string,
  resourceId: number,
  resourceName: string,
  rewardSchema: ObjectSchema<ritualRewardSchemaType | competitionRewardSchema | challengeRewardSchemaType>,
  rewards: Array<rewardType>,
  queriesToInvalidate: Array<Array<string | number | object>>,
}

export default RewardForms
