import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import RitualsQuery from '@/api/queries/Rituals'

import LoadingContainer from '@/components/LoadingContainer'
import RitualForm from '@/components/forms/rituals/RitualForm'
import RitualRewardForm from '@/components/forms/rewards/RitualRewardForm'
import { setPageTitle } from '@/store/pages'

const AdminRitualsEdit = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { data, isLoading } = RitualsQuery.getShow({ id })

  useEffect(() => {
    dispatch(setPageTitle('Edit Ritual'))
  }, [])

  return (
    <LoadingContainer
      hasContent={!!data}
      isLoading={isLoading}
      resource="rituals"
    >
      <RitualForm ritual={data} />
      <RitualRewardForm ritual={data} />
    </LoadingContainer>
  )
}

export default AdminRitualsEdit
