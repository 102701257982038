import React from 'react'
import classNames from 'classnames'

import Cards from '@/components/cards/Cards'

import styles from './styles.mod.scss'

export const TableHeader = ({ children }: TableHeaderProps) => (
  <div className={styles.header}>
    {children}
  </div>
)

export const TableHeaderCol = ({
  className = '',
  name = '',
}: TableHeaderColProps) => (
  <div className={classNames(styles.headerCol, className)}>
    {name}
  </div>
)

export const TableContent = ({ children }: TableContentProps) => (
  <div className={styles.contentWrapper}>
    {children}
  </div>
)

const AdminTable = ({ children }: AdminTableProps) => (
  <Cards verticalAlign='start'>
    {children}
  </Cards>
)

type AdminTableProps = {
  children: React.ReactNode
}

type TableHeaderProps = {
  children: React.ReactNode
}

type TableHeaderColProps = {
  className?: string
  name?: string
}

type TableContentProps = {
  children: React.ReactNode
}

export default AdminTable
