import React, { useState } from 'react'
import classNames from 'classnames'

import Reward from '@/components/Reward'
import LinkButton, { LinkButtonPrimary } from '@/common/buttons/LinkButton'
import { challengeDefault, challengeType } from '@/schemas/types/challenge'

import ChevronRight from '@/assets/images/icons/chevron-right.svg'

import styles from './styles.mod.scss'

const ChallengeCard = ({
  challenge = challengeDefault
}: ChallengeCardProps) => {
  const [open, setOpen] = useState(false)

  const { description, id, name, rewards } = challenge

  return (
    <div className={classNames('ChallengeCard', { [styles.open]: open })}>
      <button
        className={styles.head}
        aria-label="Select challenge"
        onClick={() => setOpen(!open)}
      >
        <div className={styles.title_dropdown}>
          <ChevronRight className={styles.chevron} />
          <div className={styles.title}>
            {name}
          </div>
        </div>
        <div className={styles.rewards}>
          {rewards.map((reward) => (
            <Reward
              className={styles.reward}
              reward={reward}
              key={`ChallengeCard__reward--${reward.id}`}
            />
          ))}
        </div>
      </button>
      <div className={styles.body}>
        <p className={styles.description}>
          {description}
        </p>
        <div className={styles.actions}>
          <LinkButton
            className={styles.more_details}
            full={false}
            label="More details"
            scale={0.65}
            to={`/challenges/${id}`}
          >
            More details
          </LinkButton>
          <LinkButtonPrimary
            className={styles.complete}
            full={false}
            label="Start challenge"
            scale={0.75}
            to={`/challenges/${id}/submissions/new`}
          >
            start challenge
            <ChevronRight />
          </LinkButtonPrimary>
        </div>
      </div>
    </div>
  )
}

type ChallengeCardProps = {
  challenge: challengeType,
}

export default ChallengeCard
