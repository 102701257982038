import React from 'react'
import classNames from 'classnames'

import styles from './styles.mod.scss'

const PageContent = ({
  children,
  className = '',
  layout = 'col',
  name = '',
}: PageContentProps) => (
  <div
    className={classNames(
      styles.base,
      className,
      styles[layout]
    )}
    id={name}
  >
    { children }
  </div>
)

type PageContentProps = {
  children: React.ReactNode,
  className?: string,
  layout?: string,
  name?: string,
}

export default PageContent
