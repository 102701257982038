import React from 'react'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import FormPrimary, { FormButtons, FormInput, FormInputs } from '@/components/forms/FormPrimary'
import UsersResource from '@/api/resources/Users'
import { ButtonPrimary } from '@/common/buttons/Button'
import { passwordSchema } from '@/schemas/forms/password'

const PasswordForm = () => {
  const form = useForm({ resolver: yupResolver(passwordSchema) })

  const onSubmit = async (data) => {
    try {
      await UsersResource.updatePassword(data)
      toast.success('Password updated.')
    } catch (error) {
      toast.error(error.response.data.message)
    }
  }

  return (
    <FormPrimary
      form={form}
      name="PasswordForm"
      onSubmit={onSubmit}
    >
      <FormInputs>
        <FormInput label="Current Password" name="current_password" type="password" />
        <FormInput label="New Password" name="password" type="password" />
        <FormInput label="Password Confirmation" name="password_confirmation" type="password" />
      </FormInputs>
      <FormButtons>
        <ButtonPrimary label="Save password" type="submit" scale={0.85}>
          Save password
        </ButtonPrimary>
      </FormButtons>
    </FormPrimary>
  )
}

export default PasswordForm
