import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import CategoriesResource from '../../../../api/resources/Categories'
import ChallengesMutation from '../../../../api/mutations/Challenges'

import FormPrimary, { FormButtons, FormInput, FormInputs, FormSelect, FormTextArea } from '../../FormPrimary'
import { ButtonPrimary } from '../../../../common/buttons/Button'
import { challengeDefault, challengeType } from '../../../../schemas/types/challenge'
import { challengeSchema } from '../../../../schemas/forms/challenge'

import styles from './styles.mod.scss'

const ChallengeForm = ({
  afterUpdate = () => {},
  challenge = challengeDefault,
}: ChallengeFormProps) => {
  const form = useForm({
    resolver: yupResolver(challengeSchema),
    defaultValues: challenge,
  })

  const createChallenge = ChallengesMutation.create()
  const updateChallenge = ChallengesMutation.update()

  const onSubmit = async (data: FormData) => {
    if (challenge.id) {
      updateChallenge.mutate({ id: challenge.id, data })
    } else {
      const response = await createChallenge.mutateAsync({ data })
      response.status === 201 && afterUpdate(response.data)
    }
  }

  return (
    <FormPrimary
      name={styles.base}
      form={form}
      onSubmit={onSubmit}
    >
      <FormInputs>
        <FormInput name="name" />
        <FormTextArea name="description" />
        <FormSelect
          fetchQuery={CategoriesResource.getSearch}
          field="category_id"
          name="category"
          initial={challenge.category}
        />
      </FormInputs>
      <FormButtons>
        <ButtonPrimary label="Save challenge" scale={0.85} type="submit">
          Save challenge
        </ButtonPrimary>
      </FormButtons>
    </FormPrimary>
  )
}

type ChallengeFormProps = {
  afterUpdate?: (challenge: challengeType) => void,
  challenge?: challengeType,
}

export default ChallengeForm
