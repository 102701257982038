import React from 'react'

import styles from './styles.mod.scss'
import classNames from 'classnames'

const FormError = ({
  children,
  className = '',
  name = 'field',
  present = undefined,
}: FormErrorProps) => {
  if (!present) return null

  return (
    <div className={classNames(styles.base, className)} id={`FormError__${name}`}>
      {children}
    </div>
  )
}

type FormErrorProps = {
  children?: React.ReactNode
  className?: string
  name?: string
  present?: boolean
}

export default FormError
