import React from 'react'

import ContactCard from '../../cards/contacts/Card'
import ContactsQuery from '../../../api/queries/Contacts'
import ScrollableResource from '../../ScrollableResource'
import { InfiniteScrollProvider } from '../../../context/infiniteScroll'

import './styles.mod.scss'

const MessagesUtilMenu = () => (
  <div className="MessagesUtilMenu">
    <InfiniteScrollProvider
      cardClassName="ContactCard"
      query={ContactsQuery.getInfiniteIndex}
    >
      <ScrollableResource
        Component={ContactCard}
        resourceName="contact"
      />
    </InfiniteScrollProvider>
  </div>
)

export default MessagesUtilMenu
