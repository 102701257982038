import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import NotificationsQuery from '@/api/queries/Notifications'

import Cards from '@/components/cards/Cards'
import LoadingContainer from '@/components/LoadingContainer'
import NotificationCard from '@/components/cards/notifications/Card'
import NotificationsUtilMenu from '@/components/utilityMenu/Notifications'
import PageContent from '@/components/layouts/PageContent'
import PageWrapper from '@/components/layouts/PageWrapper'
import Pages from '@/components/Pages'
import withProvider from '@/hocs/withProvider'
import withScrollTop from '@/hocs/withScrollTop'
import { PagesProvider, usePages } from '@/context/pages'
import { resetUtilMenu, setUtilMenuContent } from '@/store/utilMenu'
import { scrollToTop } from '@/components/layouts/NavigationActions'

import styles from './styles.mod.scss'

const NotificationsListPage = () => {
  const dispatch = useDispatch()
  const { currentPage, setTotalPages } = usePages()
  const { data, isLoading } = NotificationsQuery.getIndex({ page: currentPage })

  const { results, total_pages } = data || { results: [], total_pages: 0 }

  useEffect(() => {
    dispatch(setUtilMenuContent(<NotificationsUtilMenu />))

    return () => dispatch(resetUtilMenu())
  }, [])

  useEffect(() => {
    scrollToTop()
  }, [currentPage])

  useEffect(() => {
    setTotalPages(total_pages)
  }, [total_pages])

  return (
    <PageWrapper name="NotificationsIndex" title="Notifications">
      <PageContent>
        <LoadingContainer
          isLoading={isLoading}
          hasContent={!!results?.length}
          resource="notifications"
        >
          <Cards className={styles.cards} tapered>
            {results?.map((notification) => (
              <NotificationCard
                notification={notification}
                key={`notification-${notification.id}`}
              />
            ))}
          </Cards>
        </LoadingContainer>
        <Pages />
      </PageContent>
    </PageWrapper>
  )
}

export default withScrollTop(
  withProvider(PagesProvider)(NotificationsListPage)
)
