import * as yup from 'yup'

export const competitionSchema = yup.object({
  description: yup.string()
    .trim()
    .max(400, 'Description must be less than 400 characters.')
    .required('Description required.'),
  end_time: yup.string(),
  start_time: yup.string(),
  name: yup.string()
    .trim()
    .max(40, 'Name must be less than 40 characters.')
    .required('Name required.'),
  logo: yup.object()
    .nullable()
}).required()
