import React from 'react'
import classNames from 'classnames'

import { userAction } from '../../../../../common/helpers'

import styles from './styles.mod.scss'

const UserBackgroundAction = ({
  Icon,
  className = undefined,
  label = undefined,
  onClick = () => {},
  text,
}: UserBackgroundActionProps) => (
  <button
    aria-label={label || text}
    className={classNames(styles.wrapper, className)}
    onClick={userAction(onClick)}
  >
    <Icon />
    <span className={styles.text}>{text}</span>
  </button>
)

type UserBackgroundActionProps = {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>,
  className?: string | undefined,
  label?: string | undefined,
  onClick: () => void,
  text: string,
}

export default UserBackgroundAction
