import React from 'react'

import Badge from '../Badge'
import { challengeDefault, challengeType } from '@/schemas/types/challenge'

const ChallengeBadge = ({
  challenge = challengeDefault
}: ChallengeBadgeProps) => (
  <Badge color="white" className="ChallengeBadge">
    {challenge.name}
  </Badge>
)

type ChallengeBadgeProps = {
  challenge: challengeType,
}

export default ChallengeBadge
