import React from 'react'
import classNames from 'classnames'

import { usePages } from '../../../context/pages'

import ChevronLeft from '../../../assets/images/icons/chevron-left.svg'
import ChevronRight from '../../../assets/images/icons/chevron-right.svg'

import styles from './styles.mod.scss'

const PageDots = () => (
  <span className={styles.pageDots}>...</span>
)

const MiddlePages = () => {
  const { currentPage, totalPages } = usePages()

  return (
    currentPage > 2 && currentPage < totalPages - 2 ? (
      <>
        {currentPage > 4 ? <PageDots /> : null}
        {currentPage > 3
          ? (
            <PageButton key={`pb-${currentPage - 1}`} page={currentPage - 1} />
          ) : null}
        <PageButton key={`pb-${currentPage}`} page={currentPage} />
        {currentPage < totalPages - 2
          ? (
            <PageButton key={`pb-${currentPage + 1}`} page={currentPage + 1} />
          ) : null}
        {currentPage < totalPages - 3 ? <PageDots /> : null}
      </>
    ) : (currentPage > 2) ? (
      <>
        <PageDots />
        <PageButton key={`pb-${totalPages - 3}`} page={totalPages - 3} />
        <PageButton key={`pb-${totalPages - 2}`} page={totalPages - 2} />
      </>
    ) : (currentPage < totalPages - 2) ? (
      <>
        <PageButton key="pb-3" page={3} />
        <PageButton key="pb-4" page={4} />
        <PageDots />
      </>
    ) : (
      null
    )
  )
}

export const PrevButton = () => {
  const { decrement, currentPage } = usePages()

  return (
    <button
      aria-label="Previous page"
      className={[styles.button, styles.prevButton].join(' ')}
      disabled={currentPage <= 1}
      onClick={currentPage > 1 ? decrement : null}
    >
      <ChevronLeft />
      <span>PREV</span>
    </button>
  )
}

export const NextButton = () => {
  const { currentPage, totalPages, increment } = usePages()

  return (
    <button
      aria-label="Next page"
      className={[styles.button, styles.nextButton].join(' ')}
      disabled={currentPage >= totalPages}
      onClick={currentPage < totalPages ? increment : null}
    >
      <span>NEXT</span>
      <ChevronRight />
    </button>
  )
}

export const PageButton = ({
  page
}: PageButtonProps) => {
  const { currentPage, setPage } = usePages()

  return (
    <button
      aria-label={`Select page ${page}`}
      className={classNames(
        styles.button,
        styles.pageButton,
        { [styles.active]: currentPage === page }
      )}
      onClick={() => setPage(page)}
    >
      {page}
    </button>
  )
}

export const PageButtons = () => {
  const { totalPages } = usePages()

  return (
    <div className={styles.pageButtons}>
      {totalPages > 6 ? (
        <>
          {[1, 2].map((page) => (
            <PageButton key={`pb-${page}`} page={page} />
          ))}
          <MiddlePages />
          {[totalPages - 1, totalPages].map((page) => (
            <PageButton key={`pb-${page}`} page={page} />
          ))}
        </>
      ) : (
        [...Array(totalPages)].map((e, i) => (
          <PageButton key={`pb-${i + 1}`} page={i + 1} />
        ))
      )}
    </div>
  )
}

type PageButtonProps = {
  page: number,
}
