/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from 'react'

import SearchIcon from '../../../../assets/images/icons/search.svg'

import styles from './styles.mod.scss'

const restrictedListenerElements = ['input']

const AdminSearchBar = ({
  enabled = false,
  onActiveChange,
  onQueryChange,
  resource,
}: AdminSearchBarProps) => {
  if (!enabled) return null

  const [active, setActive] = useState(false)
  const [value, setValue] = useState('')

  const searchBarInputRef = useRef(null)

  const onFKey = (e) => {
    const { tagName } = document.activeElement
    const restricted = restrictedListenerElements.indexOf(tagName.toLowerCase()) > -1

    if (!restricted && e.key === 'f' && !e.metaKey) {
      searchBarInputRef.current?.focus()
      e.preventDefault()
    } else if (restricted && e.key === 'Escape') {
      searchBarInputRef.current.blur()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onFKey, false)

    return () => document.removeEventListener('keydown', onFKey, false)
  }, [])

  useEffect(() => {
    onActiveChange(active)
  }, [active])

  useEffect(() => {
    onQueryChange(value)
    setActive(value.length > 2)
  }, [value])

  return (
    <div className={styles.container}>
      <form className={styles.form}>
        <SearchIcon className={styles.svg} />
        <input
          className={styles.input}
          onChange={(e) => setValue(e.target.value)}
          placeholder={`Filter ${resource}`}
          ref={searchBarInputRef}
          type="text"
          value={value}
        />
      </form>
    </div>
  )
}

type AdminSearchBarProps = {
  enabled?: boolean
  onActiveChange: (active: boolean) => void
  onQueryChange: (query: string) => void
  resource: string
}

export default AdminSearchBar
