// Must have this as a separate file because of circular dependencies...
import React, { createContext, useContext, useMemo, useRef, useState } from 'react'
import AwsS3 from '@uppy/aws-s3'
import StatusBar from '@uppy/status-bar'
import ThumbnailGenerator from '@uppy/thumbnail-generator'
import Uppy from '@uppy/core'

import { MEDIA_FILE_TYPES } from './types'
import { pluginOpts } from './config/plugins'
import { actions } from './config/actions'

const defaultContext: MediaUploaderContextProps = {
  currentFile: '',
  isPreviewPresent: false,
  mediaType: 'image',
  name: '',
  onClear: () => {},
  onUploadComplete: () => {},
  preview: '',
  progressRef: null,
  setPreview: () => {},
  setVideoSrc: () => {},
  uppy: null,
  videoSrc: '',
}

const MediaUploaderContext: React.Context<MediaUploaderContextProps> = createContext(defaultContext)

export const useMediaUploader = () => useContext(MediaUploaderContext)

const MediaUploaderProvider = ({
  children,
  currentFile = '',
  mediaType = 'image',
  name = '',
  onClear = () => {},
  onFileAdded = () => {},
  onUploadComplete = () => {},
} : MediaUploaderProviderProps) => {
  const progressRef = useRef(null)
  const [preview, setPreview] = useState(currentFile)
  const [videoSrc, setVideoSrc] = useState('')
  const isPreviewPresent = (mediaType === 'image' && !preview)
    || (mediaType === 'video' && (videoSrc === '' || !videoSrc))

  const uppy = new Uppy({
    meta: { type: 'content' },
    restrictions: {
      allowedFileTypes: MEDIA_FILE_TYPES[mediaType],
      maxNumberOfFiles: 1
    },
    autoProceed: true,
  })

  uppy
    .use(StatusBar, pluginOpts.StatusBar(progressRef.current))
    .use(ThumbnailGenerator, pluginOpts.ThumbnailGenerator)
    .use(AwsS3, pluginOpts.AwsS3)

  uppy.on('upload-success', actions.onUploadSuccess(onUploadComplete, setVideoSrc))
  uppy.on('thumbnail:generated', actions.onThumbnailGenerated(setPreview))
  uppy.on('upload-error', actions.onUploadError)
  // uppy.on('file-added', (_file) => onFileAdded() )
  uppy.on('file-added', onFileAdded)

  const value = useMemo(() => (
    {
      currentFile,
      isPreviewPresent,
      mediaType,
      name,
      onClear,
      onUploadComplete,
      preview,
      progressRef,
      setPreview,
      setVideoSrc,
      uppy,
      videoSrc,
    }
  ), [isPreviewPresent, mediaType, preview, progressRef, uppy, videoSrc])

  return (
    <MediaUploaderContext.Provider value={value}>
      {children}
    </MediaUploaderContext.Provider>
  )
}

type MediaUploaderContextProps = {
  currentFile?: string
  isPreviewPresent: boolean
  mediaType: string
  name: string
  onClear: () => void
  onUploadComplete: () => void
  preview: string
  progressRef: any
  setPreview: (preview: string) => void
  setVideoSrc: (videoSrc: string) => void
  uppy: any
  videoSrc: string
}

type MediaUploaderProviderProps = {
  children: React.ReactNode
  currentFile?: string
  mediaType: string
  name: string
  onClear: () => void
  onFileAdded: () => void
  onUploadComplete: () => void
}

export default MediaUploaderProvider
