import { currencyDefault, currencyType } from './currency'

export const rewardDefault: rewardType = {
  currency: currencyDefault,
  id: undefined,
  value: 0,
}

export type rewardType = {
  challenge_id?: number | undefined
  competition_id?: number | undefined
  currency: currencyType
  id: number | undefined
  value: number
}
