import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import Pages from '@/components/Pages'
import withProvider from '@/hocs/withProvider'
import withScrollTop from '@/hocs/withScrollTop'
import { PagesProvider, usePages } from '@/context/pages'
import { fetchResourceFn } from '../fetchResourceFn'
import { scrollToTop } from '@/components/layouts/NavigationActions'

const AdminListPath = ({
  filterActive = false,
  searchValue = '',
  resource = 'default',
}: AdminListPathProps) => {
  const restrictedListenerElements = ['input', 'textarea', 'select']
  const fetchQuery = fetchResourceFn(resource, filterActive)

  const navigate = useNavigate()
  const { currentPage, setPage, setTotalPages } = usePages()

  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [query, setQuery] = useState(searchValue)

  const { data, isLoading } = fetchQuery({ page: currentPage, query })
  const { results, total_pages } = data || { results: [], total_pages: 0 }

  const onKeyDown = (e) => {
    const { tagName } = document.activeElement
    if (
      e.key === 'n'
        && restrictedListenerElements.indexOf(tagName.toLowerCase()) === -1
    ) {
      navigate(`/admin/${resource}/new`)
    }
  }

  useEffect(() => {
    scrollToTop()
  }, [currentPage])

  useEffect(() => {
    if (!isInitialLoad) {
      setPage(1)
    }
  }, [query, resource])

  useEffect(() => {
    if (filterActive && searchValue.length > 2) {
      setQuery(searchValue)
    }
  }, [filterActive, searchValue])

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, true)

    return () => {
      document.removeEventListener('keydown', onKeyDown, true)
    }
  }, [resource])

  useEffect(() => {
    isInitialLoad && setIsInitialLoad(false)
  }, [results])

  useEffect(() => {
    if (!isLoading) {
      setTotalPages(total_pages)
    }
  }, [isLoading, total_pages])

  const outletContext = useMemo(() => ({
    isLoading, resource, results,
  }), [isLoading, resource, results])

  return (
    <>
      <Outlet context={outletContext} />
      <Pages tapered={false} />
    </>
  )
}

type AdminListPathProps = {
  filterActive?: boolean
  searchValue?: string
  resource?: string
}

export default withScrollTop(withProvider(PagesProvider)(AdminListPath))
