import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'

import UsersResource from '../../../../api/resources/Users'

import AuthForm, { AuthButtons, AuthOptions } from '../AuthForm'
import LocalStorageService from '../../../../services/LocalStorageService'
import { FormInput, FormInputs, FormSubmit } from '../../FormPrimary'
import { LinkButtonPrimaryInverted } from '../../../../common/buttons/LinkButton'
import { loginSchema } from '../../../../schemas/forms/auth'
import { useAuth } from '../../../../context/auth'

import EmailIcon from '../../../../assets/images/icons/envelope.svg'
import LockIcon from '../../../../assets/images/icons/lock.svg'

import styles from './styles.mod.scss'
import { RootState } from '@/store'

const LoginForm = () => {
  const form = useForm({ resolver: yupResolver(loginSchema) })
  const { setFocus } = form

  const navigate = useNavigate()
  const { lastPage } = useSelector((state: RootState) => state.pages)
  const { signIn } = useAuth()

  const onSubmit = async (data: FormData) => {
    try {
      const response = await UsersResource.authenticate(data)
      signIn(response.data)
      LocalStorageService.setUser(response.data)
      navigate(lastPage)
    } catch (error) {
      toast.error('Login failed')
      console.error(error)
    }
  }

  useEffect(() => {
    setFocus('email')
  }, [])

  return (
    <AuthForm
      className={styles.base}
      onSubmit={onSubmit}
      form={form}
    >
      <FormInputs>
        <FormInput Icon={EmailIcon} name="email" type="email" />
        <FormInput Icon={LockIcon} name="password" type="password" />
      </FormInputs>
      <AuthOptions>
        <Link aria-label="Forgot your password?" to="/users/password/reset">
          Forgot your password?
        </Link>
      </AuthOptions>

      <AuthButtons>
        <FormSubmit scale={1} text="Log in" />
        <LinkButtonPrimaryInverted
          label="Sign up"
          scale={1}
          to="/users/signup"
        >
          Sign Up
        </LinkButtonPrimaryInverted>
      </AuthButtons>
    </AuthForm>
  )
}

export default LoginForm
