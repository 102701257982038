import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import AdminIndexResource from '@/hocs/AdminIndexResource'
import AdminTable, { TableContent, TableHeader, TableHeaderCol } from '../../Dashboard/AdminTable'
import CompetitionCardAdmin from '@/components/cards/competitions/CardAdmin'
import { competitionType } from '@/schemas/types/competition'
import { setAdminFooterType, setIsAdminFooterEnabled } from '@/store/adminFooter'

import styles from './styles.mod.scss'

const RESOURCE = 'competitions'

const AdminCompetitions = ({
  results = [],
}: AdminCompetitionsProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setAdminFooterType('newLink'))
    dispatch(setIsAdminFooterEnabled(true))
  }, [])

  return (
    <AdminTable>
      <TableHeader>
        <TableHeaderCol className={styles.logo} />
        <TableHeaderCol className={styles.name} name="name" />
        <TableHeaderCol className={styles.description} name="description" />
        <TableHeaderCol className={styles.category} name="category" />
        <TableHeaderCol className={styles.rewards} name="rewards" />
        <TableHeaderCol className={styles.actions} />
      </TableHeader>

      <TableContent>
        {results?.map((competition) => (
          <CompetitionCardAdmin
            competition={competition}
            key={`competition-${competition.id}`}
          />
        ))}
      </TableContent>
    </AdminTable>
  )
}

type AdminCompetitionsProps = {
  results: Array<competitionType>
}

export default AdminIndexResource({ RESOURCE })(AdminCompetitions)
