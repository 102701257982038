import React from 'react'

import UserCardExpanded from '../../../../components/cards/users/CardExpanded'
import { userDefault, userType } from '../../../../schemas/types/user'

import styles from './styles.mod.scss'

const ProfileTab = ({
  user = userDefault,
}: ProfileTabProps) => (
  <div className={styles.tab}>
    <UserCardExpanded user={user} />
  </div>
)

type ProfileTabProps = {
  user: userType,
}

export default ProfileTab
