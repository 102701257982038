import React, { useEffect } from 'react'
// import { useQuery } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// import LeaguesQuery from '@/api/queries/Leagues'

import BlankUtilMenu from '@/components/utilityMenu/Blank'
import PageContent from '@/components/layouts/PageContent'
import PageWrapper from '@/components/layouts/PageWrapper'
import SubmissionForm from '@/components/forms/SubmissionForm'
import withScrollTop from '@/hocs/withScrollTop'
import { resetUtilMenu, setUtilMenuContent } from '@/store/utilMenu'

import styles from './styles.mod.scss'

const SubmissionsNewPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const afterMutation = () => {
    navigate('/explore')
  }

  useEffect(() => {
    dispatch(setUtilMenuContent(<BlankUtilMenu />))

    return () => dispatch(resetUtilMenu())
  }, [])

  return (
    <PageWrapper name="SubmissionsNew" layout="form" title="New Submission">
      <PageContent layout="form">
        <SubmissionForm afterMutation={afterMutation} />
      </PageContent>
    </PageWrapper>
  )
}

export default withScrollTop(SubmissionsNewPage)
