import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import LeaguesQuery from '@/api/queries/Leagues'

import LeagueUtilMenu from '@/components/utilityMenu/League'
import PageContent from '@/components/layouts/PageContent'
import PageWrapper from '@/components/layouts/PageWrapper'
import ScrollableResource from '@/components/ScrollableResource'
import SubmissionCard from '@/components/cards/submissions/Card'
import withScrollTop from '@/hocs/withScrollTop'
import { InfiniteScrollProvider } from '@/context/infiniteScroll'
import { resetUtilMenu, setUtilMenuContent } from '@/store/utilMenu'

import styles from './styles.mod.scss'

const LeaguesShowPage = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    dispatch(setUtilMenuContent(
      <LeagueUtilMenu id={parseInt(id)} />
    ))

    return () => dispatch(resetUtilMenu())
  }, [id])

  return (
    <PageWrapper name={styles.wrapper} title="League Details">
      <PageContent>
        <InfiniteScrollProvider
          cardClassName="SubmissionCard"
          query={LeaguesQuery.getInfiniteSubmissions}
          params={{ id }}
        >
          <ScrollableResource
            Component={SubmissionCard}
            resourceName="submission"
          />
        </InfiniteScrollProvider>
      </PageContent>
    </PageWrapper>
  )
}

export default withScrollTop(LeaguesShowPage)
