import React, { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

const defaultContext: PagesContextType = {
  currentPage: 1,
  decrement: () => {},
  increment: () => {},
  setPage: () => {},
  setTotalPages: () => {},
  totalPages: 1,
}

const PagesContext = createContext(defaultContext)

const getCurrentPage = (searchParams: URLSearchParams) => Number(searchParams.get('page') || 1)

export const PagesProvider = ({ children }: PropsWithChildren<{}>) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [currentPage, setCurrentPage] = useState(getCurrentPage(searchParams))
  const [totalPages, setTotalPages] = useState(1)

  const increment = () => {
    setSearchParams({ ...searchParams, page: String(currentPage + 1) })
  }

  const decrement = () => {
    setSearchParams({ ...searchParams, page: String(currentPage - 1) })
  }

  const setPage = (page: number) => {
    setSearchParams({ ...searchParams, page: String(page) })
  }

  useEffect(() => {
    setCurrentPage(getCurrentPage(searchParams))
  }, [searchParams])

  const value = useMemo(() => (
    { currentPage, increment, decrement, setPage, setTotalPages, totalPages }
  ), [currentPage, increment, decrement, setPage, totalPages])

  return (
    <PagesContext.Provider value={value}>
      {children}
    </PagesContext.Provider>
  )
}

type PagesContextType = {
  currentPage: number
  decrement: () => void
  increment: () => void
  setPage: (page: number) => void
  setTotalPages: (totalPages: number) => void
  totalPages: number
}

export const usePages = () => useContext(PagesContext)
