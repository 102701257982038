import React from 'react'

import CardLink from '../../CardLink'
import CategoryBadge from '../../../badges/CategoryBadge'
import LeagueCardMembers from '../Members'
import ProgressiveLoadImage from '../../../ProgressiveLoadImage'
import { imageSmall } from '../../../../common/helpers'
import { leagueDefault, leagueType } from '../../../../schemas/types/league'

import styles from './styles.mod.scss'

const LeagueCard = ({
  league = leagueDefault
}: LeagueCardProps) => {
  const {
    categories,
    description,
    id,
    logo_url_small,
    memberships_count,
    name,
  } = league

  return (
    <CardLink className="LeagueCard" to={`/leagues/${id}`}>
      <ProgressiveLoadImage
        alt="league image"
        className={styles.img}
        img={imageSmall(logo_url_small)}
        placeholderImgs={[imageSmall(logo_url_small)]}
        rounded
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <div className={styles.name_members}>
            <div className={styles.name}>
              {name}
            </div>
            <LeagueCardMembers members={memberships_count} />
          </div>
          <div className={styles.categories}>
            {categories.map((cat) => (
              <CategoryBadge
                name={cat.name}
                slug={cat.slug}
                key={`league-category-${id}-${cat.slug}`}
              />
            ))}
          </div>
        </div>
        <div className={styles.description}>
          <p>{description}</p>
        </div>
      </div>
    </CardLink>
  )
}

type LeagueCardProps = {
  league: leagueType,
}

export default LeagueCard
