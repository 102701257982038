import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

const ApiQueries = {
  // Because this is using the useQueryClient hook, this object must be
  // constructed from directly inside a react component
  constructQuery: ({ queryFn, queryKey, params }: QueryParams) => useQuery({
    queryKey,
    queryFn: async () => {
      // try {
      //   const response = await queryFn(params)
      //   return response.data
      // } catch (error) {
      //   toast.error(getErrorMessage(error))
      // }
      const response = await queryFn(params)
      return response.data
    }
  }),

  constructInfiniteQuery: ({ queryFn, queryKey, params }: QueryParams) => useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam = 1 }) => {
      const response = await queryFn({ page: pageParam, ...params })
      return response.data
    },
    getNextPageParam: (lastPage) => {
      const { current_page, last_page } = lastPage
      return last_page ? undefined : current_page + 1
    },
  }),

  queryParams: (params: QueryParamsObject = {}) => {
    const result: Result = {}
    Object.entries(params).forEach((entry) => {
      result[entry[0]] = entry[1]?.toString()
    })
    return result
  },
}

interface QueryParams {
  queryFn: any
  queryKey: QueryKey
  params?: any
}

export type QueryKey = [string, string, Record<string, string | undefined>] | [string, string];

// export type QueryKey = [string, string, { [key: string]: any }]
type Result = Record<string, any>

interface BaseQueryParams {
  [key: string]: string | number | undefined
}

export type QueryParamsObject = BaseQueryParams & {
  successMessage?: string;
}

export default ApiQueries
