import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

import UsersResource from '@/api/resources/Users'

import FormPrimary, { FormButtons, FormInput, FormInputs } from '@/components/forms/FormPrimary'
import { ButtonPrimary } from '@/common/buttons/Button'
import { passwordEditSchema } from '@/schemas/forms/auth'

import LockIcon from '@/assets/images/icons/lock.svg'

import styles from './styles.mod.scss'

const PasswordResetForm = ({
  token
}: PasswordResetFormProps) => {
  const form = useForm({ resolver: yupResolver(passwordEditSchema) })
  const { setFocus, setValue } = form
  const navigate = useNavigate()

  const onSubmit = async (data) => {
    try {
      await UsersResource.passwordResetByToken(data)
      toast.success('Password reset. You can now login with your new password.')
      navigate('/users/login')
    } catch (error) {
      toast.error(error.response.data[0])
      console.error('error', error)
    }
  }

  useEffect(() => {
    setFocus('password')
    setValue('reset_password_token', token)
  }, [])

  return (
    <FormPrimary
      className={styles.base}
      form={form}
      name="PasswordResetForm"
      onSubmit={onSubmit}
    >
      <FormInputs>
        <FormInput
          Icon={LockIcon}
          label="New Password"
          name="password"
          type="password"
        />
        <FormInput
          Icon={LockIcon}
          label="Confirm New Password"
          name="password_confirmation"
          type="password"
        />
      </FormInputs>
      <FormButtons>
        <ButtonPrimary label="Save Password" type="submit">
          Save Password
        </ButtonPrimary>
      </FormButtons>
    </FormPrimary>
  )
}

type PasswordResetFormProps = {
  token: string
}

export default PasswordResetForm
