import React from 'react'

import CategoryBadge from '../../../badges/CategoryBadge'
import Reward from '../../../Reward'
import { challengeDefault, challengeType } from '../../../../schemas/types/challenge'
import ResourceResults, { ResourceResult } from '../../ResourceResults'

import styles from './styles.mod.scss'

const ChallengeResult = ({
  resource = challengeDefault,
}: ChallengeResultProps) => {
  const { category, description, id, name, rewards } = resource

  return (
    <ResourceResult
      className="ChallengeResult"
      resourceName="challenge"
      name={name}
      to={`/challenges/${id}`}
    >
      <div className={styles.name}>{name}</div>
      <div className={styles.description}>{description}</div>
      <div className={styles.category_rewards}>
        <div className={styles.category}>
          <CategoryBadge name={category.name} slug={category.slug} />
        </div>
        <div className={styles.rewards}>
          {rewards.map((reward) => (
            <Reward
              reward={reward}
              key={`ChallengeCard__reward--${reward.id}`}
            />
          ))}
        </div>
      </div>
    </ResourceResult>
  )
}

const ChallengeResults = ({
  isLoading = true,
  results = [],
}: ChallengeResultsProps) => (
  <ResourceResults
    ResourceComponent={ChallengeResult}
    className="ChallengeResults"
    isLoading={isLoading}
    resourceName="challenges"
    results={results}
  />
)

type ChallengeResultProps = {
  resource: challengeType
}

type ChallengeResultsProps = {
  isLoading: boolean
  results: Array<challengeType>
}

export default ChallengeResults
