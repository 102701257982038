import React from 'react'
import { Navigate } from 'react-router-dom'

import AdminRoute from '../layouts/admin'

import AdminDashboard from '@/pages/admin/Dashboard'

import AdminCategoriesEditPage from '@/pages/admin/categories/Edit'
import AdminCategoriesNewPage from '@/pages/admin/categories/New'
import AdminCategoriesPage from '@/pages/admin/categories/List'
import AdminChallengesEditPage from '@/pages/admin/challenges/Edit'
import AdminChallengesNewPage from '@/pages/admin/challenges/New'
import AdminChallengesPage from '@/pages/admin/challenges/List'
import AdminCompetitionsEditPage from '@/pages/admin/competitions/Edit'
import AdminCompetitionsNewPage from '@/pages/admin/competitions/New'
import AdminCompetitionsPage from '@/pages/admin/competitions/List'
import AdminLeaguesPage from '@/pages/admin/leagues/List'
import AdminRitualsEditPage from '@/pages/admin/rituals/Edit'
import AdminRitualsNewPage from '@/pages/admin/rituals/New'
import AdminRitualsPage from '@/pages/admin/rituals/List'
import AdminRulesEditPage from '@/pages/admin/rules/Edit'
import AdminRulesNewPage from '@/pages/admin/rules/New'
import AdminRulesPage from '@/pages/admin/rules/List'
import AdminSubmissionsPage from '@/pages/admin/submissions/List'
import AdminUsersPage from '@/pages/admin/users/List'

const adminRoutes = [
  {
    element: <AdminRoute />,
    children: [
      {
        path: '/admin',
        element: <AdminDashboard />,
        children: [
          {
            path: '',
            element: <Navigate to="challenges" replace />,
          },
          {
            path: 'challenges',
            element: <AdminChallengesPage />,
          },
          {
            path: 'challenges/:id/edit',
            element: <AdminChallengesEditPage />,
          },
          {
            path: 'challenges/new',
            element: <AdminChallengesNewPage />,
          },
          {
            path: 'competitions',
            element: <AdminCompetitionsPage />,
          },
          {
            path: 'competitions/:id/edit',
            element: <AdminCompetitionsEditPage />,
          },
          {
            path: 'competitions/new',
            element: <AdminCompetitionsNewPage />,
          },
          {
            path: 'categories',
            element: <AdminCategoriesPage />,
          },
          {
            path: 'categories/:id/edit',
            element: <AdminCategoriesEditPage />,
          },
          {
            path: 'categories/new',
            element: <AdminCategoriesNewPage />,
          },
          {
            path: 'leagues',
            element: <AdminLeaguesPage />,
          },
          {
            path: 'users',
            element: <AdminUsersPage />,
          },
          {
            path: 'rituals',
            element: <AdminRitualsPage />,
          },
          {
            path: 'rituals/:id/edit',
            element: <AdminRitualsEditPage />,
          },
          {
            path: 'rituals/new',
            element: <AdminRitualsNewPage />,
          },
          {
            path: 'rules',
            element: <AdminRulesPage />,
          },
          {
            path: 'rules/:id/edit',
            element: <AdminRulesEditPage />,
          },
          {
            path: 'rules/new',
            element: <AdminRulesNewPage />,
          },
          {
            path: 'submissions',
            element: <AdminSubmissionsPage />,
          },
        ]
      }
    ]
  },
]

export default adminRoutes
