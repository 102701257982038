import React, { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import ApiErrors from '@/api/ApiErrors';
import UsersResource from '@/api/resources/Users';
import EmailIcon from '@/assets/images/icons/envelope.svg';
import LockIcon from '@/assets/images/icons/lock.svg';
import { LinkButtonPrimaryInverted } from '@/common/buttons/LinkButton';
import { useAuth } from '@/context/auth';
import { signupSchema } from '@/schemas/forms/auth';
import LocalStorageService from '@/services/LocalStorageService';
import { RootState } from '@/store';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormInput, FormInputs, FormSubmit } from '../../FormPrimary';
import AuthForm, { AuthButtons } from '../AuthForm';
import styles from './styles.mod.scss';

const SignupForm = () => {
  const form = useForm({ resolver: yupResolver(signupSchema) })
  const { setFocus } = form

  const navigate = useNavigate()
  const { lastPage } = useSelector((state: RootState) => state.pages)
  const { signIn } = useAuth()

  const onSubmit = async (data) => {
    try {
      const response = await UsersResource.register(data)
      signIn(response.data)
      LocalStorageService.setUser(response.data)
      navigate(lastPage)
    } catch (error) {
      toast.error(ApiErrors.getErrorMessage(error))
      console.error(error)
    }
  }

  useEffect(() => {
    setFocus('name')
  }, [])

  return (
    <AuthForm
      className={styles.base}
      form={form}
      onSubmit={onSubmit}
    >
      <FormInputs>
        <FormInput name="name" />
        <FormInput Icon={EmailIcon} name="email" type="email" />
        <FormInput Icon={LockIcon} name="password" type="password" />
        <FormInput Icon={LockIcon} name="password_confirmation" label="password confirmation" type="password" />
      </FormInputs>

      <AuthButtons>
        <FormSubmit text="Sign Up" label="Sign Up" scale={1} />
        <LinkButtonPrimaryInverted
          label="Log in"
          scale={1}
          to="/users/login"
        >
          Log In
        </LinkButtonPrimaryInverted>
      </AuthButtons>
    </AuthForm>
  )
}

export default SignupForm
