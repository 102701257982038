import React from 'react'
import classNames from 'classnames'

import CardExpanded from '../../CardExpanded'
import CategoryBadge from '../../../badges/CategoryBadge'
import { CompetitionEndTime, CompetitionStartTime } from '../Time'
import { categoryType } from '../../../../schemas/types/category'
import { competitionDefault, competitionType } from '../../../../schemas/types/competition'

import sharedStyles from '../styles.mod.scss'
import styles from './styles.mod.scss'
import { type } from 'os'

const Categories = ({
  categories = []
}: CategoriesProps) => (
  <div className={sharedStyles.categories}>
    {categories.map((cat) => (
      <CategoryBadge name={cat.name} slug={cat.slug} />
    ))}
  </div>
)

const CompetitionCardExpanded = ({
  competition = competitionDefault,
}: CompetitionCardExpandedProps) => {
  const {
    categories,
    description,
    end_time,
    id,
    logo_url,
    logo_url_tiny,
    name,
    start_time,
  } = competition

  return (
    <CardExpanded
      img={logo_url}
      isLoaded={!!id}
      name={styles.base}
      placeholderImgs={[logo_url_tiny]}
    >
      <div className={classNames(sharedStyles.content, styles.content)}>
        <div className={sharedStyles.header}>
          <div className={sharedStyles.title}>
            {name}
          </div>
          <div className={sharedStyles.description}>
            {description}
          </div>
          <Categories categories={categories} />
        </div>
        <div className={sharedStyles.times}>
          <CompetitionStartTime time={start_time} />
          <CompetitionEndTime time={end_time} />
        </div>
      </div>
    </CardExpanded>
  )
}

type CompetitionCardExpandedProps = {
  competition: competitionType,
}

type CategoriesProps = {
  categories: Array<categoryType>,
}

export default CompetitionCardExpanded
