import React from 'react'
import classNames from 'classnames'

import RelationshipsMutation from '../../../../../api/mutations/Relationships'

import FriendRemoveIcon from '../../../../../assets/images/icons/check-duotone.svg'
import FriendAddIcon from '../../../../../assets/images/icons/user-group-solid.svg'
import UserBackgroundAction from '../Action'

import styles from './styles.mod.scss'

const FriendAddRemove = ({
  className = '',
  user_id,
  isFriend,
}: FriendAddRemoveProps) => {
  const addFriend = RelationshipsMutation.addFriend({ user_id })
  const removeFriend = RelationshipsMutation.removeFriend({ user_id })

  return (
    isFriend ? (
      <UserBackgroundAction
        Icon={FriendRemoveIcon}
        className={classNames(styles.remove, className)}
        label="Remove Friend"
        onClick={() => removeFriend.mutate({ user_id })}
        text="Friends"
      />
    ) : (
      <UserBackgroundAction
        Icon={FriendAddIcon}
        className={classNames(styles.add, className)}
        onClick={() => addFriend.mutate({ user_id })}
        text="Add Friend"
      />
    )
  )
}

type FriendAddRemoveProps = {
  className?: string,
  isFriend: boolean,
  user_id: number | undefined,
}

export default FriendAddRemove
