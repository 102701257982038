/* eslint-disable react-hooks/rules-of-hooks */
import { useNavigate } from 'react-router-dom'

import defaultBackgroundImgLarge from '../assets/images/default-background.png'

import defaultCoverImgLarge from '../assets/images/backgrounds/5039684.jpg'
// import defaultCoverImgLarge from '../assets/images/backgrounds/3d-digital-network-connections-modern-background.jpg'
// import defaultCoverImgLarge from '../assets/images/backgrounds/6108792.jpg'
// import defaultCoverImgLarge from '../assets/images/backgrounds/Vector_2677.jpg'

import defaultCoverAltImgLarge from '../assets/images/default-cover-5-1-alt.png'
import defaultBackgroundImgSmall from '../assets/images/default-background-square-small.png'
import { restrictedActionToast } from './toasts'
import { useAuth } from '../context/auth'

export const capitalize = (string: string): string => (
  string.charAt(0).toUpperCase() + string.slice(1)
)

export const extractImageFileExtensionFromBase64 = (base64Data: string): string => (
  base64Data.substring('data:image/'.length, base64Data.indexOf(';base64'))
)

// export const getMergedUniqArray = (arr1, arr2) => [...new Set([...arr1, ...arr2])]
export const getMergedUniqArray = <T>(arr1: T[], arr2: T[]): T[] => [...new Set([...arr1, ...arr2])];

// export const getArrayDiff = (arr1, arr2) => arr1.filter((el) => !arr2.includes(el))
export const getArrayDiff = <T>(arr1: T[], arr2: T[]): T[] => arr1.filter((el) => !arr2.includes(el));

export const getUrl = (): URL => new URL(window.location.href)

export const imageSmall = (img?: string) => img || defaultBackgroundImgSmall

export const imageLarge = (img?: string) => img || defaultBackgroundImgLarge

export const coverLarge = (img?: string) => img || defaultCoverImgLarge

export const coverAltLarge = (img?: string) => img || defaultCoverAltImgLarge

// This is used to wrap references to windows or documents in a block that won't break production builds.
// Pass in a fallback function if you need to mock a fake window or document
// export const safeWindow = <T>(fn: () => T, fallback: () => T = () => {}): T => {
//   if (typeof window === 'undefined') {
//     return fallback();
//   }
//   return fn();
// }

export const actionOrRedirect = (action: () => void, redirectUrl: string): (() => void) => {
  const { token } = useAuth()
  const navigate = useNavigate()
  return token
    ? action
    : () => {
      restrictedActionToast()
      navigate(redirectUrl)
    }
}

export const userAction = (action: () => void): (() => void) => (
  actionOrRedirect(action, '/users/login')
)
