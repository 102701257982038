import DropdownSelect from '@/components/dropdowns/DropdownSelect'
import FormField from '../FormField'
import sharedStyles from '../../styles.mod.scss'
import { useFormPrimary } from '../..'
import classNames from 'classnames'
import { dropdownItemType, dropdownOptsType } from '@/context/dropdown'

const FormSelect = ({
  className = '',
  field = '',
  initial = null,
  initialId = null,
  label = '',
  name = '',
  ...rest
} : FormSelectProps) => {
  const { setValue } = useFormPrimary()

  const onChange = (item: dropdownItemType) => {
    setValue(field, item?.id)
  }

  return (
    <FormField
      className={classNames(sharedStyles.field, className)}
      fieldName={field}
      label={label}
      name={name}
    >
      <DropdownSelect
        id={`${name}Select`}
        initial={initial}
        initialId={initialId}
        resourceId={field}
        onChange={onChange}
        resourceName={name}
        {...rest}
      />
    </FormField>
  )
}

type FormSelectProps = {
  className?: string
  fetchQuery: () => void
  field: string
  initial?: dropdownItemType
  initialId?: number
  label?: string
  name: string
  opts?: dropdownOptsType
}

export default FormSelect
