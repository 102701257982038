import { ApiRequest, ApiConfig } from '..'
import { ShowParams, CreateParams, UpdateParams, DestroyParams } from '../ApiRequest';

const RuleSectionsResource = {
  getIndex: () => (
    ApiRequest.publicGet(ApiConfig.apiUrl('rule_sections'))
  ),
  getShow: ({ id }: ShowParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl(`rule_sections/${id}`))
  ),
  create: ({ data: rule_section }: CreateParams) => (
    ApiRequest.privatePost(ApiConfig.apiUrl('rule_sections'), { rule_section })
  ),
  update: ({ id, data: rule_section }: UpdateParams) => (
    ApiRequest.privatePut(ApiConfig.apiUrl(`rule_sections/${id}`), { rule_section })
  ),
  delete: ({ id }: DestroyParams) => (
    ApiRequest.privateDelete(ApiConfig.apiUrl(`rule_sections/${id}`))
  ),
}

export default RuleSectionsResource
