import React from 'react'
import moment from 'moment'

import Badge from '../../../badges/Badge'
import CategoryBadge from '../../../badges/CategoryBadge'
import ProgressiveLoadImage from '../../../ProgressiveLoadImage'
import ResourceResults, { ResourceResult } from '../../ResourceResults'
import { imageSmall } from '../../../../common/helpers'
import { submissionDefault, submissionType } from '../../../../schemas/types/submission'

import styles from './styles.mod.scss'

const SubmissionResult = ({
  resource = submissionDefault,
}: SubmissionResultProps) => {
  const { attachment_url_small, categories, created_at, id, name, user } = resource

  return (
    <ResourceResult
      className="SubmissionResult"
      resourceName="submission"
      name={name}
      to={`/submissions/${id}`}
    >
      <ProgressiveLoadImage
        alt="attachment"
        className={styles.attachment}
        img={imageSmall(attachment_url_small)}
        placeholderImgs={[imageSmall(attachment_url_small)]}
        rounded
      />
      <div className={styles.content}>
        <div className={styles.name}>
          {name}
        </div>
        <div className={styles.categories}>
          {categories.map((category) => (
            <CategoryBadge
              key={`SubmissionResult__category__${category.id}`}
              name={category.name}
              slug={category.slug}
            />
          ))}
        </div>
        <div className={styles.user_time}>
          <Badge color="grey" className={styles.user}>
            {user.name}
          </Badge>
          <div className={styles.time}>
            {moment(created_at).fromNow()}
          </div>
        </div>
      </div>
    </ResourceResult>
  )
}

const SubmissionResults = ({
  isLoading = true,
  results = [],
}: SubmissionResultsProps) => (
  <ResourceResults
    ResourceComponent={SubmissionResult}
    className="SubmissionResults"
    isLoading={isLoading}
    resourceName="submissions"
    results={results}
  />
)

type SubmissionResultProps = {
  resource: submissionType,
}

type SubmissionResultsProps = {
  isLoading: boolean,
  results: Array<submissionType>,
}

export default SubmissionResults
