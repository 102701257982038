import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import CategoriesQuery from '@/api/queries/Categories'

import CategoryForm from '@/components/forms/categories/CategoryForm'
import LoadingContainer from '@/components/LoadingContainer'
import { setPageTitle } from '@/store/pages'

const AdminCategoriesEdit = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { data, isLoading } = CategoriesQuery.getShow({ id })

  useEffect(() => {
    dispatch(setPageTitle('Edit Category'))
  }, [])

  return (
    <LoadingContainer
      hasContent={!!data}
      isLoading={isLoading}
      resource="categories"
    >
      <CategoryForm category={data} />
    </LoadingContainer>
  )
}

export default AdminCategoriesEdit
