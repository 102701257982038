import * as yup from 'yup'

const maxCharLength = 5000

export const messageSchema = yup.object({
  // content: yup.string()
  //   .trim()
  //   .max(maxCharLength, `Message must be less than ${maxCharLength} characters.`)
  //   .test('html-content', 'Message is blank', (value) => (
  //     /<[^/][^>]*>[^<]+<\/[^>]+>/.test(value)
  //   )),
  content: yup.string()
    .trim()
    .max(maxCharLength, `Message must be less than ${maxCharLength} characters.`)
    .min(1, 'Message is blank.')
}).required()
