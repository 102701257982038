import * as yup from 'yup'

export const challengeRewardSchema = yup.object({
  currency_id: yup.number()
    .required('Category required.'),
  value: yup.number()
    .min(1)
    .required('Value required.'),
  // challenge_id: yup.number()
  //   .required('Challenge required.'),
}).required()

export type challengeRewardSchemaType = yup.InferType<typeof challengeRewardSchema>
