import { ApiRequest, ApiConfig } from '..'
import { CreateParams, PaginatedSearchParams, ShowParams, UpdateParams, DestroyParams } from '../ApiRequest';

const RulesResource = {
  getIndex: () => (
    ApiRequest.publicGet(ApiConfig.apiUrl('rules'))
  ),
  getSearch: ({ page, query }: PaginatedSearchParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl('rules/search'), { params: { page, query } })
  ),
  getShow: ({ id }: ShowParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl(`rules/${id}`))
  ),
  create: ({ data: rule }: CreateParams) => (
    ApiRequest.privatePost(ApiConfig.apiUrl('rules'), { rule })
  ),
  update: ({ id, data: rule }: UpdateParams) => (
    ApiRequest.privatePut(ApiConfig.apiUrl(`rules/${id}`), { rule })
  ),
  delete: ({ id }: DestroyParams) => (
    ApiRequest.privateDelete(ApiConfig.apiUrl(`rules/${id}`))
  ),
}

export default RulesResource
