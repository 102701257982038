import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import AdminIndexResource from '@/hocs/AdminIndexResource'
import AdminTable, { TableContent, TableHeader, TableHeaderCol } from '../../Dashboard/AdminTable'
import CategoryCardAdmin from '@/components/cards/categories/CardAdmin'
import { categoryType } from '@/schemas/types/category'
import { setAdminFooterType, setIsAdminFooterEnabled } from '@/store/adminFooter'

import styles from './styles.mod.scss'

const RESOURCE = 'categories'

const AdminCategories = ({
  results = [],
}: AdminCategoriesProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setIsAdminFooterEnabled(true))
    dispatch(setAdminFooterType('newLink'))
  }, [])

  return (
    <AdminTable>
      <TableHeader>
        <TableHeaderCol className={styles.name} name="name" />
        <TableHeaderCol className={styles.relevance} name="relevance" />
        <TableHeaderCol className={styles.actions} />
      </TableHeader>
      <TableContent>
        {results?.map((category) => (
          <CategoryCardAdmin
            category={category}
            key={`category-${category.id}`}
          />
        ))}
      </TableContent>
    </AdminTable>
  )
}

type AdminCategoriesProps = {
  results: Array<categoryType>
}

export default AdminIndexResource({ RESOURCE })(AdminCategories)
