import { ApiConfig } from '@/api'

// export class PluginOpts {
//   constructor(progressRef) {
//     this.StatusBar = {
//       hideRetryButton: true,
//       id: `StatusBar:${Math.random()}`,
//       showProgressDetails: true,
//       target: progressRef,
//     }
//     this.ThumbnailGenerator = {
//       id: `ThumbnailGenerator:${Math.random()}`,
//       thumbnailWidth: 600,
//     }
//     this.AwsS3 = {
//       companionUrl: API_BASE,
//       id: `AwsS3:${Math.random()}`,
//     }
//   }
// }

interface StatusBarOptions {
  hideRetryButton: boolean
  id: string
  showProgressDetails: boolean
  target: any // You need to replace 'any' with the appropriate type
}

interface ThumbnailGeneratorOptions {
  id: string
  thumbnailWidth: number
}

interface AwsS3Options {
  companionUrl: string
  id: string
}

export const pluginOpts = {
  StatusBar: (progressRef: any): StatusBarOptions => ({
    hideRetryButton: true,
    id: `StatusBar:${Math.random()}`,
    showProgressDetails: true,
    target: progressRef,
  }),
  ThumbnailGenerator: {
    id: `ThumbnailGenerator:${Math.random()}`,
    thumbnailWidth: 600,
  } as ThumbnailGeneratorOptions,
  AwsS3: {
    companionUrl: ApiConfig.API_BASE,
    id: `AwsS3:${Math.random()}`,
  } as AwsS3Options,
}

// export const pluginOpts = {
//   StatusBar: (progressRef) => ({
//     hideRetryButton: true,
//     id: `StatusBar:${Math.random()}`,
//     showProgressDetails: true,
//     target: progressRef,
//   }),
//   ThumbnailGenerator: {
//     id: `ThumbnailGenerator:${Math.random()}`,
//     thumbnailWidth: 600,
//   },
//   AwsS3: {
//     companionUrl: API_BASE,
//     id: `AwsS3:${Math.random()}`,
//   },
// }
