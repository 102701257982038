import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import MyStuffUtilMenu from '@/components/utilityMenu/MyStuff'
import withScrollTop from '@/hocs/withScrollTop'
import { setUtilMenuContent } from '@/store/utilMenu'

const MyStuffWrapper = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setUtilMenuContent(<MyStuffUtilMenu />))
  }, [])

  return (
    <Outlet />
  )
}

export default withScrollTop(MyStuffWrapper)
