import UsersResource from '../resources/Users'
import { ApiMutations, ApiQueries } from '..'
import { QueryParamsObject } from '../ApiQueries'

const UsersMutation = {
  update: ({
    id,
    successMessage = 'Profile updated.'
  }: QueryParamsObject) => (
    ApiMutations.constructMutation(
      UsersResource.update,
      [
        ['Users', 'getAdminIndex'],
        ['Users', 'getShow', ApiQueries.queryParams({ id })],
        ['Users', 'getMe'],
      ],
      { successMessage },
    )
  ),
}

export default UsersMutation
