import { useState } from 'react';

import classNames from 'classnames';

import { useMediaUploader } from '../../context';
import styles from './styles.mod.scss';

export const CurrentImage = ({
  image = '',
  name = 'avatar',
} : CurrentImageProps) => {
  const { setPreview } = useMediaUploader()
  const [overlayActive, setOverlayActive] = useState(false)

  const onFileChange = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    setPreview('')
  }

  return (
    <button
      className="CurrentImage"
      aria-label="avatar"
      onMouseEnter={() => setOverlayActive(true)}
      onMouseLeave={() => setOverlayActive(false)}
      onClick={onFileChange}
    >
      <img
        src={image}
        alt="Current"
        className="Uppy__preview"
      />
      <div
        className={classNames(
          'CurrentImage__overlay',
          { active: overlayActive }
        )}
      >
        change {name}
      </div>
    </button>
  )
}

export const Image = () => {
  const { preview } = useMediaUploader()

  return (
    <img
      alt="Current"
      className={styles.preview}
      src={preview || ''}
    />
  )
}

type CurrentImageProps = {
  image?: string
  name?: string
}

export default Image
