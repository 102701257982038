import React from 'react'
import classNames from 'classnames'

import UserBackgroundAction from '../Action'
import { DialogTrigger } from '@/components/Dialog'

import MessageIcon from '@/assets/images/icons/envelope-solid.svg'

import styles from './styles.mod.scss'

const SendMessageTrigger = ({
  className = '',
  mobile = false,
}: SendMessageTriggerProps) => (
  <DialogTrigger>
    <UserBackgroundAction
      Icon={MessageIcon}
      className={classNames(
        styles.trigger,
        className,
        { [styles.mobile]: mobile },
      )}
      label="Send message"
      onClick={() => {}}
      text="Message"
    />
  </DialogTrigger>
)

type SendMessageTriggerProps = {
  className?: string
  mobile?: boolean
}

export default SendMessageTrigger
