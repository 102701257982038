import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';

import PageContent from '@/components/layouts/PageContent';
import PageWrapper from '@/components/layouts/PageWrapper';
import MessagesUtilMenu from '@/components/utilityMenu/Messages';
import withScrollTop from '@/hocs/withScrollTop';
import { resetUtilMenu, setUtilMenuContent, setUtilMenuOpts } from '@/store/utilMenu';

import styles from './styles.mod.scss';

const MessagesListPage = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    dispatch(setUtilMenuContent(<MessagesUtilMenu />))
    dispatch(setUtilMenuOpts({ noPadding: true }))

    return () => dispatch(resetUtilMenu())
  }, [])

  return (
    <PageWrapper name={styles.wrapper} title="Messages" layout="partial">
      <PageContent className={styles.page_content}>
        {id ? (
          <Outlet />
        ) : (
          <div className={styles.blank}>select a message</div>
        )}
      </PageContent>
    </PageWrapper>
  )
}

export default withScrollTop((MessagesListPage))
