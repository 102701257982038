import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Link, useSearchParams } from 'react-router-dom'

import CategoriesQuery from '../../../api/queries/Categories'

import LoadingContainer from '../../LoadingContainer'

import styles from './styles.mod.scss'

const CategoriesFilterUtilMenu = () => {
  const [searchParams] = useSearchParams()
  const { data, isLoading } = CategoriesQuery.getIndex({ page: 1 })
  const { results } = data || { results: [] }

  const [category, setCategory] = useState(null)

  useEffect(() => {
    setCategory(searchParams.get('categories'))
  }, [searchParams])

  return (
    <div className={styles.categories}>
      <LoadingContainer
        hasContent={!!results?.length}
        isLoading={isLoading}
        resource="categories"
      >
        <Link
          aria-label="Filter by all categories"
          className={classNames(
            styles.category,
            { [styles.active]: category === null }
          )}
          to="/challenges"
        >
          All
        </Link>
        {results?.map((item) => (
          <Link
            aria-label={`Filter by ${item.name}`}
            className={classNames(
              styles.category,
              { [styles.active]: item.slug === category }
            )}
            key={`ChallengesIndex__category-${item.id}`}
            to={`/challenges?categories=${item.slug}`}
          >
            {item.name}
          </Link>
        ))}
      </LoadingContainer>
    </div>
  )
}

export default CategoriesFilterUtilMenu
