import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import LocalStorageService from '@/services/LocalStorageService'
import { ApiConfig } from '@/api'

const client_id = process.env.REACT_APP_OAUTH_CLIENT
const client_secret = process.env.REACT_APP_OAUTH_SECRET

const ApiRequest = {
  privateRequest: ({ data, method, params, url }: RequestParams) => {
    const retryRequestWithRefreshToken = async (error: CustomAxiosError, originalRequest: CustomAxiosRequestConfig) => {
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        const refresh_token = LocalStorageService.getRefreshToken()
        const response = await axios.post(
          `${ApiConfig.API_BASE}/oauth/token`,
          { client_id, client_secret, grant_type: 'refresh_token', refresh_token }
        )
        if (response.status === 200) {
          LocalStorageService.setUser(response.data)
          axios.defaults.headers.common.Authorization = ApiConfig.getBearerToken()
          return axios(originalRequest)
        }

        return Promise.reject(error)
      }
    }

    axios.interceptors.request.use((request) => {
      request.headers.Authorization = ApiConfig.getBearerToken()
      return request
    }, (error) => (
      Promise.reject(error)
    ))

    axios.interceptors.response.use(
      (response) => response,
      async (error: CustomAxiosError) => {
        const originalRequest = error.config

        // If request is coming from a sign in attempt
        if (error.response?.status === 401 && originalRequest?.url?.includes('auth/token')) {
          return Promise.reject(error)
        }

        // If request is coming from elsewhere, assume token might be expired
        retryRequestWithRefreshToken(error, originalRequest)

        return Promise.reject(error)
      }
    )
    return axios({ method, url, data, params })
  },

  publicRequest: ({ data, method, params, url }: RequestParams) => (
    axios({ data, method, url, params })
  ),

  privateGet: (url: string, { params }: Params = {}) => (
    ApiRequest.privateRequest({ method: 'get', params, url })
  ),

  publicGet: (url: string, { params }: Params = {}) => (
    ApiRequest.publicRequest({ method: 'get', params, url })
  ),

  privatePost: (url: string, data: any) => (
    ApiRequest.privateRequest({ method: 'post', data, url })
  ),

  publicPost: (url: string, data: any) => (
    ApiRequest.publicRequest({ method: 'post', data, url })
  ),

  privatePut: (url: string, data?: any) => (
    ApiRequest.privateRequest({ method: 'put', data, url })
  ),

  privateDelete: (url: string, params: Params = {}) => (
    ApiRequest.privateRequest({ method: 'delete', params, url })
  )
}

type CustomAxiosError = AxiosError & {
  response?: AxiosResponse
  config?: CustomAxiosRequestConfig
}

type CustomAxiosRequestConfig = AxiosRequestConfig & {
  _retry?: boolean
}

interface RequestParams {
  data?: any
  method: 'get' | 'post' | 'put' | 'delete'
  params?: string | Params | undefined
  url: string
}

export interface IndexParams {
  params?: any
}

export interface SearchParams extends IndexParams {
  query: string
}

export type Params = Record<string, any | undefined>

export interface PaginatedIndexParams {
  page: number
}

export interface PaginatedSearchParams extends PaginatedIndexParams {
  query: string
}

export interface ShowParams {
  id: number
}

export interface CreateParams {
  data: any
}

export interface DestroyParams {
  id: number
}

export interface UpdateParams {
  id: number
  data: any
}

export interface PaginatedAssociationParams {
  id: number
  page: number
}

// TODO: Either move categories to string or make other category filters accept string[]
export interface PaginatedIndexWithCategoryFilterParams extends PaginatedIndexParams {
  categories?: string
}

export default ApiRequest
