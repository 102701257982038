import classNames from 'classnames'

import styles from './styles.mod.scss'

const FormInputs = ({
  children,
  className = '',
} : FormInputsProps) => (
  <div className={classNames(styles.inputsWrapper, className)}>
    <div className={styles.inputs}>
      {children}
    </div>
  </div>
)

type FormInputsProps = {
  children: React.ReactNode
  className?: string
}

export default FormInputs
