const defaultErrorMsg = 'Something went wrong.'

const titlecase = (str: string) => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase().replace('?', '')

export const errorTypes = {
  'ActiveRecord::RecordInvalid': (data: RecordInvalidError) => data.message[0],
  'ActiveRecord::RecordNotDestroyed': (data: RecordNotDestroyedError) => data.message[0],
  'Pundit::NotAuthorizedError': (data: NotAuthorizedError) => titlecase(data.message),
}

const ApiErrors = {
  getErrorMessage: (error: ErrorType) => {
    process.env.NODE_ENV === 'development' && console.error(error)

    const { response } = error
    if (!response) return defaultErrorMsg

    console.error(response)

    const { data } = response
    if (!data) return defaultErrorMsg

    const { error_type } = data
    if (!error_type) return defaultErrorMsg

    const errorTypeData = errorTypes[error_type]

    return errorTypeData(data)
  }
}

export type ErrorType = {
  response: {
    data: {
      error_type: keyof typeof errorTypes
    }
  }
} | any

type RecordInvalidError = {
  message: string[]
}

type RecordNotDestroyedError = {
  message: string[]
}

type NotAuthorizedError = {
  message: string
}

export default ApiErrors
