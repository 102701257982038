import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'

import { setActiveDropdown } from '../../../store/dropdowns'
import { useDropdown } from '../../../context/dropdown'

import styles from './styles.mod.scss'

const DropdownCard = ({
  children,
  className = '',
}: DropdownCardProps) => {
  const dispatch = useDispatch()
  const dropdownCardRef = useRef(null)
  const { id, isActive, opts } = useDropdown()

  const onEscKeypress = (e: KeyboardEvent) => {
    const ele = document.getElementById(id)
    if (ele === null) return

    if (e.key === 'Escape' && ele.contains(document.activeElement)) {
      dispatch(setActiveDropdown(null))
    }
  }

  useEffect(() => {
    dropdownCardRef.current?.addEventListener('keydown', onEscKeypress)

    return () => {
      dropdownCardRef.current?.removeEventListener('keydown', onEscKeypress)
    }
  }, [dropdownCardRef, id])

  return (
    <div
      className={classNames(
        'DropdownCard',
        className,
        { [styles.active]: isActive },
        { [styles.no_container]: opts.noContainer },
      )}
      ref={dropdownCardRef}
      id={id}
    >
      {children}
    </div>
  )
}

type DropdownCardProps = {
  children: React.ReactNode,
  className?: string,
}

export default DropdownCard
