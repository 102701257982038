import RewardsResource from '../resources/Rewards'
import { ApiMutations } from '..'
import { QueryKey } from '../ApiQueries'

const RewardsMutation = {
  create: ({ queriesToInvalidate }: ApiQueriesToInvalidate) => (
    ApiMutations.constructMutation(
      RewardsResource.create,
      queriesToInvalidate,
      { successMessage: 'Reward created.' },
    )
  ),
  update: ({ queriesToInvalidate }: ApiQueriesToInvalidate) => (
    ApiMutations.constructMutation(
      RewardsResource.update,
      queriesToInvalidate,
      { successMessage: 'Reward updated.' },
    )
  ),
  delete: ({ queriesToInvalidate }: ApiQueriesToInvalidate) => (
    ApiMutations.constructMutation(
      RewardsResource.delete,
      queriesToInvalidate,
      { successMessage: 'Reward deleted.' },
    )
  ),
}

type ApiQueriesToInvalidate = { queriesToInvalidate: QueryKey[] }

export default RewardsMutation
