import React from 'react'

import NotFoundPage from '@/pages/static/NotFound'

import adminRoutes from './admin'
import privateRoutes from './private'
import publicRoutes from './public'

const routesConfig = [
  ...privateRoutes,
  ...publicRoutes,
  ...adminRoutes,
  {
    path: '*',
    element: <NotFoundPage />,
  },
]

export default routesConfig
