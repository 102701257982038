import React from 'react'

import RelationshipsQuery from '@/api/queries/Relationships'

import Cards from '@/components/cards/Cards'
import LoadingContainer from '@/components/LoadingContainer'
import PageContent from '@/components/layouts/PageContent'
import PageWrapper from '@/components/layouts/PageWrapper'
import UserCard from '@/components/cards/users/Card'
import withScrollTop from '@/hocs/withScrollTop'

const MyFriendsPage = () => {
  const { data, isLoading } = RelationshipsQuery.getFriends()

  return (
    <PageWrapper name="Friends" title="My Stuff">
      <PageContent>
        <LoadingContainer
          isLoading={isLoading}
          hasContent={!!data?.length}
          resource="friends"
        >
          <Cards tapered>
            {data?.map((friend) => (
              <UserCard
                user={friend}
                key={`friend-${friend.id}`}
              />
            ))}
          </Cards>
        </LoadingContainer>
      </PageContent>
    </PageWrapper>
  )
}

export default withScrollTop(MyFriendsPage)
