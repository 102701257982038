import React from 'react'

import DialogProvider, { DialogContent, useDialog } from '../../Dialog'
import MessageForm from '../../forms/messages/MessageForm'
import { InlineSpace } from '../../../common/Unicodes'
import { userDefault, userType } from '../../../schemas/types/user'

import styles from './styles.mod.scss'

const SendMessageTitle = ({
  userName = 'user'
}: SendMessageTitleProps) => (
  <div className={styles.title}>
    <span>send message to</span>
    <InlineSpace />
    <span className={styles.username}>{userName}</span>
  </div>
)

const SendMessageDialog = ({
  Trigger = () => <></>,
  children = undefined,
  className = undefined,
  onConfirm = () => {},
  user = userDefault,
  resource = 'resource',
}: SendMessageDialogProps) => (
  <DialogProvider>
    <Trigger className={className} />
    <DialogContent
      className={styles.content}
      dialogTitle={<SendMessageTitle userName={user.name} />}
    >
      <SendMessageContent
        onConfirm={onConfirm}
        userId={user.id}
      >
        {children}
      </SendMessageContent>
    </DialogContent>
  </DialogProvider>
)

const SendMessageContent = ({
  onConfirm,
  userId,
}: SendMessageContentProps) => {
  const { setIsOpen } = useDialog()

  const onSubmitSuccess = async (response) => {
    if (response) {
      onConfirm()
      setIsOpen(false)
    }
  }

  return (
    <MessageForm
      user_id={userId}
      onSubmitSuccess={onSubmitSuccess}
    />
  )
}

type TriggerProps = {
  className?: string
}

type SendMessageDialogProps = {
  Trigger: React.FC<TriggerProps>,
  children: React.ReactNode,
  className: string,
  onConfirm: Function,
  resource: string,
  user: userType,
}

type SendMessageContentProps = {
  onConfirm: Function,
  userId: number | undefined,
}

type SendMessageTitleProps = {
  userName: string,
}

export default SendMessageDialog
