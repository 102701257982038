import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ChallengeForm from '@/components/forms/challenges/ChallengeForm';
import { challengeType } from '@/schemas/types/challenge';
import { setPageTitle } from '@/store/pages';

const AdminChallengesNew = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const afterUpdate = (challenge: challengeType) => {
    navigate(`/admin/challenges/${challenge.id}/edit`)
  }

  useEffect(() => {
    dispatch(setPageTitle('New Challenge'))
  }, [])

  return (
    <ChallengeForm afterUpdate={afterUpdate} />
  )
}

export default AdminChallengesNew
