import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import PasswordResetForm from '@/components/forms/auth/PasswordResetForm'
import withScrollTop from '@/hocs/withScrollTop'
import { setPageTitle } from '@/store/pages'

import styles from './styles.mod.scss'

const PasswordResetPage = () => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const token = searchParams.get('token')

  useEffect(() => {
    dispatch(setPageTitle('Password Reset'))
  }, [])

  return (
    <div className={styles.warpper}>
      <div className={styles.header}>
        <h1>Reset your password.</h1>
      </div>
      <PasswordResetForm token={token} />
    </div>
  )
}

export default withScrollTop(PasswordResetPage)
