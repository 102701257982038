import { userDefault, userType } from './user'

export const commentDefault: commentType = {
  id: undefined,
  content: '',
  created_at: '',
  user: userDefault,
}

export type commentType = {
  id: number | undefined
  content: string
  created_at: string
  user: userType
}
