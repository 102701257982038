import React from 'react'
import { NavLink } from 'react-router-dom'

import styles from './styles.mod.scss'

const NotificationsUtilMenu = () => (
  <div className={styles.base}>
    <NavLink to="/notifications/unread">
      Unread
    </NavLink>
    <NavLink to="/notifications/read">
      Read
    </NavLink>
  </div>
)

export default NotificationsUtilMenu
