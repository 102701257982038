import React, { useEffect, useState } from 'react'

import TeamsResource from '@/api/resources/Teams'

import ComingSoon from '@/components/ComingSoon'
import PageContent from '@/components/layouts/PageContent'
import PageWrapper from '@/components/layouts/PageWrapper'
import withScrollTop from '@/hocs/withScrollTop'

import styles from './styles.mod.scss'

const TeamsMinePage = () => {
  const [_team, setTeam] = useState(null)

  const fetchData = async () => {
    const response = await TeamsResource.getNew()
    setTeam(response.data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <PageWrapper name="TeamsMine" title="My Teams">
      <PageContent>
        <ComingSoon />
      </PageContent>
    </PageWrapper>
  )
}

export default withScrollTop(TeamsMinePage)
