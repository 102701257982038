import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { useDispatch } from 'react-redux'

import PageWrapper from '@/components/layouts/PageWrapper'
import withScrollTop from '@/hocs/withScrollTop'
import { resetUtilMenu, setIsUtilMenuActive } from '@/store/utilMenu'
import { useAuth } from '@/context/auth'

import styles from './styles.mod.scss'

const AuthWrapper = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { token } = useAuth()

  useEffect(() => {
    // TODO: change to image load handler
    if (token) navigate('/explore')

    dispatch(setIsUtilMenuActive(false))

    return () => dispatch(resetUtilMenu())
  }, [])

  return (
    <PageWrapper name={styles.wrapper} layout="center">
      <AnimatePresence mode="wait">
        <div className={styles.container}>
          <Outlet />
        </div>
      </AnimatePresence>
    </PageWrapper>
  )
}

export default withScrollTop(AuthWrapper)
