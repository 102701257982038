import RelationshipsResource from '../resources/Relationships'
import { ApiMutations, ApiQueries } from '..'
import { QueryParamsObject } from '../ApiQueries'

const RelationshipsMutation = {
  addFriend: ({ successMessage, user_id, ...params }: QueryParamsObject) => (
    ApiMutations.constructMutation(
      RelationshipsResource.addFriend,
      [['Users', 'getShow', ApiQueries.queryParams({ id: user_id, ...params })]],
      // { successMessage },
    )
  ),
  removeFriend: ({ successMessage, user_id, ...params }: QueryParamsObject) => (
    ApiMutations.constructMutation(
      RelationshipsResource.removeFriend,
      [['Users', 'getShow', ApiQueries.queryParams({ id: user_id, ...params })]],
      // { successMessage },
    )
  ),
  addRival: ({ successMessage, user_id, ...params }: QueryParamsObject) => (
    ApiMutations.constructMutation(
      RelationshipsResource.addRival,
      [['Users', 'getShow', ApiQueries.queryParams({ id: user_id, ...params })]],
      // { successMessage },
    )
  ),
  removeRival: ({ successMessage, user_id, ...params }: QueryParamsObject) => (
    ApiMutations.constructMutation(
      RelationshipsResource.removeRival,
      [['Users', 'getShow', ApiQueries.queryParams({ id: user_id, ...params })]],
      // { successMessage },
    )
  ),
}

export default RelationshipsMutation
