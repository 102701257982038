import { categoryDefault, categoryType } from './category'
import { rewardType } from './reward'

export const challengeDefault: challengeType = {
  category: categoryDefault,
  category_id: undefined,
  created_at: '',
  description: '',
  end_time: '',
  id: undefined,
  kind: '',
  logo_url: '',
  name: '',
  relevance: undefined,
  rewards: [],
  ritual_id: undefined,
  slug: '',
  start_time: '',
  status: '',
  updated_at: '',
}

export type challengeType = {
  category: categoryType
  category_id: number | undefined
  created_at: string
  description: string
  end_time: string
  id: number | undefined
  kind: string
  logo_url: string
  name: string
  relevance: number | undefined
  rewards: rewardType[]
  ritual_id: number | undefined
  slug: string
  start_time: string
  status: string
  updated_at: string
}
