import React from 'react'
import { Link } from 'react-router-dom'

import CategoriesMutation from '@/api/mutations/Categories'

import CardAdmin, { ActionsCell, CardCell, DeleteAction, EditAction } from '@/components/cards/CardAdmin'
import { categoryDefault, categoryType } from '@/schemas/types/category'

import styles from './styles.mod.scss'

const CategoryCardAdmin = ({
  category = categoryDefault
}: CategoryCardAdminProps) => {
  const { id, name, relevance } = category

  const deleteCategory = CategoriesMutation.delete({ id })

  const onDeleteConfirm = async () => {
    const response = await deleteCategory.mutateAsync({ id })
    return response
  }

  return (
    <CardAdmin>
      <CardCell className={styles.name}>
        <Link aria-label={`Go to ${name} page`} to={`/challenges/${id}`}>
          {name}
        </Link>
      </CardCell>
      <CardCell className={styles.relevance}>
        {relevance}
      </CardCell>
      <ActionsCell className={styles.actions}>
        <EditAction to={`/admin/categories/${id}/edit`} />
        <DeleteAction
          onConfirm={onDeleteConfirm}
          resource="category"
        />
      </ActionsCell>
    </CardAdmin>
  )
}

type CategoryCardAdminProps = {
  category: categoryType,
}

export default CategoryCardAdmin
