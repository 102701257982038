import React from 'react'

import LeaguesQuery from '../../../api/queries/Leagues'

import LoadingContainer from '../../LoadingContainer'
import LeagueCardExpanded from '../../cards/leagues/CardExpanded'

const LeagueUtilMenu = ({ id }: LeagueUtilMenuProps) => {
  const { data, isLoading } = LeaguesQuery.getShow({ id })

  return (
    <LoadingContainer
      isLoading={isLoading}
      hasContent={!!data}
      resource="league"
    >
      <LeagueCardExpanded
        league={data}
      />
    </LoadingContainer>
  )
}

type LeagueUtilMenuProps = {
  id?: number
}

export default LeagueUtilMenu
