import CompetitionsResource from '../resources/Competitions'
import { ApiQueries } from '..'
import { QueryParamsObject } from '../ApiQueries'

const CompetitionsQuery = {
  getAdminIndex: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Competitions', 'getAdminIndex', ApiQueries.queryParams(params)],
    queryFn: CompetitionsResource.getAdminIndex,
    params,
  }),
  getSearch: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Competitions', 'getSearch', ApiQueries.queryParams(params)],
    queryFn: CompetitionsResource.getSearch,
    params,
  }),
  getShow: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Competitions', 'getShow', ApiQueries.queryParams(params)],
    queryFn: CompetitionsResource.getShow,
    params,
  }),
  getSubmissions: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Competitions', 'getSubmissions', ApiQueries.queryParams(params)],
    queryFn: CompetitionsResource.getSubmissions,
    params,
  }),
  getIndex: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Competitions', 'getInfiniteIndex', ApiQueries.queryParams(params)],
    queryFn: CompetitionsResource.getIndex,
    params,
  }),
  getInfiniteIndex: (params: QueryParamsObject) => ApiQueries.constructInfiniteQuery({
    queryKey: ['Competitions', 'getInfiniteIndex', ApiQueries.queryParams(params)],
    queryFn: CompetitionsResource.getIndex,
    params,
  }),
  getInfiniteSubmissions: (params: QueryParamsObject) => ApiQueries.constructInfiniteQuery({
    queryKey: ['Competitions', 'getInfiniteSubmissions', ApiQueries.queryParams(params)],
    queryFn: CompetitionsResource.getSubmissions,
    params,
  }),
}

export default CompetitionsQuery
