import React from 'react'

import Badge from '../Badge'

const CategoryBadge = ({
  name = '',
  slug = '',
}: CategoryBadgeProps) => (
  <Badge color="primary" className="CategoryBadge">
    {name}
  </Badge>
)

type CategoryBadgeProps = {
  name: string,
  slug: string,
}

export default CategoryBadge
