import RulesResource from '../resources/Rules'
import { ApiQueries } from '..'
import { QueryParamsObject } from '../ApiQueries'

const RulesQuery = {
  getIndex: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Rules', 'getIndex', ApiQueries.queryParams(params)],
    queryFn: RulesResource.getIndex,
    params,
  }),
  getShow: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Rules', 'getShow', ApiQueries.queryParams(params)],
    queryFn: RulesResource.getShow,
    params,
  }),
  getSearch: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Rules', 'getSearch', ApiQueries.queryParams(params)],
    queryFn: RulesResource.getSearch,
    params,
  }),
}

export default RulesQuery
