import React from 'react'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'

import ChevronDownIcon from '@/assets/images/icons/chevron-down.svg'
import { setActiveDropdown } from '@/store/dropdowns'
import { useDropdown } from '@/context/dropdown'

import styles from './styles.mod.scss'

const DropdownCardHeader = ({
  children,
  content = null,
}: DropdownCardHeaderProps) => {
  const dispatch = useDispatch()
  const { isActive, resourceName, opts } = useDropdown()

  const onClick = (e) => {
    e.preventDefault()

    isActive ? dispatch(setActiveDropdown(null)) : dispatch(setActiveDropdown(resourceName))
  }

  return (
    <div
      className={classNames(
        'DropdownCardHeader',
        { [styles.no_container]: opts.noContainer },
      )}
    >
      <button
        className={styles.activate}
        aria-label="Open dropdown"
        onClick={onClick}
      >
        <h3>
          {content || `Select ${resourceName}`}
        </h3>
        <div className={styles.actions}>
          <ChevronDownIcon />
        </div>
      </button>
      {children}
    </div>
  )
}

type DropdownCardHeaderProps = {
  children?: React.ReactNode
  content?: React.ReactNode | string
}

export default DropdownCardHeader
