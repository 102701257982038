import { ApiConfig, ApiRequest } from '../';
import {
    CreateParams, DestroyParams, PaginatedIndexWithCategoryFilterParams, PaginatedSearchParams,
    ShowParams, UpdateParams
} from '../ApiRequest';

const ChallengesResource = {
  getAdminIndex: ({ page, query }: PaginatedSearchParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl('admin/challenges'), { params: { page, query } })
  ),
  getIndex: (params: PaginatedIndexWithCategoryFilterParams) => (
    ApiConfig.getToken() ? ChallengesResource.getPrivateIndex(params) : ChallengesResource.getPublicIndex(params)
  ),
  getSearch: ({ page, query }: PaginatedSearchParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl('challenges/search'), { params: { page, query } })
  ),
  getShow: ({ id }: ShowParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl(`challenges/${id}`))
  ),
  getSubmissions: (params: ShowParams) => (
    ApiConfig.getToken()
      ? ChallengesResource.getPrivateSubmissions(params)
      : ChallengesResource.getPublicSubmissions(params)
  ),
  getPublicIndex: ({ categories, page }: PaginatedIndexWithCategoryFilterParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl('public/challenges'), { params: { categories, page } })
  ),
  getPrivateIndex: ({ categories, page = 1 }: PaginatedIndexWithCategoryFilterParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl('private/challenges'), { params: { categories, page } })
  ),
  getPrivateSubmissions: ({ id }: ShowParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl(`private/challenges/${id}/submissions`))
  ),
  getPublicSubmissions: ({ id }: ShowParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl(`public/challenges/${id}/submissions`))
  ),
  create: ({ data: challenge }: CreateParams) => (
    ApiRequest.privatePost(ApiConfig.apiUrl('challenges'), { challenge })
  ),
  update: ({ id, data: challenge }: UpdateParams) => (
    ApiRequest.privatePut(ApiConfig.apiUrl(`challenges/${id}`), { challenge })
  ),
  delete: ({ id }: DestroyParams) => (
    ApiRequest.privateDelete(ApiConfig.apiUrl(`challenges/${id}`))
  ),
}

export default ChallengesResource
