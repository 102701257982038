import { ApiRequest, ApiConfig } from '..'
import { CreateParams, DestroyParams, PaginatedSearchParams, ShowParams, UpdateParams } from '../ApiRequest'

const RitualsResource = {
  getIndex: (params: PaginatedIndexWithCategoryFilterParams) => (
    ApiConfig.getToken()
      ? RitualsResource.getPrivateIndex(params)
      : RitualsResource.getPublicIndex(params)
  ),
  getSearch: ({ page, query }: PaginatedSearchParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl('rituals/search'), { params: { page, query } })
  ),
  getShow: (params: ShowParams) => (
    ApiConfig.getToken()
      ? RitualsResource.getPrivateShow(params)
      : RitualsResource.getPublicShow(params)
  ),
  getSubmissions: (params: ShowParams) => (
    ApiConfig.getToken()
      ? RitualsResource.getPrivateSubmissions(params)
      : RitualsResource.getPublicSubmissions(params)
  ),
  getPublicIndex: ({ categoriesParams, page }: PaginatedIndexWithCategoryFilterParams) => (
    ApiRequest.publicGet(
      ApiConfig.apiUrl('public/rituals'),
      { params: { categories: categoriesParams?.join(';'), page } }
    )
  ),
  getPrivateIndex: ({ categoriesParams, page }: PaginatedIndexWithCategoryFilterParams) => (
    ApiRequest.privateGet(
      ApiConfig.apiUrl('private/rituals'),
      { params: { categories: categoriesParams?.join(';'), page } }
    )
  ),
  getPrivateShow: ({ id }: ShowParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl(`private/rituals/${id}`))
  ),
  getPublicShow: ({ id }: ShowParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl(`public/rituals/${id}`))
  ),
  getPrivateSubmissions: ({ id }: ShowParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl(`private/rituals/${id}/submissions`))
  ),
  getPublicSubmissions: ({ id }: ShowParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl(`public/rituals/${id}/submissions`))
  ),
  create: ({ data: ritual }: CreateParams) => (
    ApiRequest.privatePost(ApiConfig.apiUrl('rituals'), { ritual })
  ),
  update: ({ id, data: ritual }: UpdateParams) => (
    ApiRequest.privatePut(ApiConfig.apiUrl(`rituals/${id}`), { ritual })
  ),
  delete: ({ id }: DestroyParams) => (
    ApiRequest.privateDelete(ApiConfig.apiUrl(`rituals/${id}`))
  ),
}

// TODO: Either move categories to string or make other category filters accept string[]
type PaginatedIndexWithCategoryFilterParams = {
  categoriesParams?: string[]
  page?: number
}

export default RitualsResource
