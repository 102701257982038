import React, { useEffect } from 'react'
import { ObjectSchema } from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import RewardsMutation from '../../../../api/mutations/Rewards'
import CurrenciesResource from '../../../../api/resources/Currencies'

import DeleteConfirmationDialog, { DeleteConfirmationTrigger } from '../../../dialogs/DeleteConfirmationDialog'
import FormPrimary, { FormButtons, FormInput, FormInputs, FormSelect, FormSubmit } from '../../FormPrimary'
import { rewardDefault, rewardType } from '../../../../schemas/types/reward'

import styles from './styles.mod.scss'

const RewardForm = ({
  afterMutation = () => {},
  deleteConfirmationMessage = 'Are you sure?',
  kind = '',
  queriesToInvalidate = [[]],
  resource = 'resource',
  resourceId,
  reward = rewardDefault,
  rewardSchema,
  updateText = 'Update Form',
}: RewardFormProps) => {
  const { id } = reward
  const updateReward = RewardsMutation.update({ queriesToInvalidate })
  const deleteReward = RewardsMutation.delete({ queriesToInvalidate })
  const createReward = RewardsMutation.create({ queriesToInvalidate })

  const form = useForm({
    resolver: yupResolver(rewardSchema),
    defaultValues: reward,
  })
  const { register, setValue } = form

  const onDeleteConfirm = async () => {
    const response = await deleteReward.mutateAsync({ id })
    afterMutation()
    return response
  }

  const onSubmit = async (data) => {
    const modifiedData = { [`${resource}_id`]: resourceId, ...data }
    await id
      ? updateReward.mutateAsync({ id, data: modifiedData })
      : createReward.mutateAsync({ data: modifiedData })
    afterMutation()
  }

  useEffect(() => {
    register(`${resource}_id`)
    setValue(`${resource}_id`, resourceId)
  }, [reward, resource, resourceId])

  useEffect(() => {
    setValue('kind', kind)
  }, [kind])

  useEffect(() => {
    if (reward.id) {
      form.reset(reward)
    }
  }, [reward])

  return (
    <FormPrimary
      name="RewardForm"
      form={form}
      onSubmit={onSubmit}
    >
      <FormInputs>
        <FormInput name="value" type="number" />
        <FormSelect
          field="currency_id"
          name="currency"
          fetchQuery={CurrenciesResource.getIndex}
          initial={reward.currency}
        />
      </FormInputs>
      <FormButtons>
        <FormSubmit text={updateText} />
        {reward.id ? (
          <DeleteConfirmationDialog
            Trigger={DeleteConfirmationTrigger}
            onConfirm={onDeleteConfirm}
            resource="reward"
          >
            <p>{deleteConfirmationMessage}</p>
          </DeleteConfirmationDialog>
        ) : null}
      </FormButtons>
    </FormPrimary>
  )
}

type RewardFormProps = {
  afterMutation: () => void
  deleteConfirmationMessage: string
  kind: string
  queriesToInvalidate: Array<Array<string | number | object>>
  resource: 'challenge' | 'competition'
  resourceId: number
  reward: rewardType
  rewardSchema: ObjectSchema<rewardType>
  updateText: string
}

export default RewardForm
