import { useMediaUploader } from '../../context'

import styles from './styles.mod.scss'

const Video = () => {
  const { videoSrc } = useMediaUploader()

  return (
    <video
      className={styles.preview}
      controls
      src={videoSrc || ''}
    />
  )
}

export default Video
