import axios from 'axios'
import { ApiConfig } from '@/api'
import { UppyFile } from '@uppy/core'

const constructParams = (metadata: Record<string, string>) => (
  [
    `?X-Amz-Algorithm=${metadata['x-amz-algorithm']}`,
    `&X-Amz-Credential=${metadata['x-amz-credential']}`,
    `&X-Amz-Date=${metadata['x-amz-date']}`,
    '&X-Amz-Expires=900',
    '&X-Amz-SignedHeaders=host',
    `&X-Amz-Signature=${metadata['x-amz-signature']}`,
  ]
    .join('')
    .replaceAll('/', '%2F')
)

const getPresigned = async (id: string, type: string, setVideoSrc: (url: string) => void) => {
  const response = await axios.get(`${ApiConfig.API_BASE}/s3/params?filename=${id}&type=${type}`)

  const { fields, url } = response.data
  const params = constructParams(fields)
  const presignedUrl = `${url}/${fields.key}${params}`

  setVideoSrc(presignedUrl)
  // console.log('presignedUrl from Shrine request data', presignedUrl)
}

export const actions = {
  onUploadSuccess: (
    onUploadComplete: (data: string) => void,
    setVideoSrc: (url: string) => void
  ) => (
    // (file: { size: number; name: string; type: string; meta: { key: string } }, _response: any) => {
    (file: FileType, _response: any) => {
      const { size, name, type, meta } = file || {}

      // const url = 'https://gvc-uploads.s3.us-west-1.amazonaws.com'
      // const params = constructParams(meta)
      // const presignedUrl = `${url}/${meta.key}${params}`
      // console.log('presignedUrl from upload-success data', presignedUrl)

      const id = meta.key.split(`${process.env.REACT_APP_ENV}/cache/`)[1]
      getPresigned(id, type, setVideoSrc)

      const uploadedFileData = {
        id,
        storage: 'cache',
        metadata: {
          filename: name,
          mime_type: type,
          size,
        }
      }

      onUploadComplete(JSON.stringify(uploadedFileData))
    }
  ),
  onThumbnailGenerated: (setPreview: (thumbnail: string) => void) => (
    (_file: any, thumbnail: string) => {
      setPreview(thumbnail);
    }
  ),
  onUploadError: (_file: any, error: any, _response: any) => {
    console.error(error);
  },
}

interface MetaData {
  key: string
}

type FileType = UppyFile<Record<string, unknown>, Record<string, unknown>> & {
  meta: MetaData
};
