import React from 'react';

import SubmissionsQuery from '../../../api/queries/Submissions';
import { InfiniteScrollProvider } from '../../../context/infiniteScroll';
import CommentCard from '../../cards/comments/Card';
import AddCommentDialog from '../../dialogs/AddCommentDialog';
import ScrollableResource from '../../ScrollableResource';
import styles from './styles.mod.scss';

const CommentsUtilMenu = ({
  submissionId
}: CommentUtilMenuProps) => (
  <div className={styles.wrapper}>
    <AddCommentDialog submission_id={submissionId} />
    <InfiniteScrollProvider
      cardClassName="ContactCard"
      query={SubmissionsQuery.getInfiniteComments}
      params={{ submission_id: submissionId }}
    >
      <ScrollableResource
        Component={CommentCard}
        className={styles.scrollable_comments}
        resourceName="comment"
      />
    </InfiniteScrollProvider>
  </div>
)

type CommentUtilMenuProps = {
  submissionId: number
}

export default CommentsUtilMenu
