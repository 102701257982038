import { categoryType } from '../types/category'

export const userDefault: userType = {
  avatar_url: '',
  avatar_url_small: '',
  avatar_url_tiny: '',
  bio: '',
  categories: [],
  cover_url: '',
  cover_url_small: '',
  cover_url_tiny: '',
  email: '',
  id: undefined,
  is_friend: false,
  is_rival: false,
  name: '',
}

export type userType = {
  avatar_url: string,
  avatar_url_small: string,
  avatar_url_tiny: string,
  bio: string,
  categories: Array<categoryType>,
  cover_url: string,
  cover_url_small: string,
  cover_url_tiny: string,
  email: string,
  id: number | undefined,
  is_friend: boolean,
  is_rival: boolean,
  name: string,
}
