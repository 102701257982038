import React from 'react'
import { Link } from 'react-router-dom'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import rehypeRaw from 'rehype-raw'

import RulesMutation from '../../../../api/mutations/Rules'

import CardAdmin, { ActionsCell, CardCell, DeleteAction, EditAction } from '../../CardAdmin'
import CategoryBadge from '../../../badges/CategoryBadge'
import { ruleDefault, ruleType } from '../../../../schemas/types/rule'

import styles from './styles.mod.scss'

const RuleCardAdmin = ({
  rule = ruleDefault,
}: RuleCardAdminProps) => {
  const { category, content, id, title } = rule

  const deleteRule = RulesMutation.delete({ id })

  const onDeleteConfirm = async () => {
    const response = await deleteRule.mutate({ id })
    return response
  }

  return (
    <CardAdmin>
      <CardCell className={styles.title}>
        <Link aria-label={`Go to ${title} page`} to="/rules">
          {title}
        </Link>
      </CardCell>
      <CardCell className={styles.content}>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {content}
        </ReactMarkdown>
      </CardCell>
      <CardCell className={styles.category}>
        <CategoryBadge name={category.name} slug={category.slug} />
      </CardCell>
      <ActionsCell className={styles.actions}>
        <EditAction className={styles.edit} to={`/admin/rules/${id}/edit`} />
        <DeleteAction
          onConfirm={onDeleteConfirm}
          resource="rule"
        />
      </ActionsCell>
    </CardAdmin>
  )
}

type RuleCardAdminProps = {
  rule: ruleType,
}

export default RuleCardAdmin
