/* eslint-disable no-extra-boolean-cast */
import * as yup from 'yup'

export const ritualSchema = yup.object({
  name: yup.string()
    .trim()
    .max(50, 'Name must be less than 50 characters.')
    .required('Name required.'),
  description: yup.string()
    .trim()
    .required('Description required.'),
  challenge_ids: yup.array().of(yup.string()),
  // relevance: yup.number()
  //   .nullable()
  //   .transform((v) => (!!v ? v : null)),
  category_id: yup.number()
    .required('Category required.'),
}).required()
