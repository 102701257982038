import { ApiRequest, ApiConfig } from '..'
import { PaginatedIndexParams, PaginatedSearchParams, ShowParams, CreateParams, UpdateParams, DestroyParams } from '../ApiRequest';

const SubmissionsResource = {
  getAdminIndex: ({ page }: PaginatedIndexParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl('admin/submissions'), { params: { page } })
  ),
  getAdminSearch: ({ page, query }: PaginatedSearchParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl('admin/submissions/search'), { params: { page, query } })
  ),
  getIndex: (params: PaginatedIndexWithFilterParams) => (
    ApiConfig.getToken()
      ? SubmissionsResource.getPrivateIndex(params)
      : SubmissionsResource.getPublicIndex(params)
  ),
  getPublicIndex: ({ categories, leagues, page }: PaginatedIndexWithFilterParams) => (
    ApiRequest.publicGet(
      ApiConfig.apiUrl('public/submissions'),
      {
        params: {
          categories: categories?.join(';'),
          leagues: leagues?.join(';'),
          page,
        }
      }
    )
  ),
  getPrivateIndex: ({ categories, leagues, page }: PaginatedIndexWithFilterParams) => (
    ApiRequest.privateGet(
      ApiConfig.apiUrl('private/submissions'),
      {
        params: {
          categories: categories?.join(';'),
          leagues: leagues?.join(';'),
          page,
        }
      }
    )
  ),
  getSearch: ({ page, query }: PaginatedSearchParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl('submissions/search'), { params: { page, query } })
  ),
  getShow: (params: ShowParams) => (
    ApiConfig.getToken()
      ? SubmissionsResource.getPrivateShow(params)
      : SubmissionsResource.getPublicShow(params)
  ),
  getPublicShow: ({ id }: ShowParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl(`public/submissions/${id}`))
  ),
  getPrivateShow: ({ id }: ShowParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl(`private/submissions/${id}`))
  ),
  getComments: ({ submission_id }: CommentsParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl(`submissions/${submission_id}/comments`))
  ),
  getFavorites: () => (
    ApiRequest.privateGet(ApiConfig.apiUrl('submissions/favorites'))
  ),
  create: ({ data: submission }: CreateParams) => (
    ApiRequest.privatePost(ApiConfig.apiUrl('submissions'), { submission })
  ),
  update: ({ id, data: submission }: UpdateParams) => (
    ApiRequest.privatePut(ApiConfig.apiUrl(`submissions/${id}`), { submission })
  ),
  putAccept: ({ id }: AcceptRejectParams) => (
    ApiRequest.privatePut(ApiConfig.apiUrl(`submissions/${id}/accept`))
  ),
  putReject: ({ id }: AcceptRejectParams) => (
    ApiRequest.privatePut(ApiConfig.apiUrl(`submissions/${id}/reject`))
  ),
  delete: ({ id }: DestroyParams) => (
    ApiRequest.privateDelete(ApiConfig.apiUrl(`submissions/${id}`))
  ),
}

type PaginatedIndexWithFilterParams = {
  categories?: string[]
  leagues?: string[]
  page: number
}

type CommentsParams = {
  submission_id: number
}

type AcceptRejectParams = {
  id: number
}

export default SubmissionsResource
