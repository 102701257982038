import { useMediaUploader } from '../../context';
import Image, { CurrentImage } from '../Image';
import Video from '../Video';

const Media = () => {
  const { currentFile, mediaType, name } = useMediaUploader()

  return currentFile
    ? <CurrentImage image={currentFile} name={name} />
    : mediaType === 'image'
      ? <Image />
      : <Video />
}

export default Media
