import React, { useRef } from 'react'

import { useDropdown } from '@/context/dropdown'

import styles from './styles.mod.scss'

const SelectedItem = ({
  item
}: SelectedItemProps) => {
  const ref = useRef(null)
  const { selectedItems, setSelectedItems } = useDropdown()

  const onSelectItem = (e) => {
    e.preventDefault()
    setSelectedItems(selectedItems.filter((i) => i !== item))
  }

  return (
    <button
      aria-label="Remove item"
      className={styles.item}
      onClick={onSelectItem}
      ref={ref}
    >
      <div className={styles.name}>{item.name}</div>
      <div className={styles.remove}>Remove</div>
    </button>
  )
}

const SelectedItems = () => {
  const { resourceName, selectedItems } = useDropdown()

  return (
    <div className={styles.wrapper}>
      {selectedItems.map((item) => (
        <SelectedItem
          key={`selected_${resourceName}_${item.slug}`}
          itemName={resourceName}
          item={item}
        />
      ))}
    </div>
  )
}

type SelectedItemProps = {
  item: any
}

export default SelectedItems
