import styles from './styles.mod.scss'

import classNames from 'classnames';
import React, {
    cloneElement, createContext, forwardRef, PropsWithChildren, useContext, useEffect, useMemo,
    useState
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import TimesIcon from '@/assets/images/icons/times-circle-solid.svg';
import { setIsModalOpen } from '@/store/modal';
import {
    FloatingFocusManager, FloatingOverlay, FloatingPortal, useClick, useDismiss, useFloating,
    useInteractions, useMergeRefs, useRole, useTransitionStyles
} from '@floating-ui/react';

import { ButtonWhiteInverted } from '../../common/buttons/Button';

const DialogContext = createContext(null)

const DialogProvider = ({
  children
}: PropsWithChildren) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const [isOpen, setIsOpen] = useState(false)

  const data = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  })

  const { context } = data

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    duration: {
      open: 150,
      close: 150,
    },
  })
  const { styles: modalStyles } = useTransitionStyles(context, {
    duration: {
      open: 150,
      close: 150,
    },
    initial: {
      opacity: 0,
      transform: 'scale(0.75)',
    },
  })

  const click = useClick(context)
  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown' })
  const role = useRole(context)

  const interactions = useInteractions([click, dismiss, role])

  const value = useMemo(() => ({
    ...data,
    ...interactions,
    isMounted,
    isOpen,
    setIsOpen,
    transitionStyles,
    modalStyles,
  }), [data, isOpen, interactions, setIsOpen, transitionStyles, isMounted, modalStyles])

  useEffect(() => {
    dispatch(setIsModalOpen(isOpen))
  }, [isOpen])

  useEffect(() => {
    dispatch(setIsModalOpen(false))
    setIsOpen(false)
  }, [location])

  return (
    <DialogContext.Provider value={value}>
      {children}
    </DialogContext.Provider>
  )
}

export const DialogTrigger = forwardRef((props: PropsWithChildren, propRef) => {
  const { children } = props

  const { getReferenceProps, refs } = useDialog()
  const childrenRef = children.ref

  const ref = useMergeRefs([
    refs.setReference,
    propRef,
    childrenRef,
  ])

  return cloneElement(
    children,
    getReferenceProps({
      ref,
      ...props,
      ...children.props,
    })
  )
})

export const DialogContent = forwardRef((props: DialogContentProps, propRef) => {
  const {
    context,
    getFloatingProps,
    isMounted,
    refs,
    transitionStyles,
    modalStyles,
  } = useDialog()
  const { children, className, dialogTitle, ...rest } = props
  const ref = useMergeRefs([refs.setFloating, propRef])

  if (!isMounted) return null

  return (
    <FloatingPortal>
      <FloatingOverlay
        className={styles.overlay}
        lockScroll
        style={{ ...transitionStyles }}
      >
        <FloatingFocusManager context={context}>
          <div
            className={classNames(styles.content, className)}
            ref={ref}
            style={{ ...modalStyles }}
            {...getFloatingProps(rest)}
          >
            <div className={styles.title}>
              <h2>{dialogTitle}</h2>
              <DialogClose />
            </div>
            {children}
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  )
})

export const DialogCancel = forwardRef((props: DialogCancelProps, ref) => {
  const { setIsOpen } = useDialog()

  return (
    <ButtonWhiteInverted
      className={props.className}
      label="Cancel"
      onClick={() => setIsOpen(false)}
      ref={ref}
      {...props}
    >
      Cancel
    </ButtonWhiteInverted>
  )
})

export const DialogClose = forwardRef((props: PropsWithChildren, ref) => {
  const { setIsOpen } = useDialog()

  return (
    <button
      className={styles.close}
      aria-label="Close"
      onClick={() => setIsOpen(false)}
      ref={ref}
      type="button"
      {...props}
    >
      <TimesIcon />
    </button>
  )
})

type DialogContentProps = {
  children: React.ReactNode
  className?: string
  dialogTitle: string | React.ReactNode
}

type DialogCancelProps = {
  children?: React.ReactNode
  className?: string
}

export default DialogProvider

export const useDialog = () => useContext(DialogContext)
