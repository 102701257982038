import MessagesResource from '../resources/Messages'
import { ApiMutations } from '..'
import { QueryParamsObject } from '../ApiQueries'

const MessagesMutation = {
  create: () => (
    ApiMutations.constructMutation(
      MessagesResource.create,
      [
        ['Messages', 'getIndex'],
        ['Messages', 'getInfiniteDirectIndex'],
        ['Contacts', 'getInfiniteIndex'],
      ],
      { successMessage: 'Message sent.' },
    )
  ),
  delete: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      MessagesResource.delete,
      [
        ['Messages', 'getIndex'],
      ],
      { successMessage: 'Message deleted.' },
    )
  ),
  addToInfiniteMessages: () => (
    ApiMutations.addToInfiniteQueryData([
      ['Messages', 'getInfiniteDirectIndex'],
    ])
  )
}

export default MessagesMutation
