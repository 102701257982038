import CategoriesResource from '../resources/Categories'
import { ApiMutations, ApiQueries } from '..'
import { ShowParams } from '../ApiRequest'
import { QueryParamsObject } from '../ApiQueries'

const CategoriesMutation = {
  create: () => (
    ApiMutations.constructMutation(
      CategoriesResource.create,
      [
        ['Categories', 'getAdminIndex'],
        ['Categories', 'getIndex'],
      ],
      { successMessage: 'Category created.' },
    )
  ),
  update: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      CategoriesResource.update,
      [
        ['Categories', 'getAdminIndex'],
        ['Categories', 'getIndex'],
        ['Categories', 'getShow', ApiQueries.queryParams(params)],
      ],
      { successMessage: 'Category updated.' },
    )
  ),
  delete: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      CategoriesResource.delete,
      [
        ['Categories', 'getAdminIndex'],
        ['Categories', 'getIndex'],
        ['Categories', 'getShow', ApiQueries.queryParams(params)],
      ],
      { successMessage: 'Category deleted.' },
    )
  ),
}

export default CategoriesMutation
