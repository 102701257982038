import classNames from 'classnames';

import DropdownMultiSelect from '@/components/dropdowns/DropdownMultiSelect';
import { dropdownOptsType } from '@/context/dropdown';

import { useFormPrimary } from '../..';
import FormField from '../FormField';
import sharedStyles from '../../styles.mod.scss';

const FormMultiSelect = ({
  className = '',
  field = '',
  initial = [],
  label = '',
  name = '',
  ...rest
} : FormMultiSelectProps) => {
  const { setValue } = useFormPrimary()

  const onChange = (items: Array<dropdownItemType>) => {
    setValue(field, items.map((item) => item.id))
  }

  return (
    <FormField
      className={classNames(sharedStyles.field, className)}
      fieldName={field}
      label={label}
      name={name}
    >
      <DropdownMultiSelect
        id={`${name}MultiSelect`}
        initial={initial}
        onChange={onChange}
        resourceName={name}
        preselectedSlugs={initial.map((item) => item.slug)}
        {...rest}
      />
    </FormField>
  )
}

type FormMultiSelectProps = {
  className?: string
  fetchQuery: () => void
  field: string
  initial?: Array<dropdownItemType>
  label?: string
  maxItems?: number
  name: string
  opts?: dropdownOptsType
}

type dropdownItemType = {
  id: number
  slug: string
}

export default FormMultiSelect
