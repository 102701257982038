import React from 'react'

import AboutPage from '@/pages/static/About'
import ChallengesListPage from '@/pages/challenges/List'
import ChallengesShowPage from '@/pages/challenges/Show'
import CompetitionsListPage from '@/pages/competitions/List'
import CompetitionsShowPage from '@/pages/competitions/Show'
import ContactPage from '@/pages/static/Contact'
import ExplorePage from '@/pages/Explore'
import HomePage from '@/pages/static/Home'
import LeaguesListPage from '@/pages/leagues/List'
import LeaguesShowPage from '@/pages/leagues/Show'
import RulesListPage from '@/pages/rules/List'
import SubmissionsShowPage from '@/pages/submissions/Show'
import SupportPage from '@/pages/static/Support'
import UsersShowPage from '@/pages/users/Show'

import AuthWrapper from '@/pages/auth/AuthWrapper'
import LoginPage from '@/pages/auth/Login'
import PasswordResetPage from '@/pages/auth/PasswordReset'
import PasswordForgotPage from '@/pages/auth/PasswordForgot'
import SignupPage from '@/pages/auth/Signup'

const publicRoutes = [
  {
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
      {
        path: '/about',
        element: <AboutPage />,
      },
      {
        path: '/contact',
        element: <ContactPage />,
      },
      {
        path: '/challenges',
        element: <ChallengesListPage />,
      },
      {
        path: '/challenges/:id',
        element: <ChallengesShowPage />,
      },
      {
        path: '/competitions',
        element: <CompetitionsListPage />,
      },
      {
        path: '/competitions/:id',
        element: <CompetitionsShowPage />,
      },
      {
        path: '/explore',
        element: <ExplorePage />,
      },
      {
        path: '/rules',
        element: <RulesListPage />,
      },
      {
        path: '/submissions/:id',
        element: <SubmissionsShowPage />,
      },
      {
        path: '/leagues',
        element: <LeaguesListPage />,
      },
      {
        path: '/leagues/:id',
        element: <LeaguesShowPage />,
      },
      {
        path: '/users/:id',
        element: <UsersShowPage />,
      },
      {
        path: '/support',
        element: <SupportPage />,
      },
      {
        path: '/users',
        element: <AuthWrapper />,
        children: [
          {
            path: 'login',
            element: <LoginPage />,
          },
          {
            path: 'signup',
            element: <SignupPage />,
          },
          {
            path: 'password/reset',
            element: <PasswordForgotPage />,
          },
          {
            path: 'password/edit',
            element: <PasswordResetPage />,
          },
        ]
      }
    ]
  },
]

export default publicRoutes
