/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { setterFunction } from './helpers'

export const pagesSlice = createSlice({
  name: 'pages',
  initialState: {
    lastPage: '/explore',
    pageTitle: '',
  },
  reducers: {
    setLastPage: setterFunction('lastPage'),
    setPageTitle: setterFunction('pageTitle'),
  },
})

export const {
  setLastPage,
  setPageTitle,
} = pagesSlice.actions

export interface PagesState {
  lastPage: string,
  pageTitle: string,
}

export default pagesSlice.reducer
