import React from 'react'
import classNames from 'classnames'

import CardLink from '../../CardLink'
import CategoryBadge from '../../../badges/CategoryBadge'
import { CompetitionEndTime, CompetitionStartTime } from '../Time'
import { competitionDefault, competitionType } from '../../../../schemas/types/competition'
import { imageLarge } from '../../../../common/helpers'

import ProgressiveLoadImage from '../../../ProgressiveLoadImage'

import styles from './styles.mod.scss'
import sharedStyles from '../styles.mod.scss'
// import '../shared.scss'

const CompetitionCard = ({
  competition = competitionDefault
}: CompetitionCardProps) => {
  const {
    categories,
    description,
    end_time,
    id,
    logo_url_small,
    logo_url_medium,
    name,
    start_time,
  } = competition

  return (
    <CardLink
      className={classNames('CompetitionCard', sharedStyles.base)}
      to={`/competitions/${id}`}
      opts={{ noContainer: true }}
    >
      <ProgressiveLoadImage
        alt="logo"
        className={styles.logo}
        img={imageLarge(logo_url_small)}
        placeholderImgs={[imageLarge(logo_url_medium)]}
      />
      <div className={classNames(sharedStyles.content, styles.content)}>
        <div className={sharedStyles.header}>
          <div className={sharedStyles.title}>
            {name}
          </div>
          <div className={sharedStyles.categories}>
            {categories.map((cat) => (
              <CategoryBadge
                key={`${competition.slug}-cat-${cat.slug}`}
                name={cat.name}
                slug={cat.slug}
              />
            ))}
          </div>
          <div className={sharedStyles.description}>
            {description}
          </div>
        </div>
        <div className={sharedStyles.times}>
          <CompetitionStartTime time={start_time} />
          <CompetitionEndTime time={end_time} />
        </div>
      </div>
    </CardLink>
  )
}

type CompetitionCardProps = {
  competition: competitionType,
}

export default CompetitionCard
