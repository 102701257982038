import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import PopoverProvider, { PopoverContent, PopoverTrigger, usePopover } from '../Popover'

import styles from './styles.mod.scss'

const HeaderPopover = ({
  Trigger = null,
  children
}: HeaderPopoverProps) => (
  <PopoverProvider>
    {Trigger ? <Trigger /> : null}
    <PopoverContent>
      {children}
    </PopoverContent>
  </PopoverProvider>
)

export const HeaderPopoverContent = ({
  children,
  className = '',
  header = '',
  to = '',
}: HeaderPopoverContentProps) => (
  <div className={classNames(styles.content, className)}>
    <div className={styles.content_header}>
      <h2>{header}</h2>
      <Link aria-label="View All" to={to}>View All</Link>
    </div>
    <div className={styles.content_items}>
      {children}
    </div>
  </div>
)

export const HeaderPopoverTrigger = ({
  ActiveIcon = null,
  InactiveIcon = null,
  className = '',
  name = '',
}: HeaderPopoverTriggerProps) => {
  const { isOpen } = usePopover()

  return (
    <PopoverTrigger>
      <button
        aria-label="Open popover"
        className={classNames(
          styles.trigger,
          className,
          name,
          { [styles.open]: isOpen },
        )}
      >
        {isOpen ? ActiveIcon : InactiveIcon}
      </button>
    </PopoverTrigger>
  )
}

type HeaderPopoverProps = {
  Trigger?: React.FC | null
  children?: React.ReactNode
}

type HeaderPopoverContentProps = {
  children: React.ReactNode
  className?: string
  header: string
  to: string
}

type HeaderPopoverTriggerProps = {
  ActiveIcon?: React.ReactNode
  InactiveIcon?: React.ReactNode
  className?: string
  name?: string
}

export default HeaderPopover
