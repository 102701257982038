export const categoryDefault: categoryType = {
  id: undefined,
  name: '',
  relevance: undefined,
  slug: '',
}

export type categoryType = {
  id: number | undefined
  name: string
  relevance: number | undefined
  slug: string
}
