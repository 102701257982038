import React, { useRef } from 'react'

import { useDropdown } from '@/context/dropdown'

import styles from './styles.mod.scss'
import headerClasses from './styles.mod.scss'

const ClearButton = () => {
  const { id, selectedItems, setSelectedItems } = useDropdown()
  const ref = useRef(null)

  if (!selectedItems.length) return null

  const onClearClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setSelectedItems([])

    const nextFocusableElement = document
      .getElementById(id)
      .getElementsByClassName(headerClasses.activate)[0]
    nextFocusableElement?.focus()
  }

  return (
    <button
      aria-label="Clear selections"
      className={styles.clear}
      onClick={onClearClick}
      ref={ref}
    >
      Clear
    </button>
  )
}

export default ClearButton
