import React from 'react'
import classNames from 'classnames'

import styles from './styles.mod.scss'

const BUTTON_VARIANTS = [
  'primary',
  'primary-inverted',
  'secondary',
  'secondary-inverted',
  'black',
  'grey',
  'grey-inverted',
  'white',
  'white-inverted',
  'red',
  'red-inverted',
]

const Button = ({
  children,
  className = '',
  disabled = false,
  full = true,
  label = 'button',
  onClick = () => {},
  scale = 0.8,
  type = 'button',
  variant = 0,
} : ButtonProps) => (
  <button
    className={classNames(
      className,
      styles.base,
      styles[BUTTON_VARIANTS[variant]],
      { [styles.full]: full }
    )}
    disabled={disabled}
    onClick={onClick}
    style={{ fontSize: `${scale}rem` }}
    type={type}
    aria-label={label}
  >
    {children}
  </button>
)

export const ButtonPrimary = (props: ButtonProps) => (
  <Button variant={0} {...props}>
    {props.children}
  </Button>
)

export const ButtonPrimaryInverted = (props: ButtonProps) => (
  <Button variant={1} {...props}>
    {props.children}
  </Button>
)

export const ButtonSecondary = (props: ButtonProps) => (
  <Button variant={2} {...props}>
    {props.children}
  </Button>
)

export const ButtonSecondaryInverted = (props: ButtonProps) => (
  <Button variant={3} {...props}>
    {props.children}
  </Button>
)

export const ButtonBlack = (props: ButtonProps) => (
  <Button variant={4} {...props}>
    {props.children}
  </Button>
)

export const ButtonGrey = (props: ButtonProps) => (
  <Button variant={5} {...props}>
    {props.children}
  </Button>
)

export const ButtonGreyInverted = (props: ButtonProps) => (
  <Button variant={6} {...props}>
    {props.children}
  </Button>
)

export const ButtonWhite = (props: ButtonProps) => (
  <Button variant={7} {...props}>
    {props.children}
  </Button>
)

export const ButtonWhiteInverted = (props: ButtonProps) => (
  <Button variant={8} {...props}>
    {props.children}
  </Button>
)

export const ButtonRed = (props: ButtonProps) => (
  <Button variant={9} {...props}>
    {props.children}
  </Button>
)

export const ButtonRedInverted = (props: ButtonProps) => (
  <Button variant={10} {...props}>
    {props.children}
  </Button>
)

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode
  className?: string
  disabled?: boolean
  full?: boolean
  label?: string
  onClick?: () => void
  scale?: number
  type?: 'reset' | 'submit' | 'button' | undefined
  variant?: number
}

export default Button
