import React from 'react';

import DeleteIcon from '@/assets/images/icons/trash-regular.svg';

import { ButtonRed } from '../../../common/buttons/Button';
import DialogProvider, {
    DialogCancel, DialogContent, DialogTrigger, useDialog
} from '../../Dialog';
import styles from './styles.mod.scss';

const DeleteConfirmationDialog = ({
  Trigger = DeleteConfirmationTrigger,
  children,
  className = '',
  onConfirm = () => {},
  resource = 'resource',
}: DeleteConfirmationDialogProps) => (
  <DialogProvider>
    <Trigger className={className} />
    <DialogContent dialogTitle={`Delete ${resource}`}>
      <DeleteConfirmationContent resource={resource} onConfirm={onConfirm}>
        {children}
      </DeleteConfirmationContent>
    </DialogContent>
  </DialogProvider>
)

const DeleteConfirmationContent = ({
  children,
  onConfirm = () => {},
  resource = 'resource',
}: DeleteConfirmationContentProps) => {
  const { setIsOpen } = useDialog()

  const onConfirmClick = async () => {
    const response = await onConfirm()

    if (response) {
      setIsOpen(false)
    }
  }

  return (
    <div className={styles.confirmationContent}>
      <h3>Are you sure you want to delete this {resource}?</h3>
      {children}
      <div className={styles.buttons}>
        <DialogCancel className={styles.button} />
        <ButtonRed
          className={styles.button}
          label="Delete"
          onClick={onConfirmClick}
        >
          Delete
        </ButtonRed>
      </div>
    </div>
  )
}

export const DeleteConfirmationTrigger = ({
  className = '',
}: DeleteConfirmationTriggerProps) => (
  <DialogTrigger>
    <button
      aria-label="Delete"
      className={className}
    >
      <DeleteIcon />
    </button>
  </DialogTrigger>
)

type DeleteConfirmationDialogProps = {
  Trigger?: React.FC<DeleteConfirmationTriggerProps>
  children?: React.ReactNode
  className?: string
  onConfirm?: () => void
  resource?: string
}

type DeleteConfirmationContentProps = {
  children?: React.ReactNode
  onConfirm?: () => void
  resource?: string
}

type DeleteConfirmationTriggerProps = {
  className?: string
}

export default DeleteConfirmationDialog
