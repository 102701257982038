import * as yup from 'yup'

export const passwordSchema = yup.object({
  current_password: yup.string()
    .required('Current password required.'),
  password: yup.string()
    .required('New password required.')
    .notOneOf([yup.ref('current_password'), null], 'Cannot match current password.')
    .max(40, 'Password must be less than 40 characters.')
    .min(8, 'Password must be at least 8 characters long.')
    .required(),
  password_confirmation: yup.string()
    .required('Password confirmation required.')
    .oneOf([yup.ref('password'), null], 'Must match new password')
    .required(),
}).required()
