import React from 'react'

import PageContent from '@/components/layouts/PageContent'
import PageWrapper from '@/components/layouts/PageWrapper'
import withScrollTop from '@/hocs/withScrollTop'

import styles from './styles.mod.scss'

const AboutPage = () => (
  <PageWrapper name="About" title="About">
    <PageContent>
      <h1>About</h1>
    </PageContent>
  </PageWrapper>
)

export default withScrollTop(AboutPage)
