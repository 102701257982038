import CompetitionsResource from '../resources/Competitions'
import { ApiMutations, ApiQueries } from '..'
import { QueryParamsObject } from '../ApiQueries'

const CompetitionsMutation = {
  create: () => (
    ApiMutations.constructMutation(
      CompetitionsResource.create,
      [
        ['Competitions', 'getAdminIndex'],
        ['Competitions', 'getIndex'],
        ['Competitions', 'getInfiniteIndex'],
      ],
      { successMessage: 'Competition created.' },
    )
  ),
  update: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      CompetitionsResource.update,
      [
        ['Competitions', 'getAdminIndex'],
        ['Competitions', 'getIndex'],
        ['Competitions', 'getInfiniteIndex'],
        ['Competitions', 'getShow', ApiQueries.queryParams(params)],
      ],
      { successMessage: 'Competition updated.' },
    )
  ),
  delete: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      CompetitionsResource.delete,
      [
        ['Competitions', 'getAdminIndex'],
        ['Competitions', 'getIndex'],
        ['Competitions', 'getInfiniteIndex'],
        ['Competitions', 'getShow', ApiQueries.queryParams(params)],
      ],
      { successMessage: 'Competition deleted.' },
    )
  ),
}

export default CompetitionsMutation
