import VotesResource from '../resources/Votes'
import { ApiMutations, ApiQueries } from '..'
import { QueryParamsObject } from '../ApiQueries'

const VotesMutation = {
  create: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      VotesResource.create,
      [
        ['Submissions', 'getVotes'],
        ['Submissions', 'getShow', ApiQueries.queryParams(params)],
      ],
    )
  ),
  delete: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      VotesResource.delete,
      [
        ['Submissions', 'getVotes'],
        ['Submissions', 'getShow', ApiQueries.queryParams(params)],
      ],
    )
  ),
  invalidateSubmission: (params: QueryParamsObject) => (
    ApiMutations.invalidateQueries([
      ['Submissions', 'getShow', ApiQueries.queryParams(params)],
    ])
  ),
  updateInfiniteSubmissions: () => (
    // Order by most expensive updates last
    ApiMutations.updateInfiniteQueryData([
      ['Challenges', 'getInfiniteSubmissions'],
      ['Competitions', 'getInfiniteSubmissions'],
      ['Leagues', 'getInfiniteSubmissions'],
      ['Submissions', 'getInfiniteFavorites'],
      ['Users', 'getInfiniteSubmissions'],
      ['Rituals', 'getInfiniteSubmissions'],

      ['Submissions', 'getInfiniteIndex'],

    ])
  )
}

export default VotesMutation
