import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import CompetitionsQuery from '../../../api/queries/Competitions'

import CompetitionCardExpanded from '../../cards/competitions/CardExpanded'
import LoadingContainer from '../../LoadingContainer'
import { competitionDefault } from '../../../schemas/types/competition'
import { setPageTitle } from '../../../store/pages'

const CompetitionUtilMenu = ({ id }: CompetitionUtilMenuProps) => {
  const { data, isLoading } = CompetitionsQuery.getShow({ id })
  const dispatch = useDispatch()

  const { name } = data || competitionDefault

  useEffect(() => {
    dispatch(setPageTitle(name))
  }, [name])

  return (
    <LoadingContainer
      isLoading={isLoading}
      hasContent={!!data}
      resource="competition"
    >
      <CompetitionCardExpanded
        competition={data}
      />
    </LoadingContainer>
  )
}

type CompetitionUtilMenuProps = {
  id?: number
}

export default CompetitionUtilMenu
