import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Outlet, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import AdminDashboardUtilMenu from '@/components/utilityMenu/AdminDashboard'
import AdminListPath from './ListPath'
import PageContent from '@/components/layouts/PageContent'
import PageWrapper from '@/components/layouts/PageWrapper'
import withProvider from '@/hocs/withProvider'
import { AdminSidebarFooterProvider } from '@/context/adminSidebarFooter'
import { resetUtilMenu, setUtilMenuContent, setUtilMenuOpts } from '@/store/utilMenu'
import { setPageTitle } from '@/store/pages'

import styles from './styles.mod.scss'

const defaultResource = 'challenges'

const getCurrentResource = (pathname: string) => pathname.split('/')[2] || defaultResource
const getIsIndexPath = (pathname: string) => pathname.split('/')[3] === undefined

const AdminDashboard = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const { pathname } = location

  const [currentResource, setCurrentResource] = useState(getCurrentResource(pathname))
  const [isIndexPath, setIsIndexPath] = useState(getIsIndexPath(pathname))

  const [filterActive, setFilterActive] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    if (isIndexPath) dispatch(setPageTitle('Admin Dashboard'))

    dispatch(setUtilMenuContent(
      <AdminDashboardUtilMenu
        isIndexPath={isIndexPath}
        setFilterActive={setFilterActive}
        setQuery={setSearchValue}
        currentResource={currentResource}
      />
    ))
    dispatch(setUtilMenuOpts({ noPadding: true }))

    return () => dispatch(resetUtilMenu())
  }, [currentResource, isIndexPath])

  useEffect(() => {
    setIsIndexPath(getIsIndexPath(pathname))
    setCurrentResource(getCurrentResource(pathname))
  }, [location])

  if (!pathname.includes('admin')) return null

  return (
    <PageWrapper name="AdminDashboard">
      <PageContent>
        <div
          className={classNames(
            styles.content,
            { [styles.contentCol]: getIsIndexPath(pathname) }
          )}
        >
          {isIndexPath ? (
            <AdminListPath
              filterActive={filterActive}
              searchValue={searchValue}
              resource={currentResource}
            />
          ) : (
            <Outlet />
          )}
        </div>
      </PageContent>
    </PageWrapper>
  )
}

export default withProvider(AdminSidebarFooterProvider)(AdminDashboard)
