import React from 'react'
import { NavLink } from 'react-router-dom'

import styles from './styles.mod.scss'

const AccountUtilMenu = () => (
  <div className="AccountUtilMenu">
    <NavLink to="/account/profile" className={styles.link}>
      Profile
    </NavLink>
    <NavLink to="/account/password" className={styles.link}>
      Password
    </NavLink>
  </div>
)

export default AccountUtilMenu
