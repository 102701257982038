import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import LoginForm from '../../../components/forms/auth/LoginForm'
import withScrollTop from '../../../hocs/withScrollTop'
import { setPageTitle } from '../../../store/pages'

import styles from './styles.mod.scss'
import { setIsUtilMenuActive } from '@/store/utilMenu'

const LoginPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPageTitle('Log in'))
    dispatch(setIsUtilMenuActive(false))
  }, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1>Welcome back! Do the thing.</h1>
      </div>
      <LoginForm />
    </div>
  )
}

export default withScrollTop(LoginPage)
