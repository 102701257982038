import React from 'react'

import SubmissionsQuery from '@/api/queries/Submissions'

import PageContent from '@/components/layouts/PageContent'
import PageWrapper from '@/components/layouts/PageWrapper'
import ScrollableResource from '@/components/ScrollableResource'
import SubmissionCard from '@/components/cards/submissions/Card'
import withScrollTop from '@/hocs/withScrollTop'
import { InfiniteScrollProvider } from '@/context/infiniteScroll'

const MyFavoritesPage = () => (
  <PageWrapper name="MyFavorites" title="My Stuff">
    <PageContent>
      <InfiniteScrollProvider
        cardClassName="SubmissionCard"
        query={SubmissionsQuery.getInfiniteFavorites}
      >
        <ScrollableResource
          Component={SubmissionCard}
          resourceName="submission"
        />
      </InfiniteScrollProvider>
    </PageContent>
  </PageWrapper>
)

export default withScrollTop(MyFavoritesPage)
