import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import CommentsMutation from '@/api/mutations/Comments'
import { ApiConfig } from '@/api'

import FormError from '@/components/forms/FormPrimary/components/FormError'
import WYSIWYG from '@/components/forms/WYSIWYG'
import { ButtonPrimary } from '@/common/buttons/Button'
import { DialogCancel } from '@/components/Dialog'
import { commentSchema } from '@/schemas/forms/comment'

import SendIcon from '@/assets/images/icons/paper-plane-top-solid.svg'

import styles from './styles.mod.scss'
import classNames from 'classnames'

const CommentForm = ({
  submission_id,
  onSubmitSuccess = () => {},
}: CommentFormProps) => {
  const createComment = CommentsMutation.create({ submission_id })
  const {
    formState: { errors },
    clearErrors,
    handleSubmit,
    setValue,
  } = useForm({ resolver: yupResolver(commentSchema) })

  const formSubmit = async (data) => {
    const response = await createComment.mutateAsync({ data })

    ApiConfig.successfulResponseCallback(response, () => {
      onSubmitSuccess(response.data)
    })
  }

  const onContentChange = (contentHTML) => {
    clearErrors('content')
    setValue('content', contentHTML)
  }

  useEffect(() => {
    setValue('submission_id', submission_id)
  }, [])

  return (
    <form className={styles.form} onSubmit={handleSubmit(formSubmit)}>
      <WYSIWYG
        initialContent=""
        onChange={onContentChange}
        onSubmit={handleSubmit(formSubmit)}
        className={styles.wysiwyg}
        toolbar={{
          options: ['inline'],
          inline: {
            options: ['bold', 'italic', 'underline'],
          }
        }}
      />
      <FormError present={errors.content}>{errors.content?.message}</FormError>

      <div className={styles.buttonContainer}>
        <DialogCancel className={styles.button} />
        <ButtonPrimary
          className={classNames(styles.button, styles.buttonSubmit)}
          label="Send"
          type="submit"
        >
          Add Comment
          <SendIcon />
        </ButtonPrimary>
      </div>
    </form>
  )
}

type CommentFormProps = {
  onSubmitSuccess: () => void
  submission_id: string
}

export default CommentForm
