import React from 'react'
import classNames from 'classnames'

import UsersQuery from '../../api/queries/Users'

import Card from '../../components/cards/Card'
import Cards from '../../components/cards/Cards'
import LoadingContainer from '../../components/LoadingContainer'
import PageContent from '../../components/layouts/PageContent'
import PageWrapper from '../../components/layouts/PageWrapper'
import withScrollTop from '../../hocs/withScrollTop'

import dzastrCoinImg from '../../assets/images/dzastr-coin-green.png'
import gloryCoinImg from '../../assets/images/dzastr-coin-purple.png'

import styles from './styles.mod.scss'

const walletImgs = {
  // Clout: cloutImg,
  'Glory Coin Wallet': gloryCoinImg,
  'DZASTR Coin Wallet': dzastrCoinImg,
}

const Wallet = ({
  name,
  slug,
  balance,
}: WalletProps) => {
  const walletImg = walletImgs[name]

  return (
    <Card
      className={classNames(
        styles.Wallet,
        {
          [styles.primary]: slug.includes('dzastr'),
          [styles.secondary]: slug.includes('glory'),
        }
      )}
    >
      <div className={styles.coin_name}>
        {walletImg ? (
          <div className={styles.coin_img}>
            <img src={walletImg} alt={name} />
          </div>
        ) : (
          <div className={styles.clout} />
        )}
        <div className={styles.name_balance}>
          <div className={styles.name}>
            {name.substring(0, name.indexOf(' Wallet'))}
          </div>
          <div className={styles.balance}>{balance.toLocaleString()}</div>
        </div>
      </div>
    </Card>
  )
}

const WalletsPage = () => {
  const { data, isLoading } = UsersQuery.getLedgerAccounts()

  return (
    <PageWrapper name="Wallets" title="Clout">
      <PageContent>
        <Cards tapered>
          <div className={styles.group}>
            <h2>Wallets</h2>
            <LoadingContainer
              hasContent={!!data?.length}
              isLoading={isLoading}
              resource="wallets"
            >
              {data?.map((wallet) => (
                <Wallet
                  balance={wallet.balance}
                  key={wallet.id}
                  kind={wallet.kind}
                  name={wallet.name}
                  slug={wallet.slug}
                />
              ))}
            </LoadingContainer>
          </div>
        </Cards>
      </PageContent>
    </PageWrapper>
  )
}

type WalletProps = {
  balance: number
  kind: string
  name: string
  slug: string
}

export default withScrollTop(WalletsPage)
