import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import ChallengesQuery from '../../../api/queries/Challenges'

import ChallengeCardExpanded from '../../cards/challenges/CardExpanded'
import LoadingContainer from '../../LoadingContainer'
import { challengeDefault } from '../../../schemas/types/challenge'
import { setPageTitle } from '../../../store/pages'

const ChallengeUtilMenu = ({ id }: ChallengeUtilMenuProps) => {
  const { data, isLoading } = ChallengesQuery.getShow({ id })
  const dispatch = useDispatch()

  const { name } = data || challengeDefault

  useEffect(() => {
    dispatch(setPageTitle(`${name} Submissions`))
  }, [name])

  return (
    <LoadingContainer
      isLoading={isLoading}
      hasContent={!!data}
      resource="challenge"
    >
      <ChallengeCardExpanded
        challenge={data}
      />
    </LoadingContainer>
  )
}

type ChallengeUtilMenuProps = {
  id?: number
}

export default ChallengeUtilMenu
