import React from 'react'

import LeaguesResource from '@/api/resources/Leagues'

import Cards from '@/components/cards/Cards'
import LeagueCard from '@/components/cards/leagues/Card'
import LoadingContainer from '@/components/LoadingContainer'
import PageContent from '@/components/layouts/PageContent'
import PageWrapper from '@/components/layouts/PageWrapper'
import withScrollTop from '@/hocs/withScrollTop'
import { PaginatedQueryProvider, usePaginatedQuery } from '@/context/paginatedQuery'

const MyLeagues = () => {
  const { isLoading, items } = usePaginatedQuery()

  return (
    <PageWrapper name="MyLeagues" title="My Stuff">
      <PageContent>
        <LoadingContainer
          isLoading={isLoading}
          hasContent={!!items.length}
          resource="leagues"
        >
          <Cards tapered>
            {items.map((league) => (
              <LeagueCard
                key={`league-${league.id}`}
                league={league}
              />
            ))}
          </Cards>
        </LoadingContainer>
      </PageContent>
    </PageWrapper>
  )
}

const MyLeaguesPage = () => (
  <PaginatedQueryProvider fetchQuery={LeaguesResource.getMyLeagues}>
    <MyLeagues />
  </PaginatedQueryProvider>
)

export default withScrollTop(MyLeaguesPage)
