import ChallengesQuery from '@/api/queries/Challenges'
import CompetitionsQuery from '@/api/queries/Competitions'
import CategoriesQuery from '@/api/queries/Categories'
import LeaguesQuery from '@/api/queries/Leagues'
import RitualsQuery from '@/api/queries/Rituals'
import RulesQuery from '@/api/queries/Rules'
import SubmissionsQuery from '@/api/queries/Submissions'
import UsersQuery from '@/api/queries/Users'
// import {
//   ChallengesQuery,
//   CompetitionsQuery,
//   CategoriesQuery,
//   LeaguesQuery,
//   RitualsQuery,
//   RulesQuery,
//   SubmissionsQuery,
//   UsersQuery,
// } from '@/api/queries'

export const fetchResourceFn = (resource, filterActive) => (
  resourceQueries[resource][filterActive ? 'search' : 'index']
)

const resourceQueries = {
  categories: {
    index: CategoriesQuery.getAdminIndex,
    search: CategoriesQuery.getSearch,
  },
  challenges: {
    index: ChallengesQuery.getAdminIndex,
    search: ChallengesQuery.getSearch,
  },
  competitions: {
    index: CompetitionsQuery.getAdminIndex,
    search: CompetitionsQuery.getSearch,
  },
  leagues: {
    index: LeaguesQuery.getAdminIndex,
    search: LeaguesQuery.getSearch,
  },
  rituals: {
    index: RitualsQuery.getIndex,
    search: RitualsQuery.getSearch,
  },
  rules: {
    index: RulesQuery.getIndex,
    search: RulesQuery.getSearch,
  },
  submissions: {
    index: SubmissionsQuery.getAdminIndex,
    search: SubmissionsQuery.getSearch,
  },
  users: {
    index: UsersQuery.getIndex,
    search: UsersQuery.getSearch,
  },
  default: {
    index: () => {},
    search: () => {},
  }
}
