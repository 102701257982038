import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

const withScrollTop = (Component) => (props) => {
  const isLayoutMounted = useSelector((state) => state.components.layout.isMounted)

  useEffect(() => {
    if (isLayoutMounted) {
      const layout = document.getElementById('Layout')

      layout.scrollTo(0, 0)
    }
  }, [isLayoutMounted])

  return <Component {...props} />
}

export default withScrollTop
