import React from 'react'
import classNames from 'classnames'
import pluralize from 'pluralize'

import Badge from '@/components/badges/Badge'

import styles from './styles.mod.scss'

const LeagueCardMembers = ({
  className = '',
  members = 0
}: LeagueCardMembersProps) => (
  <div className={classNames(styles.members, className)}>
    <Badge color="grey">
      {pluralize('members', members, true)}
    </Badge>
  </div>
)

type LeagueCardMembersProps = {
  className?: string
  members: number
}

export default LeagueCardMembers
