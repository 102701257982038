import moment, { Moment } from 'moment-timezone'

moment.calendarFormat = (mom: Moment, now: Moment) => {
  const daysDiff = mom.diff(now, 'days', true)

  switch (true) {
    case (daysDiff >= 0 && daysDiff < 1): return 'today';
    case (daysDiff < 0 && daysDiff > -1): return 'yesterday';
    case (daysDiff > -6 && daysDiff < -1): return 'thisWeek';
    case (daysDiff <= -6 && mom.year() === now.year()): return 'thisYear';
    case (mom.year() < now.year()): return 'otherYear';
    default: return 'else'
  }
}

const MomentService = {
  formattedTime: (time: string | number | Date): string => (
    moment(time)
      .tz(moment.tz.guess())
      .calendar(null, {
        // today: 's [seconds ago]',
        today: '[Today at] LT',
        yesterday: '[Yesterday at] LT',
        thisWeek: 'ddd [at] LT',
        thisYear: 'MMM D [at] LT',
        otherYear: 'll',
        else: 'll',
      })
  )
}

export default MomentService
