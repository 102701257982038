import React from 'react'

import AdminIndexResource from '@/hocs/AdminIndexResource'
import AdminTable, { TableContent, TableHeader, TableHeaderCol } from '../../Dashboard/AdminTable'
import UserCardAdmin from '@/components/cards/users/CardAdmin'
import { userType } from '@/schemas/types/user'

import styles from './styles.mod.scss'

const RESOURCE = 'users'

const AdminUsers = ({
  results = []
}: AdminUsersProps) => (
  <AdminTable>
    <TableHeader>
      <TableHeaderCol className={styles.avatar} />
      <TableHeaderCol className={styles.name} name="name" />
      <TableHeaderCol className={styles.email} name="email" />
      <TableHeaderCol className={styles.bio} name="bio" />
      <TableHeaderCol className={styles.categories} name="categories" />
    </TableHeader>

    <TableContent>
      {results?.map((user) => (
        <UserCardAdmin
          key={`user-${user.id}`}
          user={user}
        />
      ))}
    </TableContent>
  </AdminTable>
)

type AdminUsersProps = {
  results: Array<userType>,
}

export default AdminIndexResource({ RESOURCE })(AdminUsers)
