import React, { useEffect } from 'react';

import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import ChallengesQuery from '@/api/queries/Challenges';
import SubmissionCard from '@/components/cards/submissions/Card';
import PageContent from '@/components/layouts/PageContent';
import PageWrapper from '@/components/layouts/PageWrapper';
import ScrollableResource from '@/components/ScrollableResource';
import ChallengeUtilMenu from '@/components/utilityMenu/Challenge';
import { InfiniteScrollProvider } from '@/context/infiniteScroll';
import withScrollTop from '@/hocs/withScrollTop';
import { resetUtilMenu, setUtilMenuContent } from '@/store/utilMenu';

const ChallengesShowPage = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    dispatch(setUtilMenuContent(
      <ChallengeUtilMenu id={id} />
    ))

    return () => dispatch(resetUtilMenu())
  }, [id])

  return (
    <motion.div>
      <PageWrapper name="ChallengesSubmissions">
        <PageContent>
          <InfiniteScrollProvider
            cardClassName="SubmissionCard"
            query={ChallengesQuery.getInfiniteSubmissions}
            params={{ id }}
          >
            <ScrollableResource
              Component={SubmissionCard}
              resourceName="submission"
            />
          </InfiniteScrollProvider>
        </PageContent>
      </PageWrapper>
    </motion.div>
  )
}

export default withScrollTop(ChallengesShowPage)
