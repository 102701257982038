import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import PasswordForgotForm from '@/components/forms/auth/PasswordForgotForm'
import withScrollTop from '@/hocs/withScrollTop'
import { setPageTitle } from '@/store/pages'

import styles from './styles.mod.scss'

const PasswordForgotPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPageTitle('Password Reset'))
  }, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1>Enter email to reset your password.</h1>
      </div>
      <PasswordForgotForm />
    </div>
  )
}

export default withScrollTop(PasswordForgotPage)
