import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import CloutUtilMenu from '@/components/utilityMenu/Clout'
import withScrollTop from '@/hocs/withScrollTop'
import { setUtilMenuContent } from '@/store/utilMenu'

const CloutWrapper = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setUtilMenuContent(<CloutUtilMenu />))
  }, [])

  return (
    <Outlet />
  )
}

export default withScrollTop(CloutWrapper)
