import React from 'react'

import CategoryBadge from '../../../badges/CategoryBadge'
import ChallengeCardBackground from '../Background'
import Reward from '../../../Reward'
import { LinkButtonPrimary } from '../../../../common/buttons/LinkButton'
import { challengeDefault, challengeType } from '../../../../schemas/types/challenge'

import styles from './styles.mod.scss'

const ChallengeCardExpanded = ({
  challenge = challengeDefault
}: ChallengeCardProps) => {
  const { category, description, id, logo_url, name, rewards } = challenge

  return (
    <div className={styles.wrapper}>
      <ChallengeCardBackground img={logo_url} isExpanded>
        <div className={styles.rewards}>
          {rewards.map((reward) => (
            <div className={styles.reward}>
              <Reward reward={reward} key={`reward--${reward.id}`} />
            </div>
          ))}
        </div>
      </ChallengeCardBackground>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.title}>
            {name}
          </div>
          <div className={styles.categories}>
            <CategoryBadge name={category.name} slug={category.slug} />
          </div>
          <div className={styles.description}>
            {description}
          </div>
        </div>
        <LinkButtonPrimary
          label="Start challenge"
          to={`/challenges/${id}/submissions/new`}
        >
          Start Challenge
        </LinkButtonPrimary>
      </div>
    </div>
  )
}

type ChallengeCardProps = {
  challenge: challengeType,
}

export default ChallengeCardExpanded
