import React from 'react'
import classNames from 'classnames'

import { NextButton, PrevButton, PageButtons } from './Buttons'

import styles from './styles.mod.scss'

const Pages = ({
  tapered = true
}: PagesProps) => (
  <div className={classNames(styles.wrapper, { [styles.tapered]: tapered })}>
    <div className={styles.container}>
      <PrevButton />
      <PageButtons />
      <NextButton />
    </div>
  </div>
)

type PagesProps = {
  tapered?: boolean,
}

export default Pages
