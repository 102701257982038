import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { useLocation } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';

import ChevronRight from '../../assets/images/icons/chevron-right.svg';
import { ruleDefault, ruleType } from '../../schemas/types/rule';
import { ruleSectionDefault, ruleSectionType } from '../../schemas/types/ruleSection';
import styles from './styles.mod.scss';

export const getLinkTitle = ({
  rule = ruleDefault,
  ruleSection = ruleSectionDefault,
  type = 'rule',
}: getLinkTitleProps) => (
  type === 'rule' ? rule?.title.trim() : `${rule?.title}/${ruleSection?.title.trim()}`
)

const RuleSection = ({
  rule = ruleDefault,
  ruleSection = ruleSectionDefault,
}: RuleSectionProps) => {
  const [active, setActive] = useState(true)
  const { content, title } = ruleSection

  return (
    <div className={classNames(styles.ruleSection, { [styles.active]: active })}>
      <span
        className={styles.anchor}
        id={getLinkTitle({ type: 'ruleSection', rule, ruleSection })}
      />
      <button
        aria-label={`${active ? 'Collapse' : 'Open'} rule section`}
        className={classNames(styles.title, 'rule-title')}
        data-title={getLinkTitle({ type: 'ruleSection', rule, ruleSection })}
        onClick={() => setActive(!active)}
      >
        <h2>
          {title}
          <ChevronRight />
        </h2>
      </button>
      <div className={styles.content}>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {content}
        </ReactMarkdown>
      </div>
    </div>
  )
}

const RulesMarkdown = ({
  rule = ruleDefault,
  ruleSections = [],
}: RulesMarkdownProps) => {
  const location = useLocation()
  const { content, title } = rule

  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  useEffect(() => {
    const { hash } = location
    const element = document.getElementById(decodeURI(hash).replace('#', '').trim())

    if (isLoaded && hash && element) {
      try {
        element.scrollIntoView({ behavior: 'smooth' })
      } catch (e) {
        console.error(e);
      } finally {
        // Do nothing
      }
    }
  }, [location, isLoaded])

  return (
    <div className={styles.base}>
      <div className={styles.rule}>
        <span
          id={getLinkTitle({ type: 'rule', rule })}
          className={styles.anchor}
        />
        <h1
          className={classNames(styles.title, 'rule-section-title')}
          data-title={getLinkTitle({ type: 'rule', rule })}
          // id={title}
        >
          {title}
        </h1>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {content}
        </ReactMarkdown>
        {ruleSections.map((ruleSection) => (
          <RuleSection
            key={getLinkTitle({ type: 'ruleSection', rule, ruleSection })}
            rule={rule}
            ruleSection={ruleSection}
          />
        ))}
      </div>
    </div>
  )
}

type RulesMarkdownProps = {
  rule: ruleType,
  ruleSections: ruleSectionType[],
}

type RuleSectionProps = {
  rule: ruleType,
  ruleSection: ruleSectionType,
}

type getLinkTitleProps = {
  rule: ruleType,
  ruleSection?: ruleSectionType,
  type: string,
}

export default RulesMarkdown
