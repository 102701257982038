import React, { useEffect, useState } from 'react'

import { leagueDefault, leagueType } from '../../../../schemas/types/league'

import CardExpanded, { Content } from '../../CardExpanded'
import CategoryBadge from '../../../badges/CategoryBadge'
import LeagueCardMembers from '../Members'
import LeagueJoinLeave from '../JoinLeave'

import styles from './styles.mod.scss'

const LeagueCardExpanded = ({
  league = leagueDefault
}: LeagueCardExpandedProps) => {
  const {
    categories,
    description,
    id,
    logo_url,
    logo_url_small,
    joined,
    memberships_count,
    name,
  } = league

  const [members, setMembers] = useState(memberships_count)

  useEffect(() => {
    setMembers(memberships_count)
  }, [league])

  return (
    <CardExpanded
      img={logo_url}
      isLoaded={!!id}
      // name="LeagueCard"
      placeholderImgs={[logo_url_small]}
    >
      <Content>
        <div className={styles.header}>
          <div className={styles.name}>
            {name}
          </div>
          <div className={styles.description}>
            <p>{description}</p>
          </div>
          <div className={styles.categories}>
            {categories.map((cat) => (
              <CategoryBadge
                name={cat.name}
                slug={cat.slug}
                key={`league-category-${id}-${cat.slug}`}
              />
            ))}
          </div>
          <LeagueCardMembers members={members} className={styles.members} />
        </div>
      </Content>
      <LeagueJoinLeave
        id={id}
        joined={joined}
      />
    </CardExpanded>
  )
}

type LeagueCardExpandedProps = {
  league: leagueType,
}

export default LeagueCardExpanded
