// import { CaseReducer, Draft } from "@reduxjs/toolkit"

// export type SetterFunction<T> = CaseReducer<T, { type: string; payload: T[keyof T] }>;

// export const setterFunction = <T>(stateValue: keyof T): SetterFunction<T> => (
//   (state: keyof T, action: { payload: T[keyof T] }) => {
//   // (state, action): CaseReducer<T> => {
//     // let value: any = state[stateValue]
//     // value = action.payload

//     // Do not refactor to check for falsey values, as 0 and false are both valid values
//     if (action.payload !== undefined && action.payload !== null) {
//       state[stateValue] = action.payload
//     }
//   }
// )

import { CaseReducer, Draft, PayloadAction } from "@reduxjs/toolkit"

// export type SetterFunction<T> = CaseReducer<T, { type: string; payload: T[keyof T] }>;
export type SetterFunction<T> = CaseReducer<T, { type: string; payload: PayloadAction<T, any> }>;

export const setterFunction = <T>(stateValue: keyof T): SetterFunction<T> => (
  // (state: Draft<T>, action: { payload: T[keyof T] }) => {
  (state: Draft<T>, action: { payload: PayloadAction<T, any> }) => {
    state[stateValue] = action.payload
  }
)
