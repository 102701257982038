import React from 'react'

import RelationshipsQuery from '@/api/queries/Relationships'

import Cards from '@/components/cards/Cards'
import LoadingContainer from '@/components/LoadingContainer'
import PageContent from '@/components/layouts/PageContent'
import PageWrapper from '@/components/layouts/PageWrapper'
import UserCard from '@/components/cards/users/Card'
import withScrollTop from '@/hocs/withScrollTop'

const MyRivalsPage = () => {
  const { data, isLoading } = RelationshipsQuery.getRivals()

  return (
    <PageWrapper name="Rivals" title="My Stuff">
      <PageContent>
        <LoadingContainer
          isLoading={isLoading}
          hasContent={!!data?.length}
          resource="rivals"
        >
          <Cards tapered>
            {data?.map((rival) => (
              <UserCard user={rival} />
            ))}
          </Cards>
        </LoadingContainer>
      </PageContent>
    </PageWrapper>
  )
}

export default withScrollTop(MyRivalsPage)
