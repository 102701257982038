import React from 'react'
import pluralize from 'pluralize'
import { useDispatch } from 'react-redux'

import DropdownCard from '../DropdownCard'
import DropdownCardContent from '../DropdownCard/Content'
import DropdownCardHeader from '../DropdownCard/Header'
import {
  DropdownProvider,
  dropdownItemDefault,
  dropdownItemType,
  dropdownOptsDefaults,
  dropdownOptsType,
  useDropdown,
} from '../../../context/dropdown'
import { PaginatedQueryProvider } from '../../../context/paginatedQuery'
import { setActiveDropdown } from '../../../store/dropdowns'

const DropdownSelect = ({
  fetchQuery = () => {},
  id = Math.floor(Math.random() * 10 ** 10).toString(),
  initial = dropdownItemDefault,
  onChange = () => {},
  opts = dropdownOptsDefaults,
  preselectedSlugs = [],
  resourceName = 'item',
}: DropdownSelectProps) => (
  <PaginatedQueryProvider fetchQuery={fetchQuery}>
    <DropdownProvider
      id={id}
      initial={initial}
      onChange={onChange}
      opts={opts}
      preselectedSlugs={preselectedSlugs}
      resourceName={resourceName}
      type="select"
    >
      <DropdownSelectConsumer />
    </DropdownProvider>
  </PaginatedQueryProvider>
)

const DropdownSelectConsumer = ({
  initial = dropdownItemDefault,
  initialId = undefined,
}: DropdownSelectConsumerProps) => {
  const { resourceName, selectedItems, setSelectedItems } = useDropdown()
  const dispatch = useDispatch()

  const onSelectItem = (_e, item) => {
    setSelectedItems([item])
    dispatch(setActiveDropdown(null))
  }

  // useEffect(() => {
  //   if (initial) {
  //     setSelectedItems([initial])
  //   }
  // }, [initial])
  // console.log(initialId)

  // useEffect(() => {
  //   if (initialId) {
  //     const initialItem = items.find((item) => item.id === initialId)
  //     console.log(initialItem)
  //     if (initialItem) {
  //       setSelectedItems([initialItem])
  //     }
  //   }
  // }, [initialId, items])

  return (
    <DropdownCard
      className="DropdownSelect"
      name={resourceName}
      urlSearchParam={pluralize.singular(resourceName)}
    >
      <DropdownCardHeader content={selectedItems[0]?.name} />
      <DropdownCardContent onSelectItem={onSelectItem} />
    </DropdownCard>
  )
}

type DropdownSelectProps = {
  fetchQuery: Function,
  id: string,
  initial: dropdownItemType,
  onChange: Function,
  opts: dropdownOptsType,
  preselectedSlugs: Array<string>,
  resourceName: string,
}

type DropdownSelectConsumerProps = {
  initial?: dropdownItemType,
  initialId?: number,
}

export default DropdownSelect
