import React from 'react'
import classNames from 'classnames'

import VotesMutation from '../../../../../api/mutations/Votes'
import { ApiConfig } from '@/api'

import { userAction } from '../../../../../common/helpers'
import { submissionDefault, submissionType } from '../../../../../schemas/types/submission'

import styles from './styles.mod.scss'

const getVoteAddition = (kind: string, upvoteActive: boolean, downvoteActive: boolean) => (
  kind === 'upvote'
    ? upvoteActive ? -1 : downvoteActive ? 2 : 1
    : upvoteActive ? -2 : downvoteActive ? 1 : -1
)

const VoteButton = ({
  kind,
  submission = submissionDefault,
  voteActiveIcon,
  voteInactiveIcon,
}: VoteButtonProps) => {
  const isActive = !!submission.votes?.filter((v) => v.kind === kind).length
  const createVote = VotesMutation.create({ id: submission.id })
  const deleteVote = VotesMutation.delete({ id: submission.id })
  const invalidateSubmission = VotesMutation.invalidateSubmission({ id: submission.id })
  const updateVote = VotesMutation.updateInfiniteSubmissions()

  const submitVoteOrRedirect = userAction(async (e) => {
    e.preventDefault()
    const upvoteActive = !!submission.votes.filter((v) => v.kind === 'upvote').length
    const downvoteActive = !!submission.votes.filter((v) => v.kind === 'downvote').length
    const vote_count = submission.vote_count + getVoteAddition(kind, upvoteActive, downvoteActive)
    const response = isActive
      ? await deleteVote.mutateAsync({ data: { kind, submission_id: submission.id } })
      : await createVote.mutateAsync({ data: { kind, submission_id: submission.id } })

      ApiConfig.successfulResponseCallback(response, () => {
      updateVote({ ...submission, votes: [response.data], vote_count })
      invalidateSubmission(submission)
    })
  })

  return (
    <button
      className={classNames(
        styles.base,
        styles[kind],
        { [styles.active]: isActive },
      )}
      aria-label={kind}
      onClick={submitVoteOrRedirect}
    >
      {isActive ? voteActiveIcon : voteInactiveIcon}
    </button>
  )
}

type VoteButtonProps = {
  kind: string,
  submission: submissionType,
  voteActiveIcon: React.ReactNode,
  voteInactiveIcon: React.ReactNode,
}

export default VoteButton
