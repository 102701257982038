import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import MessagesPopover from '../../popovers/MessagesPopover'
import NotificationsPopover from '../../popovers/NotificationsPopover'
import PageTitle from '../PageTitle'
import SearchBar from '../../search/SearchBar'
import SettingsPopover from '../../popovers/SettingsPopover'
import { LinkButtonPrimaryInverted } from '../../../common/buttons/LinkButton'
import { useAuth } from '../../../context/auth'

import logoImg from '../../../assets/images/logo/logo-small.png'

import styles from './styles.mod.scss'

const scrollThreshold = 15

const HeaderLogo = () => (
  <Link
    aria-label="Home"
    className={styles.logo}
    to="/explore"
  >
    <img src={logoImg} alt="" />
  </Link>
)

const UserButtons = () => {
  const { token } = useAuth()

  return (
    <div className={styles.userButtons}>
      {token ? (
        <>
          <NotificationsPopover />
          <MessagesPopover />
          <SettingsPopover />
        </>
      ) : (
        <>
          <Link
            aria-label="Log in"
            className={[styles.login, styles.button].join(' ')}
            to="/users/login"
          >
            Log in
          </Link>
          <LinkButtonPrimaryInverted
            className={styles.button}
            label="Sign up"
            scale={0.7}
            to="/users/signup"
          >
            Sign Up
          </LinkButtonPrimaryInverted>
        </>
      )}
    </div>
  )
}

const Header = () => {
  const isLayoutMounted = useSelector((state) => state.components.layout.isMounted)

  const [scrolled, setScrolled] = useState(false)

  const headerScroll = (e) => {
    const { scrollTop } = e.target
    setScrolled(scrollTop > scrollThreshold)
  }

  useEffect(() => {
    if (isLayoutMounted) {
      const layout = document.getElementById('Layout')
      layout.addEventListener('scroll', headerScroll)

      return () => layout.removeEventListener('scroll', headerScroll)
    }

    return () => {}
  }, [isLayoutMounted])

  return (
    <div className={classNames(styles.base, { [styles.scrolled]: scrolled })}>
      <HeaderLogo />
      <PageTitle mobile />
      <SearchBar />
      <UserButtons />
    </div>
  )
}

export default Header
