import { ApiRequest, ApiConfig } from '..'
import { PaginatedIndexParams } from '../ApiRequest'

const ContactsResource = {
  getIndex: ({ page }: PaginatedIndexParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl('contacts'), { params: { page } })
  ),
}

export default ContactsResource
