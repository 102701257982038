import React from 'react'

import RulesMarkdown from '../../../RulesMarkdown'
import { ruleDefault, ruleType } from '../../../../schemas/types/rule'

import styles from './styles.mod.scss'

const RuleCard = ({
  rule = ruleDefault,
}: RuleCardProps) => {
  const { rule_sections } = rule

  return (
    <div className={styles.base}>
      <RulesMarkdown rule={rule} ruleSections={rule_sections} />
    </div>
  )
}

type RuleCardProps = {
  rule: ruleType,
}

export default RuleCard
