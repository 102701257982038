import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'

const defaultContext: TabsContextType = {
  activeTabName: '',
  setActiveTabName: () => {},
  tabNames: [],
  tabs: {},
}

const TabsContext = createContext(defaultContext)

export const TabsProvider = ({
  children,
  tabs,
}: TabsProviderProps) => {
  const tabNames = Object.keys(tabs)

  const [activeTabName, setActiveTabName] = useState(tabNames[0])

  useEffect(() => {
    if (!tabNames.includes(activeTabName)) {
      setActiveTabName(tabNames[0])
    }
  }, [tabs])

  const value = useMemo(() => ({
    activeTabName,
    setActiveTabName,
    tabNames,
    tabs,
  }), [activeTabName, tabs])

  return (
    <TabsContext.Provider value={value}>
      {children}
    </TabsContext.Provider>
  )
}

type tabsType = {
  [key: string]: React.ReactNode
}

type TabsProviderProps = {
  children: React.ReactNode
  tabs: tabsType
}

type TabsContextType = {
  activeTabName: string
  setActiveTabName: (tabName: string) => void
  tabNames: Array<string>
  tabs: tabsType
}

export const useTabs = () => useContext(TabsContext)
