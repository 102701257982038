import React from 'react'
import classNames from 'classnames'

// import { View } from 'arena-core'

import coinGreenImg from '../../assets/images/dzastr-coin-green.png'
import coinPurpleImg from '../../assets/images/dzastr-coin-purple.png'
import { rewardDefault, rewardType } from '../../schemas/types/reward'

import styles from './styles.mod.scss'

const coins: coinTypes = {
  glory: coinPurpleImg,
  dzastr: coinGreenImg,
}

const Reward = ({
  className = '',
  reward = rewardDefault,
}: RewardProps) => {
  const { currency, value } = reward

  return (
    <div className={classNames(styles.base, className)}>
      <img src={coins[currency.slug]} alt="coin" />
      {Number(value).toLocaleString('en-us')}
    </div>
    // <View className={classNames(styles.base, className)}>
    //   <img src={coins[currency.slug]} alt="coin" />
    //   {Number(value).toLocaleString('en-us')}
    // </View>
  )
}

type RewardProps = {
  className?: string,
  reward: rewardType,
}

type coinTypes = {
  [key: string]: string,
}

export default Reward
