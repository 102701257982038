import React from 'react'

import ComingSoon from '@/components/ComingSoon'
import PageContent from '@/components/layouts/PageContent'
import PageWrapper from '@/components/layouts/PageWrapper'
import withScrollTop from '@/hocs/withScrollTop'

import styles from './styles.mod.scss'

const TeamsNewPage = () => (
  <PageWrapper name="TeamsNew" title="New Team">
    <PageContent>
      <ComingSoon />
    </PageContent>
  </PageWrapper>
)

export default withScrollTop(TeamsNewPage)
