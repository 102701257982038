import React from 'react'

import ComingSoon from '../../components/ComingSoon'
import PageContent from '../../components/layouts/PageContent'
import PageWrapper from '../../components/layouts/PageWrapper'
import withScrollTop from '../../hocs/withScrollTop'

const BadgesPage = () => (
  <PageWrapper name="Badges" title="Clout">
    <PageContent>
      <ComingSoon />
    </PageContent>
  </PageWrapper>
)

export default withScrollTop(BadgesPage)
