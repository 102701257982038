import React from 'react'

import RewardForms from '../RewardForms'
import { ApiQueries } from '../../../../api'
import { competitionDefault, competitionType } from '../../../../schemas/types/competition'
import { competitionRewardSchema } from '../../../../schemas/forms/competitionReward'

const CompetitionRewardForm = ({
  competition = competitionDefault
}: CompetitionRewardFormProps) => (
  <RewardForms
    deleteConfirmationMessage="If anyone has already completed this competition with this reward, this will invalidate those rewards."
    kind="competition"
    queriesToInvalidate={[
      ['Competitions', 'getShow', ApiQueries.queryParams({ id: competition.id })]
    ]}
    resourceId={competition.id}
    resourceName="competition"
    rewardSchema={competitionRewardSchema}
    rewards={competition.rewards}
  />
)

type CompetitionRewardFormProps = {
  competition: competitionType,
}

export default CompetitionRewardForm
