import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CompetitionForm from '@/components/forms/competitions/CompetitionForm';
import { competitionType } from '@/schemas/types/competition';
import { setPageTitle } from '@/store/pages';

const AdminCompetitionsNew = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const afterUpdate = (competition: competitionType) => {
    navigate(`/admin/competitions/${competition.id}/edit`)
  }

  useEffect(() => {
    dispatch(setPageTitle('New Competition'))
  }, [])

  return (
    <CompetitionForm afterUpdate={afterUpdate} />
  )
}

export default AdminCompetitionsNew
