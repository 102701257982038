import { ApiRequest, ApiConfig } from '..'
import { CreateParams, UpdateParams, DestroyParams } from '../ApiRequest';

const CommentsResource = {
  create: ({ data: comment }: CreateParams) => (
    ApiRequest.privatePost(ApiConfig.apiUrl('comments'), { comment })
  ),
  update: ({ data: content, id }: UpdateParams) => (
    ApiRequest.privatePut(
      ApiConfig.apiUrl(`comments/${id}`),
      { comment: { content } }
    )
  ),
  delete: ({ id }: DestroyParams) => (
    ApiRequest.privateDelete(ApiConfig.apiUrl(`comments/${id}`))
  ),
}

export default CommentsResource
