import { ApiConfig, ApiRequest } from '../';
import {
    CreateParams, DestroyParams, PaginatedIndexWithCategoryFilterParams, PaginatedSearchParams,
    ShowParams, UpdateParams
} from '../ApiRequest';

const CompetitionsResource = {
  getAdminIndex: ({ page, query }: PaginatedSearchParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl('admin/competitions'), { params: { page, query } })
  ),
  getIndex: (params: PaginatedIndexWithCategoryFilterParams) => (
    ApiConfig.getToken() ? CompetitionsResource.getPrivateIndex(params) : CompetitionsResource.getPublicIndex(params)
  ),
  getSearch: ({ page, query }: PaginatedSearchParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl('competitions/search'), { params: { page, query } })
  ),
  getShow: ({ id }: ShowParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl(`competitions/${id}`))
  ),
  getSubmissions: (params: ShowParams) => (
    ApiConfig.getToken()
      ? CompetitionsResource.getPrivateSubmissions(params)
      : CompetitionsResource.getPublicSubmissions(params)
  ),
  getPublicIndex: ({ categories, page }: PaginatedIndexWithCategoryFilterParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl('public/competitions'), { params: { categories, page } })
  ),
  getPrivateIndex: ({ categories, page }: PaginatedIndexWithCategoryFilterParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl('private/competitions'), { params: { categories, page } })
  ),
  getPrivateSubmissions: ({ id }: ShowParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl(`private/competitions/${id}/submissions`))
  ),
  getPublicSubmissions: ({ id }: ShowParams) => (
    ApiRequest.publicGet(ApiConfig.apiUrl(`public/competitions/${id}/submissions`))
  ),
  create: ({ data: competition }: CreateParams) => (
    ApiRequest.privatePost(ApiConfig.apiUrl('competitions'), { competition })
  ),
  update: ({ id, data: competition }: UpdateParams) => (
    ApiRequest.privatePut(ApiConfig.apiUrl(`competitions/${id}`), { competition })
  ),
  delete: ({ id }: DestroyParams) => (
    ApiRequest.privateDelete(ApiConfig.apiUrl(`competitions/${id}`))
  ),
}

export default CompetitionsResource
