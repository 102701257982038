import { ApiRequest, ApiConfig } from '..'
import { CreateParams, DestroyParams, PaginatedIndexParams } from '../ApiRequest';

const MessagesResource = {
  create: ({ data: message }: CreateParams) => (
    ApiRequest.privatePost(ApiConfig.apiUrl('messages'), { message })
  ),
  getIndex: ({ page }: PaginatedIndexParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl('messages'), { params: { page } })
  ),
  getDirectIndex: ({ page, user_id }: DirectIndexParams) => (
    ApiRequest.privateGet(ApiConfig.apiUrl(`messages/direct/${user_id}`), { params: { page } })
  ),
  delete: ({ id }: DestroyParams) => (
    ApiRequest.privateDelete(ApiConfig.apiUrl(`messages/${id}`))
  ),
}

type DirectIndexParams = {
  page?: number
  user_id: number
}

export default MessagesResource
