import React from 'react'
import moment from 'moment-timezone'

import Badge from '../../../badges/Badge'

import styles from '../styles.mod.scss'

const now = new Date()

const CompetitionTime = ({
  time,
  type,
}: CompetitionTimeProps) => {
  const indicator = moment(time).isAfter(moment(now)) ? `${type}s` : `${type}ed`
  return (
    <Badge color="grey" className={styles.time}>
      {`${indicator} ${moment(time).fromNow()}`}
    </Badge>
  )
}

export const CompetitionStartTime = ({ time }: Partial<CompetitionTimeProps>) => (
  <CompetitionTime
    type="start"
    time={time}
  />
)

export const CompetitionEndTime = ({ time }: Partial<CompetitionTimeProps>) => (
  <CompetitionTime
    type="end"
    time={time}
  />
)

type CompetitionTimeProps = {
  time: string | undefined
  type: string
}

export default CompetitionTime
