/* eslint-disable no-extra-boolean-cast */
import * as yup from 'yup'

export const categorySchema = yup.object({
  name: yup.string()
    .trim()
    .max(50, 'Name must be less than 50 characters.')
    .required('Name required.'),
  relevance: yup.number()
    .nullable()
    .transform((v) => (!!v ? v : null)),
}).required()
