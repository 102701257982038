import NotificationsResource from '../resources/Notifications'
import { ApiQueries } from '..'
import { QueryParamsObject } from '../ApiQueries'

const NotificationsQuery = {
  getIndex: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Notifications', 'getIndex', ApiQueries.queryParams(params)],
    queryFn: NotificationsResource.getIndex,
    params,
  }),
}

export default NotificationsQuery
