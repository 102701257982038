import { configureStore } from '@reduxjs/toolkit'

import adminFooterReducer from './adminFooter'
import componentsReducer from './components'
import dropdownsReducer from './dropdowns'
import menuReducer from './menu'
import modalReducer from './modal'
import pagesReducer from './pages'
import utilMenuReducer from './utilMenu'

const store = configureStore({
  reducer: {
    adminFooter: adminFooterReducer,
    components: componentsReducer,
    dropdowns: dropdownsReducer,
    menu: menuReducer,
    modal: modalReducer,
    pages: pagesReducer,
    utilMenu: utilMenuReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
})

export interface RootState {
  adminFooter: ReturnType<typeof adminFooterReducer>,
  components: ReturnType<typeof componentsReducer>,
  dropdowns: ReturnType<typeof dropdownsReducer>,
  menu: ReturnType<typeof menuReducer>,
  modal: ReturnType<typeof modalReducer>,
  pages: ReturnType<typeof pagesReducer>,
  utilMenu: ReturnType<typeof utilMenuReducer>,
}

export default store
