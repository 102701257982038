import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import ChallengesQuery from '@/api/queries/Challenges'

import ChallengeForm from '@/components/forms/challenges/ChallengeForm'
import ChallengeRewardForm from '@/components/forms/rewards/ChallengeRewardForm'
import LoadingContainer from '@/components/LoadingContainer'
import { setPageTitle } from '@/store/pages'

const AdminChallengesEdit = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { data, isLoading } = ChallengesQuery.getShow({ id })

  useEffect(() => {
    dispatch(setPageTitle('Edit Challenge'))
  }, [])

  return (
    <LoadingContainer
      isLoading={isLoading}
      resource="challenges"
      hasContent={!!data}
    >
      <ChallengeForm challenge={data} />
      <ChallengeRewardForm challenge={data} />
    </LoadingContainer>
  )
}

export default AdminChallengesEdit
