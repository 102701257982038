import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import styles from './styles.mod.scss'
import LoadingContainer from '../../LoadingContainer'

export const ResourceResult = ({
  children,
  className = '',
  name = '',
  resourceName = '',
  to = '',
}: ResourceResultProps) => (
  <Link
    aria-label={`View ${resourceName} page for ${name}`}
    className={classNames(styles.resource_result, className)}
    to={to}
  >
    {children}
  </Link>
)

const ResourceResults = ({
  ResourceComponent,
  className = '',
  isLoading = false,
  resourceName = '',
  results = [],
}: ResourceResultsProps) => (
  <div className={classNames(styles.resource_results, className)}>
    <LoadingContainer
      resource={resourceName}
      isLoading={isLoading}
      hasContent={!!results.length}
    >
      {results.map((result) => (
        <ResourceComponent
          resource={result}
          key={`${className}__${result.id}`}
        />
      ))}
    </LoadingContainer>
  </div>
)

type ResourceResultProps = {
  children: React.ReactNode
  className?: string
  name?: string
  resourceName?: string
  to?: string
}

type ResourceResultsProps = {
  ResourceComponent: React.FC<any>
  className?: string
  isLoading?: boolean
  resourceName?: string
  results?: any[]
}

export default ResourceResults
