import React from 'react'
import rehypeRaw from 'rehype-raw'
import { Link } from 'react-router-dom'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import classNames from 'classnames'

import Avatar from '../../../Avatar'
import Card from '../../Card'
import MomentService from '../../../../services/MomentService'
import PopoverProvider, { PopoverContent, PopoverTrigger } from '../../../popovers/Popover'
import { useAuth } from '../../../../context/auth'
import { messageDefault, messageType } from '../../../../schemas/types/message'

import DotsIcon from '../../../../assets/images/icons/ellipsis-solid.svg'

import styles from './styles.mod.scss'

const MessageCardSlim = ({
  message = messageDefault,
}: MessageCardSlimProps) => {
  const { content, created_at, sender } = message
  const { avatar_url_small, id, name } = sender

  const { currentUser } = useAuth()

  const selfAuthored = currentUser.id === sender.id

  return (
    <Card
      className={classNames(
        styles.base,
        { [styles.self_authored]: selfAuthored }
      )}
      opts={{ noContainer: true }}
    >
      <div className={styles.header}>
        <PopoverProvider placement="left">
          <PopoverTrigger>
            <DotsIcon className={styles.dots} />
          </PopoverTrigger>
          <PopoverContent className={styles.user}>
            <Link
              aria-label={`Go to ${name} user page`}
              className={styles.user_link}
              to={`/users/${id}`}
            >
              <Avatar img={avatar_url_small} />
              <span>{name}</span>
            </Link>
          </PopoverContent>
        </PopoverProvider>
        <div className={styles.time}>
          {MomentService.formattedTime(created_at)}
        </div>
      </div>
      <p className={styles.content}>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {content}
        </ReactMarkdown>
      </p>
    </Card>
  )
}

type MessageCardSlimProps = {
  message: messageType,
}

export default MessageCardSlim
