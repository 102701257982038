import React from 'react'
import { Link } from 'react-router-dom'

import CompetitionsMutation from '../../../../api/mutations/Competitions'

import CardAdmin, { ActionsCell, CardCell, DeleteAction, EditAction, LogoCell, RewardsCell } from '../../CardAdmin'
import CategoryBadge from '../../../badges/CategoryBadge'
import Reward from '../../../Reward'
import { competitionDefault, competitionType } from '../../../../schemas/types/competition'

import TrophyIcon from '../../../../assets/images/icons/trophy-star-solid.svg'

import styles from './styles.mod.scss'

const CompetitionCardAdmin = ({
  competition = competitionDefault,
}: CompetitionCardAdminProps) => {
  const { categories, description, id, logo_url_tiny, name, rewards } = competition

  const deleteCompetition = CompetitionsMutation.delete({ id })

  const onDeleteConfirm = async () => {
    const response = await deleteCompetition.mutateAsync({ id })
    return response
  }

  return (
    <CardAdmin>
      <LogoCell className={styles.logo} url={logo_url_tiny} />
      <CardCell className={styles.name}>
        <Link aria-label={`Go to ${name} page`} to={`/competitions/${id}`}>
          {name}
        </Link>
      </CardCell>
      <CardCell className={styles.description}>
        {description}
      </CardCell>
      <CardCell className={styles.categories}>
        {categories.map((cat) => (
          <CategoryBadge
            key={`competition-category-${cat.id}`}
            name={cat.name}
            slug={cat.slug}
          />
        ))}
      </CardCell>
      <RewardsCell className={styles.rewards}>
        {rewards.map((reward) => (
          <Reward reward={reward} key={`reward--${reward.id}`} />
        ))}
      </RewardsCell>
      <ActionsCell className={styles.actions}>
        <Link
          aria-label={`Go to ${name} reward page`}
          className={styles.awards}
          to={`/competitions/${id}`}
        >
          <TrophyIcon />
        </Link>
        <EditAction to={`/admin/competitions/${id}/edit`} />
        <DeleteAction
          onConfirm={onDeleteConfirm}
          resource="competition"
        />
      </ActionsCell>
    </CardAdmin>
  )
}

type CompetitionCardAdminProps = {
  competition: competitionType,
}

export default CompetitionCardAdmin
