import React, { useEffect, useState } from 'react'
import ProgressiveImage from 'react-progressive-graceful-image'
import classNames from 'classnames'

import ImageIcon from '../../assets/images/icons/image-solid.svg'

import styles from './styles.mod.scss'

const COLORS = [
  'dark',
  'black',
]

const ProgressiveLoadImage = ({
  alt = 'image',
  className = '',
  color = COLORS[0],
  img = '',
  onStatusChange = () => {},
  placeholderImgs = [],
  rounded = false,
  withMessage = false,
}: ProgressiveLoadImageProps) => {
  const [status, setStatus] = useState('loading')
  const [placeholderLoaded, setPlaceholderLoaded] = useState(false)

  useEffect(() => {
    onStatusChange(status)
  }, [status])

  return (
    <ProgressiveImage
      src={img}
      placeholder={placeholderImgs.find((el) => !!el)}
    >
      {(src, loading) => (
        <div
          className={classNames(
            styles.wrapper,
            className,
            styles[color],
            { [styles.failed]: status === 'failed' },
            { [styles.isPlaceholder]: placeholderImgs.includes(src) },
            { [styles.loading]: loading && placeholderImgs.includes(src) && placeholderLoaded },
            { [styles.rounded]: rounded },
            { [styles.withMessage]: withMessage },
          )}
        >
          <img
            alt={alt}
            src={src}
            onError={() => setStatus('failed')}
            onLoad={() => setPlaceholderLoaded(true)}
          />
          {status === 'failed' ? <ImageIcon /> : null}
        </div>
      )}
    </ProgressiveImage>
  )
}

type ProgressiveLoadImageProps = {
  alt?: string,
  className?: string,
  color?: string,
  img?: string,
  onStatusChange?: (status: string) => void,
  placeholderImgs?: string[],
  rounded?: boolean,
  withMessage?: boolean,
}

export default ProgressiveLoadImage
