import React from 'react'

import AdminIndexResource from '@/hocs/AdminIndexResource'
import AdminTable, { TableContent, TableHeader, TableHeaderCol } from '../../Dashboard/AdminTable'
import LeagueCardAdmin from '@/components/cards/leagues/CardAdmin'
import { leagueType } from '@/schemas/types/league'

import styles from './styles.mod.scss'

const RESOURCE = 'leagues'

const AdminLeagues = ({
  results = [],
}: AdminLeaguesProps) => (
  <AdminTable>
    <TableHeader>
      <TableHeaderCol className={styles.logo} />
      <TableHeaderCol className={styles.name} name="name" />
      <TableHeaderCol className={styles.description} name="description" />
      <TableHeaderCol className={styles.members} name="members" />
      <TableHeaderCol className={styles.categories} name="categories" />
    </TableHeader>

    <TableContent>
      {results?.map((league) => (
        <LeagueCardAdmin
          key={`league-${league.id}`}
          league={league}
        />
      ))}
    </TableContent>
  </AdminTable>
)

type AdminLeaguesProps = {
  results: Array<leagueType>
}

export default AdminIndexResource({ RESOURCE })(AdminLeagues)
