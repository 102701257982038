import React from 'react'

import { decrypt, encrypt } from '../common/cry'
import LocalStorageService from '../services/LocalStorageService'
import { userType } from '@/schemas/types/user'

const userCookie = process.env.REACT_APP_USER_COOKIE || ''

const defaultContext: AuthContextType = {
  signIn: () => {},
  signOut: () => {},
  token: null,
}

const AuthContext = React.createContext(defaultContext)

const getUser = () => {
  const currentUser = window.localStorage.getItem(userCookie)
  return currentUser ? decrypt(currentUser) : null
}

const setUser = (user: userType | undefined) => {
  try {
    window.localStorage.setItem(userCookie, encrypt(user))
    return decrypt(window.localStorage.getItem(userCookie))
  } catch (e) {
    console.error(e)
    return null
  }
}

export const AuthProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [currentUser, setCurrentUser] = React.useState(getUser())
  const { access_token: token } = currentUser || {}

  const signIn = (user: userType) => {
    setUser(user)
    window.dispatchEvent(new Event('storage'))
  }

  const signOut = () => {
    LocalStorageService.clearUser()
    window.dispatchEvent(new Event('storage'))
  }

  React.useEffect(() => {
    const handleStorageChange = () => {
      setCurrentUser(getUser());
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [])

  const value = React.useMemo(() => (
    { currentUser, signIn, signOut, token }
  ), [currentUser, token])

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

type AuthContextType = {
  signIn: (user: userType) => void
  signOut: () => void
  token: string | null
}

export const useAuth = () => React.useContext(AuthContext)
