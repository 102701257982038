import CommentsResource from '../resources/Comments'
import { ApiMutations, ApiQueries } from '..'
import { QueryParamsObject } from '../ApiQueries'

const CommentsMutation = {
  create: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      CommentsResource.create,
      [
        ['Submissions', 'getInfiniteComments', ApiQueries.queryParams(params)]
      ],
    )
  ),
  delete: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      CommentsResource.delete,
      [['Submissions', 'getInfiniteComments', ApiQueries.queryParams(params)]]
    )
  ),
}

export default CommentsMutation
