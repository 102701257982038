import React, { createContext, useContext, useMemo } from 'react';

import classNames from 'classnames';
import { UseFormReturn } from 'react-hook-form';
import FormButtons from './components/FormButtons';
import FormError from './components/FormError';
import FormField from './components/FormField';
import FormFile from './components/FormFile';
import FormInput from './components/FormInput';
import FormInputs from './components/FormInputs';
import FormMultiSelect from './components/FormMultiSelect';
import FormSelect from './components/FormSelect';
import FormSubmit from './components/FormSubmit';
import FormTextArea from './components/FormTextArea';

import styles from './styles.mod.scss';

const defaultContext: FormPrimaryContextType = {
  errors: {},
  formState: {},
  formName: '',
  register: () => {},
  setValue: () => {},
}

const FormPrimaryContext = createContext(defaultContext)

const FormPrimary = ({
  children,
  className = '',
  form,
  name = '',
  onSubmit = () => {},
  ...props
} : FormPrimaryProps) => {
  const { formState, handleSubmit, register, setValue } = form
  const { errors } = formState

  const onFormSubmit = handleSubmit(onSubmit)

  const value = useMemo(() => (
    { errors, formState, formName: name, register, setValue }
  ), [formState, name])

  return (
    <FormPrimaryContext.Provider value={value}>
      <form
        className={classNames(styles.base, className, name)}
        onSubmit={onFormSubmit}
        {...props}
      >
        {children}
      </form>
    </FormPrimaryContext.Provider>
  )
}

type FormPrimaryProps = {
  children: React.ReactNode
  className?: string
  form: UseFormReturn<any>
  name: string
  onSubmit: (data: FormData) => void
}

type FormPrimaryContextType = {
  errors: any
  formState: any
  formName: string
  register: any
  setValue: any
}

export default FormPrimary

export {
  FormButtons,
  FormError,
  FormField,
  FormFile,
  FormInput,
  FormInputs,
  FormMultiSelect,
  FormSelect,
  FormSubmit,
  FormTextArea,
}

export const useFormPrimary = () => useContext(FormPrimaryContext)
