import React from 'react'
import { Pulse } from 'better-react-spinkit'
import classNames from 'classnames'

import Cards from '../cards/Cards'
import NoContent from '../NoContent'

import styles from './styles.mod.scss'

const pulseColor = '#A1D341'

const PlaceholderSkeleton = ({
  className = ''
}: PlaceholderSkeletonProps) => (
  <Cards className={classNames('LoadingContainer', className)} verticalAlign='center'>
    <div className={styles.container}>
      <Pulse color={pulseColor} size={30} />
      {/* <span className={styles.text}>Loading...</span> */}
      <span className={styles.text} />
    </div>
  </Cards>
)

const Placeholder = ({
  PlaceholderComponent = null,
  className = '',
}: PlaceholderProps) => (
  PlaceholderComponent
    ? <PlaceholderComponent />
    : <PlaceholderSkeleton className={className} />
)

const LoadingContainer = ({
  PlaceholderComponent = null,
  children = null,
  className = '',
  hasContent = false,
  isLoading = false,
  resource = 'content',
}: LoadingContainerProps) => (
  isLoading && !hasContent ? (
    <Placeholder
      PlaceholderComponent={PlaceholderComponent}
      className={className}
    />
  ) : hasContent ? (
    children
  ) : (
    <NoContent resource={resource} />
  )
)

type LoadingContainerProps = {
  PlaceholderComponent?: React.ReactNode,
  children?: React.ReactNode,
  className?: string,
  hasContent: boolean,
  isLoading?: boolean,
  resource?: string,
}

type PlaceholderProps = {
  PlaceholderComponent?: React.ReactNode,
  className?: string,
}

type PlaceholderSkeletonProps = {
  className?: string,
}

export default LoadingContainer
