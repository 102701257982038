import { userDefault, userType } from '../types/user'

export const contactDefault: contactType = {
  associated_user: userDefault,
  id: undefined,
};

export type contactType = {
  associated_user: userType
  id: number | undefined
}
