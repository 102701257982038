const VIDEO_FILE_TYPES = [
  'video/avi',
  'video/x-matroska',
  'video/mov',
  'video/mp4',
  'video/webm',
  'video/wmv',
]

const IMAGE_FILE_TYPES = [
  'image/gif',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/x-png',
]

export const MEDIA_FILE_TYPES: Record<string, string[]> = {
  image: IMAGE_FILE_TYPES,
  video: VIDEO_FILE_TYPES,
}
