import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import AccountUtilMenu from '@/components/utilityMenu/Account'
import withScrollTop from '@/hocs/withScrollTop'
import { setUtilMenuContent } from '@/store/utilMenu'

const AccountWrapper = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setUtilMenuContent(<AccountUtilMenu />))
  }, [])

  return (
    <Outlet />
  )
}

export default withScrollTop(AccountWrapper)
