import classNames from 'classnames'
import { useMediaUploader } from '../../context'
import { DragDrop } from '@uppy/react'

import styles from './styles.mod.scss'

const FileUpload = () => {
  const { isPreviewPresent, uppy } = useMediaUploader()

  if (!isPreviewPresent) return null

  return (
    <DragDrop
      uppy={uppy}
      className={classNames('MediaUploader__uppy', styles.form)}
      locale={{
        strings: {
          dropHereOr: 'Drop here or %{browse}',
          browse: 'browse',
        },
      }}
    />
  )
}

export default FileUpload
