import React from 'react'
import classNames from 'classnames'

import CardLink from '../../CardLink'
import MomentService from '../../../../services/MomentService'
import { notificationDefault, notificationType } from '../../../../schemas/types/notification'

import styles from './styles.mod.scss'

const NotificationCard = ({
  notification = notificationDefault,
  slim = false,
}: NotificationCardProps) => {
  const { content, created_at, title } = notification

  return (
    <CardLink
      className={classNames(
        'NotificationCard',
        { [styles.slim]: slim }
      )}
      to="/notifications/unread"
    >
      <div className={styles.header}>
        <div className={styles.title}>
          {title}
        </div>
        <div className={styles.time}>
          {MomentService.formattedTime(created_at)}
        </div>
      </div>
      <p className={styles.content}>
        {content}
      </p>
    </CardLink>
  )
}

type NotificationCardProps = {
  notification: notificationType,
  slim: boolean,
}

export default NotificationCard
