import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import LeaguesQuery from '@/api/queries/Leagues';
import LeagueCard from '@/components/cards/leagues/Card';
import CategoriesMultiSelect from '@/components/dropdowns/resources/categories/CategoriesMultiSelect';
import Filters from '@/components/Filters';
import PageContent from '@/components/layouts/PageContent';
import PageWrapper from '@/components/layouts/PageWrapper';
import ScrollableResource from '@/components/ScrollableResource';
import { InfiniteScrollProvider, useInfiniteScroll } from '@/context/infiniteScroll';
import withScrollTop from '@/hocs/withScrollTop';
import { resetUtilMenu, setUtilMenuContent, setUtilMenuOpts } from '@/store/utilMenu';

const ScrollableLeagues = () => {
  const dispatch = useDispatch()
  const { prefilteredParams } = useInfiniteScroll()

  useEffect(() => {
    dispatch(setUtilMenuContent(
      <Filters>
        <CategoriesMultiSelect
          opts={{ urlUpdates: true, noContainer: true }}
          preselectedSlugs={prefilteredParams.categories}
        />
      </Filters>
    ))

    return () => dispatch(resetUtilMenu())
  }, [])

  return (
    <ScrollableResource
      Component={LeagueCard}
      resourceName="league"
    />
  )
}

const LeaguesListPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setUtilMenuOpts({ noPadding: true }))

    return () => dispatch(resetUtilMenu())
  }, [])

  return (
    <PageWrapper name="LeaguesIndex" title="Leagues">
      <PageContent>
        <InfiniteScrollProvider
          cardClassName="LeagueCard"
          filters={['categories']}
          query={LeaguesQuery.getInfiniteIndex}
        >
          <ScrollableLeagues />
        </InfiniteScrollProvider>
      </PageContent>
    </PageWrapper>
  )
}

export default withScrollTop(LeaguesListPage)
