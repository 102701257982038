import React, { useMemo } from 'react'
import pluralize from 'pluralize'
import { useDispatch, useSelector } from 'react-redux'

import { LinkButtonPrimary } from '../../../../common/buttons/LinkButton'
import { ButtonPrimaryInverted } from '../../../../common/buttons/Button'
import { setAdminFooterMode } from '../../../../store/adminFooter'

import styles from './styles.mod.scss'
import { RootState } from '@/store'

const Footer = ({ resource }: FooterProps) => {
  const dispatch = useDispatch()
  const { isAdminFooterEnabled, adminFooterMode, adminFooterType } = useSelector((state: RootState) => state.adminFooter)


  const NewLink = () => useMemo(() => (
    <LinkButtonPrimary
      label={`New ${pluralize.singular(resource)}`}
      scale={0.8}
      className={styles.newLink, styles.button}
      to={`/admin/${resource}/new`}
    >
      New {pluralize.singular(resource)}
    </LinkButtonPrimary>
  ))

  const ViewModeButton = () => useMemo(() => (
    adminFooterMode === 'preview' ? (
      <ButtonPrimaryInverted
        label="Edit mode"
        className={styles.editModeButton, styles.button}
        onClick={() => dispatch(setAdminFooterMode('edit'))}
      >
        Edit Mode
      </ButtonPrimaryInverted>
    ) : (
      <ButtonPrimaryInverted
        label="Preview mode"
        className={styles.previewModeButton, styles.button}
        onClick={() => dispatch(setAdminFooterMode('preview'))}
      >
        Preview Mode
      </ButtonPrimaryInverted>
    )
  ))

  const getFooterComponent = () => ({
    newLink: <NewLink />,
    viewModeButton: <ViewModeButton />,
  })

  if (!isAdminFooterEnabled) return null

  return (
    <div className={styles.base}>
      {getFooterComponent()[adminFooterType]}
    </div>
  )
}

type FooterProps = {
  resource: string
}

export default Footer
