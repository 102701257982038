import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CategoryForm from '@/components/forms/categories/CategoryForm';
import { categoryType } from '@/schemas/types/category';
import { setPageTitle } from '@/store/pages';

const AdminCategoriesNew = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const afterUpdate = (category: categoryType) => {
    navigate(`/admin/categories/${category.id}/edit`)
  }

  useEffect(() => {
    dispatch(setPageTitle('New Category'))
  }, [])

  return (
    <CategoryForm afterUpdate={afterUpdate} />
  )
}

export default AdminCategoriesNew
