import ContactsResource from '../resources/Contacts'
import { ApiQueries } from '..'
import { QueryParamsObject } from '../ApiQueries'

const ContactsQuery = {
  getInfiniteIndex: (params: QueryParamsObject) => ApiQueries.constructInfiniteQuery({
    queryKey: ['Contacts', 'getInfiniteIndex', ApiQueries.queryParams(params)],
    queryFn: ContactsResource.getIndex,
    params,
  }),
}

export default ContactsQuery
