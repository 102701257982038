import React from 'react'
import { Link } from 'react-router-dom'

import RitualsMutation from '../../../../api/mutations/Rituals'

import CardAdmin, { ActionsCell, CardCell, DeleteAction, EditAction } from '../../CardAdmin'
import CategoryBadge from '../../../badges/CategoryBadge'
import ChallengeBadge from '../../../badges/ChallengeBadge'
import Reward from '../../../Reward'
import { ritualDefault, ritualType } from '../../../../schemas/types/ritual'

import styles from './styles.mod.scss'

const RitualCardAdmin = ({
  ritual = ritualDefault
}: RitualCardAdminProps) => {
  const { category, challenges, id, name, rewards } = ritual

  const deleteRitual = RitualsMutation.delete({ id })

  const onDeleteConfirm = async () => {
    const response = await deleteRitual.mutateAsync({ id })
    return response
  }

  return (
    <CardAdmin>
      <CardCell className={styles.name}>
        {name}
      </CardCell>
      <CardCell className={styles.category}>
        <CategoryBadge name={category.name} slug={category.slug} />
      </CardCell>
      <CardCell className={styles.challenges}>
        {challenges.map((challenge) => (
          <Link
            aria-label="Go to challenge page"
            key={`CardAdmin__challenge__${challenge.id}`}
            to={`/challenges/${challenge.id}`}
            className="CardAdmin__challenge_link"
          >
            <ChallengeBadge challenge={challenge} />
          </Link>
        ))}
      </CardCell>
      <CardCell className={styles.rewards}>
        {rewards.map((reward) => (
          <Reward reward={reward} key={`CardAdmin__reward--${reward.id}`} />
        ))}
      </CardCell>
      <ActionsCell className={styles.actions}>
        <EditAction className={styles.edit} to={`/admin/rituals/${id}/edit`} />
        <DeleteAction
          onConfirm={onDeleteConfirm}
          resource="ritual"
        />
      </ActionsCell>
    </CardAdmin>
  )
}

type RitualCardAdminProps = {
  ritual: ritualType,
}

export default RitualCardAdmin
