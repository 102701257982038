export const favoriteDefault: favoriteType = {
  id: undefined,
  kind: '',
  association_id: undefined,
  user_id: undefined,
}

export type favoriteType = {
  id: number | undefined
  kind: string
  association_id: number | undefined
  user_id: number | undefined
}
