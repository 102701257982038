import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import Card from '../../Card'
import FavoriteButton from '../components/FavoriteButton'
import SubmissionCategories from '../components/SubmissionCategories'
import SubmissionLeague from '../components/SubmissionLeague'
import SubmissionMedia from '../components/SubmissionMedia'
import SubmissionTime from '../components/SubmissionTime'
import SubmissionTitle from '../components/SubmissionTitle'
import SubmissionUser from '../components/SubmissionUser'
import SubmissionVotes from '../components/SubmissionVotes'
import { submissionDefault, submissionType } from '../../../../schemas/types/submission'

import styles from './styles.mod.scss'
import sharedStyles from '../styles.mod.scss'

const SubmissionCard = ({
  submission = submissionDefault,
}: SubmissionCardProps) => {
  const { league, id } = submission

  return (
    <Card className={classNames('SubmissionCard', sharedStyles.base)}>
      <div className={classNames(styles.header, sharedStyles.header)}>
        <SubmissionLeague league={league} />
        <SubmissionCategories submission={submission} />
      </div>
      <div className={classNames(styles.subheader, sharedStyles.subheader)}>
        <SubmissionTitle isLink submission={submission} />
        <SubmissionCategories submission={submission} mobile />
        <div className={sharedStyles.user_time}>
          <SubmissionTime submission={submission} />
          <SubmissionUser submission={submission} />
        </div>
      </div>
      <div className={classNames(styles.content, sharedStyles.content)}>
        <div className={classNames(styles.actions, sharedStyles.actions)}>
          <SubmissionVotes className={styles.votes} submission={submission} />
          <FavoriteButton submission={submission} />
        </div>
        <Link
          aria-label="View submission page"
          className={classNames(styles.media_link, sharedStyles.media_link)}
          to={`/submissions/${id}`}
        >
          <SubmissionMedia submission={submission} />
        </Link>
      </div>
    </Card>
  )
}

type SubmissionCardProps = {
  submission: submissionType,
}

export default SubmissionCard
