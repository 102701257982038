import CryptoJS from 'crypto-js'

const authSecret = process.env.REACT_APP_AUTH_SECRET || ''

export const encrypt = (object: EncryptDecryptInput) => (
  CryptoJS.AES.encrypt(
    JSON.stringify(object),
    authSecret,
  ).toString()
)

export const decrypt = (ciphertext: string | null): EncryptDecryptInput => {
  const decryptedString = CryptoJS.AES.decrypt(
    ciphertext,
    authSecret,
  ).toString(CryptoJS.enc.Utf8)

  try {
    const decryptedObject = JSON.parse(decryptedString)
    return decryptedObject
  } catch (error) {
    throw new Error('Decryption error: Unable to parse decrypted data.')
  }
}

export type EncryptDecryptInput = Record<string, any> | undefined
