import * as yup from 'yup'

const maxBioLength = 5000

export const profileSchema = yup.object({
  name: yup.string()
    .trim()
    .required('Name required.'),
  email: yup.string()
    .trim()
    .email()
    .required('Email required.'),
  bio: yup.string()
    .trim()
    .max(maxBioLength, `Bio must be less than ${maxBioLength} characters.`)
}).required()
