import React from 'react'

import RewardForms from '../RewardForms'
import { ApiQueries } from '../../../../api'
import { ritualDefault, ritualType } from '../../../../schemas/types/ritual'
import { ritualRewardSchema } from '../../../../schemas/forms/ritualReward'

const RitualRewardForm = ({
  ritual = ritualDefault,
}: RitualRewardFormProps) => (
  <RewardForms
    deleteConfirmationMessage="If anyone has already completed this ritual with this reward, this will invalidate those rewards."
    kind="ritual"
    queriesToInvalidate={[
      ['Rituals', 'getShow', ApiQueries.queryParams({ id: ritual.id })]
    ]}
    resourceId={ritual.id}
    resourceName="ritual"
    rewardSchema={ritualRewardSchema}
    rewards={ritual.rewards}
  />
)

type RitualRewardFormProps = {
  ritual: ritualType,
}

export default RitualRewardForm
