import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useSearchParams } from 'react-router-dom'

import ClearButton from './ClearButton'
import DropdownCard from '../DropdownCard'
import DropdownCardContent from '../DropdownCard/Content'
import DropdownCardHeader from '../DropdownCard/Header'
import SelectedItems from '../SelectedItems'
import { DropdownProvider, dropdownItemType, dropdownOptsDefaults, dropdownOptsType, useDropdown } from '../../../context/dropdown'
import { PaginatedQueryProvider } from '../../../context/paginatedQuery'

import styles from './styles.mod.scss'

const DropdownMultiSelect = ({
  fetchQuery = () => {},
  id,
  initial = [],
  onChange = () => {},
  maxItems = 5,
  resourceName = 'items',
  opts = dropdownOptsDefaults,
  preselectedSlugs = [],
}: DropdownMultiSelectProps) => (
  <PaginatedQueryProvider fetchQuery={fetchQuery}>
    <DropdownProvider
      id={id}
      initial={initial}
      onChange={onChange}
      opts={opts}
      preselectedSlugs={preselectedSlugs}
      resourceName={resourceName}
      type="multiselect"
    >
      <DropdownMultiSelectConsumer maxItems={maxItems} />
    </DropdownProvider>
  </PaginatedQueryProvider>
)

const Header = () => {
  const { resourceName, selectedItems } = useDropdown()

  const selectedItemsDisplay = selectedItems.length ? `(${selectedItems.length})` : ''

  return `${resourceName} ${selectedItemsDisplay}`
}

const DropdownMultiSelectConsumer = ({
  maxItems = 5,
}: DropdownMultiSelectConsumerProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    opts,
    items,
    resourceName,
    selectedItems,
    setSelectedItems,
  } = useDropdown()

  const [isLoaded, setIsLoaded] = useState(false)

  const getPreselectedSlugs = () => (
    searchParams.get(resourceName)?.split(',').filter((fil) => !!fil)
  )

  const getInitialSelectedFromSearchParams = () => {
    const preselectedSlugs = getPreselectedSlugs()
    if (preselectedSlugs?.length) {
      const queriedSlugs = preselectedSlugs?.map((itemSlug) => (
        items.find((item) => (
          item.slug === itemSlug
        ))
      )).filter((i) => !!i)
      setSelectedItems(queriedSlugs)
    }
    setIsLoaded(true)
  }

  const onSelectItem = (_e, item) => {
    if (selectedItems.length >= maxItems) {
      toast.error(
        `Maximum ${maxItems} ${resourceName} allowed.`,
        { toastId: `max-${resourceName}-toast` }
      )
      throw new Error('Max items exceeded')
    }

    setSelectedItems([...selectedItems, item])
  }

  const updateSearchParams = () => {
    if (!opts.urlUpdates || !isLoaded) return
    // if (!opts.urlUpdates) return

    const oldParams = searchParams.get(resourceName)
    if (selectedItems.length) {
      searchParams.set(resourceName, selectedItems.map((item) => item.slug))
    } else {
      searchParams.delete(resourceName)
    }
    if (oldParams !== searchParams.get(resourceName)) {
      setSearchParams(searchParams)
    }
  }

  useEffect(() => {
    if (opts.urlUpdates && items.length && !isLoaded) {
      getInitialSelectedFromSearchParams()
    }
  }, [items])

  useEffect(() => {
    updateSearchParams()
  }, [selectedItems])

  return (
    <DropdownCard className={styles.wrapper}>
      <DropdownCardHeader content={<Header />}>
        <ClearButton />
      </DropdownCardHeader>
      <DropdownCardContent onSelectItem={onSelectItem}>
        <SelectedItems />
      </DropdownCardContent>
    </DropdownCard>
  )
}

type DropdownMultiSelectProps = {
  fetchQuery: Function
  id?: string
  initial?: Array<dropdownItemType>
  maxItems?: number | undefined
  onMaxItems?: Function
  onChange: Function
  opts?: dropdownOptsType
  preselectedSlugs?: Array<string>
  resourceName: string
}

type DropdownMultiSelectConsumerProps = {
  maxItems: number,
}

export default DropdownMultiSelect
