import React from 'react'
import { Link } from 'react-router-dom'

import ChallengesMutation from '@/api/mutations/Challenges'
import { challengeDefault, challengeType } from '@/schemas/types/challenge'

import CardAdmin, { ActionsCell, CardCell, DeleteAction, EditAction, RewardsCell } from '@/components/cards/CardAdmin'
import CategoryBadge from '@/components/badges/CategoryBadge'
import Reward from '@/components/Reward'

import styles from './styles.mod.scss'

const ChallengeCardAdmin = ({
  challenge = challengeDefault
}: ChallengeCardAdminProps) => {
  const { category, description, id, name, rewards } = challenge

  const deleteChallenge = ChallengesMutation.delete({ id })

  const onDeleteConfirm = async () => {
    const response = await deleteChallenge.mutateAsync({ id })
    return response
  }

  return (
    <CardAdmin>
      <CardCell className={styles.name}>
        <Link aria-label={`Go to ${name} page`} to={`/challenges/${id}`}>
          {name}
        </Link>
      </CardCell>
      <CardCell className={styles.description}>
        {description}
      </CardCell>
      <CardCell className={styles.category}>
        <CategoryBadge name={category.name} slug={category.slug} />
      </CardCell>
      <RewardsCell className={styles.rewards}>
        {rewards.map((reward) => (
          <Reward reward={reward} key={`reward--${reward.id}`} />
        ))}
      </RewardsCell>
      <ActionsCell className={styles.actions}>
        <EditAction to={`/admin/challenges/${id}/edit`} />
        <DeleteAction
          onConfirm={onDeleteConfirm}
          resource="challenge"
        />
      </ActionsCell>
    </CardAdmin>
  )
}

type ChallengeCardAdminProps = {
  challenge: challengeType,
}

export default ChallengeCardAdmin
