import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

const defaultResult: ResultType = {
  current_page: 0,
  first_page: true,
  last_page: true,
  limit_value: 0,
  results: [],
  total_pages: 0,
}

const defaultData: DataType = {
  pages: [defaultResult],
}

const InfiniteScrollContext = createContext({
  isFetching: false,
  prefilteredParams: {},
  results: [defaultResult],
})

export const InfiniteScrollProvider = ({
  cardClassName,
  children,
  filters = [],
  params: initialParams,
  query,
}: InfiniteScrollProviderProps) => {
  const [searchParams] = useSearchParams()

  const [prefilteredParams, setPrefilteredParams] = useState([])

  const getFilterParams = (): FilterParams => {
    const filterParams: FilterParams = {}
    filters.forEach((f) => {
      filterParams[f] = searchParams.get(f)?.split(',').filter((fil) => !!fil)
    })
    // console.log(filterParams)
    return filterParams
  }

  const params = initialParams || getFilterParams()
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = query(params)

  const { pages: results } = data || defaultData

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasNextPage) {
        fetchNextPage()
      }
    })

    const elements = document.getElementsByClassName(cardClassName)
    const element = elements[elements.length - 4]

    if (!element) return () => {}

    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, [results])

  useEffect(() => {
    setPrefilteredParams(getFilterParams())
  }, [])

  const value = useMemo(() => (
    { isFetching, prefilteredParams, results }
  ), [isFetching, results, prefilteredParams])

  return (
    <InfiniteScrollContext.Provider value={value}>
      {children}
    </InfiniteScrollContext.Provider>
  )
}

type InfiniteScrollProviderProps = {
  cardClassName: string
  children: React.ReactNode
  filters?: string[]
  params?: FilterParams
  query: (params: any) => any
}

type FilterParams = {
  [key: string]: string[] | string | number | number[] | undefined
}

type DataType = {
  pages: ResultType[]
}

type ResultType = {
  current_page: number
  first_page: boolean
  last_page: boolean
  limit_value: number
  results: any[]
  total_pages: number
}

export const useInfiniteScroll = () => useContext(InfiniteScrollContext)
