import React from 'react'

import Reward from '../Reward'
import { challengeDefault, challengeType } from '../../schemas/types/challenge'

import styles from './styles.mod.scss'

const ChallengeRewards = ({
  challenge = challengeDefault,
}: ChallengeRewardsProps) => {
  const { rewards } = challenge

  return (
    <div className={styles.wrapper}>
      {rewards.map((reward) => (
        <Reward
          key={`ChallengeCard__reward--${reward.id}`}
          reward={reward}
        />
      ))}
    </div>
  )
}

type ChallengeRewardsProps = {
  challenge: challengeType,
}

export default ChallengeRewards
