import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import styles from './styles.mod.scss'
import { RootState } from '@/store'

const PageTitle = ({
  mobile = false
}: PageTitleProps) => {
  const { pageTitle } = useSelector((state: RootState) => state.pages)

  return (
    <div className={classNames(
      styles.base,
      { [styles.mobile]: mobile }
    )}>
      <h1>
        {pageTitle}
      </h1>
    </div>
  )
}

type PageTitleProps = {
  mobile?: boolean
}

export default PageTitle
