import React from 'react'

import UsersQuery from '../../../../api/queries/Users'

import ScrollableResource from '../../../../components/ScrollableResource'
import SubmissionCard from '../../../../components/cards/submissions/Card'
import { InfiniteScrollProvider } from '../../../../context/infiniteScroll'

const SubmissionsTab = ({ id }: SubmissionsTabProps) => (
  <InfiniteScrollProvider
    cardClassName="SubmissionCard"
    query={UsersQuery.getInfiniteSubmissions}
    params={{ id }}
  >
    <ScrollableResource
      Component={SubmissionCard}
      resourceName="submission"
    />
  </InfiniteScrollProvider>
)

type SubmissionsTabProps = {
  id: string
}

export default SubmissionsTab
