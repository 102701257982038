import React from 'react'
import moment from 'moment'

import { submissionDefault, submissionType } from '../../../../../schemas/types/submission'

import styles from './styles.mod.scss'

const SubmissionTime = ({
  submission = submissionDefault,
}: SubmissionTimeProps) => (
  <div className={styles.time}>
    {moment(submission.created_at).fromNow()}
  </div>
)

type SubmissionTimeProps = {
  submission: submissionType,
}

export default SubmissionTime
