import React from 'react'

import ProgressiveLoadImage from '../../../ProgressiveLoadImage'
import { imageSmall } from '../../../../common/helpers'

import styles from './styles.mod.scss'

const UserAvatar = ({
  img = undefined,
  placeholderImgs = [],
}: UserAvatarProps) => (
  <ProgressiveLoadImage
    alt="user avatar"
    // className="UserCardAvatar__img"
    // className={styles.avatar}
    img={imageSmall(img)}
    placeholderImgs={placeholderImgs}
  />
)

type UserAvatarProps = {
  img?: string
  placeholderImgs?: string[]
}

export default UserAvatar
