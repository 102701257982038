import classNames from 'classnames'
import PlusIcon from '../../../../assets/images/icons/plus-regular.svg'
import { Link } from 'react-router-dom'

import styles from './styles.mod.scss'

const AddButton = ({ mobile = false }: { mobile?: boolean }) => (
  <div
    className={classNames(
      styles.wrapper,
      { [styles.mobile]: mobile }
    )}
  >
    <Link
      aria-label="Add new"
      className={styles.base}
      to="submissions/new"
    >
      <PlusIcon className={styles.svg} />
    </Link>
  </div>
)

export default AddButton
