import { ApiRequest, ApiConfig } from '..'

const RelationshipsResource = {
  addFriend: ({ user_id }: AddRemoveParams) => (
    ApiRequest.privatePost(
      ApiConfig.apiUrl('relationships'),
      { relationship: { kind: 'friend', other_user_id: user_id } },
    )
  ),
  addRival: ({ user_id }: AddRemoveParams) => (
    ApiRequest.privatePost(
      ApiConfig.apiUrl('relationships'),
      { relationship: { kind: 'rival', other_user_id: user_id } },
    )
  ),
  getFriends: () => (
    ApiRequest.privateGet(ApiConfig.apiUrl('friends'))
  ),
  getRivals: () => (
    ApiRequest.privateGet(ApiConfig.apiUrl('rivals'))
  ),
  removeFriend: ({ user_id }: AddRemoveParams) => (
    ApiRequest.privateDelete(ApiConfig.apiUrl(`friends/${user_id}/remove`))
  ),
  removeRival: ({ user_id }: AddRemoveParams) => (
    ApiRequest.privateDelete(ApiConfig.apiUrl(`rivals/${user_id}/remove`))
  ),
}

type AddRemoveParams = {
  user_id: number
}

export default RelationshipsResource
