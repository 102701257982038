import React from 'react'
import classNames from 'classnames'
import pluralize from 'pluralize'

import Cards from '../cards/Cards'
import LoadingContainer from '../LoadingContainer'
import { useInfiniteScroll } from '../../context/infiniteScroll'

import styles from './styles.mod.scss'

const ScrollableResource = ({
  Component,
  className = '',
  resourceName = '',
  tapered = true,
  unStyled = false,
  ...props
}: ScrollableResourceProps) => {
  const { isFetching, results } = useInfiniteScroll()

  return (
    <LoadingContainer
      className={classNames(styles.loadingContainer, className)}
      hasContent={!!results[0]?.results?.length}
      isLoading={isFetching}
      // isLoading
      resource={pluralize(resourceName)}
    >
      <Cards
        className={classNames(
          styles.base,
          { [styles.tapered]: tapered },
          className,
        )}
        unStyled={unStyled}
      >
        {results?.map((page) => (
          page?.results?.map((result) => {
            const dynamicProps = { ...props, [resourceName]: result }

            return (
              <Component
                key={`${resourceName}-result-${result.id}`}
                {...dynamicProps}
              />
            )
          })
        ))}
      </Cards>
    </LoadingContainer>
  )
}

type ScrollableResourceProps = {
  Component: React.FC<any>,
  className?: string,
  resourceName: string,
  unStyled?: boolean,
  tapered?: boolean,
}

export default ScrollableResource
