import React from 'react';

import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import '@uppy/informer/dist/style.css';
import './uppy-overrides.scss';

import ClearButton from '../ClearButton';
import FileUpload from '../FileUpload';
import Media from '../Media';
import ProgressBar from '../ProgressBar';
import styles from './styles.mod.scss';

export const Uploader = () => (
  <div className={styles.base}>
    <div className={styles.previewWrapper}>
      <Media />
      <ClearButton />
    </div>
    <FileUpload />
    <ProgressBar />
  </div>
)
