import React from 'react'
import { Link } from 'react-router-dom'

import CardAdmin, { CardCell, LogoCell } from '../../CardAdmin'
import CategoryBadge from '../../../badges/CategoryBadge'
import LeagueCardMembers from '../Members'
import { imageSmall } from '../../../../common/helpers'
import { leagueDefault, leagueType } from '../../../../schemas/types/league'

import styles from './styles.mod.scss'

const LeagueCardAdmin = ({
  league = leagueDefault
}: LeagueCardAdminProps) => {
  const {
    categories,
    description,
    id,
    logo_url_tiny,
    memberships_count,
    name,
  } = league

  return (
    <CardAdmin>
      <LogoCell className={styles.logo} url={imageSmall(logo_url_tiny)} />
        {/* <img
          alt=""
          className="LeagueCard__logo"
          src={imageSmall(logo_url_tiny)}
        />
      </LogoCell> */}
      <CardCell className={styles.name}>
        <Link aria-label={`Go to ${name} page`} to={`/leagues/${id}`}>
          {name}
        </Link>
      </CardCell>
      <CardCell className={styles.description}>
        {description}
      </CardCell>
      <CardCell className={styles.members}>
        <LeagueCardMembers members={memberships_count} />
      </CardCell>
      <CardCell className={styles.categories}>
        {categories.map((category) => (
          <CategoryBadge
            key={`CardAdmin__${id}__${category.slug}`}
            name={category.name}
            slug={category.slug}
          />
        ))}
      </CardCell>
    </CardAdmin>
  )
}

type LeagueCardAdminProps = {
  league: leagueType,
}

export default LeagueCardAdmin
