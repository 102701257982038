import React from 'react'

import PageContent from '@/components/layouts/PageContent'
import PageWrapper from '@/components/layouts/PageWrapper'
import PasswordForm from '@/components/forms/users/PasswordForm'
import withScrollTop from '@/hocs/withScrollTop'

const PasswordPage = () => (
  <PageWrapper
    name="PasswordPage"
    layout="form"
    title="Account"
  >
    <PageContent layout="form">
      <PasswordForm />
    </PageContent>
  </PageWrapper>
)

export default withScrollTop(PasswordPage)
