import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import CompetitionsQuery from '@/api/queries/Competitions';
import CompetitionCard from '@/components/cards/competitions/Card';
import CategoriesMultiSelect from '@/components/dropdowns/resources/categories/CategoriesMultiSelect';
import Filters from '@/components/Filters';
import PageContent from '@/components/layouts/PageContent';
import PageWrapper from '@/components/layouts/PageWrapper';
import ScrollableResource from '@/components/ScrollableResource';
import { InfiniteScrollProvider, useInfiniteScroll } from '@/context/infiniteScroll';
import withScrollTop from '@/hocs/withScrollTop';
import { resetUtilMenu, setUtilMenuContent, setUtilMenuOpts } from '@/store/utilMenu';

const ScrollableCompetitions = () => {
  const dispatch = useDispatch()
  const { prefilteredParams } = useInfiniteScroll()

  useEffect(() => {
    dispatch(setUtilMenuOpts({ noPadding: true }))
    dispatch(setUtilMenuContent(
      <Filters>
        <CategoriesMultiSelect
          opts={{ urlUpdates: true, noContainer: true }}
          preselectedSlugs={prefilteredParams.categories}
        />
      </Filters>
    ))

    return () => dispatch(resetUtilMenu())
  }, [])

  return (
    <ScrollableResource
      Component={CompetitionCard}
      resourceName="competition"
    />
  )
}

const CompetitionsListPage = () => (
  <PageWrapper name="CompetitionsIndex" title="Competitions">
    <PageContent>
      <InfiniteScrollProvider
        cardClassName="CompetitionCard"
        filters={['categories']}
        query={CompetitionsQuery.getInfiniteIndex}
      >
        <ScrollableCompetitions />
      </InfiniteScrollProvider>
    </PageContent>
  </PageWrapper>
)

export default withScrollTop(CompetitionsListPage)
