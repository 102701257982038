import ChallengesResource from '../resources/Challenges'
import { ApiMutations, ApiQueries } from '..'
import { QueryParamsObject } from '../ApiQueries'

const ChallengesMutation = {
  create: () => (
    ApiMutations.constructMutation(
      ChallengesResource.create,
      [
        ['Challenges', 'getAdminIndex'],
        ['Challenges', 'getIndex'],
      ],
      { successMessage: 'Challenge created.' },
    )
  ),
  update: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      ChallengesResource.update,
      [
        ['Challenges', 'getAdminIndex'],
        ['Challenges', 'getIndex'],
        ['Challenges', 'getShow', ApiQueries.queryParams(params)],
      ],
      { successMessage: 'Challenge updated.' },
    )
  ),
  delete: (params: QueryParamsObject) => (
    ApiMutations.constructMutation(
      ChallengesResource.delete,
      [
        ['Challenges', 'getAdminIndex'],
        ['Challenges', 'getIndex'],
        ['Challenges', 'getShow', ApiQueries.queryParams(params)],
      ],
      { successMessage: 'Challenge deleted.' },
    )
  ),
}

export default ChallengesMutation
