import { userDefault, userType } from '../types/user'

export const messageDefault: messageType = {
  id: undefined,
  content: '',
  created_at: '',
  read: false,
  sender: userDefault,
  subject: '',
}

export type messageType = {
  id: number | undefined,
  content: string,
  created_at: string,
  read: boolean,
  sender: userType,
  subject: string,
}
