import React, { useEffect } from 'react'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'

import { setPageTitle } from '../../../store/pages'

import styles from './styles.mod.scss'

const LAYOUTS = {
  adminForm: 'adminForm',
  center: 'center',
  col: 'col',
  form: 'form',
  partial: 'partial',
}

const PageWrapper = ({
  children,
  layout = LAYOUTS.col,
  name = 'PageWrapper',
  opts = {},
  title = null,
}: PageWrapperProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (title) dispatch(setPageTitle(title))
  }, [title])

  return (
    <div
      className={classNames(
        styles.base,
        {
          [styles.noPadding]: opts.noPadding,
          [styles.adminForm]: layout === LAYOUTS.adminForm,
          [styles.col]: layout === LAYOUTS.col,
          [styles.form]: layout === LAYOUTS.form,
          [styles.center]: layout === LAYOUTS.center,
          [styles.partial]: layout === LAYOUTS.partial,
        }
      )}
      id={name}
    >
      { children }
    </div>
  )
}

type PageWrapperProps = {
  children?: React.ReactNode,
  layout?: string,
  name: string,
  opts?: {
    noPadding?: boolean,
  },
  title?: string | null,
}

export default PageWrapper
