import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import Card from '../../cards/Card'
import ChallengeResults from '../results/ChallengeResults'
import LeagueResults from '../results/LeagueResults'
import LoadingContainer from '../../LoadingContainer'
import SubmissionResults from '../results/SubmissionResults'
import UserResults from '../results/UserResults'
import { ButtonWhiteInverted } from '../../../common/buttons/Button'
import { challengeType } from '../../../schemas/types/challenge'
import { leagueType } from '../../../schemas/types/league'
import { submissionType } from '../../../schemas/types/submission'
import { userType } from '../../../schemas/types/user'

import styles from './styles.mod.scss'

const getComponentForResultType = ({
  resultType,
  ...props
}: getComponentForResultTypeProps): JSX.Element | undefined => ({
  challenges: <ChallengeResults {...props} />,
  submissions: <SubmissionResults {...props} />,
  users: <UserResults {...props} />,
  leagues: <LeagueResults {...props} />,
}[resultType] || undefined)

const ResultType = ({
  resultType = '',
  onClick = () => {},
  selectedResultType = '',
}: ResultTypeProps) => (
  <ButtonWhiteInverted
    label={`Search for ${resultType}`}
    className={classNames(
      styles.result_type,
      { [styles.selected]: selectedResultType === resultType }
    )}
    onClick={() => onClick(resultType)}
    scale={0.6}
  >
    {resultType}
  </ButtonWhiteInverted>
)

const SearchBarResults = ({
  isLoading = false,
  onResultTypeChange = () => {},
  readyToFetch = true,
  resultTypes = [],
  results = [],
  selectedResultType = '',
}: SearchBarResultsProps) => {
  const [resultType, setResultType] = useState(selectedResultType)

  useEffect(() => {
    setResultType(selectedResultType)
  }, [isLoading])

  return (
    <Card className="SearchBarResults">
      <div className={styles.result_types}>
        {resultTypes.map((resType) => (
          <ResultType
            isLoading={isLoading}
            resultType={resType}
            selectedResultType={selectedResultType}
            onClick={onResultTypeChange}
            key={`ResultType__${resType}`}
          />
        ))}
      </div>

      <LoadingContainer
        isLoading={isLoading || !readyToFetch}
        hasContent={!!results?.length}
        resource={selectedResultType}
      >
        {getComponentForResultType({ isLoading, results, resultType })}
      </LoadingContainer>
    </Card>
  )
}

type ResultsType = Array<challengeType | leagueType | submissionType | userType>

type ResultTypeProps = {
  onClick: (resultType: string) => void
  resultType: string
  selectedResultType: string
}

type SearchBarResultsProps = {
  isLoading: boolean
  onResultTypeChange: (resultType: string) => void
  readyToFetch: boolean
  resultTypes: Array<string>
  results: ResultsType
  selectedResultType: string
}

type getComponentForResultTypeProps = Partial<{
  isLoading: boolean
  results: ResultsType
  resultType: 'challenges' | 'submissions' | 'users' | 'leagues'
}>

export default SearchBarResults
