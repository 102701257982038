import React from 'react'
import classNames from 'classnames'

import DialogProvider, { DialogContent, DialogTrigger, useDialog } from '@/components/Dialog'
import CommentForm from '@/components/forms/comments/CommentForm'
import CommentIcon from '@/assets/images/icons/comment-regular.svg'
import { ButtonPrimary } from '@/common/buttons/Button'
import { userAction } from '@/common/helpers'

import styles from './styles.mod.scss'

const AddCommentTitle = () => (
  <div className={styles.Title}>
    <span>Add Comment</span>
  </div>
)

const AddCommentDialog = ({
  Trigger = AddCommentTrigger,
  children,
  className = '',
  onConfirm = () => {},
  submission_id = undefined,
}: AddCommentDialogProps) => (
  <DialogProvider>
    <Trigger className={className} />
    <DialogContent
      className={styles.Content}
      dialogTitle={<AddCommentTitle />}
    >
      <AddCommentContent onConfirm={onConfirm} submission_id={submission_id}>
        {children}
      </AddCommentContent>
    </DialogContent>
  </DialogProvider>
)

const AddCommentContent = ({
  children,
  onConfirm = () => {},
  submission_id = undefined,
}: AddCommentContentProps) => {
  const { setIsOpen } = useDialog()

  const onSubmitSuccess = async (response) => {
    if (response) {
      setIsOpen(false)
    }
  }

  return (
    <CommentForm
      submission_id={submission_id}
      onSubmitSuccess={onSubmitSuccess}
    />
  )
}

export const AddCommentTrigger = ({
  onClick = () => {},
}: AddCommentTriggerProps) => (
  <DialogTrigger>
    <ButtonPrimary
      label="Add comment"
      className={classNames(styles.Trigger, styles.Button)}
      onClick={userAction(() => onClick())}
    >
      <CommentIcon />
      <span>Add Comment</span>
    </ButtonPrimary>
  </DialogTrigger>
)

type AddCommentDialogProps = {
  Trigger?: React.FC<AddCommentTriggerProps>
  children?: React.ReactNode
  className?: string
  onConfirm?: () => void
  submission_id?: number
}

type AddCommentContentProps = {
  children: React.ReactNode
  submission_id?: number
  onConfirm?: () => void
}

type AddCommentTriggerProps = {
  className?: string
  onClick?: () => void
}

export default AddCommentDialog
