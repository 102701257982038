import * as yup from 'yup'

export const ritualRewardSchema = yup.object({
  currency_id: yup.number()
    .required('Category required.'),
  value: yup.number()
    .min(1)
    .required('Value required.'),
  // ritual_id: yup.number()
  //   .required('Ritual required.'),
}).required()

export type ritualRewardSchemaType = yup.InferType<typeof ritualRewardSchema>
