import CategoriesResource from '../resources/Categories'
import { ApiQueries } from '..'
import { QueryParamsObject } from '../ApiQueries'

const CategoriesQuery = {
  getAdminIndex: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Categories', 'getAdminIndex', ApiQueries.queryParams(params)],
    queryFn: CategoriesResource.getAdminIndex,
    params,
  }),
  getSearch: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Categories', 'getSearch', ApiQueries.queryParams(params)],
    queryFn: CategoriesResource.getSearch,
    params,
  }),
  getShow: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Categories', 'getShow', ApiQueries.queryParams(params)],
    queryFn: CategoriesResource.getShow,
    params
  }),
  getIndex: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Categories', 'getIndex', ApiQueries.queryParams(params)],
    queryFn: CategoriesResource.getIndex,
    params,
  }),
}

export default CategoriesQuery
