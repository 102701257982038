import React from 'react'
import classNames from 'classnames'

import NotificationsQuery from '@/api/queries/Notifications'

import HeaderPopover, { HeaderPopoverContent, HeaderPopoverTrigger } from '../HeaderPopover'
import LoadingContainer from '@/components/LoadingContainer'
import NotificationCard from '@/components/cards/notifications/Card'

import NotificationsIcon from '@/assets/images/icons/bell-regular.svg'
import NotificationsIconActive from '@/assets/images/icons/bell-solid.svg'

import styles from './styles.mod.scss'

const NotificationsPopoverTrigger = () => (
  <HeaderPopoverTrigger
    ActiveIcon={<NotificationsIconActive />}
    InactiveIcon={<NotificationsIcon />}
    name="NotificationsPopoverTrigger"
  />
)

const NotificationsPopover = () => (
  <HeaderPopover
    Trigger={NotificationsPopoverTrigger}
    to="notifications/unread"
  >
    <NotificationsPopoverContent />
  </HeaderPopover>
)

const NotificationsPopoverContent = () => {
  const { data, isLoading } = NotificationsQuery.getIndex({ page: 1 })

  return (
    <HeaderPopoverContent
      className={styles.content}
      header="Notifications"
      to="/notifications/unread"
    >
      <LoadingContainer
        isLoading={isLoading}
        hasContent={!!data?.results?.length}
        resource="Notifications"
      >
        {data?.results?.map((notification) => (
          <div
            className={classNames(
              styles.item,
              {
                [styles.unread]: !notification.read,
                [styles.read]: notification.read,
              },
            )}
            key={notification.id}
          >
            <NotificationCard notification={notification} slim />
          </div>
        ))}
      </LoadingContainer>
    </HeaderPopoverContent>
  )
}

export default NotificationsPopover
