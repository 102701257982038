import React from 'react'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

// import { View } from 'arena-core'
import { cardOptsDefaults, cardOptsType } from '../Card'

import styles from './styles.mod.scss'

const CardLink = ({
  children,
  className = '',
  onClick = () => {},
  opts = cardOptsDefaults,
  to = '',
}: CardLinkProps) => (
  <NavLink
    className={classNames(styles.base, className)}
    onClick={onClick}
    to={to}
  >
    {opts.noContainer ? children : (
      // <View className={styles.container}>
      //   {children}
      // </View>
      <div className={styles.container}>
        {children}
      </div>
    )}
  </NavLink>
)

type CardLinkProps = {
  children?: React.ReactNode,
  className?: string,
  onClick?: () => void,
  opts?: cardOptsType,
  to: string,
}

export default CardLink
