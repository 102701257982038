export const notificationDefault: notificationType = {
  content: '',
  created_at: '',
  id: undefined,
  isRead: false,
  message: '',
  timestamp: '',
  title: '',
}

export type notificationType = {
  content: string
  created_at: string
  id: number | undefined
  isRead: boolean
  message: string
  timestamp: string
  title: string
}
