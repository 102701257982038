import ChallengesResource from '../resources/Challenges'
import { ApiQueries } from '..'
import { QueryParamsObject } from '../ApiQueries'

const ChallengesQuery = {
  getAdminIndex: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Challenges', 'getAdminIndex', ApiQueries.queryParams(params)],
    queryFn: ChallengesResource.getAdminIndex,
    params,
  }),
  getSearch: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Challenges', 'getSearch', ApiQueries.queryParams(params)],
    queryFn: ChallengesResource.getSearch,
    params,
  }),
  getShow: (params: QueryParamsObject) => ApiQueries.constructQuery({
    queryKey: ['Challenges', 'getShow', ApiQueries.queryParams(params)],
    queryFn: ChallengesResource.getShow,
    params,
  }),
  getInfiniteSubmissions: (params: QueryParamsObject) => ApiQueries.constructInfiniteQuery({
    queryKey: ['Challenges', 'getInfiniteSubmissions', ApiQueries.queryParams(params)],
    queryFn: ChallengesResource.getSubmissions,
    params,
  }),
  getInfiniteIndex: (params: QueryParamsObject) => ApiQueries.constructInfiniteQuery({
    queryKey: ['Challenges', 'getInfiniteIndex', ApiQueries.queryParams(params)],
    queryFn: ChallengesResource.getIndex,
    params,
  }),
}

export default ChallengesQuery
