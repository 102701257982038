import MediaUploader from '@/components/forms/MediaUploader'
import FormError from '../FormError'
import { useFormPrimary } from '../..'
import classNames from 'classnames'
import styles from './styles.mod.scss'

const FormFile = ({
  className = '',
  currentFile = undefined,
  mediaType = 'image',
  onFileAdded = () => {},
  onUpload = () => {},
  name = '',
} : FormFileProps) => {
  const { errors, register, setValue } = useFormPrimary()

  return (
    <div className={classNames(styles.file, className)}>
      <input {...register(name)} type="hidden" />
      <MediaUploader
        currentFile={currentFile}
        onClear={() => setValue(name, null)}
        onUploadComplete={onUpload}
        onFileAdded={onFileAdded}
        mediaType={mediaType}
      />
      <FormError present={errors[name]}>
        {errors[name]?.message}
      </FormError>
    </div>
  )
}

type FormFileProps = {
  className?: string
  currentFile?: string
  label?: string
  mediaType?: string
  name: string
  onFileAdded?: () => void
  onUpload?: (data: File) => void
}

export default FormFile
