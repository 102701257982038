import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import RulesQuery from '@/api/queries/Rules'

import Badge from '@/components/badges/Badge'
import LoadingContainer from '@/components/LoadingContainer'
import RuleForm from '@/components/forms/rules/RuleForm'
import RuleSectionForm from '@/components/forms/rules/RuleSectionForm'
import RulesMarkdown from '@/components/RulesMarkdown'
import { ButtonSecondaryInverted } from '@/common/buttons/Button'
import { ruleDefault, ruleType } from '@/schemas/types/rule'
import { ruleSectionType } from '@/schemas/types/ruleSection'
import { scrollToTop } from '@/components/layouts/NavigationActions'
import { setAdminFooterMode, setAdminFooterType, setIsAdminFooterEnabled } from '@/store/adminFooter'
import { setPageTitle } from '@/store/pages'

import TimesIcon from '@/assets/images/icons/times-circle-solid.svg'
import EditIcon from '@/assets/images/icons/pencil-regular.svg'

import styles from './styles.mod.scss'

const PreviewMode = ({
  enabled = false,
  rule = ruleDefault,
  ruleSections = [],
}: PreviewModeProps) => {
  if (!enabled) return null

  return (
    <div className={styles.PreviewMode}>
      <Badge color="grey">preview mode</Badge>
      <RulesMarkdown rule={rule} ruleSections={ruleSections} />
    </div>
  )
}

const AdminRulesEdit = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { adminFooterMode } = useSelector((state) => state.adminFooter)
  const { data, isLoading } = RulesQuery.getShow({ id })
  const { rule_sections } = data || ruleDefault

  const [activeSection, setActiveSection] = useState(null)
  const [sectionFormActive, setSectionFormActive] = useState(false)

  const addSection = () => {
    setActiveSection(null)
    setSectionFormActive(true)
  }

  const onSectionClick = (section) => {
    setActiveSection(section)
    setSectionFormActive(true)
  }

  const afterMutation = () => {
    scrollToTop()
    setSectionFormActive(false)
    setActiveSection(null)
  }

  const onKeyDown = (e) => {
    if (e.key === 'Escape') setSectionFormActive(false)
  }

  useEffect(() => {
    dispatch(setPageTitle('Edit Rule'))
    dispatch(setAdminFooterType('viewModeButton'))
    dispatch(setIsAdminFooterEnabled(true))

    document.addEventListener('keydown', onKeyDown, true)

    return () => {
      document.removeEventListener('keydown', onKeyDown, true)
      dispatch(setAdminFooterMode('edit'))
    }
  }, [])

  useEffect(() => {
    if (sectionFormActive) {
      document.querySelector('input#RuleSectionForm__title')?.focus()
    } else {
      setActiveSection(null)
    }
  }, [activeSection, sectionFormActive])

  return (
    <>
      <PreviewMode
        enabled={adminFooterMode === 'preview'}
        rule={data}
        ruleSections={rule_sections}
      />
      <div
        className={classNames(
          styles.edit_mode,
          { [styles.active]: adminFooterMode === 'edit' }
        )}
      >
        <LoadingContainer
          isLoading={isLoading}
          resource="rules"
          hasContent={!!data}
        >
          <RuleForm rule={data} />
          <div className={classNames('AssociationForms', styles.AssociationForms)}>
            <h2>Sections</h2>
            <div className={classNames('AssociationForm__container', styles.AssociationForm__container)}>
              {rule_sections.map((section) => (
                <button
                  aria-label="Edit rule"
                  className={classNames(
                    styles.RuleSection__container,
                    { active: activeSection === section }
                  )}
                  onClick={() => onSectionClick(section)}
                  key={`AssociationForm--${section.id}`}
                >
                  {section.title}
                  <EditIcon />
                </button>
              ))}
            </div>
            {sectionFormActive ? (
              <div className={styles.RuleSectionForm__container}>
                <h2>{`${activeSection ? 'Edit' : 'Add'} Section`}</h2>
                <button
                  aria-label="Close edit section"
                  className={styles.RuleSectionForm__close}
                  onClick={() => setSectionFormActive(false)}
                >
                  <TimesIcon />
                </button>
                {activeSection ? (
                  <RuleSectionForm
                    afterMutation={afterMutation}
                    rule={data}
                    ruleSection={activeSection}
                    updateText="Update Section"
                  />
                ) : (
                  <RuleSectionForm
                    rule={data}
                    afterMutation={afterMutation}
                    updateText="Add Section to Rule"
                  />
                )}
              </div>
            ) : null}
            {!sectionFormActive ? (
              <ButtonSecondaryInverted label="Add section" scale={0.85} onClick={addSection}>
                Add Section
              </ButtonSecondaryInverted>
            ) : null}
          </div>
        </LoadingContainer>
      </div>
    </>
  )
}

type PreviewModeProps = {
  enabled: boolean,
  rule: ruleType,
  ruleSections: ruleSectionType[],
}

export default AdminRulesEdit
