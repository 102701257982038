import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'

import ChallengesResource from '@/api/resources/Challenges'
import LeaguesResource from '@/api/resources/Leagues'
import RitualsResource from '@/api/resources/Rituals'
import SubmissionsResource from '@/api/resources/Submissions'
import UsersResource from '@/api/resources/Users'
import { ApiConfig } from '@/api'

import SearchBarResults from '../SearchbarResults'

import SearchIcon from '@/assets/images/icons/search.svg'

import styles from './styles.mod.scss'

const authPaths = [
  '/users/login',
  '/users/signup',
  '/users/password/reset',
  '/users/password/edit',
]

const resultTypes = [
  'challenges',
  'leagues',
  'submissions',
  'users',
]

const resourceFetchFn = {
  challenges: ChallengesResource.getSearch,
  leagues: LeaguesResource.getSearch,
  rituals: RitualsResource.getSearch,
  submissions: SubmissionsResource.getSearch,
  users: UsersResource.getSearch,
}

const restrictedListenerElements = ['input']

// const isAuthPath = (pathname) => (!pathname.split('/')[1].includes('users'))
const isAuthPath = (pathname) => (authPaths.includes(pathname))

const SearchBar = () => {
  const location = useLocation()
  const { pathname } = location
  const searchBarInputRef = useRef(null)
  const searchBarRef = useRef(null)

  const [challenges, setChallenges] = useState([])
  const [isActive, setIsActive] = useState(!isAuthPath(pathname))
  const [leagues, setLeagues] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [readyToFetch, setReadyToFetch] = useState(true)
  const [results, setResults] = useState([])
  const [rituals, setRituals] = useState([])
  const [selectedResultType, setSelectedResultType] = useState(resultTypes[0])
  const [submissions, setSubmissions] = useState([])
  const [users, setUsers] = useState([])

  // Must use useMemo because the component re-rendering will create a new function:
  // https://stackoverflow.com/a/61786423/3550183

  const fetchResults = async () => {
    const response = await resourceFetchFn[selectedResultType]({ query })

    ApiConfig.successfulResponseCallback(response, () => {
      setSelectedResults(selectedResultType, response.data.results)
    })
  }

  const onEscKey = (e) => {
    if (
      searchBarRef.current.contains(document.activeElement)
        && e.key === 'Escape'
    ) {
      document.activeElement.blur()
    }
  }

  const onForwardSlashKey = (e) => {
    const { tagName } = document.activeElement
    const restricted = restrictedListenerElements.indexOf(tagName.toLowerCase()) > -1

    if (!restricted && e.key === '/') {
      searchBarInputRef.current?.focus()
      e.preventDefault()
    } else if (restricted && e.key === 'Escape') {
      searchBarInputRef.current.blur()
    }
  }

  const setResultsForType = (resultType) => ({
    challenges: setResults(challenges),
    rituals: setResults(rituals),
    leagues: setResults(leagues),
    submissions: setResults(submissions),
    users: setResults(users),
  }[resultType])

  const setSelectedResults = (resultType, res) => ({
    challenges: setChallenges(res),
    leagues: setLeagues(res),
    rituals: setRituals(res),
    submissions: setSubmissions(res),
    users: setUsers(res),
  }[resultType])

  useEffect(() => {
    document.addEventListener('keydown', onForwardSlashKey, false)

    return () => document.removeEventListener('keydown', onForwardSlashKey, false)
  }, [])

  useEffect(() => {
    setResultsForType(selectedResultType)
  }, [challenges, leagues, submissions, users])

  useEffect(() => {
    if (isLoading) setReadyToFetch(true)
  }, [isLoading])

  useEffect(() => {
    if (document.activeElement.classList.value.includes('ResourceResult')) {
      document.activeElement.blur()
    }
    setIsActive(!isAuthPath(pathname))
  }, [location])

  useEffect(() => {
    setIsLoading(false)
  }, [results])

  useEffect(() => {
    setIsLoading(true)
    if (query.length > 2 && readyToFetch) {
      setResults([])
      fetchResults()
    } else {
      setResults([])
    }
  }, [query, readyToFetch, selectedResultType])

  useEffect(() => {
    document.addEventListener('keydown', onEscKey)

    return () => document.removeEventListener('keydown', onEscKey)
  }, [searchBarRef])

  return (
    <div
      className={classNames('SearchBar', { [styles.active]: isActive })}
      ref={searchBarRef}
    >
      <form className={styles.form} onSubmit={(e) => e.preventDefault()}>
        <SearchIcon />
        <input
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search DZASTR Arena"
          ref={searchBarInputRef}
          type="text"
          value={query}
        />
      </form>
      <SearchBarResults
        isLoading={isLoading}
        onResultTypeChange={(type) => setSelectedResultType(type)}
        readyToFetch={readyToFetch}
        resultTypes={resultTypes}
        results={results}
        selectedResultType={selectedResultType}
      />
    </div>
  )
}

export default SearchBar
