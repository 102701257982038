import React from 'react'
import classNames from 'classnames'

import styles from './styles.mod.scss'

const PageHero = ({
  children,
  className = '',
  isDefault = false,
  isImage = false,
}: PageHeroProps) => (
  <div
    className={classNames(
      styles.wrapper,
      className,
      { [styles.hero_default]: isDefault }
    )}
  >
    {children}
    {isImage ? (<div className={styles.overlay} />) : null}
  </div>
)

type PageHeroProps = {
  children: React.ReactNode,
  className?: string,
  isDefault?: boolean,
  isImage?: boolean,
}

export default PageHero
