import React, { PropsWithChildren } from 'react'

import FilterIcon from '../../assets/images/icons/sliders-h-regular.svg'

import styles from './styles.mod.scss'

const Filters = ({ children }: PropsWithChildren) => (
  <div className={styles.base}>
    <div className={styles.head}>
      <FilterIcon />
      <h2>Filter</h2>
    </div>
    {children}
  </div>
)

export default Filters
